import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import App from './App';
import { commonOperations } from './main-wrapper/duck';
import { storyboardOperations } from './storyboard/duck';
import { enqueueSnackbar } from './snackbar/duck/actions';

const mapStateToProps = state => ({
  ...state.common,
});

const mapDispatchToProps = dispatch => {
  const updateUploadData = (id, data) => {
    return dispatch(commonOperations.updateUploadData(id, data));
  };

  const removeUploadData = data => {
    return dispatch(commonOperations.removeUploadData(data));
  };

  const sendUploadStatus = (request, method) =>
    storyboardOperations.getStoryboardBucket(request, method);

  const toggleSidebar = data => dispatch(commonOperations.sidebarToggle(data));

  const toggleToast = data => dispatch(enqueueSnackbar(data));

  return {
    updateUploadData,
    removeUploadData,
    sendUploadStatus,
    toggleSidebar,
    toggleToast,
  };
};

const AppContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App)
);

export default AppContainer;
