import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { TextField, useForm } from '../FormInputs';
import { accountSettingsService } from '../../portal/duck/operations';

// Helper Functions
import {
  noSpaces,
  password,
  charactersLengthCheck,
} from '../FormInputs/helperFunctions';
import getEncrypted from '../../../utils/encrytion';

// Styles
import { Text } from '../../styles/common';
import { colors } from '../../styles/variables';
import { AppLinkButton } from '../../storyboard/components/CloudAccess/styles';
import { onInputChangeCharactersLimit } from '../../helperFunctions';

const INITIAL_STATE = {
  existingPassword: {
    value: '',
    error: undefined,
    required: true,
    validation: charactersLengthCheck,
  },

  newPassword: {
    value: '',
    error: undefined,
    required: true,
    validation: password,
  },

  confirmNewPassword: {
    value: '',
    error: undefined,
    required: true,
    validation: charactersLengthCheck,
  },
};

const INITIAL_MESSAGE_ERROR_STATE = {
  error: null,
  message: null,
};

// const selectedOptions = [
//   {
//     disabled: false,
//     label: 'Immediately',
//     value: 'immediately',
//   },
//   {
//     disabled: false,
//     label: 'Sometimes',
//     value: 'sometimes',
//   },
//   {
//     disabled: false,
//     label: 'All the time',
//     value: 'all',
//   },
// ];

const ChangePassword = ({ toggleToast, history }) => {
  const userDetails = useSelector(store => store?.common?.userDetails);
  const [state, setState] = useState(INITIAL_MESSAGE_ERROR_STATE);
  const [isSubmitting, setSubmitting] = useState(false);
  const { onChange, formValues, updateValues, onSubmit } = useForm(
    INITIAL_STATE,
    () => {
      const existingPassword = getEncrypted(
        formValues?.existingPassword?.value?.trim()
      );
      const newPassword = getEncrypted(formValues?.newPassword?.value?.trim());
      const confirmPassword = getEncrypted(
        formValues?.confirmNewPassword?.value?.trim()
      );
      setSubmitting(true);
      accountSettingsService({
        firstname: userDetails?.first_name,
        lastname: userDetails?.last_name,
        existing_password: existingPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      })
        .then(res => {
          setSubmitting(false);
          const {
            data: { response, data, errormessage },
          } = res;
          if (response) {
            history.push('/logout/');
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody: data?.message,
                variant: 'success',
              },
            });
          } else {
            throw new Error(errormessage);
          }
        })
        .catch(err => {
          setSubmitting(false);
          setState(prev => ({ ...prev, error: err?.message }));
        });
    }
  );

  const handleOnChange = e => {
    // text field onChange
    setState(INITIAL_MESSAGE_ERROR_STATE);
    onChange(e);
  };

  const onPasswordChange = e =>
    onInputChangeCharactersLimit(e, () => handleOnChange(e));

  const onConfirmPasswordChange = e => {
    if (
      e.target.value.length > 0 &&
      e.target.value.length <= 100 &&
      e?.target?.value !== formValues?.newPassword?.value
    ) {
      setState(INITIAL_MESSAGE_ERROR_STATE);
      updateValues({
        ...formValues,
        confirmNewPassword: {
          ...formValues.confirmNewPassword,
          value: e.target.value,
          error: 'Password does not match',
        },
      });
    } else {
      onPasswordChange(e);
    }
  };

  return (
    <>
      <Text paddingLeft='0' paddingRight='0'>
        Change Password
      </Text>

      <form id='change-password' name='change-password'>
        <TextField
          id='existingPassword'
          label='Existing Password'
          type={formValues.existingPassword.show ? 'text' : 'password'}
          value={formValues.existingPassword.value}
          onChange={onPasswordChange}
          onKeyPress={noSpaces}
          error={!!formValues.existingPassword.error}
          helperText={formValues.existingPassword.error}
          style={{ maxWidth: '34rem' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    updateValues({
                      ...formValues,
                      existingPassword: {
                        ...formValues.existingPassword,
                        show: !formValues.existingPassword.show,
                      },
                    });
                  }}
                >
                  {formValues.existingPassword.show ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id='newPassword'
          label='New Password'
          type={formValues.newPassword.show ? 'text' : 'password'}
          value={formValues.newPassword.value}
          onChange={onPasswordChange}
          onKeyPress={noSpaces}
          error={!!formValues.newPassword.error}
          helperText={formValues.newPassword.error}
          style={{ maxWidth: '34rem' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    updateValues({
                      ...formValues,
                      newPassword: {
                        ...formValues.newPassword,
                        show: !formValues.newPassword.show,
                      },
                    });
                  }}
                >
                  {formValues.newPassword.show ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id='confirmNewPassword'
          label='Confirm New Password'
          type={formValues.confirmNewPassword.show ? 'text' : 'password'}
          value={formValues.confirmNewPassword.value}
          onChange={onConfirmPasswordChange}
          onKeyPress={noSpaces}
          error={!!formValues.confirmNewPassword.error}
          helperText={formValues.confirmNewPassword.error}
          style={{ maxWidth: '34rem' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    updateValues({
                      ...formValues,
                      confirmNewPassword: {
                        ...formValues.confirmNewPassword,
                        show: !formValues.confirmNewPassword.show,
                      },
                    });
                  }}
                >
                  {formValues.confirmNewPassword.show ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {state.error && (
          <Text
            color={colors.error}
            medium
            style={{ textAlign: 'left' }}
            paddingLeft='0'
            paddingRight='0'
          >
            {state.error}
          </Text>
        )}
      </form>

      <AppLinkButton
        disabled={isSubmitting}
        onClick={onSubmit}
        type='button'
        style={{ marginTop: '1rem' }}
      >
        Update Password
      </AppLinkButton>

      {/* <Text bg lineHeight='2.5rem' paddingLeft='0' paddingRight='0'>
          Choose the email frequency you want
        </Text>
        <Select
          id='emailFrequency'
          label='Email Frequency'
          options={selectedOptions}
          value={selectedOptions[0].value}
          onChange={() => console.log('onchange')}
        /> */}
    </>
  );
};

ChangePassword.propTypes = {
  history: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
};

export default ChangePassword;
