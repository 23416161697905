import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';

// Helper Functions
import { serviceCallBasedOnMethod } from '../../helperFunctions';

export const doLogin = (data, method = 'post') =>
  serviceCallBasedOnMethod(`${apiConfig.auth}/login/`, data, method);

const doSignUp = (request, method = 'get') =>
  serviceCallBasedOnMethod(`${apiConfig.auth}/signup/`, request, method);

const doLogout = data => serviceCall.post(`${apiConfig.auth}/logout/`, data);

const doForgotPassword = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/recover_password/`,
    request,
    method
  );

const doVerifyEmail = data =>
  serviceCall.get(`${apiConfig.auth}/verify/email/`, data);

const isUserLoggedIn = data =>
  serviceCall.get(`${apiConfig.auth}/checklogin/`, data);

const checkHostUser = data =>
  serviceCall.post(`${apiConfig.project}/web/app/authentication/`, data);

const getSubscribedPlanDetails = data =>
  serviceCall.get(`${apiConfig.auth}/subscription/`, data);

export const twoFactorAuthenticationService = (
  request,
  method = 'post',
  authenticationFor = 'account'
) =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/${authenticationFor}/two-factor-authentication/`,
    request,
    method
  );

export const accountSettingsService = (request, method = 'post') =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/account/settings/`,
    request,
    method
  );

export const freshSupportChatService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/account/support-chat/`,
    request,
    method
  );

export default {
  doLogin,
  doSignUp,
  doLogout,
  doForgotPassword,
  isUserLoggedIn,
  doVerifyEmail,
  checkHostUser,
  getSubscribedPlanDetails,
  freshSupportChatService,
};
