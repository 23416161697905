/* eslint-disable max-len */
/* eslint-disable no-console */
import Creators, { resetState } from './actions';
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';
// Helper
import { serviceCallBasedOnMethod } from '../../helperFunctions';

const {
  actionLoadAssets,
  actionLoadDetails,
  actionListView,
  loadCollaboratedUsers,
  loadSelectedTaskDetails,
  loadAllTaskDetails,
  updateAssetsData,
  loadCloudAccessSystemDetails,
  assignedTaskFromHome,
  // eslint-disable-next-line import/no-named-as-default-member
  setImportAssetStatus,
} = Creators;

const setListView = type => dispatch => {
  dispatch(actionListView(type));
};

const createStoryboard = request =>
  serviceCall.post(`${apiConfig.project}/`, request);

// this will get storyboard assets
const fetchAssets = (request, type) => dispatch => {
  return serviceCall
    .get(`${apiConfig.project}/`, request)
    .then(res => {
      if (res.data.response) {
        dispatch(actionLoadAssets(res.data.data, type));
      } else {
        dispatch(actionLoadAssets(res.data, type));
      }
    })
    .catch(err => {
      console.log(err, 'get storyboard assets');
    });
};

const deleteStoryboard = request =>
  serviceCall.delete(`${apiConfig.project}/`, request);
const deleteAsset = request =>
  serviceCall.delete(`${apiConfig.project}/asset/`, request);

// this will get storyboard details
const fetchDetails = request => dispatch => {
  serviceCall
    .get(`${apiConfig.project}/info/`, request)
    .then(res => {
      dispatch(actionLoadDetails(res.data.data));
      if (window?.fcWidget) {
        window.fcWidget.user.setProperties({
          cf_project_id: res?.data?.data?.project_id,
          //  ...(cloudMachineDetails?.cloud_assignment_id && {
          //    cf_cloud_session_id: cloudMachineDetails?.cloud_assignment_id,
          //  }),
        });
      }
    })
    .catch(err => {
      console.log(err, 'get storyboard details');
    });
};

// this will get storyboard collaborated users
const fetchCollaborators = request =>
  serviceCall.get(`${apiConfig.project}/collaborated/users/`, request);

// this will update collaborate users
const updateCollaboratedUsers = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/collaborators/`,
    request,
    method
  );

// this will add / update brief
const sendBrief = request => () =>
  serviceCall.put(`${apiConfig.project}/`, request);

// to fetch all task types
const fetchTaskTypes = request =>
  serviceCall.get(`${apiConfig.project}/task/types/`, request);

// this will add / update brief
const fetchAssignToUsers = request =>
  serviceCall.get(`${apiConfig.project}/users/`, request);

// to create task
const createTask = request => () =>
  serviceCall.post(`${apiConfig.project}/task/`, request);

export const getStoryboardBucket = (request, method = 'post') =>
  serviceCallBasedOnMethod(
    `${apiConfig.upload}/upload/assets/`,
    request,
    method
  );

const updateAssets = (data, type) => dispatch => {
  dispatch(updateAssetsData(data, type));
};

// to create / update task
const createUpdateTask = (request, method) =>
  serviceCallBasedOnMethod(`${apiConfig.project}/task/`, request, method);

// to fetch request details
const taskRequest = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/task/request/`,
    request,
    method
  );

// as name says - fetching users based on filters based in request
export const fetchUsersBasedOnFilters = request =>
  serviceCall.get(`${apiConfig.project}/users/`, request);

// to fetch system resources
const fetchSystemResources = request =>
  serviceCall.get(`${apiConfig.project}/request/resource/`, request);

const fetchSystems = request =>
  serviceCall.get(`${apiConfig.project}/request/resource/system/`, request);

export const fetchCloudSoftwares = request =>
  serviceCall.get(`${apiConfig.project}/softwares/`, request);

export const fetchCloudHardwares = request =>
  serviceCall.get(`${apiConfig.project}/hardwares/`, request);

export const fetchCloudSoftwaresPlugins = request =>
  serviceCall.get(`${apiConfig.project}/softwares/plugins/`, request);

export const s3SyncedFiles = request =>
  serviceCall.get(`${apiConfig.project}/s3/synced/files/`, request);

export const liveCollaborationServiceCall = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/live/collaboration/`,
    request,
    method
  );

export const startAudioCallService = (request, method = 'post') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/live/collaboration/audio/call/`,
    request,
    method
  );

const isResourceAvailable = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/request/resource/check/`,
    request,
    method
  );

export const systemSession = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/request/resource/session/`,
    request,
    method
  );

// to extend the existing session
const extendSession = request =>
  serviceCall.post(
    `${apiConfig.project}/request/resource/session/extend/`,
    request
  );

// to get download link of connector app
const downloadNiceDcvSdkService = request =>
  serviceCall.get(`${apiConfig.project}/connector/app/`, request);

// to create or cancel queue request
const systemInQueue = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/request/resource/queue/`,
    request,
    method
  );

// to transfer the task
const transferTaskRequest = request =>
  serviceCall.post(`${apiConfig.project}/task/transfer/`, request);

// to complete the ongoing task
const completeTaskRequest = request =>
  serviceCall.post(`${apiConfig.project}/task/complete/`, request);

// to get, put , post member permissions of team members, collaborators
const memberPermissions = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/permissions/`,
    request,
    method
  );

// to share the asset
const shareAsset = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/share/asset/`,
    request,
    method
  );
const shareAssetSettings = request =>
  serviceCall.get(`${apiConfig.project}/share/settings/`, request);

export const getUploaderAppService = request =>
  serviceCall.get(`${apiConfig.project}/uploader/app/`, request);

// Saving data actions
const loadSelectedTask = data => dispatch =>
  dispatch(loadSelectedTaskDetails(data));
const loadDetails = data => dispatch => dispatch(actionLoadDetails(data)); // set updated brief in reducer;
const loadAssets = (data, type) => dispatch =>
  dispatch(actionLoadAssets(data, type)); // set updated brief in reducer;
const loadAllTask = data => dispatch => dispatch(loadAllTaskDetails(data));
export const loadCloudAccessDetails = data => dispatch =>
  dispatch(loadCloudAccessSystemDetails(data));
const loadCollaborators = data => dispatch =>
  dispatch(loadCollaboratedUsers(data));
const loadAssignedTaskFromHome = data => dispatch =>
  dispatch(assignedTaskFromHome(data));

// To add / get task messages
const addGetTaskMessages = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/task/messages/`,
    request,
    method
  );

const loadImportAssetStatus = data => dispatch =>
  dispatch(setImportAssetStatus(data));

export const getImportAssetStatusService = request =>
  serviceCall.get(`${apiConfig.project}/s3/synced/files/`, request);

const clearData = action => {
  return dispatch => {
    dispatch(resetState(action));
  };
};

export const getProjectsFolderCopyMoveService = request =>
  serviceCall.get(`${apiConfig.project}/asset/folders/detail/`, request);

export const copyMoveAssetService = request =>
  serviceCall.post(`${apiConfig.project}/asset/copy-move/`, request);

export const getProjectChatPreviousMessagesService = request =>
  serviceCall.get(`${apiConfig.project}/chat/`, request);

export const collaboratorsBadgeService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/collaborators/badge/`,
    request,
    method
  );

export const getProjectFilters = request =>
  serviceCall.get(`${apiConfig.project}/filters/`, request);

export const updateAssetMetatdataService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/asset/metadata/`,
    request,
    method
  );

export const exportToSprinklrService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/export/to/sprinklr/`,
    request,
    method
  );

export const getCameraModelService = request =>
  serviceCall.get(`${apiConfig.project}/camera/details/`, request);

export const getCameraFoldersService = request =>
  serviceCall.get(`${apiConfig.project}/c2e/folders/`, request);

export const getSourceDestinationFolders = request =>
  serviceCall.get(`${apiConfig.project}/folders/details/`, request);

export const getCameraUploadFilesListService = request =>
  serviceCall.get(`${apiConfig.project}/c2e/upload/files/`, request);

export const cameraSessionsService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/c2e/sessions/`,
    request,
    method
  );

export const getAssetFolderSize = request =>
  serviceCall.post(`${apiConfig.project}/calculate/folder/size/`, request);

export const systemReportService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/report/cloud/session/error/`,
    request,
    method
  );

export default {
  createStoryboard,
  setListView,
  fetchDetails,
  fetchAssets,
  sendBrief,
  loadDetails,
  loadAssets,
  fetchAssignToUsers,
  createTask,
  getStoryboardBucket,
  updateAssets,
  createUpdateTask,
  updateCollaboratedUsers,
  loadSelectedTask,
  loadAllTask,
  fetchTaskTypes,
  taskRequest,
  fetchUsersBasedOnFilters,
  deleteAsset,
  deleteStoryboard,
  fetchSystemResources,
  fetchSystems,
  isResourceAvailable,
  systemSession,
  loadCloudAccessDetails,
  extendSession,
  systemInQueue,
  fetchCollaborators,
  loadCollaborators,
  transferTaskRequest,
  completeTaskRequest,
  clearData,
  memberPermissions,
  shareAsset,
  shareAssetSettings,
  addGetTaskMessages,
  loadAssignedTaskFromHome,
  downloadNiceDcvSdkService,
  getUploaderAppService,
  loadImportAssetStatus,
};
