/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';
import { getTotalPluginsPrice } from '../../helperFunctions';
import Timer from '../../../components/timer';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { Text } from '../../../styles/common';
import { CloudScreenHead } from './styles';

const InQueue = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    type,
    cloudAccessDetails,
    createCancelQueueRequest,
    closeSidebar,
    config,
    toggleToast,
    selectedSoftware,
    selectedPlugin,
    task: { task_db_id },
    match: {
      params: { project_db_id },
    },
  } = props;

  const inQueueGranted =
    cloudAccessDetails &&
    (cloudAccessDetails.machine_status === 'machine_inqueue' ||
      cloudAccessDetails.machine_status === 'machine_available'); // to check if user is in queue system
  const dataBasedOnDetails = inQueueGranted ? cloudAccessDetails : config; // single source of props based on system type

  const onSubmit = async () => {
    setSubmitting(true);
    const createCancelResponse = await createCancelQueueRequest(
      {
        project_db_id,
        task_db_id,
        cloud_resource_queue_id: dataBasedOnDetails.cloud_resource_queue_id,
      },
      'put'
    );

    const {
      data: { response, errormessage },
    } = createCancelResponse;
    setSubmitting(false);

    if (response) {
      closeSidebar();
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        {dataBasedOnDetails.configurations && (
          <GradientCard
            {...type}
            details={[
              selectedSoftware?.title,
              `${dataBasedOnDetails?.configurations?.title}`,
              `₹${Number(
                getTotalPluginsPrice(
                  selectedPlugin || dataBasedOnDetails?.plugins
                ) + dataBasedOnDetails?.configurations?.pricing
              )} / hr`,
            ]}
          />
        )}

        {(selectedPlugin || dataBasedOnDetails?.plugins)?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm
          >
            Add-on Plug-ins:{' '}
            {(selectedPlugin || dataBasedOnDetails?.plugins)
              .map(el => el?.title)
              .join(', ')}
          </Text>
        )}

        <CloudScreenHead
          bg
          style={{
            marginTop:
              (selectedPlugin || dataBasedOnDetails?.plugins)?.length > 0
                ? '0rem'
                : '2rem',
          }}
        >
          Your request for the required system is registered
        </CloudScreenHead>

        {dataBasedOnDetails.machine_status === 'machine_inqueue' && (
          <div style={{ marginTop: '2rem' }}>
            <Text>System availability time</Text>
            <div style={{ margin: '1rem 0' }}>
              <Timer
                startTime={dataBasedOnDetails.current_time}
                endTime={dataBasedOnDetails.session_end_datetime}
              />
            </div>
          </div>
        )}

        <Text>
          Cloud ticket reference - {dataBasedOnDetails.cloud_queue_id}
        </Text>
      </Body>

      <Footer>
        <Normal onClick={onSubmit} isLoading={isSubmitting}>
          Cancel Request
        </Normal>
      </Footer>
    </>
  );
};

InQueue.propTypes = {
  config: PropTypes.instanceOf(Object),
  closeSidebar: PropTypes.func,
  createCancelQueueRequest: PropTypes.func,
  toggleToast: PropTypes.func,
  cloudAccessDetails: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object),
  selectedSoftware: PropTypes.instanceOf(Object),
  selectedPlugin: PropTypes.instanceOf(Object),
  type: PropTypes.instanceOf(Object),
};

export default InQueue;
