import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import CreateEdit from './CreateEdit';
import List from './List';
import Details from './Details';

import { SPRINKLR_STEPS } from '../../../constants/integration';

const S3Integration = ({ toggleSidebar, closeSidebar, toggleToast }) => {
  const [selectedIntegration, setIntegration] = useState(null);
  const [screen, setScreen] = useState(SPRINKLR_STEPS.list);

  const COMMON_PROPS = {
    goTo: step => setScreen(step),
    toggleSidebar,
    closeSidebar,
    toggleToast,
    setIntegration,
    selectedIntegration,
  };

  const renderScreen = index => {
    let component = <CreateEdit {...COMMON_PROPS} />;

    switch (index) {
      case SPRINKLR_STEPS.list: {
        component = <List {...COMMON_PROPS} />;
        break;
      }

      case SPRINKLR_STEPS.details: {
        component = <Details {...COMMON_PROPS} />;
        break;
      }

      default:
        break;
    }

    return component;
  };

  return <>{renderScreen(screen)}</>;
};

S3Integration.propTypes = {
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
};

export default S3Integration;
