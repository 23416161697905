import types from './types';

const updatePusher = (data, trayName, type) => {
  return {
    type: types.UPDATE_PUSHER_DATA,
    payload: {
      data,
      trayName,
      type,
    },
  };
};

const resetState = () => {
  return {
    type: types.RESET_STATE,
  };
};

const updateTrayData = (payload) => {
  return {
    type: types.UPDATE_TRAY_DATA,
    payload,
  };
};

const loadProgramStructure = (payload) => {
  return {
    type: types.FETCH_PROGRAM_STRUCTURE,
    payload,
  };
};

const loadProgramData = (payload) => {
  return {
    type: types.FETCH_PROGRAM_DATA,
    payload,
  };
};

export default {
  updatePusher,
  resetState,
  updateTrayData,
  loadProgramStructure,
  loadProgramData,
};
