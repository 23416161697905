/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// Components
import { Normal } from '../../components/Button';
import { selectItemsAction } from '../duck/actions';

import { SELECTION_KEYS } from '../constants';
import Mixpanel from '../../../utils/mixpanel';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';
import { fs } from '../../styles/variables';

const FilesGroupStyles = styled.ul`
  margin-left: 1.5rem;
  paddng-right: 1.5rem;
`;

const FileGroupItemStyles = styled.li`
  font-size: ${fs.sm};
  line-height: 1.4;
  font-weight: 300;
  overflow-wrap: break-word;
`;

const DeleteAsset = props => {
  const { project_db_id } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const selectedItems = useSelector(store => store?.storyboard?.selectedItems);
  const dispatch = useDispatch();

  const { toggleModal, toggleToast, deleteAsset, assetDetails } = props;

  const handleDelete = async () => {
    Mixpanel.track('Delete Asset', {
      title: assetDetails?.title ?? 'Delete Multiple Assets',
      file_type: assetDetails?.file_type ?? 'multiple',
    });
    setSubmitting(true);

    const deleteResponse = await deleteAsset({
      project_db_id,
      ingest_db_ids: assetDetails?.isMultiple
        ? Object.keys(selectedItems)
        : [assetDetails?.ingest_db_id],
      comment: 'Delete Asset',
    });

    const {
      data: { response, errormessage },
    } = deleteResponse;

    setSubmitting(false);

    if (response) {
      toggleModal(null, false, null);

      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Asset deleted successfully',
          variant: 'success',
        },
      });

      if (assetDetails?.isMultiple) {
        dispatch(
          selectItemsAction({
            type: SELECTION_KEYS.reset,
          })
        );
      }
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        {assetDetails?.isMultiple ? (
          <>
            <div
              style={{
                overflowY: 'auto',
                maxHeight: '20rem',
              }}>
              <FilesGroupStyles>
                {Object.values(selectedItems)?.map(el => (
                  <FileGroupItemStyles key={el?.ingest_db_id}>
                    {el?.title}
                  </FileGroupItemStyles>
                ))}
              </FilesGroupStyles>
            </div>
          </>
        ) : (
          <Text padding='0' md>
            {assetDetails?.title}
          </Text>
        )}
      </Body>

      <Footer>
        <Normal
          id='delete-asset-no'
          onClick={() => toggleModal(null, false, null)}
          color='secondary'
          style={{ marginRight: '2rem' }}>
          No
        </Normal>
        <Normal
          id='delete-asset-yes'
          onClick={handleDelete}
          isLoading={isSubmitting}>
          Yes
        </Normal>
      </Footer>
    </>
  );
};

DeleteAsset.propTypes = {
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  deleteAsset: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
};

export default DeleteAsset;
