import styled from 'styled-components';

// colors
import { Fab } from '../components/Button';
import { colors, padding } from '../styles/variables';
import { Text } from '../styles/common';
import { whiteAlpha } from '../styles/utils';

const Form = styled.form`
  padding: 2rem 0 1rem 0;
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const PortalHead = styled.h2`
  text-align: center;
  font-size: 2.6rem;
  color: ${colors.white};
  padding: 0 0 1rem 0;
`;

const Para = styled(Text)`
  color: ${whiteAlpha(0.8)};
  text-align: center;
`;

const Submit = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 1rem;
`;

const Anchor = styled.div`
  font-size: 1.4rem;
  color: ${whiteAlpha(0.7)};
  font-weight: 300;
  a,
  button {
    color: ${colors.white};
    font-weight: 400;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
`;

const LinkWrapper = styled.div`
  text-align: center;
  margin: ${({ secondary }) => (secondary ? '3rem 0' : '1.5rem 0')};
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Message = styled.div`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  padding: ${padding.h};
  background-color: ${({ error }) => (error ? colors.error : 'transparent')};
  font-size: 1.4rem;
  color: ${colors.white};
  border-radius: 0.4rem;
`;

export const AnchorFabStyles = styled(Fab)`
  &.MuiFab-primary {
    background-color: ${colors.blue.primary};
    &:hover {
      background-color: ${colors.blue.secondary};
    }

    .MuiSvgIcon-root {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

export {
  Form,
  Submit,
  Anchor,
  Container,
  LinkWrapper,
  PortalHead,
  Para,
  Message,
};
