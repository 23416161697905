import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField as MaterialTextField } from '@material-ui/core';

// Colors
import { colors, padding, radius } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const StyledMaterialTextField = styled(MaterialTextField)`
  .MuiFilledInput-root {
    width: 100%;
    color: ${colors.white};
    background-color: ${whiteAlpha(0.15)};
    border: 0.1rem solid transparent;
    border-radius: ${radius.default};
    font-size: 1.4rem;
    transition: none;

    &:hover,
    &.Mui-focused {
      background-color: ${whiteAlpha(0.15)};
      outline: none;
    }

    &.Mui-error {
      border-color: ${colors.error};
    }

    &.Mui-disabled {
      color: ${colors.white};
      background-color: ${whiteAlpha(0.05)};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-internal-autofill-selected {
    caret-color: ${colors.white};
    border-radius: ${radius.default};
    -webkit-text-fill-color: ${colors.white} !important;
    transition: background-color 400s ease-in-out 0s;
    box-shadow: 0 60px 0px inset rgba(255, 255, 255, 0);
  }

  .MuiFilledInput-input {
    padding: ${padding.input};
    line-height: 1.4;

    &::-webkit-autofill {
      border-radius: ${radius.default};
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }
  }

  .MuiFilledInput-multiline {
    padding: 0;
  }

  .MuiFilledInput-underline {
    &:after,
    &:before {
      display: none;
    }
  }

  .MuiFilledInput-adornedEnd {
    padding-right: 0;

    .MuiInputAdornment-positionEnd {
      padding: 1rem;
      margin: 0;

      .MuiIconButton-root {
        padding: 1.2rem;
        color: ${whiteAlpha(0.6)};
      }
    }
  }

  .MuiFormLabel-root {
    color: ${whiteAlpha(0.6)};
    font-size: 1.4rem;
    transform: translate(1.2rem, 2rem) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(1.2rem, 1rem) scale(0.75);
    }

    &.Mui-focused,
    &.Mui-disabled {
      color: ${whiteAlpha(0.6)};
    }

    &.Mui-error {
      color: ${colors.error};
    }
  }

  .MuiFormHelperText-root {
    margin: 0;
    font-size: 1.3rem;
    padding-bottom: 0;
    line-height: 1.4;

    &.Mui-error {
      color: ${colors.error};
      border-color: ${colors.error};
    }
  }

  .MuiSvgIcon-root {
    width: 2rem;
    height: 2rem;
    color: ${whiteAlpha(0.8)};
  }
`;

const TextField = props => {
  const { textboxtype } = props;
  return (
    <div className='v-padd'>
      <StyledMaterialTextField
        {...props}
        fullWidth
        autoComplete='off'
        variant={textboxtype || 'filled'}
      />
    </div>
  );
};

TextField.propTypes = {
  textboxtype: PropTypes.string,
};

export default TextField;
