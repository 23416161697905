/* eslint-disable camelcase */
/* eslint-disable indent */
import * as types from './types';

const INITIAL_STATE = {
  availablePlans: [],
  selectedPlan: {},
  currentPlan: {},
  tabs: {},
};

const subscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOAD_SUBSCRIPTON_DETAILS: {
      return {
        ...state,
        ...action?.payload,
      };
    }

    case types.LOAD_CURRENT_PLAN_DETAILS: {
      return {
        ...state,
        currentPlan: { ...action?.payload },
      };
    }

    case types.LOAD_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: action.payload,
      };
    }

    case types.LOAD_SELECTED_PLAN: {
      return {
        ...state,
        selectedPlan: { ...action.payload },
      };
    }

    case types.LOAD_TAB_DATA: {
      const page_no = 1;
      const data = action?.payload?.data;
      return {
        ...state,
        [action.payload.key]: {
          page_no,
          data,
        },
      };
    }

    case types.UPDATE_CREDITS_AVAILABLE: {
      return {
        ...state,
        credits: {
          ...state?.credits,
          credits_available: action?.payload?.credits_available,
        },
      };
    }

    default:
      return state;
  }
};

export default subscriptionReducer;
