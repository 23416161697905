import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

import { font } from '../../styles/variables';

const useStyles = makeStyles({
  arrow: {
    color: '#333',
  },
  tooltip: {
    fontWeight: font.normal,
    background: '#333',
    fontSize: '1.2rem',
  },
});

export default function Tooltip(props) {
  const classes = useStyles();
  const { placement } = props;
  return (
    <MaterialTooltip
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
      arrow
      placement={placement}
      classes={classes}
      {...props}
    />
  );
}

Tooltip.propTypes = {
  placement: PropTypes.string,
};
