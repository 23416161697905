import types from './types';

export const setAssetData = payload => {
  // this will set user Data in our reducer
  return {
    type: types.SET_ASSET_DATA,
    payload,
  };
};

const setPlaylistData = payload => {
  // this will set user Data in our reducer
  return {
    type: types.SET_PLAYLIST_DATA,
    payload,
  };
};

const setTabData = payload => {
  // this will set user Data in our reducer
  return {
    type: types.SET_TAB_DATA,
    payload,
  };
};

export const updateComments = payload => {
  return {
    type: types.UPDATE_COMMENTS,
    payload,
  };
};

const clearData = () => {
  // this will set user Data in our reducer
  return {
    type: types.CLEAR_DATA,
  };
};

const setUpdateGuestReviewData = payload => {
  return {
    type: types.GUEST_REVIEW_ASSET_DATA,
    payload,
  };
};

export default {
  setAssetData,
  setPlaylistData,
  setTabData,
  clearData,
  updateComments,
  setUpdateGuestReviewData,
};
