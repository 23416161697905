/* eslint-disable array-callback-return */
/* eslint-disable no-console */
import Creators from './actions';
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';

const {
  updatePusher,
  resetState,
  updateTrayData,
  loadProgramStructure,
  loadProgramData,
} = Creators;

// fetch
const fetchProgramStructure = () =>
  serviceCall.get(`${apiConfig.project}/asset/trays/structure/`);

const fetchProgramData = structure => dispatch => {
  structure.map(item => {
    item.values.map(async el => {
      const entriesPerPage = 10;
      const programResponse = await serviceCall.get(
        `${apiConfig.project}/asset/trays/?form_action=""&tray_name=${el.field}&from_date=""&to_date=""&entries_per_page=${entriesPerPage}&page_no=1&hostname=""`
      );
      const {
        data: { response, data },
      } = programResponse;
      if (response) {
        // we dont need permissions, action_elements therefore removing from data
        const { permissions, action_elements, ...otherProps } = data;

        // "field" is important key to be passed as structure is dependent on it
        dispatch(loadProgramData({ ...otherProps, field: el.field }));
      }
    });
  });
};

const fetchSearchedStoryboard = (searchKeyword, trayName) => dispatch => {
  const entriesPerPage = 10; // item.field === 'storyboard' ? 10 : 50;
  return serviceCall
    .get(
      `${apiConfig.project}/asset/trays/?form_action=""&tray_name=${trayName}&from_date=""&to_date=""&entries_per_page=${entriesPerPage}&page_no=1&hostname=""&search=${searchKeyword}`
    )
    .then(response => {
      const {
        data: { data },
      } = response;
      // we dont need permissions, action_elements therefore removing from data
      const { permissions, ...otherProps } = data;
      // "field" is important key to be passed as structure is dependent on it
      dispatch(loadProgramData({ ...otherProps, field: trayName }));
    })
    .catch(err => console.log(err, '/asset/trays/structure'));
};

const getPaginatedData = request => dispatch => {
  return serviceCall
    .get(`${apiConfig.project}/asset/trays/`, request)
    .then(res => {
      const {
        data: {
          data: { permissions, action_elements, ...otherProps },
        },
      } = res;
      dispatch(updateTrayData({ ...otherProps, field: request.tray_name }));
    });
};

const updatePusherData = (data, trayName, type) => {
  return dispatch => {
    dispatch(updatePusher(data, trayName, type));
  };
};

const clearData = () => {
  return dispatch => {
    dispatch(resetState());
  };
};

// To save
const loadProgramStructureAction = data => dispatch =>
  dispatch(loadProgramStructure(data));

const loadProgramTrays = data => dispatch => dispatch(loadProgramData(data));

export default {
  updatePusherData,
  clearData,
  fetchSearchedStoryboard,
  getPaginatedData,
  fetchProgramStructure,
  fetchProgramData,
  loadProgramStructureAction,
  loadProgramTrays,
};
