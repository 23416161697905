import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Utils
import Mixpanel from '../../../utils/mixpanel';

// Components
import {
  TextField,
  useForm,
  Select,
  AutoComplete,
  Calendar,
} from '../../components/FormInputs';
import { Normal } from '../../components/Button';

// Constants
import sidebarTypes from '../../components/Sidebar/constants';
import { MODAL_TYPES } from '../../constants';

// Helper Functions
import {
  required,
  emailRegex,
} from '../../components/FormInputs/helperFunctions';
import { formatLabelValue } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text } from '../../styles/common';
import { AppLinkButton } from './CloudAccess/styles';
import { whiteAlpha } from '../../styles/utils';

const TaskWrapper = styled.div`
  textarea {
    padding: 2.5rem 1.2rem 1.2rem;
    min-height: 10rem;
    max-height: -webkit-fill-available;
  }
`;

const INITIAL_STATE = {
  taskType: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  assignTo: {
    value: '', // define its value type i.e in this case its {} = object
    error: undefined,
    required: true,
    validation: required,
  },

  dueDate: {
    value: '',
    error: undefined,
    required: true,
    validation: value => {
      if (!value) {
        return 'Enter valid date';
      }
      return undefined;
    },
  },

  description: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },
};

const Task = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    open: false,
    assignToUsers: [],
    taskTypes: [],
  });

  const {
    closeSidebar,
    toggleToast,
    toggleModal,
    getAssignToUsers,
    match: { params },
    selectedTask,
    createUpdateFetchTask,
    view,
    getTaskTypes,
    toggleUpgradeModal,
    getCollaborators,
    loadCollaborators,
    creatorDetails,
  } = props;

  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      const updatedDateFormat = new Date(formValues.dueDate.value)
        .toDateString()
        .split(' ');

      setSubmitting(true);

      const emailOrUserId = formValues.assignTo.value.value;
      const request = {
        form_action: formValues.taskType.value,
        expire_at: `${updatedDateFormat[2]} ${updatedDateFormat[1]} ${updatedDateFormat[3]}`,
        description: formValues.description.value,
        user_email: '',
        assigned_to: '',
      };

      if (emailRegex(emailOrUserId)) {
        request.user_email = emailOrUserId;
      } else request.assigned_to = emailOrUserId;

      Mixpanel.track(
        `${view === sidebarTypes.updateTask ? 'Update' : 'Create'} Task`,
        {
          task_type: formValues.taskType.value,
          assigne_name: formValues.assignTo.value.label,
        }
      );

      // function to be called after validations are passed like API call etc;
      let taskResponse = null;

      if (view === sidebarTypes.updateTask) {
        taskResponse = await createUpdateFetchTask(
          {
            task_db_id: selectedTask.task_db_id,
            project_db_id: selectedTask.project_db_id,
            ...request,
          },
          'put'
        );
      } else {
        taskResponse = await createUpdateFetchTask(
          {
            project_db_id: params.project_db_id,
            ...request,
          },
          'post'
        );
      }

      const {
        data: { response, data, errormessage },
      } = taskResponse;

      if (response) {
        // API call to get collaborators Avatars
        getCollaborators({
          project_db_id: params.project_db_id,
        })
          .then(res => {
            setSubmitting(false);
            loadCollaborators(res.data.data);
            closeSidebar();
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody:
                  view === sidebarTypes.updateTask
                    ? 'Task updated successfully'
                    : 'Task created successfully',
                variant: 'success',
              },
            });
          })
          .catch(() => {
            setSubmitting(false);
          });
      } else if (data && data.upgrade_required) {
        setSubmitting(false);
        toggleUpgradeModal({ show: true, data });
      } else {
        setSubmitting(false);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  const getUpdatedValueOfAssignTo = useCallback(() => {
    if (
      selectedTask &&
      Object.keys(selectedTask.assigned_to_user_profiles).length > 0
    ) {
      return {
        label: selectedTask.assigned_to_user_profiles[0].name,
        value: selectedTask.assigned_to_user_profiles[0].user_id,
      };
    }
    if (selectedTask && selectedTask.invited_team_members) {
      return {
        label: selectedTask.invited_team_members[0],
        value: selectedTask.invited_team_members[0],
      };
    }
    return {
      label: '',
      value: '',
    };
  }, [selectedTask]);

  useEffect(() => {
    // once to fetch task types

    (async () => {
      // to fetch task types
      const taskTypesResponse = await getTaskTypes();

      const {
        data: { response, data },
      } = taskTypesResponse;
      if (response) {
        const formated = data.map(item => {
          return {
            label: item.title,
            value: item.field,
            disabled: !item.can_select,
          };
        });
        setState({
          ...state,
          taskTypes: formated,
        });
      }
    })();

    if (Object.keys(selectedTask).length) {
      updateValues({
        ...formValues,
        dueDate: {
          ...formValues.dueDate,
          value: selectedTask.expire_at,
        },
        taskType: {
          ...formValues.taskType,
          value: selectedTask.task_type,
        },
        assignTo: {
          ...formValues.assignTo,
          value: getUpdatedValueOfAssignTo(),
        },
        description: {
          ...formValues.description,
          value: selectedTask.description,
        },
      });
    }
  }, []); // once

  const getUser = async (e, value, reason) => {
    const { target } = e;

    const id = 'assignTo';

    if (reason === 'select-option') {
      onChange({
        target: { id, value: { label: value.label, value: value.value } },
      });
      setState({
        ...state,
        open: false,
      });
    } else if (target.value) {
      onChange({
        target: { id, value: { label: target.value, value: target.value } },
      });
      if (target.value.length > 2) {
        const usersResponse = await getAssignToUsers({
          filters: formValues.taskType.value,
          search_text: target.value?.trim(),
        });

        const {
          data: { data, response },
        } = usersResponse;

        if (response) {
          const formated = formatLabelValue(
            data,
            'name',
            'user_id',
            'username'
          );
          setState({
            ...state,
            open: true,
            assignToUsers: formated,
          });
        } else {
          setState({ ...state, open: true, assignToUsers: [] });
        }
      }
    } else {
      onChange({ target: { id, value: '' } });
      setState({
        ...state,
        open: false,
      });
    }
  };

  function autosize() {
    const el = document.getElementById('description');
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + 'px';
  }

  return (
    <>
      <Body>
        <TaskWrapper>
          <Select
            id='taskType'
            label='Task Type'
            value={formValues.taskType.value}
            onChange={onChange}
            error={!!formValues.taskType.error}
            helperText={formValues.taskType.error}
            options={state.taskTypes}
            disabled={selectedTask && selectedTask.task_type}
          />

          <AutoComplete
            id='assignTo'
            label='Assign To'
            open={state.open}
            onClose={() => setState({ ...state, open: false })}
            value={formValues.assignTo.value}
            onChange={getUser}
            options={state.assignToUsers}
            error={!!formValues.assignTo.error}
            helperText={formValues.assignTo.error}
            disabled={selectedTask?.is_completed}
            filteredValue={
              formValues.assignTo.value ? formValues.assignTo.value.value : ''
            }
          />

          <Calendar
            parentStyles={{
              pointerEvents: selectedTask?.is_completed ? 'none' : 'default',
            }}
            id='dueDate'
            label='Due Date'
            disablePast
            value={formValues.dueDate.value}
            onChange={onChange}
            error={!!formValues.dueDate.error}
            helperText={formValues.dueDate.error}
            isDisabled={selectedTask?.is_completed}
          />

          <TextField
            multiline
            rows='4'
            id='description'
            label='Description'
            value={formValues.description.value}
            onChange={onChange}
            error={!!formValues.description.error}
            helperText={formValues.description.error}
            disabled={selectedTask?.is_completed}
            onKeyDown={autosize}
          />

          {selectedTask?.is_completed && (
            <Text color={whiteAlpha(0.6)} className='px-0'>
              This task is completed
            </Text>
          )}

          {creatorDetails?.created_by === selectedTask?.assigned_by?.user_id &&
            !selectedTask?.is_completed && (
              <AppLinkButton
                onClick={() => {
                  toggleModal(MODAL_TYPES.completeTask, true, 'Complete Task');
                }}
              >
                Complete Task
              </AppLinkButton>
            )}
        </TaskWrapper>
      </Body>

      <Footer>
        {view === sidebarTypes.updateTask && (
          <Normal
            disabled={isSubmitting}
            color='secondary'
            onClick={() => {
              toggleModal(MODAL_TYPES.deleteTask, true, 'Delete Task');
            }}
            style={{ marginRight: '2rem' }}
          >
            Delete
          </Normal>
        )}
        {!selectedTask?.is_completed && (
          <Normal onClick={onSubmit} isLoading={isSubmitting}>
            {view === sidebarTypes.updateTask ? 'Update' : 'Create'}
          </Normal>
        )}
      </Footer>
    </>
  );
};

Task.defaultProps = {
  view: sidebarTypes.createTask,
};

Task.propTypes = {
  closeSidebar: PropTypes.func,
  getAssignToUsers: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  createUpdateFetchTask: PropTypes.func,
  selectedTask: PropTypes.instanceOf(Object),
  creatorDetails: PropTypes.instanceOf(Object),
  getTaskTypes: PropTypes.func,
  toggleModal: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  getCollaborators: PropTypes.func,
  loadCollaborators: PropTypes.func,
  toggleToast: PropTypes.func,
  view: PropTypes.string,
};

export default Task;
