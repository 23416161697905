import styled from 'styled-components';

// Styles
import { whiteAlpha } from '../../../styles/utils';
import { colors, font } from '../../../styles/variables';
import { Text } from '../../../styles/common';

export const AppItem = styled.div`
  position: relative;
  border-left: 0.1rem solid ${whiteAlpha(0.3)};
  margin-top: 2rem;
  padding-left: 2rem;
`;

export const AppTitle = styled.div`
  font-weight: ${font.normal};
  font-size: 1.7rem;
  color: ${whiteAlpha(0.8)};
  margin: ${({ margin }) => margin};
`;

export const AppLinkButton = styled.button`
  color: ${colors.blue.shadeTwo};
  font-size: 1.4rem;
  background-color: transparent;
  border: none;
  font-weight: ${font.normal};
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const GridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`;

export const CloudScreenHead = styled(Text)`
  line-height: 1.4;
  padding-left: 0;
  padding-right: 0;
  margin: 0rem 0 1rem 0;
`;

export const DownloadNativeAppParentStyles = styled.ul`
  list-style-type: none;

  li {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;

    &:not(:first-child) {
      button {
        margin-left: 0.5rem;
      }
      &:after {
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        border: 0.75px solid ${colors?.blue.shadeTwo};
        top: 0;
        left: 0;
      }
    }
  }
`;
