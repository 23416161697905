/* eslint-disable camelcase */
import React, { useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

import { Normal } from '../../../components/Button';
import TextField from '../../../components/FormInputs/TextField';
import GradientCard from '../CloudAccess/GradientCard';
import { getTotalPluginsPrice } from '../../helperFunctions';
import { liveCollaborationServiceCall } from '../../duck/operations';
import { formatLabelValue } from '../../../helperFunctions';

import { LIVE_COLLABORATION_STEPS } from '../../constants';

import {
  StyledPaper,
  MaterialAutocompleteStyles,
  StyledUser,
} from '../../../components/FormInputs/autoComplete';

// Styles
import { colors } from '../../../styles/variables';
import { blackAlpha } from '../../../styles/utils';
import { Body, Footer } from '../../../styles/sidebar';
import { Text } from '../../../styles/common';

const NewInviteLiveCollaboration = ({
  match: { params },
  toggleToast,
  goTo,
}) => {
  // const [inputText, setInputText] = useState('');
  const [selectedOptions, selectSelectedOptions] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const users = useSelector(store => store.storyboard?.members);
  const userDetails = useSelector(store => store?.common?.userDetails);
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);
  const cloudDetails = useSelector(
    store => store?.storyboard?.cloudAccessDetails
  );

  const options = useMemo(() => {
    const admins = formatLabelValue(
      users?.admins,
      'name',
      'user_id',
      'username'
    );

    const members = formatLabelValue(
      users?.collaborators?.members,
      'name',
      'user_id',
      'username'
    );

    return [...admins, ...members]?.filter(
      el => el?.value !== userDetails?.user_id
    );
  }, [users?.collaborators?.members, users?.admins]);

  const onChange = (e, value, reason) => {
    if (reason === 'select-option') {
      selectSelectedOptions([value]);
    } else {
      selectSelectedOptions([]);
    }
  };

  const onBack = () => goTo(LIVE_COLLABORATION_STEPS.list);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await liveCollaborationServiceCall(
        {
          team_db_id: selectedTeam?.team_db_id,
          project_db_id: params?.project_db_id,
          cloud_assignment_db_id: cloudDetails?.cloud_access_db_id,
          user_db_ids: selectedOptions?.map(el => el.value),
        },
        'post'
      );

      if (res?.data?.response) {
        const {
          data: {
            data: { message },
          },
        } = res;

        // setInputText('');
        selectSelectedOptions([]);
        setSubmitting(false);
        onBack();
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: message,
            variant: 'success',
          },
        });
      } else {
        throw new Error(res?.data?.errormessage);
      }
    } catch (err) {
      setSubmitting(false);
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: err?.message,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <GradientCard
          label={cloudDetails?.cloud_system?.label}
          icon={cloudDetails?.cloud_system?.icon}
          value={cloudDetails?.cloud_system?.value}
          details={[
            cloudDetails?.configurations?.software,
            `${cloudDetails?.configurations?.title}`,
            `₹${Number(
              cloudDetails?.plugins
                ? getTotalPluginsPrice(cloudDetails?.plugins) +
                    cloudDetails?.configurations?.pricing
                : cloudDetails?.configurations?.pricing
            )} / hr`,
          ]}
        />

        <Text padding='0' bg margin='1rem 0'>
          Invite a collaborator for live cloud session
        </Text>

        <MaterialAutocompleteStyles
          id='members'
          disableClearable
          autoHighlight
          filterSelectedOptions
          options={options}
          getOptionLabel={option => option.label}
          onChange={onChange}
          PaperComponent={paperProps => <StyledPaper {...paperProps} />}
          renderInput={props => (
            <TextField {...props} fullWidth label='Add by Username or Email' />
          )}
          renderOption={option => {
            return (
              <StyledUser>
                <Text color={colors.black.default} padding='0'>
                  {option?.label}
                </Text>
                <Text color={blackAlpha(0.7)} padding='0'>
                  {option?.username}
                </Text>
              </StyledUser>
            );
          }}
        />
      </Body>
      <Footer style={{ columnGap: '1.5rem' }}>
        <Normal onClick={onBack} color='secondary'>
          Back
        </Normal>
        {selectedOptions?.length > 0 && (
          <Normal onClick={onSubmit} isLoading={isSubmitting}>
            Invite
          </Normal>
        )}
      </Footer>
    </>
  );
};

NewInviteLiveCollaboration.propTypes = {
  goTo: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  startJoinAudioCall: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
  toggleMute: PropTypes.func,
  isAudioMuted: PropTypes.bool,
};

export default NewInviteLiveCollaboration;
