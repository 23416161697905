/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ArrowUpward } from '@material-ui/icons';

const UploadButtonStyles = styled.button`
  background-color: #6faaff;
  border: none;
  margin: 0;
  box-shadow: 0 1.2rem 2.4rem 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 17.4rem;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    .icon {
      animation: upDown 1s linear;
    }
  }

  .upload-arrow {
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid #fff;
    color: #fff;
    border-radius: 100%;
    margin-bottom: 1.6rem;
    .icon {
      font-size: 2rem;
      position: relative;
    }
  }
  .card-header {
    padding: 0;
    margin-bottom: 0.9rem;
    font-size: 1.6rem;
    font-weight: 400;
  }
  .upload-folder {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
  .upload-help-text {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 400;
  }
  @keyframes upDown {
    0% {
      top: 0rem;
    }
    25% {
      top: -0.5rem;
    }
    50% {
      top: 0rem;
    }
    75% {
      top: 0.5rem;
    }
    100% {
      top: 0rem;
    }
  }
`;

const UploadCard = props => {
  const { cardClick, disabled } = props;

  const onUploadCardClick = e => {
    if (e.target.classList.contains('upload-folder')) {
      cardClick('folder');
    } else {
      cardClick('file');
    }
  };

  return (
    <div>
      <UploadButtonStyles
        onClick={e => onUploadCardClick(e)}
        disabled={disabled}
      >
        <div className='upload-arrow'>
          <ArrowUpward className='icon' />
        </div>
        <p className='card-header'>Upload Files</p>
        <p className='upload-folder'>Upload Folder</p>
        <p className='upload-help-text'>Drag &amp; Drop anywhere</p>
      </UploadButtonStyles>
    </div>
  );
};

UploadCard.propTypes = {
  cardClick: PropTypes.func,
  disabled: PropTypes.bool,
};

UploadCard.defaultProps = {
  disabled: false,
};

export default UploadCard;
