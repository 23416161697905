/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../components/Button';

// utils
import Mixpanel from '../../../utils/mixpanel';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';

const CompleteTask = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    toggleModal,
    toggleToast,
    completeTask,
    closeSidebar,
    assetDetails: { task_db_id, title },
  } = props;

  const closeModal = () => toggleModal(null, false, null);

  const onComplete = async () => {
    setSubmitting(true);
    Mixpanel.track('Complete Task');
    completeTask({
      task_db_id,
      role: 'editor',
    })
      .then(res => {
        const {
          data: { response, errormessage },
        } = res;

        setSubmitting(false);

        if (response) {
          closeSidebar();
          closeModal();
          toggleToast({
            message: {
              messageHead: 'Success',
              messageBody: 'Task marked as completed',
              variant: 'success',
            },
          });
        } else {
          throw new Error(errormessage);
        }
      })
      .catch(err => {
        closeSidebar();
        closeModal();
        setSubmitting(false);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        });
      });
  };

  return (
    <>
      <Body>
        <Text padding='0' md>{`Are you sure you want to mark "${title}" task as complete?`}</Text>
      </Body>

      <Footer>
        <Normal
          onClick={closeModal}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          No
        </Normal>
        <Normal onClick={onComplete} isLoading={isSubmitting}>
          Yes
        </Normal>
      </Footer>
    </>
  );
};

CompleteTask.propTypes = {
  toggleModal: PropTypes.func,
  completeTask: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  closeSidebar: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
};

export default CompleteTask;
