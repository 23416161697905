/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Delete, Person, Settings } from '@material-ui/icons';
import styled from 'styled-components';

// Components
import { Normal } from '../../components/Button';
import { useForm, AutoComplete } from '../../components/FormInputs';

import sidebarTypes from '../../components/Sidebar/constants';

// Helper Functions
import { formatLabelValue } from '../../helperFunctions';
import { emailRegex } from '../../components/FormInputs/helperFunctions';
import { requestForIdsEmails } from '../../storyboard/helperFunctions';

import { IconButton } from '../../storyboard/components/styles';
import { getTeamMembersServiceCall } from '../duck/operations';
import { loadTeamDetails } from '../duck/actions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import {
  Flex,
  Text,
  CustomAvatarStyles,
  AvatarUsernameStyles,
  AvatarSecondaryTextStyles,
} from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';

const ParentStyles = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  &:hover ${IconButton} {
    display: block;
  }
`;

const WrapperStyles = styled(Flex)`
  padding: 0.8rem 0;
  align-items: center;
  justify-content: flex-start;
`;

const INITIAL_STATE = {
  members: {
    value: [],
    error: undefined,
    required: true,
  },
};

const TeamMembers = ({
  closeSidebar,
  toggleToast,
  toggleUpgradeModal,
  team_id,
  toggleSidebar,
  isBack,
}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(store => store?.common?.userDetails);
  const teamDetails = useSelector(store => store?.settings?.teamDetails);
  const [isSubmitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const { formValues, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      const baseRequest = {
        team_id,
        user_emails: [],
        user_db_ids: [],
        permissions: [],
      };

      const request = requestForIdsEmails(
        formValues?.members?.value,
        baseRequest,
        'user_emails',
        'user_db_ids'
      );

      const collaboratorsResponse = await getTeamMembersServiceCall(
        request,
        'post'
      ); // API call

      const {
        data: { response, errormessage, data },
      } = collaboratorsResponse;
      setSubmitting(false);
      if (response) {
        closeSidebar();
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: 'Members added successfully',
            variant: 'success',
          },
        });
      } else if (data && data.upgrade_required) {
        toggleUpgradeModal({ show: true, data });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  const searchEligibleUser = async (e, value, reason) => {
    // onChange
    if (reason === 'select-option' || reason === 'remove-option') {
      const updatedUsers = value.map(item => ({
        label: item.label,
        value: item.value,
      }));
      updateValues({
        ...formValues,
        members: {
          ...formValues.members,
          error: updatedUsers.length ? undefined : 'This field is required',
          value: updatedUsers,
        },
      });
    } else if (e.target.value && e.target.value.length > 2) {
      setOpen(true);
      const usersResponse = await getTeamMembersServiceCall({
        search_text: e.target.value?.trim(),
      });
      const {
        data: {
          data: { users },
        },
      } = usersResponse;
      const formated = formatLabelValue(users, 'name', 'user_id', 'user_email');
      setOptions(formated || []);
    } else {
      setOptions([]);
      setOpen(false);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getTeamMembersServiceCall({ team_id });
      const {
        data: {
          team_detail: { invited_users, ...rest },
        },
        response,
      } = res?.data;
      if (response) {
        setLoading(false);
        dispatch(
          loadTeamDetails({
            ...rest,
            invited_users: invited_users?.map(el => ({ user_db_id: el })),
          })
        );
      } else {
        setLoading(false);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: 'Something went wrong while fetching team members',
            variant: 'error',
          },
        });
      }
    })();

    return () => {
      dispatch(loadTeamDetails(null));
    };
  }, []);

  const deleteUser = async user => {
    const request = {
      team_id,
    };

    if (emailRegex(user?.user_db_id)) {
      request.user_email = user?.user_db_id;
    } else request.user_db_id = user?.user_db_id;

    const collaboratorsResponse = await getTeamMembersServiceCall(
      request,
      'put'
    ); // API call

    const {
      data: { response, errormessage },
    } = collaboratorsResponse;

    if (response) {
      closeSidebar();
    }

    toggleToast({
      message: {
        messageHead: response ? 'Success' : 'Error',
        messageBody: response ? 'Member removed successfully' : errormessage,
        variant: response ? 'success' : 'error',
      },
    });
  };

  const onSettingsClick = item => {
    toggleSidebar({
      show: true,
      type: sidebarTypes.settingsTeamMemberPermissions,
      title: 'Permissions',
      componentProps: {
        team_id,
        user: { ...item },
      },
    });
  };

  const renderUsers = item => {
    return (
      <>
        <WrapperStyles>
          <CustomAvatarStyles
            key={item?.user_db_id}
            color={item?.user_badge_color}
            style={{ marginRight: '1rem' }}
          >
            {!emailRegex(item?.user_db_id) ? item?.name.charAt(0) : <Person />}
          </CustomAvatarStyles>
          <div>
            <AvatarUsernameStyles>
              {item?.name || item?.user_db_id}
            </AvatarUsernameStyles>
            <AvatarSecondaryTextStyles sm>
              {emailRegex(item?.user_db_id) ? 'Pending' : item?.email}
            </AvatarSecondaryTextStyles>
          </div>
        </WrapperStyles>
        <Flex>
          {teamDetails?.is_admin && item?.user_db_id !== userDetails?.user_id && (
            <>
              {!emailRegex(item?.user_db_id) && (
                <IconButton>
                  <Settings
                    onClick={() => onSettingsClick(item)}
                    style={{ marginRight: '1rem' }}
                  />
                </IconButton>
              )}
              <IconButton>
                <Delete onClick={() => deleteUser(item)} />
              </IconButton>
            </>
          )}
        </Flex>
      </>
    );
  };

  return (
    <>
      <Body>
        {teamDetails?.is_admin && (
          <div style={{ marginBottom: '1rem' }}>
            <AutoComplete
              id='members'
              label='Add Members'
              multiple
              open={open}
              onClose={() => setOpen(false)}
              value={formValues.members.value}
              onChange={searchEligibleUser}
              options={options}
              error={!!formValues.members.error}
              helperText={formValues.members.error}
              getOptionDisabled={option =>
                [
                  ...teamDetails?.owners,
                  ...teamDetails?.members,
                  ...teamDetails?.invited_users,
                ].some(item => {
                  if (item.user_db_id) {
                    return item.user_db_id === option.value;
                  }
                  return item === option.value;
                })
              }
            />
          </div>
        )}

        {isLoading ? (
          <Text paddingLeft='0' paddingRight='0'>
            Fetching users. Please wait...
          </Text>
        ) : (
          <>
            <Text padding='0 0 1rem 0' color={whiteAlpha(0.8)}>
              Team Admins
            </Text>
            {teamDetails?.owners?.map(item => (
              <ParentStyles key={item?.user_db_id}>
                {renderUsers(item)}
              </ParentStyles>
            ))}
            {(teamDetails?.members || teamDetails?.invited_users)?.length >
              0 && (
              <div style={{ marginTop: '1rem' }}>
                <Text padding='0 0 1rem 0' color={whiteAlpha(0.8)}>
                  Team Members
                </Text>
                {teamDetails?.members?.map(item => (
                  <ParentStyles key={item?.user_db_id}>
                    {renderUsers(item)}
                  </ParentStyles>
                ))}
                {teamDetails?.invited_users?.map(item => (
                  <ParentStyles key={item?.user_db_id}>
                    {renderUsers(item)}
                  </ParentStyles>
                ))}
              </div>
            )}
          </>
        )}
      </Body>

      <Footer>
        {!formValues.members.value.length && isBack && (
          <Normal
            type='submit'
            color='secondary'
            onClick={() => {
              toggleSidebar({
                type: sidebarTypes.settingsCreateTeam,
                show: true,
                title: 'Manage Team',
                componentProps: {
                  team_id,
                },
              });
            }}
          >
            Back
          </Normal>
        )}
        {formValues?.members?.value?.length > 0 && (
          <Normal type='submit' onClick={onSubmit} isLoading={isSubmitting}>
            Update
          </Normal>
        )}
      </Footer>
    </>
  );
};

TeamMembers.propTypes = {
  toggleToast: PropTypes.func,
  isBack: PropTypes.bool,
  closeSidebar: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  toggleSidebar: PropTypes.func,
  team_id: PropTypes.string,
};

export default TeamMembers;
