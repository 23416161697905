// eslint-disable-next-line no-unused-vars
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

// Styles
import { colors } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';
import TextField from './TextField';

const StyledSelectTextField = styled(TextField)`
  .MuiFilledInput-root {
    .MuiSelect-root {
      &.MuiSelect-filled {
        padding-right: 4.2rem;
      }
    }

    .MuiSvgIcon-root {
      &.MuiSelect-icon {
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  // Dropdown Menu - Card
  paper: {
    color: colors.white,
    borderRadius: '0.5rem',
    boxShadow: 'none',
    backdropFilter: 'blur(1rem)',
    backgroundColor: whiteAlpha(0.15),
    height: ({ options }) =>
      options && options.length > 4 ? '20.5rem' : 'auto',
  },

  // Dropdown Menu List
  menuList: {
    padding: 0,
    '& .MuiMenuItem-root': {
      // Menu Item
      padding: '1.2rem',
      fontSize: '1.4rem',
      borderBottom: `0.1rem solid ${whiteAlpha(0.15)}`,
      '&:last-child': {
        border: 'none',
      },
      '&:hover': {
        color: colors.white,
        backgroundColor: whiteAlpha(0.2),
      },
      '&.Mui-selected': {
        color: colors.white,
        backgroundColor: colors.blue.primary,
      },
    },
  },
}));

const Select = props => {
  const { onChange, options, id, ...otherProps } = props;
  const classes = useStyles(props);
  return (
    <>
      <StyledSelectTextField
        {...otherProps}
        select
        onChange={e =>
          onChange({ ...e, target: { value: e.target.value, id } })
        }
        SelectProps={{
          MenuProps: {
            classes: {
              paper: classes.paper,
              list: classes.menuList,
            },
            transformOrigin: {
              vertical: 'top',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {options && options.length > 0 ? (
          options.map(item => (
            <MenuItem
              key={`menu-item-${id}-${item.label}`}
              value={item.value}
              disabled={!!item.disabled}
            >
              {item.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value=''>No Record Found</MenuItem>
        )}
      </StyledSelectTextField>
    </>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.shape([]).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Select;
