/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { CheckCircle } from '@material-ui/icons';
import { useParams } from 'react-router-dom';

// Components
import { Normal } from '../../components/Button';
import {
  s3BucketService,
  s3UploadService,
} from '../../import-assets/duck/operations';
import { selectItemsAction, setImportAssetStatus } from '../duck/actions';
import { getImportAssetStatusService } from '../duck/operations';
import { SELECTION_KEYS } from '../constants';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';
import { colors } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';
import {
  ListContainerStyles,
  ListItemExtendedStyles,
} from '../../settings/components/integration/index';

const SelectedStyles = styled.div`
  position: absolute;
  right: 1.5rem;
  z-index: 1;
  svg {
    color: #43cea2;
    width: 2rem;
    height: 2rem;
  }
`;

const ShareToAmazonS3 = props => {
  const dispatch = useDispatch();
  const { project_db_id } = useParams();
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);
  const selectedItems = useSelector(store => store?.storyboard?.selectedItems);
  const [isSubmitting, setSubmitting] = useState(false);
  const [bucketDetails, setBucketDetails] = useState(null);
  const [selectedBucket, selectBucket] = useState(null);
  const [fetchingDetails, setFetchingDetaails] = useState(true);

  const {
    toggleModal,
    toggleToast,
    assetDetails,
    match: { params },
  } = props;

  const onModalClose = () => toggleModal(null, false, null);

  const getImportStatus = async () => {
    const res = await getImportAssetStatusService({
      team_db_id: selectedTeam?.team_db_id,
      project_db_id: params?.project_db_id,
      entries_per_page: 25,
      page_no: 1,
    });

    if (res?.data?.response) {
      if (res?.data?.data?.show_notification) {
        dispatch(setImportAssetStatus({ data: res?.data?.data }));
      }
    }
  };

  useEffect(() => {
    s3BucketService({
      team_db_id: selectedTeam?.team_db_id,
      project_db_id,
    })
      .then(res => {
        if (res?.data?.response) {
          setFetchingDetaails(false);
          setBucketDetails(res?.data?.data);
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setFetchingDetaails(false);
        setBucketDetails(null);
      });
  }, []);

  const onSubmit = async () => {
    setSubmitting(true); // disable button

    const res = await s3UploadService({
      files: [],
      folders: [],
      ingest_db_ids: assetDetails?.isMultiple
        ? Object.keys(selectedItems)
        : [assetDetails?.ingest_db_id],
      project_db_id,
      team_db_id: selectedTeam?.team_db_id,
      source_bucket: bucketDetails?.source_bucket,
      destination_bucket: selectedBucket?.output_bucket,
      destination_folder: selectedBucket?.output_folder_path ?? '',
    });

    const {
      data: { response, errormessage, data },
    } = res;

    setSubmitting(false);

    if (response) {
      if (assetDetails?.isMultiple) {
        dispatch(
          selectItemsAction({
            type: SELECTION_KEYS.reset,
          })
        );
      }
      onModalClose();
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: data?.message,
          variant: 'success',
        },
      });
      setTimeout(getImportStatus, 1000);
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  if (!fetchingDetails) {
    return (
      <>
        <Body style={{ paddingTop: '1rem' }}>
          {!assetDetails?.isMultiple &&
            assetDetails?.shared_detail?.s3_shared_by?.name && (
              <Text padding='0 0 2rem 0' color={whiteAlpha(0.6)}>
                This asset has been shared by{' '}
                {assetDetails?.shared_detail?.s3_shared_by?.name}
              </Text>
            )}

          {bucketDetails?.s3_integrations?.length > 0 ? (
            <>
              <Text padding='0 0 1.5rem 0' color={whiteAlpha(0.6)}>
                Select Output Bucket
              </Text>
              <div>
                <ListContainerStyles>
                  {bucketDetails?.s3_integrations?.map((el, index) => (
                    <li key={index}>
                      <ListItemExtendedStyles
                        style={{
                          borderColor:
                            selectedBucket?.id === index && colors?.white,
                        }}
                        type='button'
                        onClick={() => selectBucket({ ...el, id: index })}
                      >
                        <>
                          {selectedBucket?.id === index && (
                            <SelectedStyles>
                              <CheckCircle />
                            </SelectedStyles>
                          )}
                          {el?.output_bucket && (
                            <div aria-label='output-bucket-name'>
                              <p>Output Bucket Name</p>
                              <h4>{el?.output_bucket}</h4>
                            </div>
                          )}
                          {el?.output_folder_path && (
                            <div aria-label='output-bucket-name'>
                              <p>Output Folder Path</p>
                              <h4>{el?.output_folder_path}</h4>
                            </div>
                          )}
                        </>
                      </ListItemExtendedStyles>
                    </li>
                  ))}
                </ListContainerStyles>
              </div>
            </>
          ) : (
            <Text padding='0'>No Bucket found</Text>
          )}
        </Body>

        {bucketDetails?.s3_integrations?.length > 0 && (
          <Footer style={{ columnGap: '1.5rem' }}>
            <Normal id='share-no' onClick={onModalClose} color='secondary'>
              Cancel
            </Normal>
            <Normal
              id='share-yes'
              onClick={onSubmit}
              isLoading={isSubmitting}
              disabled={!selectedBucket}
            >
              Share
            </Normal>
          </Footer>
        )}
      </>
    );
  }

  return (
    <>
      <Body>
        <Text>Fetching bucket details</Text>
      </Body>
    </>
  );
};

ShareToAmazonS3.propTypes = {
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};

export default ShareToAmazonS3;
