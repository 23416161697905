import { keyframes } from 'styled-components';

const slideUp = keyframes`
  from { transform: translateY(3rem); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const slideDown = keyframes`
  from { transform: translateY(-2rem); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
	to { opacity: 1; }
`;

const ripple = keyframes`
  0%{ opacity: 1; transform: scale(0) }
  100%{ opacity: 0; transform: scale(1) }
`;

export { slideUp, slideDown, fadeIn, ripple };
