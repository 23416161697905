/* eslint-disable indent */
import types from './types';

const INITIAL_STATE = {
  assetData: null,
  guestReviewAssetDetails: null,
  playlist: [],
  tabsData: [],
};

const playerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ASSET_DATA: {
      const { type, data } = action.payload;
      if (type === 'duration') {
        return {
          ...state,
          assetData: { ...state?.assetData, duration: data },
        };
      }
      return {
        ...state,
        assetData: action.payload,
      };
    }

    case types.SET_PLAYLIST_DATA: {
      return {
        ...state,
        playlist: action.payload,
      };
    }

    case types.SET_TAB_DATA: {
      return {
        ...state,
        tabsData: [...state.tabsData, action.payload],
      };
    }

    case types.GUEST_REVIEW_ASSET_DATA: {
      return {
        ...state,
        guestReviewAssetDetails: action.payload,
      };
    }

    case types.UPDATE_COMMENTS: {
      const { type } = action.payload;
      let tabData = [...state.tabsData];
      tabData = tabData.map(tab => {
        const tabObj = { ...tab };
        if (tabObj.tab_name === 'comments') {
          if (type === 'pagination') {
            tabObj.page_no = action?.payload?.data?.page_no;
            tabObj.comments = [
              ...tabObj.comments,
              ...action.payload.data?.comments,
            ];
          }
          if (type === 'comment') {
            tabObj.total_entries += 1;
            tabObj.comments = [action.payload.data, ...tabObj.comments];
          }
          if (type === 'comment_update') {
            tabObj.comments = tabObj.comments.map(comment => {
              if (comment._id === action.payload.data._id) {
                return action.payload.data;
              }
              return comment;
            });
          }
          if (type === 'reply') {
            const reply = action.payload.data;
            tabObj.comments = tabObj.comments.map(comment => {
              // eslint-disable-next-line no-underscore-dangle
              if (comment._id === reply.comment_id) {
                comment.replies.push(reply);
              }
              return comment;
            });
            tabObj.comments.push();
          }
        }
        return tabObj;
      });

      return {
        ...state,
        tabsData: tabData,
      };
    }

    case types.CLEAR_DATA: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default playerReducer;
