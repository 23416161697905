/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { PropTypes } from 'prop-types';

// Utils
import Mixpanel from '../../../../utils/mixpanel';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';
import { getTotalPluginsPrice } from '../../helperFunctions';
// import Timer from '../../../components/timer';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { Text } from '../../../styles/common';
import { CloudScreenHead } from './styles';
import { CLOUD_SCREENS } from './constants';

const NotAvailable = props => {
  const {
    goTo,
    type,
    createCancelQueueRequest,
    task: { task_db_id },
    toggleToast,
    selectedPlugin,
    match: {
      params: { project_db_id },
    },
    config: {
      cloud_configuration_db_id,
      duration,
      // current_time,
      // session_end_datetime,
      configurations,
    },
    selectedSoftware,
  } = props;

  const onSubmit = async () => {
    Mixpanel.track('Queue Session', { config: { ...props.config } });
    const createCancelResponse = await createCancelQueueRequest(
      {
        project_db_id,
        task_db_id,
        cloud_configuration_db_id,
        duration,
      },
      'post'
    );

    const {
      data: { response, errormessage },
    } = createCancelResponse;
    if (response) {
      goTo(CLOUD_SCREENS.inQueue); // success - go to inQueue screen
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <GradientCard
          {...type}
          details={[
            selectedSoftware?.title,
            `${configurations?.title}`,
            `₹${Number(
              getTotalPluginsPrice(selectedPlugin || configurations?.plugins) +
                configurations?.pricing
            )} / hr`,
          ]}
        />

        {(selectedPlugin || configurations?.plugins)?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm
          >
            Add-on Plug-ins:{' '}
            {(selectedPlugin || configurations?.plugins)
              .map(el => el?.title)
              .join(', ')}
          </Text>
        )}

        <CloudScreenHead
          bg
          style={{
            marginTop:
              (selectedPlugin || configurations?.plugins)?.length > 0
                ? '0rem'
                : '2rem',
          }}
        >
          Your requested system is not available. Confirm to enter the queue. We
          will notify you when your system is available.
        </CloudScreenHead>

        {/* <div style={{ marginTop: '2rem' }}>
          <Text>System availability time</Text>
          <div style={{ margin: '1rem 0' }}>
            <Timer startTime={current_time} endTime={session_end_datetime} />
          </div>
        </div> */}
      </Body>

      <Footer>
        <Normal
          onClick={() => goTo(CLOUD_SCREENS.type)}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          Back
        </Normal>
        <Normal onClick={onSubmit}>Confirm</Normal>
      </Footer>
    </>
  );
};

NotAvailable.propTypes = {
  goTo: PropTypes.func,
  config: PropTypes.instanceOf(Object),
  createCancelQueueRequest: PropTypes.func,
  toggleToast: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object),
  type: PropTypes.instanceOf(Object),
  selectedSoftware: PropTypes.instanceOf(Object),
  selectedPlugin: PropTypes.instanceOf(Object),
};

export default NotAvailable;
