import * as types from './types';

export const enqueueSnackbar = (snackbar) => {
  const key = snackbar.options && snackbar.options.key;

  return {
    type: types.ENQUEUE_SNACKBAR,
    snackbar: {
      ...snackbar,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: types.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: types.REMOVE_SNACKBAR,
  key,
});
