import { combineReducers } from 'redux';
import homeReducer from '../../app/home/duck';
import storyboardReducer from '../../app/storyboard/duck';
import subscriptionReducer from '../../app/subscription/duck';
import importAssetsReducer from '../../app/import-assets/duck/reducer';
// '../../app/storyboard/duck';
import commonReducer from '../../app/main-wrapper/duck';
import playerReducer from '../../app/player/duck';
import snackbarReducer from '../../app/snackbar/duck';
import statisticsReducer from '../../app/statistics/duck';
import settingsReducer from '../../app/settings/duck';

const rootReducer = combineReducers({
  home: homeReducer,
  common: commonReducer,
  storyboard: storyboardReducer,
  subscription: subscriptionReducer,
  player: playerReducer,
  snackbar: snackbarReducer,
  statistics: statisticsReducer,
  settings: settingsReducer,
  importAssets: importAssetsReducer,
});

export default rootReducer;
