import React from 'react';
import loadable from '@loadable/component';

// Components
import { Storyboard as StoryboardSkeleton } from '../app/components/Skeleton';
import Loader from '../app/portal/loader';

const Home = loadable(() => import('../app/home'), {
  fallback: <Loader />,
});

const Project = loadable(() => import('../app/storyboard'), {
  fallback: <StoryboardSkeleton />,
});

const ImportAssets = loadable(
  () => import('../app/import-assets/ImportAssets'),
  {
    fallback: <StoryboardSkeleton />,
  }
);

const Statistics = loadable(() => import('../app/statistics'), {
  fallback: <Loader />,
});

const Subscription = loadable(() => import('../app/subscription'), {
  fallback: <Loader />,
});

const UserGuide = loadable(() => import('../app/user-guide'), {
  fallback: <Loader />,
});

const Settings = loadable(() => import('../app/settings'), {
  fallback: <Loader />,
});

const Player = loadable(() => import('../app/player'));

export default [
  {
    component: Project,
    path: '/project/:project_db_id/:folder_db_id?',
  },
  {
    component: ImportAssets,
    path: '/import-assets/:project_db_id/:service_name/:bucket_name?',
  },
  {
    component: Player,
    path: '/player/:id',
  },

  {
    component: Statistics,
    path: '/statistics',
  },

  {
    component: Subscription,
    path: '/subscription',
  },

  {
    component: UserGuide,
    path: '/user-guide',
  },

  {
    component: Settings,
    path: '/settings',
  },

  {
    component: Home,
    path: '/',
    exact: true,
  },
];
