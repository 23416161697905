import styled from 'styled-components';

import { blackAlpha, whiteAlpha } from './utils';
import { colors, fs, blur } from './variables';
import { slideUp } from './animations';

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1101;
  height: calc(100vh - 4rem);
  margin: 2rem;
  min-width: 42rem;
  width: 42rem;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transform: ${({ show }) =>
    show ? 'translate3d(0,0,0)' : 'translate3d(110%,0,0)'};
  transition: all 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  .holder {
    width: 100%;
    margin: auto;
    height: 100%;
  }

  > .inner {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 1.6rem;
    backdrop-filter: blur(${blur});
    padding: 4rem;
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: ${blackAlpha(0.1)};
    border: 1px solid ${whiteAlpha(0.2)};
  }
`;

// Header, Body, Backdrop, Actions are not used in Portal Components
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: ${colors.white};
    font-size: ${fs.lg};
    padding: 0;
  }

  svg {
    fill: ${whiteAlpha(0.8)};
    cursor: pointer;
    font-size: 2.6rem;
    transition: 0.5s;
    &:hover {
      fill: ${colors.white};
    }
  }
`;

const Body = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin: 2rem 0;
  height: ${({ noFooter }) =>
    `calc(100vh - ${noFooter ? '17.8rem' : '23.8rem'})`};
  box-sizing: border-box;
  scroll-behavior: smooth;
  animation-name: ${slideUp};
  animation-duration: 1s;
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${blackAlpha(0.5)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transition: all 225ms ease 0ms;
`;

const Footer = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
`;

export { Header, Backdrop, Sidebar, Body, Footer };
