import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Styles
import { whiteAlpha } from '../../styles/utils';

const useStyles = makeStyles({
  root: {
    width: (props) => props.width ? props.width : '8rem',
    height: '1.6rem',
    backgroundColor: whiteAlpha(0.08),
  },
});

const Title = (props) => {
  const classes = useStyles(props);
  const { width } = props;
  return (
    <Skeleton
      variant="text"
      className={classes.root}
      width={width} // width should not be in % as its independent
      height="1.6rem"
    />
  );
};

Title.propTypes = {
  width: PropTypes.string,
};

export default Title;