/* eslint-disable multiline-comment-style */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Utils
import Mixpanel from '../../../utils/mixpanel';

// Components
import { TextField, useForm } from '../../components/FormInputs';
import { Normal } from '../../components/Button';
import LabelValue from './LabelValue';

// Helper Functions
import { required } from '../../components/FormInputs/helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text } from '../../styles/common';
import { colors } from '../../styles/variables';

const BriefWrapper = styled.div`
  textarea {
    min-height: 20rem;
    max-height: -webkit-fill-available;
    padding: 2.5rem 1.2rem 1.2rem;
  }
`;

const Brief = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    closeSidebar,
    sendBrief,
    selectedTask,
    creatorDetails: { title, brief },
    match: { params },
    toggleToast,
  } = props;
  const taskdbId = (selectedTask && selectedTask.task_db_id) || null;
  const INITIAL_STATE = {
    brief: {
      // initial state to set using id of input field eg. brief is passed as "id" to TextField
      value: brief || '', // example to set default value from props
      error: undefined,
      validation: required,
      required: true,
    },
  };

  function autosize() {
    const el = document.getElementById('brief');
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + 'px';
  }

  const { formValues, onChange, onSubmit } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      Mixpanel.track('Add / Update Project brief');
      const briefResponse = await sendBrief({
        project_db_id: params.project_db_id,
        data: {
          title,
          brief: formValues.brief.value,
        },
      });

      const {
        data: { response, errormessage },
      } = briefResponse;

      setSubmitting(false);

      if (response) {
        closeSidebar();
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: 'Brief updated successfully',
            variant: 'success',
          },
        });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  const renderComponents = () => {
    if (!taskdbId) {
      return (
        <>
          <TextField
            multiline
            rows='10'
            id='brief'
            label='Add Brief'
            value={formValues.brief.value}
            onChange={onChange}
            onKeyDown={autosize}
            error={!!formValues.brief.error}
            disabled={taskdbId}
            style={{ lineHeight: '1.4' }}
          />
          {formValues.brief.error && (
            <Text color={colors?.error} lineHeight='1.2' medium>
              {formValues.brief.error}
            </Text>
          )}
        </>
      );
    }

    if (taskdbId && formValues?.brief?.value) {
      return <LabelValue label='Description' value={formValues.brief.value} />;
    }

    return (
      <Text paddingLeft='0' paddingRight='0'>
        Request your team members to add a brief
      </Text>
    );
  };

  return (
    <>
      <Body>
        <BriefWrapper>{renderComponents()}</BriefWrapper>
      </Body>

      {!taskdbId && (
        <Footer>
          <Normal onClick={onSubmit} isLoading={isSubmitting}>
            Update
          </Normal>
        </Footer>
      )}
    </>
  );
};

Brief.propTypes = {
  sendBrief: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  selectedTask: PropTypes.instanceOf(Object),
  creatorDetails: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};

export default Brief;
