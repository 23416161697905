import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { blackAlpha, whiteAlpha } from '../../styles/utils';
import { blur } from '../../styles/variables';

import NestedMenu from './nestedMenu';

/*
 * import { Send, PriorityHigh } from '@material-ui/icons';
 */

const useStyles = makeStyles({
  root: {
    margin: '0 !important',
    '& .MuiPaper-root': {
      marginTop: '2.5rem',
      minWidth: props =>
        props.orientation === 'horizontal'
          ? props?.menuWidth || '20rem'
          : 'auto',
      maxHeight: props => props?.maxHeight ?? '40rem',
      backgroundColor: blackAlpha(0.5),
      border: `1px solid ${whiteAlpha(0.2)}`,
      backdropFilter: `blur(${blur})`,
      borderRadius: '0.4rem',
      '&::-webkit-scrollbar': {
        width: 0,
      },
      '& .MuiList-root': {
        outline: 0,
        padding: 0,
        display: props =>
          props.orientation === 'horizontal' ? 'block' : 'flex',
      },
    },
  },
  menuItem: {
    minHeight: 'auto',
    padding: '1.3rem 1.5rem',
    borderBottom: props =>
      props.orientation === 'horizontal'
        ? `solid 0.1rem ${whiteAlpha(0.2)}`
        : 'none',
    borderRight: props =>
      props.orientation === 'horizontal'
        ? 'none'
        : `solid 0.1rem ${whiteAlpha(0.2)}`,
    '&:hover:not([disabled])': {
      backgroundColor: '#559eff',
    },
    '&.Mui-selected': {
      backgroundColor: '#559eff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#559eff',
    },
    '&:last-child': {
      border: 'none',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'fit-content',
      marginRight: '1rem',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem',
      fill: '#fff',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: '0.2',
    },
  },
  text: {
    textTransform: ({ menuItemTextTransform }) =>
      menuItemTextTransform ? 'capitalize' : 'inherit',
    padding: 0,
    fontSize: '1.2rem',
    fontWeight: 400,
  },
  menuButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default function IconMenu(props) {
  const classes = useStyles(props);

  const {
    menuList,
    icon,
    customMenu,
    onMenuItemClick,
    anchorOrigin,
    transformOrigin,
    isSelected,
  } = props;

  const [menuPosition, setMenuPosition] = useState(null);

  const handleClick = event => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  const onItemClick = item => {
    if (onMenuItemClick) {
      onMenuItemClick(item);
    }
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        color='primary'
        className={classes.menuButton}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Menu
        id='customized-menu'
        anchorReference='anchorPosition'
        anchorPosition={menuPosition}
        keepMounted
        open={!!menuPosition}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: anchorOrigin?.vertical || 'bottom',
          horizontal: anchorOrigin?.horizontal || 'center',
        }}
        transformOrigin={{
          vertical: transformOrigin?.vertical || 'top',
          horizontal: transformOrigin?.horizontal || 'center',
        }}
        className={classes.root}
        {...props}
      >
        <div>
          {menuList && menuList.length
            ? menuList.map(
                item =>
                  (item?.isVisible || item?.isVisible === undefined) && (
                    <>
                      {item?.nested?.length > 0 ? (
                        <NestedMenu
                          {...item}
                          onMenuItemClick={onItemClick}
                          menuPosition={menuPosition}
                        />
                      ) : (
                        <MenuItem
                          key={item?.field}
                          className={classes.menuItem}
                          disabled={item?.isDisabled || false}
                          selected={item?.field === isSelected || false}
                          onClick={() => onItemClick(item)}
                        >
                          {item?.icon && (
                            <ListItemIcon>{item.icon}</ListItemIcon>
                          )}
                          {typeof item.name === 'string' ? (
                            <Typography className={classes.text} noWrap>
                              {item.name}
                            </Typography>
                          ) : (
                            item.name
                          )}
                        </MenuItem>
                      )}
                    </>
                  )
              )
            : null}
          {customMenu || null}
        </div>
      </Menu>
    </div>
  );
}

IconMenu.propTypes = {
  menuList: PropTypes.instanceOf(Array),
  icon: PropTypes.node,
  customMenu: PropTypes.node,
  onMenuItemClick: PropTypes.func,
  orientation: PropTypes.string,
  anchorOrigin: PropTypes.instanceOf(Object),
  transformOrigin: PropTypes.instanceOf(Object),
  isSelected: PropTypes.number,
  menuWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  menuItemTextTransform: PropTypes.bool,
};

IconMenu.defaultProps = {
  menuItemTextTransform: true,
  orientation: 'horizontal',
  maxHeight: '40rem',
};
