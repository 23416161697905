/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import Card from './Card';
import GradientCard from './GradientCard';
import Pricing from './Pricing';
import {
  fetchCloudSoftwaresPlugins,
  fetchCloudHardwares,
} from '../../duck/operations';
import { getPluginRequest } from '../../helperFunctions';
import { CLOUD_SCREENS } from './constants';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { whiteAlpha } from '../../../styles/utils';
import { Text } from '../../../styles/common';
import { GridStyles, CloudScreenHead } from './styles';

const checkPluginSelected = (plugins, slug) =>
  plugins.findIndex(el => el.slug === slug) >= 0;

const Plugins = props => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [plugins, setPlugins] = useState(null);
  const {
    match: { params },
    task: { task_db_id, project_db_id },
    type,
    goTo,
    selectPlugin,
    selectedSoftware,
    selectedPlugin,
    toggleUpgradeModal,
    toggleToast,
    setHardwares,
  } = props;

  useEffect(() => {
    setLoading(true);
    fetchCloudSoftwaresPlugins({
      software_slug: selectedSoftware?.slug,
      ...(task_db_id && { task_db_id }),
      project_db_id: params?.project_db_id || project_db_id,
      task_type: type?.value,
    })
      .then(res => {
        const { response, data, errormessage } = res?.data;
        if (response) {
          setLoading(false);
          setPlugins(data?.plugins);
        } else if (data?.upgrade_required) {
          setLoading(false);
          toggleUpgradeModal({ show: true, data });
        } else {
          throw new Error(errormessage);
        }
      })
      .catch(err => {
        setLoading(false);
        setPlugins(null);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: err?.errormessage,
            variant: 'error',
          },
        });
      });
  }, []);

  const togglePlugin = item => {
    const updated = [...selectedPlugin];

    const autoSelectPluginsConfigs = plugins.filter(
      x => item.configurations.auto_select.indexOf(x.slug) !== -1
    );

    const selectedIndex = selectedPlugin.findIndex(
      el => item?.slug === el?.slug
    );

    if (selectedIndex >= 0) {
      autoSelectPluginsConfigs.length = 0;
      updated.splice(selectedIndex, 1);
    } else {
      const autoSelectIndex = updated.findIndex(
        x => item.configurations.auto_select.indexOf(x.slug) !== -1
      );

      if (autoSelectIndex >= 0) {
        updated.push(item);
      } else {
        updated.push(...autoSelectPluginsConfigs, item);
      }
    }
    selectPlugin(updated);
  };

  const onSubmit = async () => {
    setSubmitting(true);

    const configsResponse = await fetchCloudHardwares({
      // this will get system configs available based on task type
      software_slug: selectedSoftware?.slug,
      project_db_id: params?.project_db_id,
      task_type: type?.value,
      ...(task_db_id && { task_db_id }),
      ...(selectedPlugin?.length > 0 && {
        plugin_slug: getPluginRequest(selectedPlugin),
      }),
    });

    const {
      data: { response, data, errormessage },
    } = configsResponse;

    if (response) {
      setSubmitting(false);
      setHardwares(data?.hardwares);
      goTo(CLOUD_SCREENS.hardware);
    } else {
      setSubmitting(false);
      setHardwares([]);
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <GradientCard {...type} details={[selectedSoftware?.title]} />

        <CloudScreenHead bg style={{ marginTop: '2rem' }}>
          Choose the add-on plug-ins you want with the application
          package(optional):
        </CloudScreenHead>

        {loading ? (
          <Text color={whiteAlpha(0.8)} paddingLeft='0' paddingRight='0'>
            Fetching
          </Text>
        ) : (
          <>
            {plugins?.length > 0 ? (
              <GridStyles>
                {plugins.map(item => {
                  const { title, slug, configurations } = item;
                  return (
                    <div key={slug}>
                      <Card
                        isSelected={checkPluginSelected(selectedPlugin, slug)}
                        label={title}
                        hoverScreen={{
                          para: configurations?.description || '',
                        }}
                        onClick={() => {
                          togglePlugin(item);
                        }}
                      >
                        {item?.pricing > 0 && <Pricing {...item} />}
                      </Card>
                    </div>
                  );
                })}
              </GridStyles>
            ) : (
              <Text lineHeight='1.4' paddingLeft='0' paddingRight='0'>
                No Plugin Found
              </Text>
            )}
          </>
        )}
      </Body>

      <Footer>
        <Normal
          onClick={() => {
            selectPlugin([]);
            goTo(CLOUD_SCREENS.softwares);
          }}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          Back
        </Normal>
        <Normal onClick={onSubmit} isLoading={submitting}>
          Next
        </Normal>
      </Footer>
    </>
  );
};

Plugins.propTypes = {
  goTo: PropTypes.func,
  selectSoftware: PropTypes.func,
  setConfig: PropTypes.func,
  selectPlugin: PropTypes.func,
  getSystems: PropTypes.func,
  toggleToast: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  type: PropTypes.instanceOf(Object),
  selectedSoftware: PropTypes.instanceOf(Object),
  selectedPlugin: PropTypes.instanceOf(Object),
  selectedHardware: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  setHardwares: PropTypes.func,
};

export default Plugins;
