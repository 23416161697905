/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */

Object.defineProperty(exports, '__esModule', {
  value: true,
});
const monthName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DateTime = {
  toDateString: function toDateString(date, format) {
    const updatedDate =
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const updatedYear = date.getFullYear();
    if (typeof date === 'number') {
      date = new Date(date);
    }
    if (format === 'mmm DD, YYYY') {
      return updatedDate + ' ' + monthName[date.getMonth()] + ' ' + updatedYear;
    }
    return (
      updatedDate +
      '/' +
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      '/' +
      updatedYear
    );
  },

  toTimeString: function toTimeString(date, format) {
    if (typeof date === 'number') {
      date = new Date(date);
    }
    const hrs = date.getHours();
    const mins = date.getMinutes();

    if (format === 'HH:MM AP') {
      return (
        (hrs < 12
          ? hrs == 0
            ? '12'
            : hrs < 10
            ? '0' + hrs
            : hrs
          : hrs == 12
          ? '12'
          : hrs < 22
          ? '0' + (hrs - 12)
          : hrs - 12) +
        ':' +
        (mins < 10 ? '0' + mins : mins) +
        ' ' +
        (hrs < 12 ? 'AM' : 'PM')
      );
    }
    return (hrs < 10 ? '0' + hrs : hrs) + ':' + (mins < 10 ? '0' + mins : mins);
  },

  toDateTimeString: function toDateTimeString(date) {
    return (
      DateTime.toDateString(date, 'mmm DD, YYYY') +
      ' | ' +
      DateTime.toTimeString(date, 'HH:MM AP')
    );
  },
};

exports.default = DateTime;
