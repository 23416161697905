/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Delete } from '@material-ui/icons';
import { PropTypes } from 'prop-types';

// Utils
import Mixpanel from '../../../utils/mixpanel';

// Components
import { useForm, AutoComplete } from '../../components/FormInputs';
import { Normal } from '../../components/Button';
import AssetOverview from './AssetOverview';

// Helper Functions
import { formatLabelValue } from '../../helperFunctions';
import { requestForIdsEmails } from '../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import {
  Text,
  Flex,
  AvatarSecondaryTextStyles,
  CustomAvatarStyles,
  AvatarUsernameStyles,
} from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { AvatarBadgeParent, Detail, IconButton, User } from './styles';

const INITIAL_STATE = {
  /*
   * initial state to set using id of input field eg. brief is passed as "id" to TextField
   * example to set default value from props
   */

  approvalFrom: {
    value: [],
  },
};

const TASK_APPROVAL_KEY = 'task_approval';

const RequestApproval = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    loading: false,
    apiResponse: {},
    users: [],
    open: false,
  });

  const {
    closeSidebar,
    toggleToast,
    getUsersBasedOnFilters,
    assetDetails,
    createUpdateTaskRequest,
    userDetails,
    creatorDetails,
    toggleUpgradeModal,
  } = props;

  const { formValues, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      Mixpanel.track('Request Approval', {
        asset_type: assetDetails.file_type,
      });
      const baseRequest = {
        ingest_db_id: assetDetails.ingest_db_id,
        user_ids: [],
        user_emails: [],
        task_type: TASK_APPROVAL_KEY,
        comment: 'Approval Request created',
      };

      const request = requestForIdsEmails(
        formValues.approvalFrom.value,
        baseRequest,
        'user_emails',
        'user_ids'
      );
      const usersResponse = await createUpdateTaskRequest(
        { ...request },
        'post'
      );
      const {
        data: { response, errormessage, data },
      } = usersResponse;
      setSubmitting(false);
      if (response) {
        closeSidebar();
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: 'Approval request created successfully',
            variant: 'success',
          },
        });
      } else if (data && data.upgrade_required) {
        toggleUpgradeModal({ show: true, data });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    // this to fetch user if already assinged users for review
    (async () => {
      const usersResponse = await createUpdateTaskRequest(
        {
          ingest_db_id: assetDetails.ingest_db_id,
          action: TASK_APPROVAL_KEY,
        },
        'get'
      );

      const {
        data: { response, data },
      } = usersResponse;
      setState({
        ...state,
        loading: false,
        apiResponse: response ? data : {},
      });
    })();
  }, []);

  const getUsersToApprove = async (e, value, reason) => {
    // this is triggered onChange
    const { target } = e;
    if (reason === 'select-option' || reason === 'remove-option') {
      updateValues({
        ...formValues,
        approvalFrom: {
          ...formValues.approvalFrom,
          value: value.map(item => ({ label: item.label, value: item.value })),
        },
      });
      setState({ ...state, open: false });
    } else if (target.value.length > 2) {
      const usersResponse = await getUsersBasedOnFilters({
        filters: TASK_APPROVAL_KEY,
        search_text: target.value?.trim(),
      });

      const {
        data: { response, data },
      } = usersResponse;
      if (response) {
        const formated = formatLabelValue(data, 'name', 'user_id', 'username');
        setState({
          ...state,
          users: formated,
          open: true,
        });
      }
    } else {
      setState({
        ...state,
        users: [],
        open: false,
      });
    }
  };

  const renderDeleteButton = (user, key) => {
    return (
      creatorDetails &&
      creatorDetails.created_by_profile &&
      creatorDetails.created_by_profile.user_id === userDetails.user_id && (
        <Flex>
          <IconButton>
            <Delete
              onClick={() => {
                setState({
                  ...state,
                  loading: true,
                });

                const request = {
                  task_request_db_id:
                    state.apiResponse.request_detail.task_request_db_id,
                  ingest_db_id: assetDetails.ingest_db_id,
                  task_type: TASK_APPROVAL_KEY,
                  [key]: key === 'user_db_id' ? user.user_id : user,
                };

                createUpdateTaskRequest({ ...request }, 'put')
                  .then(res => {
                    const {
                      data: { response, errormessage, data },
                    } = res;
                    toggleToast({
                      message: {
                        messageHead: response ? 'Success' : 'Error',
                        messageBody: response
                          ? 'Deleted user successfully'
                          : errormessage,
                        variant: response ? 'success' : 'error',
                      },
                    });

                    setState({
                      ...state,
                      loading: false,
                      apiResponse: data.request,
                    });
                  })
                  .catch(() => {
                    toggleToast({
                      message: {
                        messageHead: 'Error',
                        messageBody: 'Something went wrong',
                        variant: 'error',
                      },
                    });
                  });
              }}
            />
          </IconButton>
        </Flex>
      )
    );
  };

  return (
    <>
      <Body noFooter={!formValues.approvalFrom.value.length}>
        <AssetOverview {...assetDetails} />
        <Text color={whiteAlpha(0.6)} className='px-0 mt-2'>
          Add people you want Approval from
        </Text>

        <div>
          <AutoComplete
            id='approvalFrom'
            label='Add by Username or Email'
            multiple
            open={state.open}
            onClose={() => setState({ ...state, open: false })}
            value={formValues.approvalFrom.value}
            onChange={getUsersToApprove}
            options={state.users}
            getOptionDisabled={option =>
              [
                ...state.apiResponse.users,
                userDetails,
                ...state.apiResponse.invited,
              ].some(item => {
                if (item.user_id) {
                  return item.user_id === option.value;
                }
                return item === option.value;
              })
            }
          />
        </div>

        {state.loading ? (
          <Text color={whiteAlpha(0.8)} className='px-0'>
            Fetching users
          </Text>
        ) : (
          <div>
            {state.apiResponse &&
              ((state.apiResponse.invited &&
                state.apiResponse.invited.length > 0) ||
                (state.apiResponse.users &&
                  state.apiResponse.users.length > 0)) && (
                <>
                  <Text color={whiteAlpha(0.8)} className='px-0'>
                    This asset has been shared previously with
                  </Text>

                  {state.apiResponse.users.map((item, i) => {
                    const {
                      first_name,
                      last_name,
                      name,
                      username,
                      user_badge_color,
                    } = item;
                    return (
                      <User key={`users-${i + 1}`}>
                        <Detail>
                          <AvatarBadgeParent>
                            <CustomAvatarStyles color={user_badge_color}>
                              {first_name.charAt(0)}
                              {last_name.charAt(0)}
                            </CustomAvatarStyles>
                          </AvatarBadgeParent>
                          <div>
                            <AvatarUsernameStyles>{name}</AvatarUsernameStyles>
                            <AvatarSecondaryTextStyles secondary>
                              {username}
                            </AvatarSecondaryTextStyles>
                          </div>
                        </Detail>
                        {renderDeleteButton(item, 'user_db_id')}
                      </User>
                    );
                  })}

                  {state.apiResponse.invited.map((item, i) => {
                    return (
                      <User key={`invited-${i + 1}`}>
                        <Detail>
                          <AvatarBadgeParent>
                            <CustomAvatarStyles secondary />
                          </AvatarBadgeParent>
                          <div>
                            <AvatarUsernameStyles>{item}</AvatarUsernameStyles>
                            <AvatarSecondaryTextStyles secondary>
                              {item}
                            </AvatarSecondaryTextStyles>
                          </div>
                        </Detail>
                        {renderDeleteButton(item, 'user_email')}
                      </User>
                    );
                  })}
                </>
              )}
          </div>
        )}
      </Body>

      {formValues.approvalFrom.value.length > 0 && (
        <Footer>
          <Normal onClick={onSubmit} isLoading={isSubmitting}>
            Share
          </Normal>
        </Footer>
      )}
    </>
  );
};

RequestApproval.propTypes = {
  closeSidebar: PropTypes.func,
  createUpdateTaskRequest: PropTypes.func,
  getUsersBasedOnFilters: PropTypes.func,
  toggleToast: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  creatorDetails: PropTypes.instanceOf(Object),
  assetDetails: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object),
};

export default RequestApproval;
