/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {useState, useMemo, useEffect, useCallback} from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import { Normal } from '../../components/Button';
import { Checkbox } from '../../components/FormInputs';

// helper functions
import { amountWithCommas } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text, Flex } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';

const GradientBoxStyles = styled(Flex)`
  padding: 0.5rem 0;
  margin-top: 1.5rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid ${({ plan }) => {
    if (plan === 'Pro') {
      return '#E2C00F';
    }
    if (plan === 'Team') {
      return '#ff8d29';
    }
    if (plan === 'SME') {
      return '#6085FF';
    }
    return '#18B96E';
  }};

  background: ${({ plan }) => {
    if (plan === 'Pro') {
      return 'linear-gradient(to right, #E2C00F, #FFA751)';
    }
    if (plan === 'Team') {
      return 'linear-gradient(to right, #ff8d29, #f6462c)';
    }
    if (plan === 'SME') {
      return 'linear-gradient(to right, #6085FF, #C16DC4)';
    }
    return 'linear-gradient(to right, #18B96E, #2C86FF)';
  }};
`;

const UseCredits = ({upgradePlan, renewSubscription, selectPlan, closeSidebar, toggleToast, loadSubscriptionDetails}) => {
  const { credits, selectedPlan, plan_detail, storage_detail } = useSelector((state) => state.subscription);
  const [isCheckboxSelected, toggleCheckbox] = useState(false);
  const [addonStorage, setAddonStorage] = useState(0);
  const [amountToBePaid, setAmountToBePaid] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [total, setTotal] = useState(selectedPlan?.amount || plan_detail?.subscription_amount || 0);

  // reset the selected plan
  const clearSelectedPlan = useCallback(() => {
    selectPlan({});
    closeSidebar();
  }, []);

  // to reset the selected plan on sidebar close
  useEffect(() => {
    return () => {
      selectPlan({});
    };
  }, []);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    let requestResponse = null;

    if (Object.keys(selectedPlan).length) {
      // upgrade
      requestResponse = await upgradePlan({
        subscription_db_id: selectedPlan?.subscription_db_id,
        credits_used: isCheckboxSelected
      }, 'post');
    } else {
      // renew
      requestResponse = await renewSubscription({
        months: 1,
        subscription_db_id: selectedPlan?.subscription_db_id,
        credits_used: isCheckboxSelected
      });
    }

    setSubmitting(false);

    const { errormessage, data, response } = requestResponse?.data;
    if (response) {
      if (Object.keys(selectedPlan).length) {
        loadSubscriptionDetails(data);
      }
      clearSelectedPlan();
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: data?.message || 'Plan updated',
          variant: 'success',
        },
      });
    } else {
      clearSelectedPlan();
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  }, [isCheckboxSelected]);

  useEffect(() => {
    let updatedTotal = 0;
    let updatedAddonStorage = ((storage_detail?.total_storage - selectedPlan?.storage) * selectedPlan?.amount_per_gb_storage) || storage_detail?.storage_amount;
    
    if (updatedAddonStorage < 0) {
      updatedAddonStorage = 0;
    }

    updatedTotal = updatedAddonStorage + (selectedPlan?.amount || plan_detail?.subscription_amount);
    setTotal(updatedTotal);

    if (isCheckboxSelected) {
      if (updatedTotal < credits?.credits_available) {
        setAmountToBePaid(updatedTotal);
      } else {
        updatedTotal -= credits?.credits_available;
        setAmountToBePaid(updatedTotal);
      }
    } else {
      setAmountToBePaid(updatedTotal);
    }

    setAddonStorage(updatedAddonStorage);

  }, [isCheckboxSelected, credits?.credits_available, selectedPlan, plan_detail, storage_detail]);

  return useMemo(() => {
    return (
      <>
        <Body> 
        {
          Object.keys(selectedPlan).length > 0 ? <GradientBoxStyles
            plan={selectedPlan?.title}
          >
            <div>
              <Text style={{ fontSize: '3rem' }}>₹{amountWithCommas(selectedPlan?.amount)}</Text>
              <Text md className="pt-0">
                {selectedPlan?.title}
              </Text>
            </div>
            </GradientBoxStyles>
          
          :
            <div className='mt-1 rounded p-1 border border-1 border-white-0-point-3'>
              <Flex className='items-baseline justify-between'>
                <Text color={whiteAlpha(0.8)}>Subscription Charges</Text>
                <Text className='py-0'>{amountWithCommas(plan_detail?.subscription_amount)}</Text>
              </Flex>
              <Flex className='items-baseline justify-between'>
                <Text color={whiteAlpha(0.8)}>Add-on Storage: {storage_detail?.storage_added} GB</Text>
                <Text className='py-0'>{amountWithCommas(addonStorage)}</Text>
              </Flex>
              <div className='mx-0-8 border-t border-1 border-white-0-point-3 mt-1' />
              <Flex className='items-baseline justify-between pt-1'>
                <Text color={whiteAlpha(0.8)}>Total (PSC)</Text>
                <Text className='py-0'>{amountWithCommas(total)}</Text>
              </Flex>
            </div>
        }
                        
          <div
            className='flex items-center justify-between rounded px-1 py-2 border border-1 border-white-0-point-3 w-full bg-transparent cursor-pointer my-1'
            onClick={() => toggleCheckbox((prev) => !prev)}
            aria-hidden="true"
          >
            <div>
              <Text className='py-0' color={whiteAlpha(0.8)}>Available Credits</Text>
              <Flex className='items-baseline'>
                <Text bg className='pb-0 pr-0-5'>{amountWithCommas(credits?.credits_available)}</Text>
                <Text sm color={whiteAlpha(0.6)} className='px-0 py-0'>PSC</Text>
              </Flex>
            </div>

            <div className='px-0-8'>
              <Checkbox
                checked={isCheckboxSelected}
                value={isCheckboxSelected}
              />
            </div>
          </div>

          <Text className='px-0'>
            1 PSC = ₹1.
          </Text>
          <Text padding='0'>{total < credits?.credits_available && isCheckboxSelected ? `₹${amountWithCommas(total)} will be deducted from your credits` : `Make a payment of ₹${amountWithCommas(amountToBePaid)}`}</Text>
          
        </Body>
        <Footer>
          <Normal onClick={onSubmit} isLoading={isSubmitting}>Next</Normal>
        </Footer>
      </>
    );
  }, [amountToBePaid, setAmountToBePaid, addonStorage, isSubmitting, isCheckboxSelected, toggleCheckbox, credits?.credits_available, selectedPlan, storage_detail, total]);
};

UseCredits.propTypes = {
  credits: PropTypes.instanceOf(Object),
  selectedPlan: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  renewSubscription: PropTypes.func,
  selectPlan: PropTypes.func,
  upgradePlan: PropTypes.func,
  loadSubscriptionDetails: PropTypes.func
};

export default UseCredits;
