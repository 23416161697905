import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

import { Normal } from '../../../../components/Button';

import { sprinklrIntegrationService } from '../../../duck/operations';

import { SPRINKLR_STEPS } from '../../../constants/integration';

import { Body, Footer } from '../../../../styles/sidebar';
import {
  Text,
  ListKeyValueItemStyles,
  AppLinkButton,
} from '../../../../styles/common';
import { IntegrationStatusStyles } from '../index';
import DeleteModal from './DeleteModal';

const Details = ({ setIntegration, selectedIntegration, goTo }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [details, setDetails] = useState(null);
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);

  const onBack = () => goTo(SPRINKLR_STEPS.list);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data } = await sprinklrIntegrationService({
          team_db_id: selectedTeam?.team_db_id,
          team_id: selectedTeam?.team_db_id,
          integration_db_id: selectedIntegration,
        });
        if (data?.response) {
          setFetchingDetails(false);
          setDetails(data?.data?.integration);
        } else throw Error(data?.errormessage);
      } catch (err) {
        setFetchingDetails(false);
        setDetails(null);
        console.log('🚀 ~ fetchDetails ~ err:', err?.message);
      }
    };

    if (selectedIntegration) {
      fetchDetails();
    }

    return () => {
      setIntegration(null);
      setDetails(null);
    };
  }, [selectedIntegration]);

  return (
    <>
      {fetchingDetails ? (
        <Body noFooter>
          <Text>Fetching bucket details</Text>
        </Body>
      ) : (
        <>
          {details && (
            <>
              <Body>
                <div className='grid gap-y-1-point-5'>
                  <div>
                    <ul
                      className='grid gap-y-1-point-5'
                      style={{ gridTemplateColumns: 'repeat(2,minmax(0,1fr))' }}
                    >
                      <ListKeyValueItemStyles>
                        <p>Environment</p>
                        <h4>{details?.environment}</h4>
                      </ListKeyValueItemStyles>

                      <ListKeyValueItemStyles>
                        <p>Business Unit</p>
                        <h4>{details?.business_unit}</h4>
                      </ListKeyValueItemStyles>

                      <ListKeyValueItemStyles>
                        <p>Campaign ID</p>
                        <h4>{details?.campaign_id}</h4>
                      </ListKeyValueItemStyles>

                      <ListKeyValueItemStyles>
                        <p>Campaign Name</p>
                        <h4>{details?.campaign_name}</h4>
                      </ListKeyValueItemStyles>
                    </ul>
                  </div>

                  <IntegrationStatusStyles
                    status={details?.status?.toLowerCase()}
                  >
                    <Text>Integration Status: {details?.status}</Text>
                  </IntegrationStatusStyles>

                  <AppLinkButton
                    onClick={() => {
                      toggleModal(true);
                    }}
                    style={{ textAlign: 'left' }}
                  >
                    Delete Integration
                  </AppLinkButton>
                </div>
              </Body>
              <Footer>
                <Normal onClick={onBack} color='secondary'>
                  Back
                </Normal>
              </Footer>
            </>
          )}
        </>
      )}
      {isModalOpen && (
        <DeleteModal
          toggleModal={toggleModal}
          integration_db_id={selectedIntegration}
          open={isModalOpen}
          goTo={goTo}
          details={details}
        />
      )}
    </>
  );
};

Details.propTypes = {
  selectedIntegration: PropTypes.string,
  setIntegration: PropTypes.func,
  goTo: PropTypes.func,
};

export default Details;
