/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

// Styles
import { colors } from '../../styles/variables';
import { blackAlpha } from '../../styles/utils';

const Parent = styled.div`
  width: auto;
  padding: 0.5rem 0.9rem;
  background-color: ${({ dark }) => dark ? blackAlpha(0.5) : colors.black.primary};
  color: ${colors.white};
  font-size: 1rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;

  > .color {
    margin-right: 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
    background-color: ${({ color }) => color && color};
  }
`;

const getColorBasedOnStatus = (status) => {

  let color = '';

  switch (status) {

    case 'Pending': {
      color = '#559eff';
      break;
    }
    case 'Overdue': {
      color = '#f6462c';
      break;
    }
    case 'In Progress': {
      color = '#ffa751';
      break;
    }
    case 'Completed': {
      color = '#00e6b9';
      break;
    }
    case 'Transfer Request': {
      color = '#ff9a23';
      break;
    }
    default: break;
  }
  return color;
};

const Pill = (props) => {
  const { status } = props;
  return (
    <Parent color={getColorBasedOnStatus(status)}>
      <div className="color" />
      <div>{status}</div>
    </Parent>
  );
};

Pill.propTypes = {
  status: PropTypes.string,
};

export default Pill;