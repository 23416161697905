export const INTEGRATION_SCREENS = {
  list: 'LIST',
};

export const S3_STEPS = {
  newEdit: 'NEW_EDIT',
  bucketDetails: 'BUCKET_DETAILS',
  integratedRegions: 'INTEGRATED_REGIONS',
};

export const SPRINKLR_STEPS = {
  newEdit: 'NEW_EDIT',
  list: 'LIST',
  details: 'DETAILS',
  integratedRegions: 'INTEGRATED_REGIONS',
};
