const sidebarTypes = {
  profileSettings: 'PROFILE_SETTINGS',
  accountSettings: 'ACCOUNT_SETTINGS',
  notification: 'NOTIFICATION',
  addUpdateBrief: 'ADD_UPDATE_BRIEF',
  createTask: 'CREATE_TASK',
  updateTask: 'UPDATE_TASK',
  addUpdateCollaborators: 'ADD_UPDATE_COLLABORATORS',
  taskDetails: 'TASK_DETAILS',
  manageProject: 'MANAGE_PROJECT',
  requestApproval: 'REQUEST_APPROVAL',
  requestReview: 'REQUEST_REVIEW',
  cloudAccess: 'CLOUD_ACCESS',
  transferTask: 'TRANSFER_TASK',
  permissions: 'PERMISSIONS',
  shareAsset: 'SHARE_ASSET',
  chat: 'TASK_CHAT',
  subscriptionPlans: 'SUBSCRIPTION_PLANS',
  addStorage: 'ADD_STORAGE',
  addCredits: 'ADD_CREDITS',
  useCredits: 'USE_CREDITS',
  subscriptionPlanDetails: 'SUBSCRIPTION_PLAN_DETAILS',
  subscriptionRenewalDetails: 'SUBSCRIPTION_PLAN_RENEWAL_DETAILS',
  subscriptionStorageDetails: 'SUBSCRIPTION_PLAN_STORAGE_DETAILS',
  cloudNextSteps: 'CLOUD_NEXT_STEPS',
  settingsCreateTeam: 'SETTINGS_CREATE_TEAM',
  settingsBillingDetails: 'SETTINGS_BILLING_DETAILS',
  settingsTeamMembers: 'SETTINGS_TEAM_MEMBERS',
  settingsTeamMemberPermissions: 'SETTINGS_TEAM_MEMBERS_PERMISSIONS',
  settingsTeam: 'SETTINGS_TEAM',
  integration: 'INTEGRATION',
  s3Integration: 'S3_INTEGRATION',
  sprinklrIntegration: 'SPRINKLR_INTEGRATION',
  liveCollaboration: 'LIVE_COLLABORATION',
  projectChat: 'PROJECT_CHAT',
  projectHelp: 'PROJECT_HELP',
  metadata: 'ASSET_METADATA',
  cameraToEdit: 'CAMERA_TO_EDIT',
  fetchToUploadRawFiles: 'FETCH_TO_UPLOAD_RAW_FILES',
};

export default sidebarTypes;
