const SIDEBAR_TYPES = {
  addUpdateBrief: 'ADD_UPDATE_BRIEF',
  createTask: 'CREATE_TASK',
  updateTask: 'UPDATE_TASK',
  addUpdateCollaborators: 'ADD_UPDATE_COLLABORATORS',
  taskDetails: 'TASK_DETAILS',
  manageStoryboard: 'MANAGE_STORYBOARD',
  requestApproval: 'REQUEST_APPROVAL',
  requestReview: 'REQUEST_REVIEW',
  cloudAccess: 'CLOUD_ACCESS',
  transferTask: 'TRANSFER_TASK',
};

const FILE_EXTENSTIONS_NOT_ALLOWED = [
  'py',
  'pyc',
  'pyo',
  'pyw',
  'pyz',
  'pyzw',
  'ps1',
  'ps1xml',
  'ps2',
  'ps2xml',
  'psc1',
  'psc2',
  'psd1',
  'psdm1',
  'psd1',
  'psdm1',
  'cdxml',
  'pssc',
  'jar',
  'jnlp',
  'cer',
  'crt',
  'der',
  'appref-ms',
  'udl',
  'wsb',
  'bat',
  'apk',
  'cgi',
  'com',
  'gadget',
  'wsf',
  'torrent',
  'appcontent-ms',
  'settingcontent-ms',
  'cnt',
  'hpj',
  'website',
  'webpnp',
  'mcf',
  'printerexport',
  'pl',
  'theme',
  'vbp',
  'xbap',
  'xll',
  'xnk',
  'msu',
  'diagcab',
  'grp',
  'exe',
  'msi',
  'msix',
  'DS_Store',
];

const LIVE_COLLABORATION_STEPS = {
  list: 'list',
  invite: 'INVITE',
};

const CAMERA_TO_EDIT_SESSION_STEPS = {
  new: 'NEW',
  list: 'LIST',
  details: 'DETAILS',
};

export const UPLOADER_CONNECTOR_APP_KEYS = {
  windows: {
    label: 'Windows',
    key: 'windows',
  },
  windowsPortable: {
    label: 'Windows Portable',
    key: 'windows-portable',
  },
  macosIntel: {
    label: 'macOS Intel Chip',
    key: 'macos-intel',
  },
  macosSilicon: {
    label: 'macOS Apple Silicon',
    key: 'macos-silicon',
  },
};

const TAB_KEYS = {
  userAsset: 'user_asset',
  projectAsset: 'project_asset',
  cameraToEdit: 'camera_to_edit_asset',
};

const SELECTION_KEYS = {
  selectItem: 'SELECT_ITEM',
  removeItem: 'REMOVE_ITEM',
  reset: 'RESET_SELECTION',
  all: 'SELECT_ALL',
};

export {
  SIDEBAR_TYPES,
  FILE_EXTENSTIONS_NOT_ALLOWED,
  LIVE_COLLABORATION_STEPS,
  TAB_KEYS,
  SELECTION_KEYS,
  CAMERA_TO_EDIT_SESSION_STEPS,
};
