import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ArrowUpward,
  FolderOpen,
  CreateNewFolderOutlined,
} from '@material-ui/icons';

import apiConfig from '../../../config';

// Styles
import { whiteAlpha } from '../../styles/utils';
import { colors } from '../../styles/variables';

const UploadImage = `${apiConfig.assets}/upload.png`;

const Browse = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  .image-wrapper {
    width: 24rem;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .upload-text {
    font-size: 2rem;
    color: ${whiteAlpha(0.8)};
    padding: 0;
    margin: 3rem 0;
  }
  .upload-block {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3rem;
  }
`;

const UploadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid ${colors.blue.primary};
    border-radius: 50%;
    background: transparent;
    padding: 0.5rem;
    .MuiSvgIcon-root {
      fill: ${colors.blue.primary};
      width: 1.4em;
      height: 1.4em;
    }
  }
  .upload-type {
    font-size: 1.4rem;
    color: ${colors.blue.primary};
    padding: 0 0 0 1rem;
  }
  &:hover {
    .icon-wrapper {
      background: ${colors.blue.primary};
      .MuiSvgIcon-root {
        fill: ${colors.white};
      }
    }
  }
`;

const BrowseFiles = props => {
  const { optionClick } = props;
  return (
    <Browse>
      <div className='image-wrapper'>
        <img src={UploadImage} alt='Upload assets' />
      </div>

      <p className='upload-text'>
        Browse assets/folders or Drag &amp; Drop here to upload.
      </p>
      <div className='upload-block'>
        {/* file */}
        <UploadSection
          onClick={() => optionClick('upload_files')}
          role='presentation'>
          <div className='icon-wrapper'>
            <ArrowUpward />
          </div>
          <p className='upload-type'>Upload Files</p>
        </UploadSection>

        {/* folder */}
        <UploadSection
          onClick={() => optionClick('upload_folders')}
          role='presentation'>
          <div className='icon-wrapper'>
            <FolderOpen />
          </div>
          <p className='upload-type'>Upload Folder</p>
        </UploadSection>

        {/* create folder */}
        <UploadSection
          onClick={() => optionClick('create_folder')}
          role='presentation'>
          <div className='icon-wrapper'>
            <CreateNewFolderOutlined />
          </div>
          <p className='upload-type'>Create Folder</p>
        </UploadSection>

        {/* <UploadSection onClick={onDownloadUploaderApp} role='presentation'>
          <div className='icon-wrapper'>
            <GetApp />
          </div>
          <p className='upload-type'>Download Uploader App</p>
        </UploadSection> */}
      </div>
    </Browse>
  );
};

BrowseFiles.propTypes = {
  optionClick: PropTypes.func,
  // onDownloadUploaderApp: PropTypes.func,
};

export default BrowseFiles;
