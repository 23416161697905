/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button as MaterialNormalButton,
  CircularProgress,
} from '@material-ui/core';

// Styles
import { colors } from '../../styles/variables';

const useStyles = makeStyles({
  btn: {
    minWidth: props => props.minWidth || '16rem',
    background: props =>
      props.color === 'secondary' ? colors.black.primary : colors.blue.primary,
    color: colors.white,
    borderRadius: '3.2rem',
    padding: ({ padding }) => padding || '1.5rem 3rem',
    fontSize: props => props.fontSize || '1.4rem',
    lineHeight: 'normal',
    fontWeight: '400',
    textTransform: ({ textTransform }) => textTransform || 'uppercase',
    '&:hover': {
      background: props =>
        props.color === 'secondary'
          ? colors.black.secondary
          : colors.blue.secondary,
    },

    '&.Mui-disabled': {
      color: colors.white,
      background: props =>
        props.color === 'secondary'
          ? colors.black.primary
          : colors.blue.primary,
      opacity: '0.7',
    },
  },

  circularProgress: {
    '&.MuiCircularProgress-colorPrimary': {
      color: colors.white,
    },
  },
});

const Normal = props => {
  const { children, disabled, isLoading, ...otherProps } = props;
  const classes = useStyles(otherProps);
  return (
    <MaterialNormalButton
      {...otherProps}
      variant='contained'
      disableElevation
      disabled={disabled || isLoading}
      classes={{
        root: classes.btn,
      }}
    >
      {isLoading ? (
        <CircularProgress
          size={15}
          classes={{
            root: classes.circularProgress,
          }}
        />
      ) : (
        children
      )}
    </MaterialNormalButton>
  );
};

Normal.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Normal;
