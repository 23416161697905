/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router-dom';

// Components
import { Select, useForm } from '../../components/FormInputs';
import { Normal } from '../../components/Button';
import { getCameraModelService } from '../duck/operations';
import { required } from '../../components/FormInputs/helperFunctions';

// Styles
import { Text } from '../../styles/common';
import { Body, Footer } from '../../styles/modal';

const INITIAL_STATE = {
  model: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },
};

const SelectCameraModelDialog = props => {
  const { toggleModal, onSuccess } = props;
  const { project_db_id } = useParams();
  const [cameraModelOptions, setCameraModelOptions] = useState([]);

  const onClose = () => toggleModal(null, false, null);

  const { formValues, onChange, onSubmit } = useForm(
    INITIAL_STATE,
    async () => {
      onSuccess(formValues.model.value);
      onClose();
    }
  );

  useEffect(() => {
    const getCameraModels = async () => {
      try {
        const res = await getCameraModelService({
          project_db_id,
        });
        setCameraModelOptions(res?.data?.data?.camera_details ?? []);
      } catch (err) {
        console.log('🚀 ~ getCameraModels ~ err:', err);
      }
    };
    getCameraModels();
  }, []);

  return (
    <>
      <Body>
        <Text padding='0' md>
          Provide the camera details:
        </Text>
        <Select
          id='model'
          label='Camera Model'
          value={formValues.model.value}
          onChange={onChange}
          error={!!formValues.model.error}
          helperText={formValues.model.error}
          options={cameraModelOptions}
        />
      </Body>

      <Footer>
        <Normal
          onClick={onClose}
          color='secondary'
          style={{ marginRight: '2rem' }}>
          Cancel
        </Normal>
        <Normal type='submit' onClick={onSubmit}>
          Submit
        </Normal>
      </Footer>
    </>
  );
};

SelectCameraModelDialog.propTypes = {
  toggleModal: PropTypes.func,
  onSuccess: PropTypes.func,
  // deleteAsset: PropTypes.func,
  // assetDetails: PropTypes.instanceOf(Object),
};

export default SelectCameraModelDialog;
