/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../components/Button';
import { getTeamMembersServiceCall } from '../duck/operations';

// Styles
import { Body, Footer } from '../../styles/modal';

const DeletTeamUser = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    toggleModal,
    toggleToast,
    modal: { selected, initial },
  } = props;

  const handleDelete = async () => {
    setSubmitting(true); // disable button
    const deleteResponse = await getTeamMembersServiceCall(
      { user_db_id: selected?.user_db_id },
      'delete'
    );

    const {
      data: { response, errormessage, data },
    } = deleteResponse;

    setSubmitting(false);

    if (response) {
      toggleModal(initial);
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: data?.message,
          variant: 'success',
        },
      });
    } else {
      toggleModal(initial);
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>{`Are you sure you want to delete ${selected?.name} user?`}</Body>

      <Footer>
        <Normal
          id='delete-asset-no'
          onClick={() => toggleModal(initial)}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          No
        </Normal>
        <Normal
          id='delete-asset-yes'
          onClick={handleDelete}
          isLoading={isSubmitting}
        >
          Yes
        </Normal>
      </Footer>
    </>
  );
};

DeletTeamUser.propTypes = {
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  modal: PropTypes.instanceOf(Object),
};

export default DeletTeamUser;
