/* eslint-disable no-console */
import { useState } from 'react';

const useForm = (initialState, cb) => {
  const [formValues, setFormValues] = useState(initialState);

  const onChange = e => {
    let updatedValues = {}; // udpating values in this blank object to avoid overriding initialState
    const { id, value } = e.target;
    updatedValues = {
      [id]: {
        ...formValues[id],
        value,
        error: formValues[id].validation
          ? formValues[id].validation(value, {
              ...formValues,
              ...updatedValues,
            })
          : undefined,
      },
    };

    setFormValues({
      ...formValues,
      ...updatedValues,
    });
  };

  const validations = () => {
    // to validate form if requrired is true
    let updatedValues = {}; // to update new values
    const validated = Object.entries(formValues).reduce(
      (acc, curr) => {
        const value = curr[1]?.value;

        const typeCheckedValue =
          // eslint-disable-next-line no-nested-ternary
          typeof value === 'object'
            ? Object.keys(value).length
            : value === 'array'
            ? value.length
            : value;

        if (!typeCheckedValue && curr[1]?.required) {
          updatedValues = {
            // udpating values in this blank objec to avoid overriding initialState
            ...acc.values,
            [curr[0]]: {
              ...curr[1],
              error: 'This field is required',
            },
          };
          acc.isSubmitError = true; // to check trigger form validations
        }

        if (curr[1]?.error) {
          // to check if any error exists while submitting the form
          acc.isSubmitError = true;
        }

        acc.values = updatedValues;
        return acc;
      },
      { values: {}, isSubmitError: false }
    );
    setFormValues({ ...formValues, ...validated.values });
    return validated.isSubmitError;
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!validations()) {
      // if validation = false then call cb()
      cb();
    }
  };

  const reset = () => {
    // this will reset form
    setFormValues(initialState);
  };

  const updateValues = obj => {
    setFormValues({ ...obj });
  };

  return {
    formValues, // form values
    reset, // will reset form
    onChange, // pass this to onChange of form field
    onSubmit, // pass this to submit form
    updateValues,
    setFormValues,
  };
};

export default useForm;
