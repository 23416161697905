import React from 'react';
import styled from 'styled-components';

// Colors
import { colors, font } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';
import { Text } from '../../styles/common';

const Wrapper = styled.div`
  position: relative;
  padding: 1rem 0;
  word-break: break-word;
`;

const Label = styled(Text)`
  color: ${whiteAlpha(0.6)};
  margin-bottom: 0.4rem;
  font-size: 1.1rem;
  font-weight: ${font.bold};
  text-transform: uppercase;
  padding: 0;
`;

const Value = styled(Text)`
  color: ${colors.white};
  font-size: 1.35rem;
  padding: 0;
`;

const LabelValue = ({ label, value, style }) => {
  return (
    <Wrapper style={style}>
      <Label className='label'>{label}</Label>
      <Value className='value'>{value}</Value>
    </Wrapper>
  );
};

export default LabelValue;
