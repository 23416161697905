/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Add,
  Call,
  CallEnd,
  OpenInNew,
  MicOff,
  Mic,
  Delete,
} from '@material-ui/icons';
import styled from 'styled-components';

import { liveCollaborationServiceCall } from '../../duck/operations';
import { LIVE_COLLABORATION_STEPS } from '../../constants';
import { launchApplication } from '../../../helperFunctions';
import Tooltip from '../../../components/tooltip';

// Styles
import { Body } from '../../../styles/sidebar';
import {
  Text,
  NewAddButtonStyles,
  CustomAvatarStyles,
  AvatarUsernameStyles,
  AvatarSecondaryTextStyles,
} from '../../../styles/common';
import { User, Detail, AvatarBadgeParent, Row } from '../styles';
import { ListContainerStyles } from '../../../settings/components/integration';
import { whiteAlpha } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const JOIN_CALL_RGB = '24, 185, 110';
const END_CALL_RGB = '255, 108, 86';
const OPEN_LIVE_RGB = '44, 134, 255';

const UserBorderStyles = styled.div`
  border-radius: 1rem;
  padding: 1.5rem;
  border: 0.1rem solid
    ${({ invited }) => (invited ? '#18B96E' : whiteAlpha(0.3))};
  &:hover {
    border-color: ${({ invited }) => (invited ? '#18B96E' : colors.white)};
  }

  > .user {
    padding-top: 0;
  }
`;

export const CallButtonStyles = styled(User)`
  padding: 0;
  column-gap: 1rem;
  .icon {
    width: auto;
    border-radius: 100%;
    column-gap: 0.5rem;
    padding: 0.75rem;
    border: none;
    background-color: ${whiteAlpha(0.15)};
    color: ${colors.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.join,
    &.open-in {
      background-color: rgb(${JOIN_CALL_RGB});
      border-radius: 2rem;
      padding: 0.75rem 1rem;

      span {
        display: inherit;
      }
    }

    &.open-in {
      background-color: rgb(${OPEN_LIVE_RGB});
    }

    &.end {
      background-color: rgb(${END_CALL_RGB});
    }

    &:hover {
      background-color: ${whiteAlpha(0.35)};

      &.open-in {
        background-color: rgba(${OPEN_LIVE_RGB}, 0.7);
      }

      &.end {
        background-color: rgba(${END_CALL_RGB}, 0.7);
      }

      &.join {
        background-color: rgba(${JOIN_CALL_RGB}, 0.7);
      }
    }
  }
`;

const ActionButtonStyles = styled.div`
  width: 100%;
  column-gap: 1rem;
  margin-top: 1rem;
`;

const InvitedListLiveCollaboration = ({
  goTo,
  match: { params },
  startJoinAudioCall,
  leaveAudioCall,
  pusher,
  toggleMute,
  isAudioMuted,
  otherDetails,
  setOtherDetails,
  toggleToast,
}) => {
  const [isFetchingUsers, setFetchingUsers] = useState(false);
  const [liveInvitations, setLiveInvitations] = useState(null);
  const [invitedCollaborators, setInvitedCollaborators] = useState(null);

  const userDetails = useSelector(store => store?.common?.userDetails);
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);
  const cloudDetails = useSelector(
    store => store?.storyboard?.cloudAccessDetails
  );

  const getSharedUsers = async () => {
    setFetchingUsers(true);
    const res = await liveCollaborationServiceCall({
      team_db_id: selectedTeam?.team_db_id,
      project_db_id: params?.project_db_id,
      cloud_assignment_db_id: cloudDetails?.cloud_access_db_id,
    });
    const {
      data: {
        data: { live_collaboration_invitations, live_collaborators, ...rest },
        response,
      },
    } = res;
    if (response) {
      setFetchingUsers(false);
      const updated = live_collaboration_invitations.reduce((acc, curr) => {
        acc[curr.live_collaboration_db_id] = { ...curr };
        return acc;
      }, {});
      setInvitedCollaborators(live_collaborators ?? null);
      setLiveInvitations(updated);
      setOtherDetails({ ...rest });
    } else {
      setFetchingUsers(false);
      setLiveInvitations(null);
      setInvitedCollaborators(null);
      setOtherDetails(null);
    }
  };

  useEffect(() => {
    getSharedUsers();
  }, []);

  const joinAudioCall = async (e, el) => {
    e.stopPropagation();
    await startJoinAudioCall(el, 'collaborator');
    // setTimeout(
    //   () => launchApplication('web', el, `&user_db_id=${userDetails.user_id}`),
    //   1000
    // );
  };

  const endAudioCall = (e, el) => {
    e.stopPropagation();
    leaveAudioCall(el, 'collaborator');
  };

  useEffect(() => {
    let invitedCollaboratorsChannel = null;
    let collaborationChannel = null;
    let projectChannel = null;

    if (pusher) {
      if (invitedCollaborators) {
        invitedCollaboratorsChannel = pusher.subscribe(
          `private-${otherDetails?.live_collaboration_db_id}`
        );
        invitedCollaboratorsChannel.bind('call_initiator', data => {
          const {
            live_collaboration_invitations,
            live_collaborators,
            ...rest
          } = data;
          setInvitedCollaborators(live_collaborators);
          setOtherDetails({ ...rest });
        });
      }

      if (liveInvitations) {
        projectChannel = pusher.subscribe(`private-${params?.project_db_id}`);
        projectChannel.bind(
          `live_collaboration_remove_${userDetails?.user_id}`,
          data => {
            const updated = { ...liveInvitations };
            delete updated[data?.live_collaboration_db_id];
            setLiveInvitations(updated);
          }
        );

        Object.values(liveInvitations).forEach(el => {
          collaborationChannel = pusher.subscribe(
            `private-${el?.live_collaboration_db_id}`
          );
          if (collaborationChannel) {
            collaborationChannel.bind('live_collaboration', data => {
              if (data?.call_ended_by_host) {
                leaveAudioCall(el, 'collaborator');
              }
              const updated = { ...liveInvitations };
              updated[data?.live_collaboration_db_id] = { ...data };
              setLiveInvitations(updated);
            });
          }
        });
      }
    }

    return () => {
      if (
        projectChannel &&
        collaborationChannel &&
        invitedCollaboratorsChannel &&
        pusher
      ) {
        invitedCollaboratorsChannel.unbind();
        collaborationChannel.unbind();
        projectChannel.unbind();
        pusher.unsubscribe();
      }
    };
  }, [
    pusher,
    invitedCollaborators,
    liveInvitations,
    otherDetails?.live_collaboration_db_id,
  ]);

  useEffect(() => {
    getSharedUsers();
  }, []);

  const onDelete = async id => {
    try {
      const data = await liveCollaborationServiceCall(
        {
          team_db_id: selectedTeam?.team_db_id,
          project_db_id: params?.project_db_id,
          cloud_assignment_db_id: cloudDetails?.cloud_access_db_id,
          user_db_id: id,
        },
        'put'
      );
      if (data?.data?.response) {
        setInvitedCollaborators(data?.data?.data?.users ?? []);
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody:
              data?.data?.data?.message ?? 'User deleted successfully',
            variant: 'success',
          },
        });
      } else {
        throw new Error(data?.data?.errormessage);
      }
    } catch (err) {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: err?.message,
          variant: 'error',
        },
      });
    }
  };

  // eslint-disable-next-line react/prop-types
  const StartJoinCallButton = ({ onClick, title = 'Start Call' }) => {
    return (
      <button type='button' onClick={onClick} className='icon join'>
        <span>
          <Call />
        </span>
        <span>{title}</span>
      </button>
    );
  };

  // eslint-disable-next-line react/prop-types
  const JoinScreenShareButton = ({ onClick, title = 'Launch Session' }) => {
    return (
      <button type='button' className='icon open-in' onClick={onClick}>
        <span>
          <OpenInNew />
        </span>
        <span>{title}</span>
      </button>
    );
  };

  const InvitedCollaborators = () => {
    return (
      <Row aria-label='invited-collaborators'>
        {invitedCollaborators?.length > 0 &&
          invitedCollaborators?.map(el => (
            <UserBorderStyles key={el?.user_id}>
              <User className='user'>
                <Detail>
                  <AvatarBadgeParent>
                    <CustomAvatarStyles color={el?.user_badge_color}>
                      {el?.first_name?.charAt(0)}
                      {el?.last_name?.charAt(0)}
                    </CustomAvatarStyles>
                  </AvatarBadgeParent>
                  <div>
                    <AvatarUsernameStyles style={{ maxWidth: '14rem' }}>
                      {el?.name}
                    </AvatarUsernameStyles>
                    <AvatarSecondaryTextStyles secondary>
                      {el?.username}
                    </AvatarSecondaryTextStyles>
                  </div>
                </Detail>
                <div className='flex items-center'>
                  <CallButtonStyles>
                    {!otherDetails?.call_joined ? (
                      <>
                        <Tooltip
                          title='Remove Collaborator'
                          arrow
                          placement='top'>
                          <button
                            aria-label='delete-user'
                            type='button'
                            onClick={() => onDelete(el?.user_id)}
                            className='icon'>
                            <Delete />
                          </button>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {!isAudioMuted ? (
                          <Tooltip title='Mute' arrow placement='top'>
                            <button
                              type='button'
                              aria-label='toggle-mute'
                              onClick={() => toggleMute()}
                              className='icon'>
                              <Mic />
                            </button>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Unmute' arrow placement='top'>
                            <button
                              aria-label='unmute'
                              type='button'
                              onClick={() => toggleMute('unmute')}
                              className='icon'>
                              <MicOff />
                            </button>
                          </Tooltip>
                        )}

                        <Tooltip title='End Call' arrow placement='top'>
                          <button
                            aria-label='leave-call'
                            type='button'
                            onClick={() => leaveAudioCall()}
                            className='icon end'>
                            <CallEnd />
                          </button>
                        </Tooltip>
                      </>
                    )}
                  </CallButtonStyles>
                </div>
              </User>

              <CallButtonStyles>
                <ActionButtonStyles className='flex items-center'>
                  {!otherDetails?.call_joined && (
                    <StartJoinCallButton
                      onClick={() => startJoinAudioCall(otherDetails)}
                    />
                  )}

                  <JoinScreenShareButton
                    onClick={() => {
                      launchApplication('web', cloudDetails);
                    }}
                  />
                </ActionButtonStyles>
              </CallButtonStyles>
            </UserBorderStyles>
          ))}
      </Row>
    );
  };

  const LiveCollaborators = () => {
    return (
      <Row aria-label='invitations'>
        {isFetchingUsers && <Text padding='0'>Fetching invitations...</Text>}
        {!isFetchingUsers &&
          liveInvitations &&
          Object.keys(liveInvitations)?.length > 0 && (
            <>
              <Text padding='0' bg marginBottom='1rem'>
                Join Live Session
              </Text>

              <ListContainerStyles>
                {liveInvitations &&
                  Object.values(liveInvitations)?.map(el => (
                    <li key={el.live_collaboration_db_id}>
                      <UserBorderStyles
                        invited={el?.audio_call_detail?.channel_name}
                        type='button'>
                        <User className='user'>
                          <Detail>
                            <AvatarBadgeParent>
                              <CustomAvatarStyles
                                color={el?.invited_by?.user_badge_color}>
                                {el?.invited_by?.first_name?.charAt(0)}
                                {el?.invited_by?.last_name?.charAt(0)}
                              </CustomAvatarStyles>
                            </AvatarBadgeParent>
                            <div>
                              <AvatarUsernameStyles
                                style={{ maxWidth: '14rem' }}>
                                {el?.invited_by?.name}
                              </AvatarUsernameStyles>
                              <AvatarSecondaryTextStyles secondary>
                                {el?.task_type}
                              </AvatarSecondaryTextStyles>
                            </div>
                          </Detail>

                          <CallButtonStyles>
                            {el?.call_joined && (
                              <>
                                {!isAudioMuted ? (
                                  <Tooltip title='Mute' arrow placement='top'>
                                    <button
                                      aria-label='mute'
                                      type='button'
                                      onClick={() => toggleMute()}
                                      className='icon'>
                                      <Mic />
                                    </button>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title='Unmute' arrow placement='top'>
                                    <button
                                      aria-label='unmute'
                                      type='button'
                                      onClick={() => toggleMute('unmute')}
                                      className='icon'>
                                      <MicOff />
                                    </button>
                                  </Tooltip>
                                )}

                                <Tooltip title='End Call' arrow placement='top'>
                                  <button
                                    aria-label='end-call'
                                    type='button'
                                    onClick={e => endAudioCall(e, el)}
                                    className='icon end'>
                                    <CallEnd />
                                  </button>
                                </Tooltip>
                              </>
                            )}
                          </CallButtonStyles>
                        </User>

                        <CallButtonStyles>
                          <ActionButtonStyles className='flex items-center'>
                            {!el?.call_joined &&
                              el?.audio_call_detail?.channel_name && (
                                <StartJoinCallButton
                                  title='Join Call'
                                  onClick={e => joinAudioCall(e, el)}
                                />
                              )}

                            <JoinScreenShareButton
                              title='Join Screen Share'
                              onClick={() => {
                                launchApplication(
                                  'web',
                                  el,
                                  `&user_db_id=${userDetails.user_id}`
                                );
                              }}
                            />
                          </ActionButtonStyles>
                        </CallButtonStyles>
                      </UserBorderStyles>
                    </li>
                  ))}
              </ListContainerStyles>
            </>
          )}
      </Row>
    );
  };

  return (
    <>
      <Body>
        <ListContainerStyles>
          <li>
            <NewAddButtonStyles
              disabled={
                cloudDetails?.machine_status !== 'machine_started' ||
                invitedCollaborators?.length
              }
              className='flex items-center'
              onClick={() => goTo(LIVE_COLLABORATION_STEPS.new)}>
              <span className='icon'>
                <Add />
              </span>
              <h4>Invite a Collaborator</h4>
            </NewAddButtonStyles>
            {cloudDetails?.machine_status !== 'machine_started' && (
              <Text padding='1rem 0 0 0' sm color={whiteAlpha(0.7)}>
                This will be enabled when you have an active cloud session
              </Text>
            )}
          </li>
        </ListContainerStyles>
        <InvitedCollaborators />
        <LiveCollaborators />
      </Body>
    </>
  );
};

InvitedListLiveCollaboration.propTypes = {
  goTo: PropTypes.func,
  startJoinAudioCall: PropTypes.func,
  leaveAudioCall: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
  isAudioMuted: PropTypes.bool,
  toggleMute: PropTypes.func,
  setOtherDetails: PropTypes.func,
  otherDetails: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
};

export default InvitedListLiveCollaboration;
