import React, { useState, useEffect, useRef } from 'react';
import { IconButton, InputBase } from '@material-ui/core';
import styled from 'styled-components';
import { Send } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { Configuration, OpenAIApi } from 'openai';
import { useSelector } from 'react-redux';

import { useForm } from '../../../components/FormInputs';
import sidebarTypes from '../../../components/Sidebar/constants';

// styles
import { Body, Footer } from '../../../styles/sidebar';

import { Flex, Text, CustomAvatarStyles } from '../../../styles/common';

import { whiteAlpha } from '../../../styles/utils';
import { colors, fs } from '../../../styles/variables';

const TextBoxWithSubmit = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  background-color: ${whiteAlpha(0.15)};
`;

const SubmitButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 1rem 1.5rem;
    padding: 0.95rem;
    background-color: ${colors.blue.shadeTwo};

    &:disabled {
      background-color: ${colors.blue.shadeTwo};
      opacity: 0.7;
    }

    &:hover {
      background-color: #2c86ff;
      .MuiSvgIcon-root {
        color: ${colors.white};
      }
    }
  }

  .MuiSvgIcon-root {
    width: 1.4rem;
    height: 1.4rem;
    color: ${colors.blue.navy};
  }
`;

const InputStyled = styled(InputBase)`
  &.MuiInputBase-root {
    flex: 1;
    padding: 1rem 0 1rem 1.5rem;
    font-size: ${fs.sm};
    color: ${whiteAlpha(0.8)};
  }
`;

// const MessageDateLabelStyles = styled(Text)`
//   text-align: center;
//   margin: 0;
//   padding: 0.5rem 1rem;
//   border-radius: 0.5rem;
//   font-size: ${fs.xs};
//   display: inline-block;
//   background-color: ${whiteAlpha(0.15)};
// `;

const MessageBodyStyles = styled.div`
  margin-bottom: 1.3rem;
`;

const MessageStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  p {
    padding: 0;
  }

  #user {
    font-weight: 500;
  }

  .message-container {
    position: relative;

    .message {
      font-size: ${fs.sm};
      color: ${whiteAlpha(0.9)};
      padding: 0.2rem 0.2rem 0.2rem 0;
      margin-left: 1rem;
    }
  }
`;

const INITIAL_STATE = {
  message: {
    value: '',
  },
};

const configuration = new Configuration({
  apiKey: process.env.GPT_API_KEY,
});

const openai = new OpenAIApi(configuration);

const OpenAi = props => {
  const { toggleSidebar } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);

  const bottomOfChatRef = useRef(null);
  const userDetails = useSelector(store => store.common?.userDetails);

  // to scroll bottom
  useEffect(() => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation]);

  const { onChange, onSubmit, formValues, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      if (formValues?.message?.value) {
        setLoading(true);
        setErrorMessage('');

        updateValues({
          ...formValues,
          message: {
            value: '',
          },
        });

        setConversation([
          ...conversation,
          { content: { text: formValues?.message?.value }, role: 'user' },
          { content: { text: 'Generating...' }, role: 'system' },
        ]);

        try {
          const result = await openai.createCompletion({
            model: 'text-curie-001',
            prompt: formValues?.message?.value,
            temperature: 1,
            max_tokens: 300,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            stop: ['\\n'],
          });

          setConversation([
            ...conversation,
            { content: { text: formValues?.message?.value }, role: 'user' },
            { content: result?.data?.choices[0], role: 'system' },
          ]);
        } catch (e) {
          setErrorMessage(e?.message);
        }

        setLoading(false);
      }
    }
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <Body>
          {conversation?.length > 0 &&
            conversation?.map(el => (
              <MessageBodyStyles>
                <MessageStyle>
                  <Flex className='items-start'>
                    {el?.role === 'user' ? (
                      <CustomAvatarStyles color={userDetails?.user_badge_color}>
                        {userDetails?.first_name.charAt(0) +
                          userDetails?.last_name.charAt(0)}
                      </CustomAvatarStyles>
                    ) : (
                      <CustomAvatarStyles>
                        <svg
                          fill='currentColor'
                          strokeWidth='0'
                          role='img'
                          viewBox='0 0 24 24'
                          height='1em'
                          width='1em'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path d='M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z' />
                        </svg>
                      </CustomAvatarStyles>
                    )}
                    <div className='message-container'>
                      <Text className='message'>{el?.content?.text}</Text>
                    </div>
                  </Flex>
                </MessageStyle>
              </MessageBodyStyles>
            ))}

          <Text className='text-center' style={{ color: colors?.error }}>
            {errorMessage}
          </Text>
          <div ref={bottomOfChatRef} />
        </Body>
        <Footer>
          <TextBoxWithSubmit>
            <InputStyled
              autoComplete='off'
              id='message'
              placeholder='Hey, ask me anything :D'
              onChange={onChange}
              value={formValues.message.value}
            />
            <SubmitButton type='submit' disabled={loading}>
              <Send />
            </SubmitButton>
          </TextBoxWithSubmit>
        </Footer>
      </form>
    </>
  );
};

OpenAi.propTypes = {
  // match: PropTypes.instanceOf(Object),
  toggleSidebar: PropTypes.func,
};

export default OpenAi;
