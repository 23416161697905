/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import Card from './Card';
import GradientCard from './GradientCard';
import { CLOUD_SCREENS } from './constants';

import { fetchCloudSoftwares } from '../../duck/operations';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { whiteAlpha } from '../../../styles/utils';
import { Text } from '../../../styles/common';
import { GridStyles, CloudScreenHead } from './styles';

const Softwares = props => {
  const [loading, setLoading] = useState(true);
  const [systemConfigs, setSystemConfigs] = useState([]);
  const {
    task: { task_db_id },
    match: { params },
    type,
    goTo,
    selectSoftware,
    selectPlugin,
    selectSkipPlugin,
    setHardwares,
  } = props;

  useEffect(() => {
    setHardwares([]); // reset hardwares
    (async () => {
      // to fetch users for review
      const configsResponse = await fetchCloudSoftwares({
        // this will get system configs available based on task type
        ...(task_db_id && { task_db_id }),
        project_db_id: params?.project_db_id,
        task_type: type?.value,
      });

      const {
        data: { response, data },
      } = configsResponse;
      if (response) {
        setLoading(false);
        setSystemConfigs(data?.softwares); // to list all the configs
      } else {
        setSystemConfigs([]);
        setLoading(false);
      }
    })();
  }, []);

  const handleNext = async item => {
    if (item.slug === 'produb_editing') {
      window.open(
        `https://dev.v2.produb.postud.io/?project=${params?.project_db_id}`,
        '_blank'
      );
      return;
    }

    selectSoftware(item); // system config object
    selectPlugin([]);
    selectSkipPlugin(item?.skip_plugin_step);

    if (item?.skip_plugin_step) {
      goTo(CLOUD_SCREENS.hardware);
    } else {
      goTo(CLOUD_SCREENS.plugins);
    }
  };

  return (
    <>
      <Body>
        <GradientCard {...type} />
        <CloudScreenHead bg style={{ marginTop: '2rem' }}>
          Choose the application package you want to work on:
        </CloudScreenHead>

        {loading ? (
          <Text color={whiteAlpha(0.8)} paddingLeft='0' paddingRight='0'>
            Fetching
          </Text>
        ) : (
          <>
            {systemConfigs?.length > 0 ? (
              <GridStyles>
                {systemConfigs.map(item => {
                  const { id, title, configurations, image_url } = item;
                  return (
                    <Card
                      key={id}
                      label={title}
                      hoverScreen={{
                        para:
                          configurations?.secondary_softwares?.join(', ') || '',
                      }}
                      onClick={() => {
                        handleNext(item);
                      }}>
                      {image_url && (
                        <img
                          alt={id + 'icon'}
                          src={image_url}
                          style={{ width: '25px', objectFit: 'contain' }}
                        />
                      )}
                    </Card>
                  );
                })}
              </GridStyles>
            ) : (
              <Text lineHeight='1.4' paddingLeft='0' paddingRight='0'>
                Details coming soon
              </Text>
            )}
          </>
        )}
      </Body>

      <Footer>
        <Normal onClick={() => goTo(CLOUD_SCREENS.type)} color='secondary'>
          Back
        </Normal>
      </Footer>
    </>
  );
};

Softwares.propTypes = {
  goTo: PropTypes.func,
  setConfig: PropTypes.func,
  getSystems: PropTypes.func,
  toggleToast: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  type: PropTypes.instanceOf(Object),
  isResourceAvailable: PropTypes.func,
  selectSoftware: PropTypes.func,
  task: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  selectPlugin: PropTypes.func,
  selectSkipPlugin: PropTypes.func,
  setHardwares: PropTypes.func,
};

export default Softwares;
