/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Components
import { Normal } from '../../components/Button';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import {
  Flex,
  CustomAvatarStyles,
  AvatarUsernameStyles,
} from '../../styles/common';

import { IconButton } from '../../storyboard/components/styles';

const ParentStyles = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  &:hover ${IconButton} {
    display: block;
  }
`;

const WrapperStyles = styled(Flex)`
  padding: 0.8rem 0;
  align-items: center;
  justify-content: flex-start;
`;

const Team = ({ closeSidebar, teams }) => {
  return (
    <>
      <Body>
        {teams &&
          teams?.map(item => (
            <ParentStyles key={item}>
              <WrapperStyles>
                <CustomAvatarStyles
                  color={item?.user_badge_color}
                  key={item}
                  style={{ marginRight: '1rem' }}
                >
                  {item.charAt(0)}
                </CustomAvatarStyles>
                <div>
                  <AvatarUsernameStyles>{item}</AvatarUsernameStyles>
                </div>
              </WrapperStyles>
            </ParentStyles>
          ))}
      </Body>

      <Footer>
        <Normal type='submit' color='secondary' onClick={closeSidebar}>
          Close
        </Normal>
      </Footer>
    </>
  );
};

Team.propTypes = {
  teams: PropTypes.instanceOf(Array),
  closeSidebar: PropTypes.func,
};

export default Team;
