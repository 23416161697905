import loadable from '@loadable/component';

const Login = loadable(() => import('../app/portal/login'));
const SignUp = loadable(() => import('../app/portal/signup'));
const TwoFactorAuthentication = loadable(() =>
  import('../app/portal/twoFactorAuthentication')
);
const Logout = loadable(() => import('../app/portal/logout'));
const ForgotPassword = loadable(() => import('../app/portal/forgot-password'));
const ResetPassword = loadable(() => import('../app/portal/reset-password'));
const Verify = loadable(() => import('../app/portal/verify'));
const FourOFour = loadable(() => import('../app/portal/fourOFour'));

export default [
  {
    component: Login,
    path: '/login',
  },

  {
    component: SignUp,
    path: '/signup',
  },

  {
    component: Verify,
    path: '/verify',
    exact: true,
  },

  {
    component: ForgotPassword,
    path: '/forgot-password',
  },

  {
    component: TwoFactorAuthentication,
    path: '/2fa',
  },

  {
    component: ResetPassword,
    path: '/reset-password',
  },

  {
    component: Logout,
    path: '/logout',
  },

  {
    component: FourOFour,
    path: '*',
  },
];
