import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import NewSession from './New';
import List from './List';
import Details from './Details';
import { CAMERA_TO_EDIT_SESSION_STEPS } from '../../constants';

export default function SidebarSession({
  toggleSidebar,
  closeSidebar,
  toggleToast,
  match,
  pusher,
  view,
}) {
  const [screen, setScreen] = useState(view);

  const COMMON_PROPS = {
    goTo: step => setScreen(step),
    toggleSidebar,
    closeSidebar,
    toggleToast,
    match,
    pusher,
  };

  const renderScreen = index => {
    let component = null;

    switch (index) {
      case CAMERA_TO_EDIT_SESSION_STEPS.new: {
        component = <NewSession {...COMMON_PROPS} />;
        break;
      }

      case CAMERA_TO_EDIT_SESSION_STEPS.list: {
        component = <List {...COMMON_PROPS} />;
        break;
      }

      case CAMERA_TO_EDIT_SESSION_STEPS.details: {
        component = <Details {...COMMON_PROPS} />;
        break;
      }

      default:
        break;
    }

    return component;
  };

  return <>{renderScreen(screen)}</>;
}

SidebarSession.propTypes = {
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  view: PropTypes.string,
  match: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
};
