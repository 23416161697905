/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../components/Button';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';

const RestrictedFiles = props => {
  const { toggleModal, files, onReset, onProceed, isProceedBtnVisible } = props;

  const onCancel = () => {
    toggleModal(null, false, null);
    onReset();
  };

  const onUpload = async () => {
    toggleModal(null, false, null);
    onProceed();
  };

  return (
    <>
      <Body
        style={{
          padding: 0,
        }}>
        <Text paddingLeft='0' paddingRight='0' md>
          Unsupported file formats or special characters found, skip the files
          and proceed to upload:
        </Text>
        <div
          style={{
            overflowY: 'auto',
            maxHeight: '20rem',
            margin: '2rem 0 3rem 0',
          }}>
          {files?.map(item => (
            <Text paddingLeft='0' paddingRight='0' key={item?.name}>
              {item?.name}
            </Text>
          ))}
        </div>
      </Body>

      <Footer>
        <Normal
          id='cancel-upload'
          onClick={onCancel}
          color='secondary'
          style={
            isProceedBtnVisible
              ? { marginRight: '2rem' }
              : { maxWidth: '16rem' }
          }>
          Cancel
        </Normal>
        {isProceedBtnVisible && (
          <Normal id='proceed-upload' onClick={onUpload}>
            Proceed
          </Normal>
        )}
      </Footer>
    </>
  );
};

RestrictedFiles.propTypes = {
  toggleModal: PropTypes.func,
  onReset: PropTypes.func,
  onProceed: PropTypes.func,
  isProceedBtnVisible: PropTypes.bool,
  files: PropTypes.instanceOf(Object),
};

export default RestrictedFiles;
