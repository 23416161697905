/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../components/Button';

// constants
import sidebarTypes from '../../components/Sidebar/constants';

// helper functions
import { amountWithCommas } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text, Flex } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';

const RenewalDetails = ({ toggleSidebar, ...rest }) => {
  const { plan_detail, storage_detail, renewal } = useSelector((state) => state.subscription);

  const onSubmit = useCallback(() => {
    toggleSidebar({
      type: sidebarTypes.useCredits,
      show: true,
      title: 'Renew Subscription',
      componentProps: {
        ...rest,
      },
    });
  }, []);

  return useMemo(() => {
    return (
      <>
        <Body noFooter={plan_detail?.is_free}>
          <Text paddingRight="0" paddingLeft="0" md>{`The next renewal date is ${renewal?.renewal_date}.`}</Text>
          <Text sm padding="0" marginTop="1rem">
            Monthly renewal calculation:
          </Text>
          <div className="mt-1 rounded p-1 border border-1 border-white-0-point-3">
            <Flex className="items-baseline justify-between">
              <Text color={whiteAlpha(0.8)}>Subscription Charges</Text>
              <Text className="py-0">{amountWithCommas(plan_detail?.subscription_amount)}</Text>
            </Flex>
            <Flex className="items-baseline justify-between">
              <Text color={whiteAlpha(0.8)}>Add-on Storage: {storage_detail?.storage_added} GB</Text>
              <Text className="py-0">{amountWithCommas(storage_detail?.storage_amount)}</Text>
            </Flex>
            <div className="mx-0-8 border-t border-1 border-white-0-point-3 mt-1" />
            <Flex className="items-baseline justify-between pt-1">
              <Text color={whiteAlpha(0.8)}>Total (PSC)</Text>
              <Text className="py-0">
                {amountWithCommas(plan_detail?.subscription_amount + storage_detail?.storage_amount)}
              </Text>
            </Flex>
          </div>
        </Body>
        {!plan_detail?.is_free && (
          <Footer>
            <Normal onClick={onSubmit}>Renew</Normal>
          </Footer>
        )}
      </>
    );
  }, [storage_detail, plan_detail, renewal]);
};

RenewalDetails.propTypes = {
  credits: PropTypes.instanceOf(Object),
  selectedPlan: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  renewSubscription: PropTypes.func,
  selectPlan: PropTypes.func,
  upgradePlan: PropTypes.func,
  loadSubscriptionDetails: PropTypes.func,
};

export default RenewalDetails;
