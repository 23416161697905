import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import { AppLinkButton } from '../../storyboard/components/CloudAccess/styles';

// Styles
import { colors, radius } from '../../styles/variables';
import { Text } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import {
  getMinutes,
  getSeconds,
  formatMinutesSeconds,
} from '../../helperFunctions';

const BACKSPACE = 8;
const SPACEBAR = 32;

const GridStyles = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;

const StyledInputField = styled.input`
  padding: 1.5rem 1rem;
  width: 100%;
  color: ${colors.white};
  background-color: ${whiteAlpha(0.15)};
  border: 0.1rem solid transparent;
  border-radius: ${radius.default};
  font-size: 2.5rem;
  text-align: center;
`;

const SubmittingStyles = styled.div`
  text-align: center;
  padding-top: 1rem;
  > .MuiCircularProgress-colorPrimary {
    color: ${colors.white};
  }
`;

const VerificationCodeGrid = ({
  setValues,
  values,
  onResend,
  onSubmit,
  isSubmitting,
}) => {
  const [totalTime, setTotalTime] = useState(120);

  const focusPrev = target => {
    if (target?.previousSibling) {
      target.previousSibling.focus();
    }
  };

  const focusNext = target => {
    if (target?.nextSibling) {
      target.nextSibling.focus();
    } else {
      onSubmit();
    }
  };

  const onChange = (target, index) => {
    const data = [...values];
    data[index].value = target.value;
    setValues(data);
    focusNext(target);
  };

  const onKeyDown = (e, index) => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      const data = [...values];
      data[index].value = '';
      setValues(data);
      focusPrev(e?.target);
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.key === 'Space'
    ) {
      e.preventDefault();
    }
  };

  const onPaste = event => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain').split('');
    const updatedValues = [...values];

    if (pastedData?.length > 0 && pastedData?.length <= 6) {
      for (let i = 0; i <= pastedData.length - 1; i += 1) {
        updatedValues[i].value = pastedData[i];
      }

      setValues(updatedValues);
      onSubmit();
    }
  };

  const onResendCode = () => {
    setTotalTime(120);
    onResend();
  };

  useEffect(() => {
    let interval;
    if (totalTime > 0) {
      interval = setInterval(() => {
        setTotalTime(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [totalTime]);

  return (
    <div aria-label='Verification Code'>
      <GridStyles>
        {values?.map((el, index) => (
          <StyledInputField
            key={index}
            id={index}
            onKeyDown={e => onKeyDown(e, index)}
            onFocus={event => event.target.select()}
            tabIndex={index}
            type='text'
            autoComplete='off'
            maxLength='1'
            value={el?.value}
            pattern='[0-9]{1}'
            title='Only numbers are allowed'
            onPaste={onPaste}
            onChange={e => onChange(e?.target, index)}
          />
        ))}
      </GridStyles>
      <div
        className='flex items-center justify-between'
        style={{ margin: '1rem 0 0 0' }}
      >
        <AppLinkButton
          type='button'
          onClick={onResendCode}
          disabled={totalTime > 0}
        >
          Resend 2FA Code
        </AppLinkButton>
        {totalTime > 0 && (
          <Text sm paddingRight='0'>
            Resend in{' '}
            {`${getMinutes(totalTime)}:${formatMinutesSeconds(
              getSeconds(totalTime)
            )}`}
          </Text>
        )}
      </div>

      <SubmittingStyles>
        {isSubmitting && <CircularProgress size={18} />}
      </SubmittingStyles>
    </div>
  );
};

VerificationCodeGrid.propTypes = {
  values: PropTypes.instanceOf(Object),
  setValues: PropTypes.func,
  onResend: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default VerificationCodeGrid;
