/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import { Checkbox, TextField } from '../../../components/FormInputs';

// services
import { collaboratorsBadgeService } from '../../duck/operations';

// Constants
import sidebarTypes from '../../../components/Sidebar/constants';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import {
  Flex,
  AvatarSecondaryTextStyles,
  CustomAvatarStyles,
  AvatarUsernameStyles,
  Text,
} from '../../../styles/common';
import { colors } from '../../../styles/variables';
import {
  MaterialAutocompleteStyles,
  StyledUser,
  StyledPaper,
} from '../../../components/FormInputs/autoComplete';
import { Row, AvatarBadgeParent, Detail } from '../styles';

const MenuStyledPaper = styled(StyledPaper)`
  &.MuiPaper-root {
    max-height: 15rem;
    overflow-y: auto;
  }
`;

const TextWithCheckbox = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  ${({ is_disabled }) =>
    is_disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}:
`;

const Permissions = props => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [badgeInputValue, setBadgeInput] = useState('');
  const [badgeOptions, setBadgeOptions] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputHasChanged, setInputHasChanged] = useState(false);

  const {
    getUpdateMemberPermissions,
    toggleToast,
    toggleSidebar,
    match: { params },
    user: {
      username,
      name,
      first_name,
      last_name,
      user_id,
      user_badge_color,
      user_badge,
    },
  } = props;

  const isPermissionsAvailable =
    permissions?.all &&
    Object.values(permissions?.all) &&
    Object.values(permissions?.all).length > 0;

  const getPostCollaboratorsBadges = async () => {
    const {
      data: { response, data },
    } = await collaboratorsBadgeService({
      project_db_id: params.project_db_id,
      user_db_id: user_id,
    });

    if (response) {
      const updated = data?.collaborators_badges?.map(el => ({
        label: el,
        value: el,
      }));
      setBadgeOptions(updated);
    } else {
      setBadgeOptions([]);
    }
  };

  const onBadgeChange = async (e, option, reason) => {
    if (reason === 'select-option' || reason === 'clear') {
      const {
        data: { response, errormessage, data },
      } = await collaboratorsBadgeService(
        {
          project_db_id: params.project_db_id,
          user_db_id: user_id,
          collaborator_badge: option?.value ?? '',
        },
        reason === 'clear' ? 'put' : 'post'
      );

      if (response) {
        setSelectedValue(option);
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: data?.message ?? 'Badge updated successfully',
            variant: 'success',
          },
        });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  };

  const goBack = useCallback(() => {
    // open collaborators view
    toggleSidebar({
      show: true,
      type: sidebarTypes.addUpdateCollaborators,
      title: 'Collaborate',
    });
  }, []);

  const getPermissions = async () => {
    const permissionsResponse = await getUpdateMemberPermissions(
      {
        project_db_id: params.project_db_id,
        user_db_id: user_id,
      },
      'get'
    );

    const {
      data: { data, response },
    } = permissionsResponse;

    if (response) {
      const filtered = data.permissions.reduce(
        (acc, curr) => {
          acc.all[curr.code] = { ...curr };
          if (curr.is_selected) {
            acc.selected.push(curr.permission_db_id);
          }
          return acc;
        },
        { selected: [], all: {} }
      );
      setPermissions({
        ...permissions,
        ...filtered,
      });
    }
    setLoading(false);
  };

  const handleCheckboxChange = (e, item) => {
    const { checked } = e.target;

    let selected = permissions?.selected; // making copy
    const copyPermissions = { ...permissions?.all };

    if (checked) {
      selected.push(item.permission_db_id);
      copyPermissions[item?.code].is_selected = true;

      if (item.code === 'share_to_download_all_assets') {
        const downloadAllAsset = copyPermissions.download_all_assets;
        downloadAllAsset.is_disabled = true;
        downloadAllAsset.is_selected = true;
        selected.push(downloadAllAsset?.permission_db_id);
      }
    } else {
      selected = selected.filter(user => user !== item.permission_db_id);
      copyPermissions[item?.code].is_selected = false;

      if (item.code === 'share_to_download_all_assets') {
        const downloadAllAsset = copyPermissions.download_all_assets;
        downloadAllAsset.is_disabled = false;
      }
    }

    if (!inputHasChanged) {
      // if false then set true
      setInputHasChanged(true);
    }

    setPermissions({
      ...permissions,
      all: copyPermissions,
      selected,
    });
  };

  const updatePermissions = async () => {
    setSubmitting(true);
    const permissionsResponse = await getUpdateMemberPermissions(
      {
        project_db_id: params.project_db_id,
        user_db_id: user_id,
        permissions: permissions.selected,
      },
      'post'
    );

    const {
      data: { response, errormessage },
    } = permissionsResponse;
    setSubmitting(false);
    if (response) {
      goBack();
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Permissions updated successfully',
          variant: 'success',
        },
      });
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  useEffect(() => {
    getPostCollaboratorsBadges();
    getPermissions();
  }, []);

  useEffect(() => {
    if (user_badge) {
      setSelectedValue({ label: user_badge, value: user_badge });
    }
  }, [user_badge]);

  return (
    <>
      <Body style={{ paddingRight: '1.5rem' }}>
        <Row>
          <Detail>
            <AvatarBadgeParent>
              <CustomAvatarStyles color={user_badge_color}>
                {first_name.charAt(0)}
                {last_name.charAt(0)}
              </CustomAvatarStyles>
            </AvatarBadgeParent>
            <div>
              <AvatarUsernameStyles>{name}</AvatarUsernameStyles>
              <AvatarSecondaryTextStyles secondary>
                {username}
              </AvatarSecondaryTextStyles>
            </div>
          </Detail>

          {badgeOptions && (
            <div className='mt-2'>
              <MaterialAutocompleteStyles
                id='userBadge'
                autoHighlight
                getOptionLabel={option => option.label ?? ''}
                onChange={onBadgeChange}
                options={badgeOptions}
                value={selectedValue}
                inputValue={badgeInputValue}
                PaperComponent={paperProps => (
                  <MenuStyledPaper {...paperProps} />
                )}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                onInputChange={(event, newInputValue) => {
                  setBadgeInput(newInputValue);
                }}
                renderInput={textProps => (
                  <TextField
                    {...textProps}
                    fullWidth
                    label='Collaborator Role'
                  />
                )}
                renderOption={option => {
                  return (
                    <StyledUser>
                      <Text color={colors.black.default} padding='0'>
                        {option?.label}
                      </Text>
                    </StyledUser>
                  );
                }}
              />
            </div>
          )}
        </Row>
        <Row style={{ margin: '2rem 0' }}>
          {loading ? (
            <AvatarSecondaryTextStyles>Loading...</AvatarSecondaryTextStyles>
          ) : (
            <>
              {isPermissionsAvailable ? (
                Object.values(permissions?.all).map(item => {
                  const { title, is_selected, code, is_disabled } = item;
                  return (
                    <TextWithCheckbox
                      key={title}
                      is_disabled={is_disabled ?? false}
                    >
                      <AvatarSecondaryTextStyles
                        secondary
                        style={{
                          fontSize: '1.4rem',
                        }}
                      >
                        {title}
                      </AvatarSecondaryTextStyles>
                      <Checkbox
                        disabled={is_disabled ?? false}
                        checked={is_selected ?? false}
                        value={code}
                        onChange={e => handleCheckboxChange(e, item)}
                      />
                    </TextWithCheckbox>
                  );
                })
              ) : (
                <AvatarSecondaryTextStyles>
                  No permissions found
                </AvatarSecondaryTextStyles>
              )}
            </>
          )}
        </Row>
      </Body>
      <Footer>
        <Normal
          onClick={goBack}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          Back
        </Normal>
        {inputHasChanged && isPermissionsAvailable && (
          <Normal onClick={updatePermissions} isLoading={isSubmitting}>
            Update
          </Normal>
        )}
      </Footer>
    </>
  );
};

Permissions.propTypes = {
  toggleToast: PropTypes.func,
  role: PropTypes.string,
  match: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  getUpdateMemberPermissions: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

export default Permissions;
