/* eslint-disable max-len */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
import React, { useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../components/Button';
import { amountWithCommas } from '../../helperFunctions';

// constants
import sidebarTypes from '../../components/Sidebar/constants';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text } from '../../styles/common';
import { colors, fs, font } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const ListStyles = styled.ul`
  margin: 2rem 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;

  li {
    p,
    h4 {
      font-weight: ${font.medium};
      padding: 0;
    }

    p {
      text-transform: uppercase;
      font-size: 1rem;
      color: ${whiteAlpha(0.6)};
      margin-bottom: 0.5rem;
    }

    h4 {
      color: ${colors.white};
      line-height: 1.4;
      font-size: ${fs.sm};
    }
  }
`;

const PlanDetails = ({
  toggleSidebar,
  loadCurrentPlanDetails,
  fetchCurrentPlanDetails,
  ...rest
}) => {
  const { plan_detail, currentPlan } = useSelector(
    state => state?.subscription
  );

  useEffect(() => {
    fetchCurrentPlanDetails({
      subscription_db_id: plan_detail?.subscription_db_id,
    }).then(res => {
      const {
        data: {
          no_of_concurrent_systems,
          no_of_teams,
          no_of_collaborators,
          collaborators_limit,
          machine_hour_limit,
          machines_hours,
          team_limit,
          project_limit,
          projects,
        },
      } = res?.data;
      if (res?.data?.response) {
        loadCurrentPlanDetails({
          project_limit,
          projects,
          no_of_concurrent_systems,
          team_limit,
          no_of_teams,
          collaborators_limit,
          no_of_collaborators,
          machine_hour_limit,
          machines_hours,
        });
      }
    });
  }, []);

  const onSubmit = useCallback(() => {
    toggleSidebar({
      type: sidebarTypes.subscriptionPlans,
      show: true,
      title: 'Subscription Upgrade',
      componentProps: { ...rest },
    });
  }, []);

  return useMemo(() => {
    return (
      <>
        <Body>
          <Text
            padding='0'
            md
          >{`You have subscribed to the ${plan_detail?.title} plan.`}</Text>
          <Text padding='1rem 0 0 0' md>
            The Plan includes:
          </Text>
          <div>
            {Object.keys(currentPlan).length > 0 ? (
              <ListStyles>
                <li>
                  <p>Users</p>
                  <h4>
                    {!currentPlan?.collaborators_limit
                      ? 'Unlimited'
                      : `${currentPlan?.no_of_collaborators}`}
                  </h4>
                </li>
                <li>
                  <p>Teams</p>
                  {!currentPlan?.team_limit ? (
                    <h4>Unlimited</h4>
                  ) : (
                    <>
                      <h4>
                        {currentPlan?.no_of_teams > 1 && 'Up to'} 0
                        {currentPlan?.no_of_teams}
                      </h4>
                    </>
                  )}
                </li>
                <li>
                  <p>Projects</p>
                  <h4>
                    {!currentPlan?.project_limit
                      ? 'Unlimited'
                      : `${currentPlan?.projects}`}
                  </h4>
                </li>
                <li>
                  <p>Storage</p>
                  <h4>{amountWithCommas(plan_detail?.storage_size)} GB</h4>
                </li>
                <li>
                  <p>Cloud Systems</p>
                  <h4>{`${currentPlan?.no_of_concurrent_systems} Concurrent Systems`}</h4>
                </li>
                <li>
                  <p>Cloud Sessions</p>
                  <h4>
                    {!currentPlan?.machine_hour_limit
                      ? 'Unlimited'
                      : `${currentPlan?.machines_hours}`}
                  </h4>
                </li>
              </ListStyles>
            ) : (
              <Text padding='0'>Details not found</Text>
            )}
          </div>
        </Body>

        <Footer>
          <Normal onClick={onSubmit}>upgrade</Normal>
        </Footer>
      </>
    );
  }, [plan_detail, currentPlan]);
};

PlanDetails.propTypes = {
  closeSidebar: PropTypes.func,
  fetchCurrentPlanDetails: PropTypes.func,
  loadCurrentPlanDetails: PropTypes.func,
};

export default PlanDetails;
