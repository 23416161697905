/* eslint-disable indent */
import types from './types';

const INITIAL_STATE = {
  projectStorage: {},
  filesStorage: {},
  projectUsage: {},
  activityUsage: {}
};

const statisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOAD_PROJECT_STORAGE: {
      // project storage
      return {
        ...state,
        projectStorage: { ...state.projectStorage, ...action.payload },
      };
    }

    case types.LOAD_FILES_STORAGE: {
      // file storage
      return {
        ...state,
        filesStorage: { ...state.filesStorage, ...action.payload },
      };
    }

    case types.LOAD_PROJECT_USAGE: {
      // project usage
      return {
        ...state,
        projectUsage: { ...state.projectUsage, ...action.payload },
      };
    }

    case types.LOAD_ACTIVITY_USAGE: {
      // activity usage
      return {
        ...state,
        activityUsage: { ...state.activityUsage, ...action.payload },
      };
    }

    case types.RESET_STATISTICS_STATE: {
      // clear redux state on component unmount
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};

export default statisticsReducer;
