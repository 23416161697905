import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';

import { CarouselViewParentStyles, Text } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { font } from '../../styles/variables';
import { AppTitle } from '../../storyboard/components/CloudAccess/styles';

export default function StepsCarousel({ options }) {
  const [step, setStep] = useState(0);
  const message = useMemo(
    () =>
      typeof options[step] === 'function' ? options[step]() : options[step],
    [step, options]
  );

  return (
    <CarouselViewParentStyles style={{ margin: '0 0 1rem 0' }}>
      <div className='head'>
        <AppTitle>Steps</AppTitle>
        <div aria-label='indicators'>
          {options.map((el, index) => (
            <button
              key={el}
              type='button'
              className={index === step ? 'indicator active' : 'indicator'}
              aria-label={el}
              onClick={() => setStep(index)}
            />
          ))}
        </div>
      </div>
      <Text
        color={whiteAlpha(0.8)}
        lineHeight='1.4'
        marginTop='1rem'
        padding='0'
        style={{ fontWeight: font.medium }}>
        {message}
      </Text>
    </CarouselViewParentStyles>
  );
}

StepsCarousel.propTypes = {
  options: PropTypes.instanceOf([]),
};
