// Helper Functions
import { emailRegex } from '../components/FormInputs/helperFunctions';

export const requestForIdsEmails = (
  value,
  baseRequest,
  firstKey,
  secondKey
) => {
  // firstKey should be of email
  // secondKey should be id of user
  const request = value.reduce(
    (acc, curr) => {
      // to format data of emails and ids.
      if (emailRegex(curr.value)) {
        acc[firstKey] = [...acc[firstKey], curr.value];
      } else {
        acc[secondKey] = [...acc[secondKey], curr.value];
      }
      return acc;
    },
    { ...baseRequest }
  );

  return request;
};

export const getPluginRequest = plugins => plugins.map(el => el.slug).join(',');

export const getTotalPluginsPrice = plugins =>
  plugins.reduce((acc, curr) => acc + curr?.pricing, 0);
