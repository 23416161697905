/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import { CheckCircle } from '@material-ui/icons';

// styles
import { radius, colors } from '../../../styles/variables';
import { Text } from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';

const ButtonStyles = styled.button`
  outline: none;
  border-radius: ${radius.default};
  padding: 1rem 1.4rem;
  border: none;
  position: relative;
  background-color: ${whiteAlpha(0.15)};
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  transition: background 0.3s ease;
  width: 100%;
  border: 0.1rem solid transparent;

  .head {
    font-size: 2rem;
  }

  .message {
    margin-top: 0.75rem;
  }

  .bottom {
    svg {
      width: 1.3rem;
      height: 1.3rem;
    }

    p {
      color: ${whiteAlpha(0.6)};
    }
  }

  &.selected {
    border-color: ${colors.white};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: ${colors.white};

    .head {
      color: ${colors.black.main};
    }

    .message,
    .hover-screen p {
      color: #666;
    }

    .hover-screen {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  ${({ hideMessageOnHover }) =>
    hideMessageOnHover &&
    css`
      &:hover {
        .message,
        .bottom {
          opacity: 0;
        }
      }
    `}
`;

const HeadIconStyles = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  column-gap: 0.5rem;

  .check-mark {
    color: #43cea2;
    width: 2rem;
    height: 2rem;
  }
`;

const AdditionalScreenStyles = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: none;
  padding: 1.4rem;
  word-break: break-word;
`;

const Para = styled(Text)`
  color: ${whiteAlpha(0.8)};
`;

const Card = props => {
  const {
    disabled,
    onClick,
    label,
    children,
    message,
    hoverScreen = {},
    isSelected,
  } = props;

  return (
    <ButtonStyles
      onClick={onClick}
      className={isSelected ? 'selected' : ''}
      disabled={disabled}
      hideMessageOnHover={typeof hoverScreen?.para === 'string'}
    >
      <div>
        <HeadIconStyles>
          <Text className='head' padding='0' lineHeight='1.3'>
            {label}
          </Text>
          {isSelected && <CheckCircle className='check-mark' />}
        </HeadIconStyles>
        <Para className='message' padding='0' sm lineHeight='1.3'>
          {message}
        </Para>
      </div>

      {children && <div className='bottom'>{children}</div>}

      {hoverScreen?.para && (
        <AdditionalScreenStyles className='hover-screen'>
          <div />
          {typeof hoverScreen?.para === 'object' ? (
            hoverScreen?.para
          ) : (
            <Text padding='0' sm lineHeight='1.3'>
              {hoverScreen?.para}
            </Text>
          )}
        </AdditionalScreenStyles>
      )}
    </ButtonStyles>
  );
};

Card.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  label: PropTypes.string,
  hoverScreen: PropTypes.instanceOf(Object),
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Card;
