import React from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { PropTypes } from 'prop-types';

// Styles
import { colors } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const StyledProgress = styled(LinearProgress)`
  width: 100%;
  border-radius: 0.2rem;
  &.MuiLinearProgress-colorPrimary {
    background-color: ${whiteAlpha(0.1)};
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({value}) => value > 0 ? colors.blue.primary : whiteAlpha(0.1)};
    border-radius: 0.2rem;
  }
`;

const StyledLinearProgress = ({ value = 0 }) => <StyledProgress variant="determinate" value={value} />;

StyledLinearProgress.propTypes = {
  value: PropTypes.number,
};

export default StyledLinearProgress;
