import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { Normal } from '../../../../components/Button';
import Modal from '../../../../components/modal';

import { sprinklrIntegrationService } from '../../../duck/operations';
import { enqueueSnackbar } from '../../../../snackbar/duck/actions';

import { SPRINKLR_STEPS } from '../../../constants/integration';

// styles
import { Body, Footer } from '../../../../styles/modal';
import { Text } from '../../../../styles/common';

const DeleteModal = ({
  integration_db_id,
  toggleModal,
  open,
  details,
  goTo,
}) => {
  const dispatch = useDispatch();
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);
  const [isSubmitting, setSubmitting] = useState(false);

  const onDelete = async () => {
    setSubmitting(true);

    try {
      const { data } = await sprinklrIntegrationService(
        {
          team_db_id: selectedTeam?.team_db_id,
          team_id: selectedTeam?.team_db_id,
          integration_db_id,
        },
        'delete'
      );

      if (data?.response) {
        goTo(SPRINKLR_STEPS.list);
        setSubmitting(false);
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Success',
              messageBody: data?.data?.message,
              variant: 'success',
            },
          })
        );
      } else throw new Error(data?.errormessage);
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        })
      );
    }
  };

  const onClose = () => toggleModal(false);

  return (
    <>
      <Modal onClose={onClose} open={open} title='Delete Integration'>
        <Body>
          <div style={{ display: 'grid', rowGap: '0.5rem' }}>
            {details?.business_unit && (
              <Text padding='0' md>
                Business Unit: {details?.business_unit}
              </Text>
            )}
            {details?.campaign_name && (
              <Text padding='0' md>
                Campaign Name: {details?.campaign_name}
              </Text>
            )}
          </div>
        </Body>
        <Footer>
          <Normal
            id='delete-asset-no'
            onClick={onClose}
            color='secondary'
            style={{ marginRight: '2rem' }}
          >
            No
          </Normal>
          <Normal
            id='delete-asset-yes'
            onClick={onDelete}
            isLoading={isSubmitting}
          >
            Yes
          </Normal>
        </Footer>
      </Modal>
    </>
  );
};

DeleteModal.propTypes = {
  integration_db_id: PropTypes.string,
  toggleModal: PropTypes.func,
  details: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  goTo: PropTypes.func,
};

export default DeleteModal;
