import axios from 'axios';
import { APP_TYPES } from '../app/constants';

const checkIfPathsArePublic = () => {
  const { pathname, hash } = window?.location;
  const PUBLIC_PATHS = [
    '/login',
    '/signup',
    '/forgot-password',
    '/verify',
    '/reset-password',
    '/2fa',
  ];
  let path;
  if (process.env.TYPE === APP_TYPES.app) {
    path = hash?.split('#')[1];
  } else {
    path = pathname;
  }
  return !PUBLIC_PATHS.includes(path);
};

const instance = axios.create({
  baseURL: '',
  timeout: 60000,
  withCredentials: true,
});

const redirectToLogout = () => {
  if (process.env.TYPE === APP_TYPES.app) {
    // eslint-disable-next-line global-require
    window.location.hash = '#/logout';
  } else {
    window.location = '/logout';
  }
};

const getAuthorization = () => {
  const headerData = {};
  const token = localStorage.getItem('Authorization');
  if (token && token.length > 0) {
    headerData.Authorization = token;
  }
  return headerData;
};

const request = (method, url, data, headers) => {
  // passing team_db_id in every request apart from these following request
  const updatedData = [
    '/checklogin/',
    '/login/',
    '/signup/',
    '/reset-password/',
    '/recover_password/',
    '/logout/',
    '/verify/email/',
    '/product/tour/',
  ].some(str => url.includes(str))
    ? { ...data }
    : { ...data, team_db_id: localStorage.getItem('team_db_id') || '' };

  let headersObj = headers || {};

  if (
    !(
      url.indexOf('localhost.filecatalyst') >= 0 ||
      url.indexOf('s3.ap-south-1.amazonaws.com') >= 0
    )
  ) {
    headersObj = {
      ...getAuthorization(),
      ...headers,
      'API-Type': process.env.TYPE === APP_TYPES.app ? 'WEB-APP' : 'WEB',
      'API-Version': '1.0',
    };
  }

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line consistent-return
    (() => {
      if (method === 'get') {
        return instance.request({
          url,
          method,
          params: updatedData,
          headers: headersObj || {},
        });
      }
      if (method === 'post') {
        return instance.request({
          url,
          method,
          data: updatedData,
          headers: headersObj || {},
        });
      }
      if (method === 'put') {
        return instance.request({
          url,
          method,
          data: updatedData,
          headers: headersObj || {},
        });
      }
      if (method === 'delete') {
        return instance.request({
          url,
          method,
          data: updatedData,
          headers: headersObj || {},
        });
      }
    })()
      .then(res => {
        const isPublic = checkIfPathsArePublic();
        if (
          res.data.errorcode === 401 &&
          isPublic &&
          !window.location.search.split('=')[0].includes('shared')
        ) {
          redirectToLogout();
        }
        resolve(res);
      })
      .catch(err => {
        // redirectToLogout();
        reject(err.response);
      });
  });
};

export default {
  get: (endpoint, data, headers) => request('get', endpoint, data, headers),
  post: (endpoint, data, headers) => request('post', endpoint, data, headers),
  put: (endpoint, data) => request('put', endpoint, data),
  delete: (endpoint, data) => request('delete', endpoint, data),
  baseUrl: process.env.BASE_URL,
};
