/* eslint-disable max-len */
/* eslint-disable no-console */
import Creators from './actions';
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';

const {
  actionLoadProjectStortage,
  actionLoadFilesStortage,
  actionLoadProjectUsage,
  actionLoadActivityUsage,
  resetState,
} = Creators;

const getProjectStorage = () => dispatch => {
  serviceCall
    .get(`${apiConfig.project}/projects/storage/`)
    .then(res => {
      dispatch(actionLoadProjectStortage(res.data.data));
    })
    .catch(err => {
      console.log(err, 'fetch project storage error');
    });
};

const getFileStorage = () => dispatch => {
  serviceCall
    .get(`${apiConfig.project}/file/storage/`)
    .then(res => {
      dispatch(actionLoadFilesStortage(res.data.data));
    })
    .catch(err => {
      console.log(err, 'fetch file storage error');
    });
};

const getProjectCloudUsage = () => dispatch => {
  serviceCall
    .get(`${apiConfig.project}/projects/cloud-usage/`)
    .then(res => {
      dispatch(actionLoadProjectUsage(res.data.data));
    })
    .catch(err => {
      console.log(err, 'fetch cloud project usage error');
    });
};

const getActivityCloudUsage = () => dispatch => {
  serviceCall
    .get(`${apiConfig.project}/activities/cloud-usage/`)
    .then(res => {
      dispatch(actionLoadActivityUsage(res.data.data));
    })
    .catch(err => {
      console.log(err, 'fetch cloud activity usage error');
    });
};

const clearData = action => {
  return dispatch => {
    dispatch(resetState(action));
  };
};

export default {
  getProjectStorage,
  getFileStorage,
  getProjectCloudUsage,
  getActivityCloudUsage,
  clearData,
};
