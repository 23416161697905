/* eslint-disable react/no-array-index-key */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import { padding } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

// Components
import Title from './Title';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },

  col: {
    position: 'relative',
    padding: padding.container,
    width: '100%',
    boxSizing: 'border-box',
    '& .inner': {
      backgroundColor: '#191919',
      borderRadius: '8rem',
      width: '100%',
      justifyContent: 'space-between',
      padding: '1.6rem',
      boxSizing: 'border-box',
      alignItems: 'center',
      display: 'flex',
    }
  },

  root: {
    backgroundColor: whiteAlpha(0.08),
  },

  margin: {
    margin: padding.container,
  },

});

const Taskbar = () => {
  const classes = useStyles();
  return (
    <div className={classes.col}>
      <div className="inner">
        <div className={classes.flex}>
          <Skeleton variant="circle" width="4.8rem" height="4.8rem" className={classes.root} />
          <div className={classes.margin}>
            <Title width="12.8rem" />
            <Title width="8rem" />
          </div>
        </div>

        <div className={classes.flex}>
          {Array.from(new Array(2)).map((el, j) => <div
            key={`el-${j}`}
            className={classes.margin}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Title width="12.8rem" />
            <Title width="8rem" />
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default Taskbar;