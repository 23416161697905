import { useEffect, useRef } from 'react';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';

const Upload = props => {
  const cancelUpload = useRef(null);
  const {
    abort,
    pause,
    multiparts: { parts, upload_id, part_size },
    file,
    folder_path,
    project_db_id,
    onUploadComplete,
    onUploadError,
    onUploadProgress,
    onUploadCancel,
  } = props;

  let state = {
    status: 'pending',
    percentage: 0,
    completed: 0,
    total: 0,
  };

  const onCancel = () => {
    const request = {
      folder_path,
      upload_id,
    };

    if (abort) {
      cancelUpload.current('User has cancelled');
      if (state.status !== 'finished') {
        onUploadCancel('user_cancel', request);
      } else {
        onUploadCancel('user_delete', request);
      }
    }
  };

  const uploadFile = async () => {
    const promises = [];
    let uploaded = 0;
    let percent = 0;
    const options = {
      onUploadProgress: e => {
        percent = parseInt(((uploaded + e.loaded) * 100) / file.size, 10);
        if (percent > 100) {
          percent = 100;
        }
        state = {
          status: 'inprogress',
          percentage: percent,
          completed: uploaded + e.loaded,
          total: file.size,
        };
        onUploadProgress(state);
      },
      cancelToken: new CancelToken(e => {
        cancelUpload.current = e;
      }),
    };

    for (let index = 0; index < parts.length; index++) {
      const start = index * part_size;
      const end = (index + 1) * part_size;
      const blob =
        index < parts.length ? file.slice(start, end) : file.slice(start);
      uploaded = index < parts.length ? start : uploaded + blob.size;
      const response = await axios.put(parts[index].signed_url, blob, options);
      promises.push(response);
    }

    const response = await Promise.all(promises);
    if (response.length) {
      const chunks = response.map((item, i) => {
        return {
          ETag: item.headers.etag,
          PartNumber: i + 1,
        };
      });

      state = {
        ...state,
        status: 'finished',
        project_db_id,
      };

      const request = {
        folder_path,
        upload_id,
        parts: chunks,
      };

      onUploadComplete(state, request);
    } else {
      state = {
        ...state,
        status: 'error',
      };
      onUploadError(state);
    }
  };

  const onPlayPause = () => {
    if (state.status === 'inprogress' && pause) {
      // upload.abort();
    } else if (state.status === 'error') {
      uploadFile();
    }
  };

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, []);

  // useEffect(() => {
  //   onPlayPause();
  // }, [pause]);

  useEffect(() => {
    onCancel();
  }, [abort]);

  return null;
};

Upload.propTypes = {
  pause: PropTypes.bool,
  abort: PropTypes.bool,
  file: PropTypes.instanceOf(Object),
  multiparts: PropTypes.instanceOf(Object),
  onUploadComplete: PropTypes.func,
  onUploadError: PropTypes.func,
  onUploadProgress: PropTypes.func,
  onUploadCancel: PropTypes.func,
};

export default Upload;
