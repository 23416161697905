/* eslint-disable camelcase */
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.MIXPANEL_ID);
mixpanel.set_config({
  secure_cookie: true,
});

const env_check = process.env.NODE_ENV !== 'development';

const Mixpanel = {
  reset: () => {
    if (env_check) mixpanel.reset();
  },
  register: data => {
    if (env_check) mixpanel.register(data);
  },
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props || '');
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export default Mixpanel;
