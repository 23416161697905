/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { CheckCircle } from '@material-ui/icons';
import { useParams } from 'react-router-dom';

// Components
import { Normal } from '../../components/Button';
import { enqueueSnackbar } from '../../snackbar/duck/actions';
import {
  exportToSprinklrService,
  getImportAssetStatusService,
} from '../duck/operations';
import { selectItemsAction, setImportAssetStatus } from '../duck/actions';
import { SELECTION_KEYS } from '../constants';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';
import { colors } from '../../styles/variables';
import {
  ListContainerStyles,
  ListItemExtendedStyles,
} from '../../settings/components/integration/index';
import { whiteAlpha } from '../../styles/utils';

const SelectedStyles = styled.div`
  position: absolute;
  right: 1.5rem;
  z-index: 1;
  svg {
    color: #43cea2;
    width: 2rem;
    height: 2rem;
  }
`;

const AlreadySharedFilesStyles = styled.div`
  margin-top: 2rem;

  ul {
    margin-left: 1.5rem;
  }
`;

const ShareToSprinklr = props => {
  const dispatch = useDispatch();
  const { project_db_id } = useParams();
  const selectedTeam = useSelector(store => store?.common?.selectedTeam);
  const selectedItems = useSelector(store => store?.storyboard?.selectedItems);
  const [isSubmitting, setSubmitting] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(null);
  const [alreadySharedFiles, setAlreadySharedFiles] = useState([]);
  const [selectedIntegration, setIntegration] = useState(null);
  const [fetchingDetails, setFetchingDetaails] = useState(true);
  const [updatedSelectedItems, setSelectedItems] = useState(selectedItems);

  const { assetDetails, toggleModal } = props;

  const onModalClose = () => toggleModal(null, false, null);

  const getImportStatus = async () => {
    const res = await getImportAssetStatusService({
      team_db_id: selectedTeam?.team_db_id,
      project_db_id,
      entries_per_page: 25,
      page_no: 1,
    });

    if (res?.data?.response) {
      if (res?.data?.data?.show_notification) {
        dispatch(setImportAssetStatus({ data: res?.data?.data }));
      }
    }
  };

  useEffect(() => {
    const getList = async () => {
      try {
        const { data } = await exportToSprinklrService({
          team_db_id: selectedTeam?.team_db_id,
          // team_id: selectedTeam?.team_db_id,
          project_db_id,
        });

        if (data?.response) {
          setFetchingDetaails(false);
          setDetails(data?.data?.integrations);
        } else throw new Error(data?.errormessage);
      } catch (err) {
        setFetchingDetaails(false);
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          })
        );
      }
    };

    getList();
  }, []);

  const onSubmit = async (e, type) => {
    setSubmitting(true);

    const payload = {
      team_db_id: selectedTeam?.team_db_id,
      project_db_id,
      integration_db_id: selectedIntegration,
      ingest_db_ids: assetDetails?.isMultiple
        ? Object.keys(updatedSelectedItems)
        : [assetDetails?.ingest_db_id],
    };

    if (type) {
      // skip files & proceed
      const copy = { ...updatedSelectedItems };
      alreadySharedFiles.forEach(el => delete copy[el?.ingest_db_id]);
      setSelectedItems(copy || null);
      payload.ingest_db_ids = Object.keys(copy);
    }

    try {
      const { data } = await exportToSprinklrService(payload, 'post');

      if (data?.response) {
        if (assetDetails?.isMultiple) {
          dispatch(
            selectItemsAction({
              type: SELECTION_KEYS.reset,
            })
          );
        }
        setSubmitting(false);
        onModalClose();
        setTimeout(getImportStatus, 1000);
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Success',
              messageBody: data?.data?.message,
              variant: 'success',
            },
          })
        );
      } else if (data?.data?.files?.length) {
        if (!updatedSelectedItems) {
          throw new Error(data?.errormessage);
        } else {
          setAlreadySharedFiles(data?.data?.files);
          setSubmitting(false);
          setError(data?.data?.message);
        }
      } else throw new Error(data?.errormessage);
    } catch (err) {
      setSubmitting(false);
      dispatch(
        enqueueSnackbar({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        })
      );
    }
  };

  if (!fetchingDetails) {
    return (
      <>
        <Body style={{ paddingTop: '1rem' }}>
          {!assetDetails?.isMultiple &&
            assetDetails?.shared_detail?.sprinklr_shared_by?.name && (
              <Text padding='0 0 2rem 0' color={colors?.error}>
                This asset has been shared by{' '}
                {assetDetails?.shared_detail?.sprinklr_shared_by?.name}
              </Text>
            )}
          {details?.length > 0 ? (
            <>
              <Text padding='0 0 1.5rem 0' color={whiteAlpha(0.6)}>
                Select Integration
              </Text>
              <div>
                <ListContainerStyles>
                  {details?.map(el => (
                    <li key={el?.integration_db_id}>
                      <ListItemExtendedStyles
                        style={{
                          borderColor:
                            selectedIntegration === el?.integration_db_id &&
                            colors?.white,
                        }}
                        type='button'
                        onClick={() => setIntegration(el?.integration_db_id)}>
                        <>
                          {selectedIntegration === el?.integration_db_id && (
                            <SelectedStyles>
                              <CheckCircle />
                            </SelectedStyles>
                          )}

                          {el?.business_unit && (
                            <div aria-label='Business Unit'>
                              <p>Business Unit</p>
                              <h4>{el?.business_unit}</h4>
                            </div>
                          )}

                          {el?.agency_for_media_assets && (
                            <div aria-label='Agency - For Media Assets'>
                              <p>Agency - For Media Assets</p>
                              <h4>{el?.agency_for_media_assets}</h4>
                            </div>
                          )}

                          <div aria-label='Campaign Name'>
                            <p>Campaign Name</p>
                            <h4>{el?.campaign_name}</h4>
                          </div>
                        </>
                      </ListItemExtendedStyles>
                    </li>
                  ))}
                </ListContainerStyles>
              </div>
            </>
          ) : (
            <Text padding='0'>No integration found</Text>
          )}

          {alreadySharedFiles?.length > 0 && (
            <AlreadySharedFilesStyles aria-label='already-shared-list'>
              <Text padding='0 0 1rem 0' color={colors.error}>
                {error}
              </Text>
              <ul>
                {alreadySharedFiles?.map(el => (
                  <li key={el?.ingest_db_id}>
                    <Text padding='0' style={{ wordBreak: 'break-word' }}>
                      {el?.filename}
                    </Text>
                  </li>
                ))}
              </ul>
            </AlreadySharedFilesStyles>
          )}
        </Body>

        {details?.length > 0 && (
          <Footer style={{ columnGap: '1.5rem' }}>
            <Normal id='share-no' onClick={onModalClose} color='secondary'>
              Cancel
            </Normal>
            <Normal
              id='share-yes'
              isLoading={isSubmitting}
              disabled={
                !selectedIntegration ||
                assetDetails?.shared_detail?.sprinklr_shared_by?.name ||
                alreadySharedFiles?.length ===
                  (assetDetails?.isMultiple
                    ? updatedSelectedItems &&
                      Object.keys(updatedSelectedItems)?.length
                    : 1)
              }
              onClick={e =>
                onSubmit(e, alreadySharedFiles?.length > 0 ? 'proceed' : '')
              }>
              {alreadySharedFiles?.length > 0 ? 'Proceed' : 'Share'}
            </Normal>
          </Footer>
        )}
      </>
    );
  }

  return (
    <>
      <Body>
        <Text>Fetching bucket details</Text>
      </Body>
    </>
  );
};

ShareToSprinklr.propTypes = {
  toggleModal: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
};

export default ShareToSprinklr;
