import React from 'react';
import styled from 'styled-components';

// Styles
import { ripple } from '../../styles/animations';

const LoaderWrapper = styled.div`
  position: relative;
  width: 19.4rem;
  height: 17.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .blueBg {
    background-color: rgba(44, 134, 255, 0.5);
  }
  .loaderCircle {
    height: 4rem;
    width: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin: -2rem -2rem;
    border-radius: 10rem;
    z-index: 1;

    .ripple {
      position: absolute;
      width: 10rem;
      height: 10rem;
      z-index: -1;
      left: 50%;
      top: 50%;
      opacity: 0;
      margin: -5rem 0 0 -5rem;
      border-radius: 10rem;
      animation: ${ripple} 1.8s infinite;

      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
    }
  }
`;

const CardLoader = () => {
  return (
    <LoaderWrapper>
      <div className='loaderCircle blueBg'>
        <span className='ripple blueBg' />
        <span className='ripple blueBg' />
        <span className='ripple blueBg' />
      </div>
    </LoaderWrapper>
  );
};

export default CardLoader;
