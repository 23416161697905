/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Normal } from '../../components/Button';
import { SecondaryModalBodyStyles } from '../../components/modal/secondary';
import apiConfig from '../../../config';

// Styles
import { Text } from '../../styles/common';
import { fs } from '../../styles/variables';

const UpgradeManageTeamBanner = `${apiConfig.assets}/upgrade-manage-team.png`;

const UpgradeTeamModal = props => {
  const { data } = props;
  return (
    <SecondaryModalBodyStyles>
      <div>
        <img src={UpgradeManageTeamBanner} alt='Team Modal' />
        <h2 className='head'>Manage Team</h2>
        <Text className='text'>
          <b>{data?.name}</b> Team is created, start adding members to it.
        </Text>
      </div>
      <div className='cta-section'>
        <a href='/settings'>
          <Normal fontSize={fs.sm}>Manage</Normal>
        </a>
      </div>
    </SecondaryModalBodyStyles>
  );
};

UpgradeTeamModal.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default UpgradeTeamModal;
