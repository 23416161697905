/* eslint-disable max-len */
/* eslint-disable no-console */
import Creators from './actions';
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';
import { serviceCallBasedOnMethod } from '../../helperFunctions';

const {
  actionLoadSubscriptionDetails,
  actionLoadCurrentPlanDetails,
  actionLoadAvailablePlans,
  actionLoadTabData,
  actionLoadSelectedPlan,
  actionUpdateCreditsAvailable,
} = Creators;

const getSubscriptionDetails = () =>
  serviceCall.get(`${apiConfig.auth}/user/subscription/`);
const getSubscribedDetails = request =>
  serviceCall.get(`${apiConfig.auth}/subscription/`, request);
const getTabDetailsService = request =>
  serviceCall.get(`${apiConfig.auth}/transactions/`, request);
const upgradePlanDetailsService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/upgrade/subscription/`,
    request,
    method
  );
const saveAvailablePlans = data => dispatch =>
  dispatch(actionLoadAvailablePlans(data));
const renewSubscriptionService = request =>
  serviceCall.post(`${apiConfig.auth}/subscription/renew/`, request);
const addCreditsService = request =>
  serviceCall.post(`${apiConfig.auth}/add/credits/`, request);
const addStorageService = request =>
  serviceCall.post(`${apiConfig.auth}/add/storage/`, request);
export const getTeamStorageService = request =>
  serviceCall.get(`${apiConfig.auth}/team/storage/`, request);
export const getFiltersService = request =>
  serviceCall.get(`${apiConfig.auth}/filters/`, request);
const saveSubscriptionDetails = data => dispatch =>
  dispatch(actionLoadSubscriptionDetails(data));
const saveCurrentPlanDetails = data => dispatch =>
  dispatch(actionLoadCurrentPlanDetails(data));
const loadTabDetails = data => dispatch => dispatch(actionLoadTabData(data));
const loadSelectedPlan = data => dispatch =>
  dispatch(actionLoadSelectedPlan(data));
const loadCreditsAvailable = data => dispatch =>
  dispatch(actionUpdateCreditsAvailable(data));

export default {
  getSubscriptionDetails,
  saveSubscriptionDetails,
  upgradePlanDetailsService,
  addCreditsService,
  getTabDetailsService,
  loadTabDetails,
  loadSelectedPlan,
  renewSubscriptionService,
  addStorageService,
  loadCreditsAvailable,
  saveAvailablePlans,
  getSubscribedDetails,
  saveCurrentPlanDetails,
  getTeamStorageService,
};
