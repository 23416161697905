import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PropTypes } from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FileCopyOutlined } from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';

import DeleteSessionModal from './DeleteSession';
import { Normal } from '../../../components/Button';
import { cameraSessionsService } from '../../duck/operations';
import { formatDateTime } from '../../../helperFunctions';
import { enqueueSnackbar } from '../../../snackbar/duck/actions';
import { CAMERA_TO_EDIT_SESSION_STEPS, TAB_KEYS } from '../../constants';

import {
  ListKeyValueItemStyles,
  AppLinkButton,
  CopiedTextStyles,
  Text,
  AnchorLinkStyles,
} from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';
import { colors, radius } from '../../../styles/variables';
import { Body, Footer } from '../../../styles/sidebar';

const RootStyles = styled.div`
  display: grid;
  row-gap: 1.5rem;
`;

const CredentialsStyles = styled.div`
  position: relative;
  padding: 1.4rem;
  width: 100%;
  border-radius: 1rem;
  border: 0.1rem solid ${whiteAlpha(0.3)};

  ul {
    display: grid;
    row-gap: 1.5rem;
  }
`;

const CopyParentStyles = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 1rem;
`;

const ControlsStyles = styled.div`
  display: grid;
  row-gap: 1rem;
  border-top: 1px solid ${whiteAlpha(0.2)};
`;

const Loader = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: rgba(255, 215, 0, 0.3);
    border-radius: ${radius.default};
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #ffd700;
  }
`;

const STATUS = {
  active: 'active',
  pending: 'pending',
  pausing: 'pausing',
  paused: 'paused',
  resuming: 'resuming',
};

const SwitchStyles = styled(Switch)`
  .MuiSwitch-switchBase {
    padding: 5.8px;

    &.Mui-checked + .MuiSwitch-track {
      opacity: 1;
    }
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${colors.white};
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.blue.shadeOne};
  }

  .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
    background-color: ${whiteAlpha(0.8)};
  }

  .MuiSwitch-track {
    background-color: ${whiteAlpha(0.8)};
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
  }
`;

export default function SessionDetails({ goTo, pusher }) {
  const dispatch = useDispatch();
  const credentialsRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const { project_db_id } = useParams();
  const [details, setDetails] = useState(null);
  const [copied, setCopied] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);

  const query = new URLSearchParams(location?.search);

  const onCopyCredentials = useCallback(() => {
    navigator.clipboard.writeText(credentialsRef?.current?.value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [credentialsRef, setCopied]);

  useEffect(() => {
    let channel = null;
    if (pusher && project_db_id) {
      channel = pusher.subscribe(`private-${project_db_id}`);
      if (channel) {
        channel.bind('c2e_session_update', data => {
          setDetails(data);
        });
      }
    }

    return () => {
      if (channel && pusher) {
        channel.unbind();
        pusher.unsubscribe();
      }
    };
  }, [pusher, project_db_id]);

  useEffect(() => {
    const getDetails = async () => {
      const session = query?.get('s');
      try {
        const res = await cameraSessionsService({
          project_db_id,
          c2e_session_db_id: session,
        });
        setDetails(res?.data?.data?.c2e_session ?? null);
      } catch (err) {
        console.log('🚀 ~ getDetails ~ err:', err);
      }
    };
    getDetails();
    return () =>
      history.replace(`${location.pathname}?tab=${TAB_KEYS.cameraToEdit}`);
  }, []);

  const status = useMemo(
    () => details?.status?.toLowerCase(),
    [details?.status]
  );

  const onToggleSession = useCallback(
    async action => {
      const session = query?.get('s');
      try {
        const res = await cameraSessionsService(
          {
            project_db_id,
            c2e_session_db_id: session,
            action,
          },
          'put'
        );
        if (res?.data?.response) {
          dispatch(
            enqueueSnackbar({
              message: {
                messageHead: 'Success',
                messageBody: res?.data?.data?.message || 'C2E session paused',
                variant: 'success',
              },
            })
          );
        }
      } catch (err) {
        console.log('🚀 ~ getDetails ~ err:', err);
      }
    },
    [query]
  );

  return (
    <>
      <Body>
        <RootStyles>
          {details ? (
            <>
              <ul style={{ display: 'grid', rowGap: '1.5rem' }}>
                <ListKeyValueItemStyles aria-label='id'>
                  <p>Session ID</p>
                  <h4>{details?.c2e_session_id}</h4>
                </ListKeyValueItemStyles>

                {details?.folder_name && (
                  <ListKeyValueItemStyles aria-label='folder'>
                    <p>Folder Name</p>
                    <h4>{details?.folder_name}</h4>
                  </ListKeyValueItemStyles>
                )}
                <ListKeyValueItemStyles aria-label='camera'>
                  <p>Camera</p>
                  <h4>{details?.camera_model}</h4>
                </ListKeyValueItemStyles>

                <ListKeyValueItemStyles aria-label='created-on'>
                  <p>Created On</p>
                  <h4>{`${formatDateTime(details?.created_on)?.date} | ${
                    formatDateTime(details?.created_on)?.time
                  }`}</h4>
                </ListKeyValueItemStyles>
              </ul>

              {details?.hostname && details?.password && details?.username ? (
                <div aria-label='session-controls-credentials'>
                  <Text padding='0 0 1rem 0'>
                    Share the session credentials with the camera person to
                    start uploading assets from the{' '}
                    <span>
                      <AnchorLinkStyles
                        href='https://postudio.tawk.help/article/camera-edit'
                        target='_blank'
                        rel='noreferrer'>
                        Camera to Postudio
                      </AnchorLinkStyles>
                    </span>
                    . Download the{' '}
                    <span>
                      <AnchorLinkStyles href='https://bit.ly/c2e-cert'>
                        certificate file
                      </AnchorLinkStyles>
                    </span>{' '}
                    and keep it inside the memory card(SDXC) root directory.
                  </Text>

                  <CredentialsStyles>
                    <ul>
                      <ListKeyValueItemStyles aria-label='hostname'>
                        <p>Hostname</p>
                        <h4>{details?.hostname}</h4>
                      </ListKeyValueItemStyles>

                      <ListKeyValueItemStyles aria-label='username'>
                        <p>Username</p>
                        <h4>{details?.username}</h4>
                      </ListKeyValueItemStyles>

                      <ListKeyValueItemStyles aria-label='camera'>
                        <p>Password</p>
                        <h4>{details?.password}</h4>
                      </ListKeyValueItemStyles>
                    </ul>
                  </CredentialsStyles>

                  <textarea
                    style={{ display: 'none', whiteSpace: 'pre-wrap' }}
                    ref={credentialsRef}
                    value={`Use the C2E session credentials to connect your camera to Postudio and start uploading assets seamlessly.\nLearn More: https://postudio.tawk.help/article/camera-edit\nDownload the certificate file: https://bit.ly/c2e-cert and keep it inside the memory card(SDXC) root directory.\n\nHostname: ${details?.hostname} \nUsername: ${details?.username}\nPassword: ${details?.password}`}
                  />

                  <CopyParentStyles>
                    <AppLinkButton onClick={onCopyCredentials}>
                      <span
                        style={{
                          verticalAlign: 'middle',
                          marginRight: '0.5rem',
                        }}>
                        <FileCopyOutlined />
                      </span>
                      <span>Copy Credentials</span>
                    </AppLinkButton>
                    {copied && (
                      <CopiedTextStyles className='copied'>
                        Copied
                      </CopiedTextStyles>
                    )}
                  </CopyParentStyles>
                </div>
              ) : (
                <>
                  <Text padding='0' color='#FFD700'>
                    {status === STATUS.pending && 'Creating C2E session...'}
                    {status === STATUS.resuming && 'Turning on C2E session...'}
                    {status === STATUS.pausing && 'Turning off C2E session...'}
                    {status === STATUS.paused && 'C2E session is turned off.'}
                  </Text>
                  {status !== STATUS.active && status !== STATUS.paused && (
                    <Loader />
                  )}
                </>
              )}

              {status !== STATUS.pending && (
                <ControlsStyles>
                  <Text padding='1rem 0 0 0' color={whiteAlpha(0.6)}>
                    Session Controls
                  </Text>

                  <div className='flex items-center justify-between'>
                    <Text padding='0'>Session Status</Text>
                    <div className='flex items-center'>
                      <Text padding='0'>OFF</Text>
                      <SwitchStyles
                        size='small'
                        color='primary'
                        checked={status === STATUS.active}
                        disabled={
                          status !== STATUS.paused && status !== STATUS.active
                        }
                        onChange={() =>
                          onToggleSession(
                            status === STATUS.paused ? 'resume' : 'pause'
                          )
                        }
                      />
                      <Text padding='0'>ON</Text>
                    </div>
                  </div>

                  <AppLinkButton
                    disabled={
                      status !== STATUS.active && status !== STATUS.paused
                    }
                    style={{ textAlign: 'left' }}
                    onClick={() => {
                      toggleModal(true);
                    }}>
                    Delete C2E Session
                  </AppLinkButton>
                </ControlsStyles>
              )}
            </>
          ) : (
            <>
              <Text padding='0'>Session details not found.</Text>
            </>
          )}
        </RootStyles>
      </Body>

      <Footer>
        <Normal
          type='submit'
          onClick={() => goTo(CAMERA_TO_EDIT_SESSION_STEPS.list)}
          color='secondary'>
          Back
        </Normal>
      </Footer>

      {isModalOpen && (
        <DeleteSessionModal
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          details={details}
          goTo={goTo}
        />
      )}
    </>
  );
}

SessionDetails.propTypes = {
  goTo: PropTypes.func,
  pusher: PropTypes.instanceOf(Object),
};
