/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';
import Pricing from './Pricing';
import Card from './Card';
import { getPluginRequest, getTotalPluginsPrice } from '../../helperFunctions';

import { fetchCloudHardwares } from '../../duck/operations';
import { CLOUD_SCREENS } from './constants';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { whiteAlpha } from '../../../styles/utils';
import { Text } from '../../../styles/common';
import { GridStyles, CloudScreenHead } from './styles';

const Hardware = props => {
  const [loading, setLoading] = useState(false);
  const {
    match: { params },
    task: { task_db_id },
    toggleToast,
    getSystems,
    type,
    setConfig,
    goTo,
    selectedSoftware,
    selectHardware,
    selectedPlugin,
    isPluginSkipped,
    hardwares,
    setHardwares,
  } = props;

  const fetchHardwares = async () => {
    setLoading(true);
    const configsResponse = await fetchCloudHardwares({
      // this will get system configs available based on task type
      software_slug: selectedSoftware?.slug,
      ...(task_db_id && { task_db_id }),
      ...(selectedPlugin?.length > 0 && {
        plugin_slug: getPluginRequest(selectedPlugin),
      }),
      project_db_id: params?.project_db_id,
      task_type: type?.value,
    });

    const {
      data: { response, data },
    } = configsResponse;
    if (response) {
      setLoading(false);
      setHardwares(data?.hardwares);
    } else {
      setHardwares([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isPluginSkipped) fetchHardwares();
  }, [isPluginSkipped]);

  const handleNext = async item => {
    selectHardware(item);
    const systemResponse = await getSystems({
      ...(task_db_id && { task_db_id }),
      hardware_slug: item?.slug,
      software_slug: selectedSoftware?.slug,
      project_db_id: params?.project_db_id,
      task_type: type?.value,
      ...(selectedPlugin?.length > 0 && {
        plugin_slug: getPluginRequest(selectedPlugin),
      }),
    });

    const {
      data: { response, data, errormessage },
    } = systemResponse;

    if (response) {
      setConfig(data);
      goTo(CLOUD_SCREENS.duration);
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  const onBack = () => {
    if (isPluginSkipped) {
      goTo(CLOUD_SCREENS.softwares);
    } else {
      goTo(CLOUD_SCREENS.plugins);
    }
  };

  return (
    <>
      <Body>
        <GradientCard
          {...type}
          details={[
            selectedSoftware?.title,
            selectedPlugin?.length > 0 &&
              `₹${Number(getTotalPluginsPrice(selectedPlugin))} / hr`,
          ]}
        />

        {selectedPlugin?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm
          >
            Add-on Plug-ins:{' '}
            {selectedPlugin.map(el => el?.title || el).join(', ')}
          </Text>
        )}

        <CloudScreenHead
          bg
          style={{
            marginTop: selectedPlugin?.length > 0 ? '0rem' : '2rem',
          }}
        >
          Choose the hardware configuration you want to work on:
        </CloudScreenHead>

        {loading ? (
          <Text color={whiteAlpha(0.8)} paddingLeft='0' paddingRight='0'>
            Fetching
          </Text>
        ) : (
          <>
            {hardwares && hardwares.length ? (
              <GridStyles>
                {hardwares.map(item => {
                  const {
                    configurations: { processors, message },
                  } = item;
                  return (
                    <Card
                      key={item?.slug}
                      label={item?.title}
                      onClick={() => handleNext(item)}
                      message={processors}
                      hoverScreen={{
                        para: (message && `Recommended for ${message}`) || (
                          <Pricing {...item} />
                        ),
                      }}
                    >
                      <Pricing {...item} />
                    </Card>
                  );
                })}
              </GridStyles>
            ) : (
              <Text paddingLeft='0' paddingRight='0'>
                Details coming soon
              </Text>
            )}
          </>
        )}
      </Body>

      <Footer>
        <Normal
          onClick={onBack}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          Back
        </Normal>
      </Footer>
    </>
  );
};

Hardware.propTypes = {
  goTo: PropTypes.func,
  setConfig: PropTypes.func,
  getSystems: PropTypes.func,
  type: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  selectedSoftware: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  selectHardware: PropTypes.func,
  selectedPlugin: PropTypes.instanceOf(Array),
  isPluginSkipped: PropTypes.bool,
  hardwares: PropTypes.instanceOf(Object),
  setHardwares: PropTypes.func,
};

export default Hardware;
