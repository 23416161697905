import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@material-ui/icons';

import { colors, fs, padding } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const SearchBarStyles = styled.div`
  border: 0.1rem solid rgba(44, 134, 255, 0.7);
  border-radius: 2rem;
  padding: ${padding.container};
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${colors.white};
  }

  input {
    background: transparent;
    outline: none;
    border: none;
    color: ${colors.white};
    font-size: ${fs.sm};
    padding-left: ${padding.v};
    width: 300px;

    &::placeholder {
      color: ${whiteAlpha(0.7)};
    }
  }
`;

const AssetSearchBar = ({ value, onChange }) => {
  return (
    <SearchBarStyles>
      <SearchOutlined />
      <input
        type='search'
        value={value}
        onChange={onChange}
        placeholder='Search Assets'
      />
    </SearchBarStyles>
  );
};

AssetSearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AssetSearchBar;
