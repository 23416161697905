/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../components/Button';
import { Checkbox } from '../../components/FormInputs';

// helper functions
import {
  amountWithCommas,
  getDaysFromCurrentDate,
} from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text, Flex } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { colors } from '../../styles/variables';

const WrapperStyles = styled.div`
  .MuiSvgIcon-root {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${colors.white};
  }

  .increment-decrement-actions {
    padding: 1.5rem 3rem;

    button {
      svg {
        fill: ${whiteAlpha(0.8)};
      }
    }

    button:hover {
      > svg {
        fill: ${colors.white};
      }
    }

    button:disabled {
      cursor: not-allowed;
      > svg {
        fill: ${whiteAlpha(0.5)};
      }
    }
  }
`;

const AddStorage = props => {
  const { addStorage, closeSidebar, toggleToast } = props;
  const { storage_detail, plan_detail, credits, renewal } = useSelector(
    state => state.subscription
  );
  const [isMakePayment, setMakePayment] = useState(false);
  const [updatedStorage, setStorage] = useState(0);
  const [addonStorage, setAddonStorage] = useState(0);
  const [total, setTotal] = useState(0);
  const [amountToBePaid, setAmountToBePaid] = useState(0);
  const [isCheckboxSelected, toggleCheckbox] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const onNext = useCallback(() => {
    setSubmitting(true);
    addStorage({
      storage_size: updatedStorage,
      credits_used: isCheckboxSelected,
    })
      .then(res => {
        const { errormessage, data, response } = res?.data;
        setSubmitting(false);
        if (response) {
          closeSidebar();
          toggleToast({
            message: {
              messageHead: 'Success',
              messageBody: data?.message,
              variant: 'success',
            },
          });
        } else {
          throw new Error(errormessage);
        }
      })
      .catch(err => {
        setSubmitting(false);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        });
      });
  }, [isCheckboxSelected, updatedStorage]);

  useEffect(() => {
    let updatedTotal = 0;
    const updatedAddonStorage =
      updatedStorage * storage_detail?.amount_per_gb_storage +
      storage_detail?.storage_amount;
    setAddonStorage(updatedAddonStorage);
    updatedTotal = updatedAddonStorage + plan_detail?.subscription_amount;
    setTotal(updatedTotal);

    // prorated calculation
    const difference = getDaysFromCurrentDate(renewal?.renewal_date) || 0;
    let updatedProrated = Math.ceil(
      ((updatedStorage * storage_detail?.amount_per_gb_storage) / 30) *
        difference
    );

    if (isCheckboxSelected) {
      if (updatedProrated < credits?.credits_available) {
        setAmountToBePaid(updatedProrated);
        setMakePayment(false);
      } else {
        setMakePayment(true);
        updatedProrated -= credits?.credits_available;
        setAmountToBePaid(Math.ceil(updatedProrated));
      }
    } else {
      setMakePayment(true);
      setAmountToBePaid(updatedProrated);
    }
  }, [
    isMakePayment,
    updatedStorage,
    isCheckboxSelected,
    storage_detail,
    plan_detail,
  ]);

  const onStorageChange = useCallback(
    type => {
      let storage = updatedStorage;
      if (type === 'increment') {
        storage += storage_detail?.storage_size_addition_step;
      } else if (storage !== 0 && type === 'decrement') {
        storage -= storage_detail?.storage_size_addition_step;
      }
      setStorage(storage);
    },
    [updatedStorage, storage_detail?.storage_added]
  );

  return useMemo(() => {
    return (
      <>
        <Body>
          <WrapperStyles>
            <Flex className='increment-decrement-actions items-center justify-between my-1-5 bg-white-0-point-15 rounded'>
              <button
                type='button'
                label='Decrment'
                disabled={updatedStorage === 0}
                className='increment px-0-8 border-none outline-none bg-transparent cursor-pointer'
                onClick={() => onStorageChange('decrement')}
              >
                <RemoveCircleOutline />
              </button>

              <div style={{ textAlign: 'center' }}>
                <Text className='py-0' style={{ fontSize: '3rem' }}>
                  {updatedStorage} GB
                </Text>
                <Text xs>
                  Each {storage_detail?.storage_size_addition_step} GB costs{' '}
                  {storage_detail?.amount_per_gb_storage * 100} PSC/Month
                </Text>
              </div>

              <button
                type='button'
                label='Increment'
                className='decrement px-0-8 border-none outline-none bg-transparent cursor-pointer'
                onClick={() => onStorageChange('increment')}
              >
                <AddCircleOutline />
              </button>
            </Flex>

            {updatedStorage > 0 && (
              <>
                <div className='mt-1 p-1 rounded border border-1 border-white-0-point-3'>
                  <Flex className='items-baseline justify-between'>
                    <Text color={whiteAlpha(0.8)}>Prorated Amount(PSC)</Text>
                    <Text>{amountWithCommas(amountToBePaid)}</Text>
                  </Flex>
                  <div className='mx-0-8 border-t border-1 border-white-0-point-3 my-1' />
                  <Text color={whiteAlpha(0.6)}>
                    {updatedStorage} PSC will be charged from the next renewal
                    date
                  </Text>
                </div>

                <div
                  className='flex items-center justify-between rounded px-1 py-2 border border-1 border-white-0-point-3 w-full bg-transparent cursor-pointer mt-1'
                  onClick={() => toggleCheckbox(prev => !prev)}
                  aria-hidden='true'
                >
                  <div>
                    <Text className='py-0' color={whiteAlpha(0.8)}>
                      Available Credits
                    </Text>
                    <Flex className='items-baseline'>
                      <Text bg className='pb-0 pr-0-5'>
                        {amountWithCommas(credits?.credits_available)}
                      </Text>
                      <Text sm color={whiteAlpha(0.6)} className='px-0 py-0'>
                        PSC
                      </Text>
                    </Flex>
                  </div>

                  <div className='px-0-8'>
                    <Checkbox
                      checked={isCheckboxSelected}
                      value={isCheckboxSelected}
                    />
                  </div>
                </div>
              </>
            )}

            {addonStorage > 0 && amountToBePaid > 0 && (
              <>
                <Text className='px-0 mt-1'>1 PSC = ₹1.</Text>
                <Text padding='0'>
                  {isMakePayment
                    ? `Make a payment of ₹${amountWithCommas(amountToBePaid)}`
                    : `₹${amountWithCommas(
                        amountToBePaid
                      )} will be deducted from your credits`}
                </Text>
              </>
            )}
          </WrapperStyles>
        </Body>

        <Footer>
          {!updatedStorage && (
            <Normal color='secondary' onClick={closeSidebar}>
              Close
            </Normal>
          )}
          {updatedStorage > 0 && (
            <Normal onClick={onNext} isLoading={isSubmitting}>
              Next
            </Normal>
          )}
        </Footer>
      </>
    );
  }, [
    amountToBePaid,
    setAmountToBePaid,
    setAddonStorage,
    addonStorage,
    total,
    storage_detail,
    updatedStorage,
    plan_detail,
    isSubmitting,
    credits?.credits_available,
    isCheckboxSelected,
    isMakePayment,
    setMakePayment,
  ]);
};

AddStorage.propTypes = {
  addStorage: PropTypes.func,
  toggleToast: PropTypes.func,
  closeSidebar: PropTypes.func,
};

export default AddStorage;
