import React from 'react';
import styled from 'styled-components';

// Styles
import { ripple } from '../../styles/animations';

const LoaderSvg = styled.div`
  top: ${(props) => (props.storyboardLoader ? '35rem' : '-10rem')};
  left: 0;
  height: ${(props) => (props.storyboardLoader ? '100%' : '100vh')};
  width: 100vw;
  position: absolute;
  z-index: 1111;
  background-color: #121212;
  .loader-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .blueBg {
      background-color: rgba(44, 134, 255, 0.5);
    }
    .loaderCircle {
      height: 4rem;
      width: 4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      margin: -3rem 0 0 -3rem;
      border-radius: 10rem;
      z-index: 1;

      .ripple {
        position: absolute;
        width: 10rem;
        height: 10rem;
        z-index: -1;
        left: 50%;
        top: 50%;
        opacity: 0;
        margin: -5rem 0 0 -5rem;
        border-radius: 10rem;
        animation: ${ripple} 1.8s infinite;

        &:nth-child(2) {
          animation-delay: 0.3s;
        }
        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }
  }
`;

const Loader = (props) => {
  return (
    <LoaderSvg {...props}>
      <div className="loader-wrapper">
        <div className="loaderCircle blueBg">
          <span className="ripple blueBg" />
          <span className="ripple blueBg" />
          <span className="ripple blueBg" />
        </div>
      </div>
    </LoaderSvg>
  );
};

export default Loader;
