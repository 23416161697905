/* eslint-disable camelcase */
import React, { useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../components/Button';
import { TextField, useForm } from '../../components/FormInputs';

// Helper Functions
import { onlyNumbers } from '../../components/FormInputs/helperFunctions';
import { VALIDATION_MESSAGES } from '../../constants';

import { amountWithCommas } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text, Flex } from '../../styles/common';
import { colors } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const INITIAL_STATE = {
  credits: {
    value: null,
    error: undefined,
    required: false,
    validation: value => {
      if (!onlyNumbers(value)) {
        return VALIDATION_MESSAGES.onlyNumbers;
      }
      return undefined;
    },
  },
};

const AddCredits = ({
  closeSidebar,
  toggleToast,
  addCredits,
  updateCreditsAvailable,
}) => {
  const { credits } = useSelector(state => state.subscription);
  const [isSubmitting, setSubmitting] = useState(false);
  const { formValues, onChange, onSubmit } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      addCredits({ credits: Number(formValues.credits.value) })
        .then(res => {
          const { errormessage, data, response } = res?.data;
          setSubmitting(false);
          if (response) {
            updateCreditsAvailable({
              credits_available: data?.credits_available,
            });
            closeSidebar();
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody: data?.message,
                variant: 'success',
              },
            });
          } else {
            throw new Error(errormessage);
          }
        })
        .catch(err => {
          setSubmitting(false);
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          });
        });
    }
  );

  return useMemo(() => {
    return (
      <>
        <Body>
          <Text md className='px-0'>
            Postudio Credits(PSC) helps you to work on the cloud systems through
            a prepaid payment process.
          </Text>

          <div className='flex items-center justify-between rounded px-1 py-2 my-1 border border-1 border-white-0-point-3 w-full bg-transparent'>
            <div>
              <Text className='py-0' color={whiteAlpha(0.8)}>
                Available Credits
              </Text>
              <Flex className='items-baseline'>
                <Text bg className='pb-0'>
                  {amountWithCommas(credits?.credits_available)}
                </Text>
                <Text sm color={whiteAlpha(0.6)} className='px-0 py-0'>
                  PSC
                </Text>
                {credits?.credits_available < 100 && (
                  <Text sm color={colors.error} className='py-0'>
                    Low Balance
                  </Text>
                )}
              </Flex>
            </div>
          </div>

          <TextField
            id='credits'
            label='Add Credits'
            value={formValues.credits.value}
            onChange={onChange}
            error={!!formValues.credits.error}
            helperText={formValues.credits.error}
          />

          {formValues.credits.value > 0 && (
            <>
              <Text className='px-0'>1 PSC = ₹1.</Text>
              <Text padding='0'>{`Make a payment of ₹${amountWithCommas(
                parseInt(formValues.credits.value, 10)
              )}`}</Text>
            </>
          )}
        </Body>

        {!formValues.credits.error && Number(formValues.credits.value) > 0 && (
          <Footer>
            <Normal type='submit' onClick={onSubmit} isLoading={isSubmitting}>
              Next
            </Normal>
          </Footer>
        )}
      </>
    );
  }, [
    isSubmitting,
    formValues.credits?.value,
    formValues.credits.error,
    credits?.credits_available,
  ]);
};

AddCredits.propTypes = {
  addCredits: PropTypes.func,
  toggleToast: PropTypes.func,
  closeSidebar: PropTypes.func,
  updateCreditsAvailable: PropTypes.func,
};

export default AddCredits;
