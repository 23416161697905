import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Components
import { TextField, useForm } from '../../components/FormInputs';
import { Normal } from '../../components/Button';

// Helper Functions
import {
  required,
  fullName,
  noBlankSpaces,
} from '../../components/FormInputs/helperFunctions';

import { getStoryboardBucket as getStoryboardBucketService } from '../duck/operations';

// Constants
import { VALIDATION_MESSAGES } from '../../constants';

// Styles
import { Body, Footer } from '../../styles/modal';

const INITIAL_STATE = {
  name: {
    value: '',
    error: undefined,
    required: true,
    validation: value => {
      if (!value) return required(value);
      if (!fullName(value)) return VALIDATION_MESSAGES.noSpecialCharacters;
      if (value) return noBlankSpaces(value);
      return undefined;
    },
  },
};

const CreateFolder = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const location = useLocation();
  const breadcrumbs = useSelector(
    store => store.storyboard?.assets?.breadcrumbs
  );

  const query = new URLSearchParams(location?.search);

  const {
    toggleToast,
    toggleModal,
    history,
    match: { params },
  } = props;

  const { formValues, onChange, onSubmit } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      const path = breadcrumbs
        .map(breadcrumb => breadcrumb.name)
        .join('/')
        .concat(`/${formValues.name.value?.trim()}`);
      const folderResponse = await getStoryboardBucketService({
        project_db_id: params?.project_db_id,
        data: {
          upload_status: 'started',
          file_info: {
            filename: '',
            folder_path: path,
            extension: '',
            size: '',
            mime_type: '',
          },
        },
      });

      const {
        data: { response, errormessage, data },
      } = folderResponse;

      if (response) {
        setSubmitting(false);
        history.push(
          `/project/${params?.project_db_id}/${
            data?.ingest_db_id
          }?tab=${query?.get('tab')}`
        );
        toggleModal(null, false, null);
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: 'Folder created successfully',
            variant: 'success',
          },
        });
      } else {
        setSubmitting(false);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  return (
    <form onSubmit={onSubmit} name='create-folder'>
      <Body>
        <TextField
          inputRef={input => input && input.focus()}
          id='name'
          label='Folder Name'
          value={formValues.name.value}
          onChange={onChange}
          error={!!formValues.name.error}
          helperText={formValues.name.error}
        />
      </Body>

      <Footer>
        <Normal
          onClick={() => toggleModal(null, false, null)}
          color='secondary'
          style={{ marginRight: '2rem' }}>
          Cancel
        </Normal>
        <Normal type='submit' disabled={isSubmitting} isLoading={isSubmitting}>
          Create
        </Normal>
      </Footer>
    </form>
  );
};

CreateFolder.propTypes = {
  toggleToast: PropTypes.func,
  toggleModal: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
};

export default CreateFolder;
