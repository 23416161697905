import React from 'react';
import { PropTypes } from 'prop-types';

// Components
import { TextField, useForm } from '../../components/FormInputs';
import { Normal } from '../../components/Button';

// Helper Functions
import {
  required,
  fullName,
  noBlankSpaces,
} from '../../components/FormInputs/helperFunctions';

// Constants
import { MODAL_TYPES, VALIDATION_MESSAGES } from '../../constants';

// Styles
import { Body, Footer } from '../../styles/sidebar';

const ManageProject = props => {
  const {
    closeSidebar,
    toggleModal,
    details,
    sendBrief,
    toggleToast,
    toggleUpgradeModal,
    match: { params },
  } = props;

  const INITIAL_STATE = {
    name: {
      value: details?.title || '',
      error: undefined,
      required: true,
      validation: value => {
        if (!value) {
          return required(value);
        }
        if (!fullName(value)) {
          return VALIDATION_MESSAGES.noSpecialCharacters;
        }
        if (value) {
          return noBlankSpaces(value);
        }
        return undefined;
      },
    },

    storage: {
      value: details?.storage_used || '',
      error: undefined,
    },
  };

  const { formValues, onChange } = useForm(INITIAL_STATE, async () => {
    const briefResponse = await sendBrief({
      project_db_id: params.project_db_id,
      storage: formValues.storage.value || details?.storage,
      data: {
        title: formValues.name.value.trim(),
      },
    });

    const {
      data: { response, errormessage, data },
    } = briefResponse;

    if (response) {
      closeSidebar();
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Details updated successfully',
          variant: 'success',
        },
      });
    } else if (data?.upgrade_required) {
      toggleUpgradeModal({
        show: true,
        data,
      });
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  });

  const handleDelete = () => {
    closeSidebar();
    toggleModal(MODAL_TYPES.deleteStoryboard, true, 'Delete Project');
  };

  return (
    <>
      <Body>
        <TextField
          id='name'
          label='Name'
          value={formValues.name.value}
          onChange={onChange}
          error={!!formValues.name.error}
          helperText={formValues.name.error}
          disabled
        />

        <TextField
          id='storage'
          label='Storage Used'
          value={formValues.storage.value}
          onChange={onChange}
          error={!!formValues.storage.error}
          helperText={formValues.storage.error}
          disabled
        />
      </Body>

      <Footer>
        <Normal
          onClick={handleDelete}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          Delete
        </Normal>
        {/* <Normal onClick={onSubmit}>Update</Normal> */}
      </Footer>
    </>
  );
};

ManageProject.propTypes = {
  toggleToast: PropTypes.func,
  sendBrief: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleModal: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  details: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};

export default ManageProject;
