import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { SnackbarProvider } from 'notistack';
import CustomSanckbar from './app/snackbar/CustomSanckbar';

import App from './app/AppContainer';
import { configureStore } from './store/web/configureStore';

JavascriptTimeAgo.locale(en);

// import registerServiceWorker from './utils/registerServiceWorkers';
const store = configureStore();

render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        maxSnack={3}
        content={(key, message, options) => (
          <CustomSanckbar id={key} message={message} options={options} />
        )}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
// {/* registerServiceWorker(); */}
