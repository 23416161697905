/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Fab as MaterialFab, CircularProgress } from '@material-ui/core';

// Variables
import { colors } from '../../styles/variables';

const { blue } = colors; // extract blue

const styles = {
  root: {
    boxShadow: 'none',
    width: '5.6rem',
    height: '5.6rem',
    '& .MuiSvgIcon-root': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },

  primary: {
    backgroundColor: blue.primary,
    borderColor: blue.primary,
    '&:hover': {
      backgroundColor: blue.secondary,
      borderColor: blue.secondary,
    },

    '&.Mui-disabled': {
      background: props =>
        props.color === 'secondary'
          ? colors.black.primary
          : colors.blue.primary,
      opacity: '0.7',
    },
  },

  circularProgress: {
    '&.MuiCircularProgress-colorPrimary': {
      color: colors.white,
    },
  },
};

const useStyles = makeStyles(styles);

const Fab = props => {
  const { icon, type, isLoading, ...otherProps } = props;
  const classes = useStyles();
  return (
    <MaterialFab
      type={type || 'button'}
      disabled={isLoading}
      className={clsx(classes.root, classes.primary)}
      color='primary'
      {...otherProps}
    >
      {isLoading ? (
        <CircularProgress
          size={20}
          classes={{
            root: classes.circularProgress,
          }}
        />
      ) : (
        icon
      )}
    </MaterialFab>
  );
};

Fab.propTypes = {
  icon: PropTypes.node.isRequired,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Fab;
