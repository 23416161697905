/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import {
  IconButton as MaterialIconButton,
  InputAdornment,
} from '@material-ui/core';
import { FileCopyOutlined, Delete } from '@material-ui/icons';

// Components
import {
  TextField,
  useForm,
  AutoComplete,
  Checkbox,
} from '../../components/FormInputs';
import { Normal } from '../../components/Button';
import AssetOverview from './AssetOverview';

// Helper Functions
import {
  required,
  onlyNumbers,
} from '../../components/FormInputs/helperFunctions';
import { VALIDATION_MESSAGES } from '../../constants';

import {
  formatLabelValue,
  getQueryParams,
  debounce,
  isBrowser,
} from '../../helperFunctions';
import { requestForIdsEmails } from '../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { whiteAlpha } from '../../styles/utils';
import {
  Text,
  Flex,
  TextButton,
  AvatarSecondaryTextStyles,
  CustomAvatarStyles,
  AvatarUsernameStyles,
} from '../../styles/common';
import { fs, radius } from '../../styles/variables';
import { Row, User, Detail, AvatarBadgeParent, IconButton } from './styles';

const TextCheckboxWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

const CopyPasscodeStyles = styled.span`
  padding: 0.25rem 0.5rem;
  font-size: ${fs.xs};
  border-radius: ${radius.default};
  background-color: ${whiteAlpha(0.15)};
`;

const KEYS = {
  allowDownload: 'is_download_allowed',
  shareOriginal: 'share_original',
  share: 'SHARE',
  list: 'LIST',
};

const INITIAL_STATE = {
  /*
   * initial state to set using id of input field eg. brief is passed as "id" to TextField
   * example to set default value from props
   */
  shareWith: {
    value: [],
  },

  settings: {
    value: {
      [KEYS.allowDownload]: true,
    },
  },

  duration: {
    value: 48,
    required: false,
    validation: value => {
      if (!value) {
        return required(value);
      }

      if (!onlyNumbers(value)) {
        return VALIDATION_MESSAGES.onlyNumbers;
      }

      if (
        !/^[1-9][0-9]*$/.test(value) ||
        Number(value) < 1 ||
        Number(value) > 120
      ) {
        return 'Expiration duration must be between 1 to 120 hours';
      }
      return undefined;
    },
  },

  passcode_protection: {
    value: '',
    required: false,
    validation: value => {
      if (!value) return required(value);
      if (value.includes(' ')) return VALIDATION_MESSAGES.noSpaces;
      if (value?.length < 8 || value?.length > 16)
        return 'Use min 8 and max 16 characters';
      return undefined;
    },
  },
};

const ShareAsset = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [isPasscodeCopied, setPasscodeCopy] = useState(false);
  const [state, setState] = useState({
    users: [],
    open: false,
    view: KEYS.share,
    sharedUsers: {},
  });

  const {
    closeSidebar,
    assetDetails,
    getUsersBasedOnFilters,
    location,
    shareAsset,
    toggleToast,
    fetchSettings,
    creatorDetails,
    match: { params },
  } = props;

  const isEditor = getQueryParams(location).get('task');
  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    () => {
      setSubmitting(true);
      const baseRequest = {
        project_db_id: params.project_db_id,
        user_ids: [],
        user_emails: [],
        ingest_db_id: assetDetails.ingest_db_id,
        duration: Number(formValues.duration.value),
        ...formValues.settings.value,
        ...(formValues?.passcode_protection?.value && {
          passcode: formValues?.passcode_protection?.value,
        }),
      };

      const request = requestForIdsEmails(
        formValues.shareWith.value,
        baseRequest,
        'user_emails',
        'user_ids'
      );

      shareAsset(request, 'post')
        .then(res => {
          const {
            data: { response, errormessage },
          } = res;
          setSubmitting(false);
          if (response) {
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody: 'Asset shared successfully',
                variant: 'success',
              },
            });
            closeSidebar();
          } else {
            toggleToast({
              message: {
                messageHead: 'Error',
                messageBody: errormessage,
                variant: 'error',
              },
            });
          }
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  );

  useEffect(() => {
    (async () => {
      const settingsResponse = await fetchSettings({
        project_db_id: params.project_db_id,
        ingest_db_id: assetDetails.ingest_db_id,
        role: isEditor ? 'editor' : 'collaborator',
      });

      const {
        data: { response, data },
      } = settingsResponse;
      if (response) {
        setSettings(data.share_settings);
        const values = data?.share_settings.reduce((acc, curr) => {
          const copy = { ...acc };
          copy[curr.field] = curr.selected;
          return copy;
        }, {});

        const copy = { ...formValues };
        copy.settings.value = values;
        updateValues(copy);
      }
    })();

    (async () => {
      // to get users which whom asset is already shared
      const usersResponse = await shareAsset(
        {
          project_db_id: params.project_db_id,
          ingest_db_id: assetDetails.ingest_db_id,
        },
        'get'
      );

      const {
        data: { response, data },
      } = usersResponse;
      if (response) {
        setState({
          ...state,
          sharedUsers: data,
        });
      }
    })();

    const copy = { ...INITIAL_STATE };
    copy.passcode_protection.required = false;
    return () => {
      updateValues({ ...copy });
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isPasscodeCopied) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setPasscodeCopy(false);
      }, 2000);
    }
  }, [isPasscodeCopied]);

  const onPasscodeCopy = () => {
    if (isBrowser) {
      navigator.clipboard
        .writeText(formValues?.passcode_protection?.value)
        .then(() => setPasscodeCopy(true))
        .catch(err =>
          console.log(
            '🚀 ~ file: [slug].tsx:189 ~ navigator.clipboard.writeText ~ err:',
            err
          )
        );
    }
  };

  const fetchUsers = debounce(async (...args) => {
    const usersResponse = await getUsersBasedOnFilters({
      filters: 'project',
      search_text: args[0]?.value?.trim(),
    });

    const {
      data: { data },
    } = usersResponse;
    const formated =
      data?.length > 0
        ? formatLabelValue(data, 'name', 'user_id', 'username')
        : [];
    setState({
      ...state,
      users: formated,
      open: true,
    });
  });

  const getUsersToShareWith = async (e, value, reason) => {
    const { target } = e;

    if (reason === 'select-option' || reason === 'remove-option') {
      updateValues({
        ...formValues,
        shareWith: {
          ...formValues.shareWith,
          value: value.map(item => ({
            label: item.label,
            value: item.value,
          })),
        },
      });
      setState({ ...state, open: false });
    } else if (target.value.length > 2) {
      fetchUsers(target);
    } else {
      setState({
        ...state,
        users: [],
        open: false,
      });
    }
  };

  const handleCheckboxChange = (e, item) => {
    const { checked } = e.target;
    const updatedFormValues = { ...formValues }; // making copy
    updatedFormValues[item.field].required = checked;
    updatedFormValues[item.field].error = '';
    updatedFormValues.settings.value[item.field] = checked;
    updateValues({ ...updatedFormValues });
  };

  const renderDeleteButton = (user, key) => {
    return (
      (creatorDetails?.permissions?.can_share_to_download_postudio_assets ||
        creatorDetails?.permissions?.can_share_to_download_all_assets) && (
        <Flex>
          <IconButton>
            <Delete
              onClick={() => {
                const request = {
                  ingest_db_id: assetDetails?.ingest_db_id,
                  project_db_id: assetDetails.project_db_id,
                  [key]: key === 'user_db_id' ? user.user_id : user,
                };

                shareAsset({ ...request }, 'put')
                  .then(res => {
                    const {
                      data: { response, data, errormessage },
                    } = res;
                    toggleToast({
                      message: {
                        messageHead: response ? 'Success' : 'Error',
                        messageBody: response
                          ? 'Deleted user successfully'
                          : errormessage,
                        variant: response ? 'success' : 'error',
                      },
                    });

                    const sharedUsers = {
                      users: data?.users,
                      user_emails: data?.user_emails,
                      shared_count: data?.shared_count,
                    };

                    setState({
                      ...state,
                      sharedUsers,
                      view: KEYS.share,
                    });
                  })
                  .catch(() => {
                    toggleToast({
                      message: {
                        messageHead: 'Error',
                        messageBody: 'Something went wrong',
                        variant: 'error',
                      },
                    });
                  });
              }}
            />
          </IconButton>
        </Flex>
      )
    );
  };

  return (
    <>
      <Body
        style={{ paddingRight: '1.5rem' }}
        noFooter={
          state.view === KEYS.share && !formValues.shareWith.value.length
        }
      >
        <AssetOverview {...assetDetails} />
        {state.view === KEYS.share ? (
          <>
            <Text color={whiteAlpha(0.6)} className='px-0 mt-2'>
              Add people with whom you want to share this asset
            </Text>
            <div>
              <AutoComplete
                isLoading
                multiple
                label='Add by Username or Email'
                id='shareWith'
                open={state.open}
                onClose={() =>
                  setState({
                    ...state,
                    open: false,
                  })
                }
                value={formValues.shareWith.value}
                onChange={getUsersToShareWith}
                options={state.users}
              />

              {state.sharedUsers && state.sharedUsers.shared_count > 0 && (
                <Text
                  color={whiteAlpha(0.8)}
                  style={{ textAlign: 'right' }}
                  className='pt-0'
                >
                  Shared with
                  <TextButton
                    color={whiteAlpha(0.8)}
                    style={{
                      fontSize: '1.4rem',
                      marginLeft: '0.5rem',
                    }}
                    onClick={() =>
                      setState({
                        ...state,
                        view: KEYS.list,
                      })
                    }
                  >
                    {state.sharedUsers.shared_count}{' '}
                    {state.sharedUsers.shared_count === 1 ? 'user' : 'users'}
                  </TextButton>
                </Text>
              )}

              {settings && (
                <>
                  <Text
                    color={whiteAlpha(0.8)}
                    secondary
                    md
                    className='px-0 mt-1'
                  >
                    Share Settings
                  </Text>
                  <div className='mb-1'>
                    {settings.map((item, i) => {
                      const { title, selected } = item;
                      return (
                        <div key={`setting-${i + 1}`}>
                          <TextCheckboxWrapper>
                            <Text className='px-0'>{title}</Text>
                            <Checkbox
                              defaultChecked={selected}
                              onChange={e => handleCheckboxChange(e, item)}
                            />
                          </TextCheckboxWrapper>
                        </div>
                      );
                    })}

                    {formValues?.settings?.value?.passcode_protection && (
                      <>
                        <TextField
                          id='passcode_protection'
                          label='Set the Passcode'
                          value={formValues.passcode_protection.value}
                          onChange={onChange}
                          error={!!formValues.passcode_protection.error}
                          helperText={formValues.passcode_protection.error}
                          InputProps={{
                            endAdornment:
                              formValues.passcode_protection.value &&
                              !formValues?.passcode_protection?.error ? (
                                <InputAdornment
                                  position='end'
                                  className='flex items-center p-0 pr-2'
                                >
                                  {isPasscodeCopied && (
                                    <CopyPasscodeStyles>
                                      Copied
                                    </CopyPasscodeStyles>
                                  )}
                                  <MaterialIconButton
                                    onClick={onPasscodeCopy}
                                    style={{ paddingRight: '0' }}
                                    className='relative'
                                  >
                                    <FileCopyOutlined
                                      style={{ color: whiteAlpha(0.6) }}
                                    />
                                  </MaterialIconButton>
                                </InputAdornment>
                              ) : null,
                          }}
                        />
                        <Text sm className='px-0' color={whiteAlpha(0.6)}>
                          Copy Passcode and share it externally via
                          email/message.
                        </Text>
                      </>
                    )}

                    <div>
                      <Text className='px-0'>Link Expiration</Text>
                      <TextField
                        id='duration'
                        label='Expiration Duration in Hours'
                        value={formValues.duration.value}
                        onChange={onChange}
                        error={!!formValues.duration.error}
                        helperText={formValues.duration.error}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Row style={{ marginTop: '3.6rem' }}>
            {state?.sharedUsers?.users.map(item => {
              const {
                first_name,
                last_name,
                name,
                username,
                user_id,
                user_badge_color,
              } = item;
              return (
                <User key={user_id}>
                  <Detail>
                    <AvatarBadgeParent>
                      <CustomAvatarStyles color={user_badge_color}>
                        {first_name.charAt(0)}
                        {last_name.charAt(0)}
                      </CustomAvatarStyles>
                    </AvatarBadgeParent>
                    <div>
                      <AvatarUsernameStyles>{name}</AvatarUsernameStyles>
                      <AvatarSecondaryTextStyles secondary>
                        {username}
                      </AvatarSecondaryTextStyles>
                    </div>
                  </Detail>
                  {renderDeleteButton(item, 'user_db_id')}
                </User>
              );
            })}

            {state?.sharedUsers?.user_emails.map(item => {
              return (
                <User key={item}>
                  <Detail>
                    <AvatarBadgeParent>
                      <CustomAvatarStyles secondary />
                    </AvatarBadgeParent>
                    <div>
                      <AvatarUsernameStyles>{item}</AvatarUsernameStyles>
                      <AvatarSecondaryTextStyles secondary>
                        {item}
                      </AvatarSecondaryTextStyles>
                    </div>
                  </Detail>
                  {renderDeleteButton(item, 'user_email')}
                </User>
              );
            })}
          </Row>
        )}
      </Body>

      <Footer>
        {state.view === KEYS.list ? (
          <Normal
            color='secondary'
            onClick={() =>
              setState({
                ...state,
                view: KEYS.share,
              })
            }
          >
            Back
          </Normal>
        ) : (
          formValues.shareWith.value.length > 0 && (
            <Normal onClick={onSubmit} isLoading={isSubmitting}>
              Share
            </Normal>
          )
        )}
      </Footer>
    </>
  );
};

ShareAsset.propTypes = {
  closeSidebar: PropTypes.func,
  createUpdateTaskRequest: PropTypes.func,
  getUsersBasedOnFilters: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  shareAsset: PropTypes.func,
  toggleToast: PropTypes.func,
  fetchSettings: PropTypes.func,
  creatorDetails: PropTypes.instanceOf(Object),
};

export default ShareAsset;
