/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../components/Button';
import StyledLinearProgress from './styledLinearProgress';

// constants
import sidebarTypes from '../../components/Sidebar/constants';

// helper functions
import { amountWithCommas } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Text, Flex } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';

const StorageDetails = ({ toggleSidebar, ...rest }) => {
  const { plan_detail, storage_detail } = useSelector((state) => state.subscription);

  const onSubmit = useCallback(() => {
    toggleSidebar({
      type: sidebarTypes.addStorage,
      show: true,
      title: 'Add Storage',
      componentProps: { ...rest },
    });
  }, []);

  return useMemo(() => {
    return (
      <>
        <Body noFooter={plan_detail?.is_free}>
          <Text
            paddingRight="0"
            paddingLeft="0"
            md
          >{`The account storage with the ${plan_detail?.title} plan is ${plan_detail?.storage_size} GB.`}</Text>
          <div className="mt-1 rounded p-1 border border-1 border-white-0-point-3">
            <Flex className="items-baseline justify-between">
              <Text>Available Storage</Text>
              <Text sm className="py-0" color={whiteAlpha(0.6)}>
                Add-on Storage: {storage_detail?.storage_added} GB
              </Text>
            </Flex>
            <div className="my-1">
              <Flex className="justify-between items-center">
                <Text sm className="py-0" color={whiteAlpha(0.8)}>
                  {storage_detail?.used_storage} GB used of {storage_detail?.total_storage} GB
                </Text>
              </Flex>
              <div className="px-1" style={{ paddingTop: '0.5rem' }}>
                <StyledLinearProgress value={(storage_detail?.used_storage / storage_detail?.total_storage) * 100} />
              </div>
            </div>
          </div>
        </Body>
        {!plan_detail?.is_free && (
          <Footer>
            <Normal onClick={onSubmit}>Add Storage</Normal>
          </Footer>
        )}
      </>
    );
  }, [storage_detail, plan_detail]);
};

StorageDetails.propTypes = {
  toggleSidebar: PropTypes.func,
};

export default StorageDetails;
