/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Avatar, IconButton, InputBase } from '@material-ui/core';
import styled from 'styled-components';
import { Send } from '@material-ui/icons';

// Components
import InfiniteScroll from '../../components/infiniteScroll';
import { useForm } from '../../components/FormInputs';
import DateTime from '../../components/dateTime';

// Firebase
import {
  db,
  CHAT_REF,
  CHAT_COUNT_REF,
  CHAT_NOTIFICATION_REF,
} from '../../Firebase';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { whiteAlpha } from '../../styles/utils';
import { Flex, NoDetailsFound, Text } from '../../styles/common';
import { colors, fs, font } from '../../styles/variables';
import apiConfig from '../../../config';

const UserChat = styled.div`
  position: relative;
  background-color: ${whiteAlpha(0.15)};
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 1rem;
  animation-name: slideUp;
  animation-duration: 0.5s;

  .name,
  .message {
    font-size: ${fs.sm};
    color: ${whiteAlpha(0.8)};
  }

  .head {
    display: flex;
    align-items: center;

    .name {
      margin: 0 1rem;
    }

    .time {
      font-size: ${fs.xs};
      color: ${whiteAlpha(0.6)};
    }
  }

  .message {
    margin-top: 1rem;
  }
  @keyframes slideUp {
    from {
      transform: translateY(4rem);
      opacity: 0;
    }
    to {
      transform: translateY(0rem);
      opacity: 1;
    }
  }
`;

const CustomAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    position: relative;
    width: 3.4rem;
    height: 3.4rem;
    font-size: ${fs.xs};
    font-weight: ${font.bold};
    background: #1d4376;
  }
`;

const TextBoxWithSubmit = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  background-color: ${whiteAlpha(0.15)};
`;

const SubmitButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 1rem 1.5rem;
    padding: 0.95rem;
    background-color: ${colors.blue.shadeTwo};

    &:hover {
      background-color: #2c86ff;
      .MuiSvgIcon-root {
        color: ${colors.white};
      }
    }
  }

  .MuiSvgIcon-root {
    width: 1.4rem;
    height: 1.4rem;
    color: ${colors.blue.navy};
  }
`;

const InputStyled = styled(InputBase)`
  &.MuiInputBase-root {
    flex: 1;
    padding: 1rem 0 1rem 1.5rem;
    font-size: ${fs.sm};
    color: ${whiteAlpha(0.8)};
  }
`;

const StyledLabel = styled(Text)`
  text-align: center;
  margin: 1.2rem 0;
`;

const INITIAL_STATE = {
  comment: {
    value: '',
  },
};

const Chat = props => {
  // Props
  const {
    toggleToast,
    addGetTaskMessages,
    loadTaskMessages,
    taskDbId,
    subTitle,
    userDetails,
    goBackFlag,
    match: {
      params: { project_db_id },
    },
  } = props;

  // Refs
  const messagesEndRef = useRef(null);

  // useState
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadPreviousMessages, setloadPreviousMessages] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [scrollingTop, setScrollingTop] = useState(false);

  const BASE_TASK_CHATS_REF = CHAT_REF + `/${project_db_id}_${taskDbId}/`;
  const TASK_CHATS_REF = BASE_TASK_CHATS_REF + '/chats';
  const TOTAL_CHAT_COUNT_REF = CHAT_COUNT_REF + `/${project_db_id}/${taskDbId}`;

  // Selectors
  const { messages, total_entries } = useSelector(
    state => state.storyboard.selectedTask.messages
  );

  const fetchMessages = (request, success, error) => {
    // this functions takes 3 arguments
    // request - to sent in API call
    // success - callback function, execute in then block
    // error - callback function, execute in catch block
    const baseRequest = {
      task_db_id: taskDbId,
      entries_per_page: 10,
    };

    addGetTaskMessages(
      {
        ...baseRequest,
        ...request,
      },
      'get'
    )
      .then(res => {
        const {
          data: { data, response },
        } = res;
        if (response) {
          success(data);
        }
      })
      .catch(err => {
        error(err);
      });
  };

  useEffect(() => {
    const total =
      messages && messages.reduce((acc, curr) => acc + curr.messages.length, 0);
    if (total < total_entries && scrollingTop) {
      setloadPreviousMessages(true);
      fetchMessages(
        { page_no: pageNo + 1 },
        data => {
          setPageNo(prev => prev + 1);
          setloadPreviousMessages(false);
          loadTaskMessages({ data, type: 'scroll_load' });
          setScrollingTop(false);
        },
        () => {
          setloadPreviousMessages(false);
          setScrollingTop(false);
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: 'Something went wrong. Try reloading the chat again',
              variant: 'error',
            },
          });
        }
      );
    }
  }, [scrollingTop]);

  useEffect(() => {
    setLoading(true);
    let messagesFromFirebase = [];

    db.ref(TASK_CHATS_REF)
      .once('value', snapshot => {
        const values = snapshot.val();
        messagesFromFirebase = values ? Object.values(values) : [];
      })
      .then(() => {
        fetchMessages(
          { page_no: pageNo },
          data => {
            const updated = [...data.messages];
            // eslint-disable-next-line no-shadow
            const { total_entries, ...otherResponse } = data;
            setLoading(false);
            if (messagesFromFirebase.length > 0) {
              updated.push({
                label: 'Today',
                messages: messagesFromFirebase,
              });
            }
            loadTaskMessages({
              data: {
                ...otherResponse,
                total_entries: total_entries + messagesFromFirebase.length,
                messages: updated,
              },
            });
          },
          err => {
            setLoading(false);
            toggleToast({
              message: {
                messageHead: 'Error',
                messageBody: err.data.errormessage,
                variant: 'error',
              },
            });
          }
        );
      });

    db.ref(TASK_CHATS_REF).on('child_added', snapshot => {
      const values = snapshot.val();
      loadTaskMessages({ data: values, type: 'add' });
    });

    return () => {
      // to update last_seen_count of user
      db.ref(TOTAL_CHAT_COUNT_REF).transaction(snapshot => {
        if (snapshot) {
          snapshot[userDetails.user_id] = {
            last_seen_count: snapshot.chats_count,
          };
        }
        return snapshot;
      });
      db.ref(TASK_CHATS_REF).off();
    };
  }, []);

  // to scroll bottom
  useEffect(() => {
    if (messages && scrollToBottom) {
      // eslint-disable-next-line no-unused-expressions
      messagesEndRef?.current?.scrollIntoView();
      setScrollingTop(false);
    }
  }, [messages, scrollToBottom, loading]);

  // form hook
  const { onChange, onSubmit, formValues, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      if (formValues.comment.value) {
        const common = {
          user_id: userDetails?.user_id,
          user_name: userDetails?.name,
          message: formValues?.comment.value,
          timestamp: new Date().getTime(),
          image_url: `${apiConfig?.assets}/chat-icon.svg`,
        };

        const updates = {};
        // eslint-disable-next-line prefer-destructuring
        const key = db.ref().child(TASK_CHATS_REF).push().key;
        common.id = key;
        updates[TASK_CHATS_REF + '/' + key] = common;
        updates[CHAT_NOTIFICATION_REF + '/' + key] = {
          ...common,
          id: key,
          project_id: project_db_id,
          task_id: taskDbId,
        };

        db.ref()
          .update(updates)
          .then(() => {
            // transaction for concurrent updates
            db.ref(TOTAL_CHAT_COUNT_REF).transaction(snapshot => {
              if (snapshot) {
                snapshot.chats_count += 1;
              } else {
                snapshot = { chats_count: 1 };
              }
              return snapshot;
            });
          })
          .catch(() => {
            toggleToast({
              message: {
                messageHead: 'Error',
                messageBody: 'Something went wrong',
                variant: 'error',
              },
            });
          });

        // reset text field
        updateValues({
          ...formValues,
          comment: {
            value: '',
          },
        });
      }
      setScrollToBottom(true);
    }
  );

  return (
    <form onSubmit={onSubmit} style={{ height: '98%' }}>
      <Body style={{ marginTop: '0' }}>
        <Text padding='0' margin={`0.5rem 0 0 ${goBackFlag ? '3rem' : '0'}`}>
          {subTitle}
        </Text>
        {loading ? (
          <StyledLabel>Fetching converstions...</StyledLabel>
        ) : (
          <>
            {messages && messages.length ? (
              <InfiniteScroll
                scrollDirection='reverse'
                isLoading={loadPreviousMessages}
                loadAssets={() => {
                  setScrollingTop(true);
                  setScrollToBottom(false);
                }}
              >
                {loadPreviousMessages && (
                  <StyledLabel>Fetching previous converstions</StyledLabel>
                )}
                {messages.map(item => {
                  return (
                    <>
                      <StyledLabel>{item.label}</StyledLabel>
                      {item.messages.map(el => {
                        const { user_name, message, id, timestamp } = el;
                        const initials = user_name.split(' '); // get initials
                        const date = new Date(timestamp);
                        return (
                          <UserChat key={id || el.message_db_id}>
                            <div className='head'>
                              <CustomAvatar secondary>
                                {initials[0].charAt(0) + initials[1].charAt(0)}
                              </CustomAvatar>
                              <div className='name'>{user_name}</div>
                              <div className='time'>
                                {DateTime.toTimeString(date, 'HH:MM AP')}
                              </div>
                            </div>
                            <div className='message'>{message}</div>
                          </UserChat>
                        );
                      })}
                    </>
                  );
                })}
                <div ref={messagesEndRef} />
              </InfiniteScroll>
            ) : (
              <NoDetailsFound>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='60'
                  height='56'
                  viewBox='0 0 60 56'
                >
                  <g fill='#FFF' fillOpacity='.4'>
                    <path d='M10 23.465h13c.553 0 1-.448 1-1s-.447-1-1-1H10c-.553 0-1 .448-1 1s.447 1 1 1zM36 27.465H10c-.553 0-1 .448-1 1s.447 1 1 1h26c.553 0 1-.448 1-1s-.447-1-1-1zM36 33.465H10c-.553 0-1 .448-1 1s.447 1 1 1h26c.553 0 1-.448 1-1s-.447-1-1-1z' />

                    <path d='M54.072.535L19.93.465c-3.27 0-5.93 2.66-5.93 5.93v5.124l-8.07.017c-3.27 0-5.93 2.66-5.93 5.93v21.141c0 3.27 2.66 5.929 5.93 5.929H12v10c0 .413.254.784.64.933.117.045.239.067.36.067.276 0 .547-.115.74-.327l9.704-10.675 16.626-.068c3.27 0 5.93-2.66 5.93-5.929v-.113l5.26 5.786c.193.212.464.327.74.327.121 0 .243-.022.36-.067.386-.149.64-.52.64-.933v-10h1.07c3.27 0 5.93-2.66 5.93-5.929V6.465c0-3.269-2.659-5.929-5.928-5.93zM44 38.536c0 2.167-1.763 3.929-3.934 3.929l-17.07.07c-.28.001-.548.12-.736.327L14 51.949v-8.414c0-.552-.447-1-1-1H5.93c-2.167 0-3.93-1.763-3.93-3.929V17.465c0-2.167 1.763-3.93 3.932-3.93L15 13.516h.002l25.068-.052c2.167 0 3.93 1.763 3.93 3.93v21.142zm14-10.93c0 2.167-1.763 3.929-3.93 3.929H52c-.553 0-1 .448-1 1v8.414l-5-5.5V17.395c0-3.27-2.66-5.93-5.932-5.93L16 11.514v-5.12c0-2.167 1.763-3.93 3.928-3.93l34.141.07h.002c2.167 0 3.93 1.763 3.93 3.93v21.142H58z' />
                  </g>
                </svg>

                <div className='content'>
                  <div className='head'>It &apos; quiet in here...</div>
                  <div className='sub-head'>
                    Some feedback and get the ball rolling!
                  </div>
                </div>
              </NoDetailsFound>
            )}
          </>
        )}
      </Body>

      <Footer>
        <TextBoxWithSubmit>
          <InputStyled
            autoComplete='off'
            id='comment'
            placeholder='Write a comment...'
            onChange={onChange}
            value={formValues.comment.value}
          />
          <SubmitButton type='submit'>
            <Send />
          </SubmitButton>
        </TextBoxWithSubmit>
      </Footer>
    </form>
  );
};

Chat.propTypes = {
  match: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object),
  loadTaskMessages: PropTypes.func,
  addGetTaskMessages: PropTypes.func,
  toggleToast: PropTypes.func,
  taskDbId: PropTypes.string,
  subTitle: PropTypes.string,
  goBackFlag: PropTypes.bool,
};

export default Chat;
