import React from 'react';
import styled from 'styled-components';

// Components
import Card from './card';
import Title from './Title';
import Loader from '../../portal/loader';

import { devices } from '../../styles/variables';

// Styles
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.5rem;

  @media screen and (min-width: ${devices.md}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: ${devices.lg}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Storyboard = () => {
  return (
    <div className='container-fluid' style={{ padding: '0 2rem' }}>
      <div style={{ marginBottom: '2rem' }}>
        <Title width='12.8rem' />
      </div>
      <Row>
        <Card />
        <Card />
        <Card />
      </Row>
      <Loader storyboardLoader />
    </div>
  );
};

export default Storyboard;
