/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Normal } from '../../components/Button';
import { SecondaryModalBodyStyles } from '../../components/modal/secondary';

// Styles
import { Text } from '../../styles/common';
import { fs } from '../../styles/variables';

const UpgradePlanModal = props => {
  const {
    upgradePlan: { data },
    onClose,
    history,
  } = props;

  const onCta = () => {
    if (data?.website_link) {
      history.push(data?.website_link);
      return;
    }

    onClose();
  };

  return (
    <SecondaryModalBodyStyles>
      <div>
        <img src={data.image_url} alt={data?.title} />
        <h2 className='head'>{data?.title}</h2>
        <Text className='text'>{data?.message || data?.sub_title}</Text>
      </div>
      {data?.button_text && (
        <div className='cta-section'>
          <Normal fontSize={fs.sm} onClick={onCta}>
            {data?.button_text}
          </Normal>
        </div>
      )}
    </SecondaryModalBodyStyles>
  );
};

UpgradePlanModal.propTypes = {
  upgradePlan: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  history: PropTypes.instanceOf(Object),
};

export default UpgradePlanModal;
