/* eslint-disable camelcase */
/* eslint-disable indent */
import * as types from './types';

const INITIAL_STATE = {
  teams: null,
  users: null,
  organisation: null,
  billing: null,
  teamDetails: null,
  permissions: null,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOAD_TABS: {
      const page_no = 1;
      let data = null;

      if (action?.payload?.type === 'team_delete') {
        const updated = state?.teams?.data.filter(
          item => item.team_db_id !== action?.payload?.data?.team_db_id
        );
        data = updated;
      } else if (action?.payload?.type === 'team_add') {
        const updated = [...state?.teams?.data, action?.payload?.data];
        data = updated;
      } else if (action?.payload?.type === 'team_update') {
        const updated = [...state?.teams?.data];
        const index = updated.findIndex(
          item => item.team_db_id === action?.payload?.data?.team_db_id
        );
        updated[index < -1 ? 0 : index] = { ...action?.payload?.data };
        data = updated;
      } else {
        data = action?.payload?.data[action.payload.key];
      }
      return {
        ...state,
        [action.payload.key]: {
          page_no,
          data,
        },
      };
    }

    case types.LOAD_BILLING_DETAILS: {
      return {
        ...state,
        billing: action?.payload,
      };
    }

    case types.LOAD_TEAM_DETAILS: {
      return {
        ...state,
        teamDetails: { ...action?.payload },
      };
    }

    case types.LOAD_TEAM_PERMISSIONS: {
      return {
        ...state,
        permissions: { ...action?.payload },
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
