import styled from 'styled-components';

// Styles
import { padding, colors } from '../../styles/variables';
import { Flex } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';

const Row = styled.div`
  position: relative;
  margin-top: 1.8rem;
`;

const Detail = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled.div`
  font-size: 1.4rem;
  color: ${colors.white};
  text-align: left;
  margin-bottom: 0.3rem;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  display: none;

  svg {
    cursor: pointer;
    font-size: 2rem;
    fill: ${whiteAlpha(0.4)};

    &:hover {
      fill: ${colors.white};
    }
  }

  &:focus {
    outline: none;
  }
`;

const User = styled(Flex)`
  padding: ${padding.v} 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  &:hover ${IconButton} {
    display: block;
  }
`;

const AvatarBadgeParent = styled.div`
  position: relative;
  margin-right: 1rem;
`;

const ActionButtonStyles = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 0.5rem;
  background-color: transparent;
  cursor: pointer;

  > p {
    padding: 0.8rem;
    color: ${colors.blue.shadeTwo};
  }

  .MuiSvgIcon-root {
    fill: ${colors.blue.shadeTwo};
    width: 1.4em;
    height: 1.4em;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    p {
      color: ${colors.blue.primary};
    }
    .MuiSvgIcon-root {
      fill: ${colors.blue.primary};
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

export {
  Row,
  Detail,
  Name,
  IconButton,
  AvatarBadgeParent,
  User,
  ActionButtonStyles,
};
