/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';
import Card from './Card';
import { getTotalPluginsPrice } from '../../helperFunctions';
import { CLOUD_SCREENS } from './constants';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { Text } from '../../../styles/common';
import { GridStyles, CloudScreenHead } from './styles';

const DURATION_OPTIONS = [
  {
    label: '2 hours',
    value: 120,
  },
  {
    label: '3 hours',
    value: 180,
  },
  {
    label: '4 hours',
    value: 240,
  },
];

const EXTEND_DURATION_OPTIONS = [
  {
    label: '1 hour',
    value: 60,
  },
];

const Duration = props => {
  const [isDisabled, toggleDisable] = useState(false);
  const {
    type,
    config,
    isResourceAvailable,
    extendSession,
    task: { task_db_id, task_type },
    match: {
      params: { project_db_id },
    },
    cloudAccessDetails,
    goTo,
    closeSidebar,
    setConfig,
    toggleUpgradeModal,
    toggleToast,
    selectedSoftware,
    selectedPlugin,
  } = props;

  const isCloudAccessGranted =
    cloudAccessDetails?.machine_status === 'machine_started'; // to check if user is allocated system
  const configDetails = isCloudAccessGranted
    ? { ...cloudAccessDetails }
    : { ...config };

  const handleNext = async el => {
    const { cloud_configuration_db_id, cloud_access_db_id } = configDetails;

    const request = {
      project_db_id,
      duration: el?.value,
    };

    toggleDisable(true);

    if (isCloudAccessGranted) {
      // while extending the session
      const extendResponse = await extendSession({
        ...request,
        cloud_id: cloud_access_db_id,
      });

      const {
        data: { response, data },
      } = extendResponse;

      if (response) {
        closeSidebar();
      } else if (data && data.upgrade_required) {
        toggleDisable(false);
        toggleUpgradeModal({ show: true, data });
      }
    } else {
      // creating request for the first time, to check if resource is avaliable
      const configsResponse = await isResourceAvailable(
        {
          ...request,
          task_type: task_type || type?.value, // collaborator or editor role
          cloud_configuration_db_id,
          task_db_id,
        },
        'post'
      );

      const {
        data: { response, data, errormessage },
      } = configsResponse;

      // extracting the details based on data.
      const resourceDetails = data || {};
      const {
        session_end_datetime,
        current_time,
        resource_available,
        upgrade_required,
      } = resourceDetails;

      if (response) {
        toggleDisable(false);
        setConfig({
          ...configDetails,
          duration: request.duration,
          current_time: current_time || 0,
          session_end_datetime: session_end_datetime || 0,
        });

        if (resource_available) {
          goTo(CLOUD_SCREENS.available);
        } else {
          goTo(CLOUD_SCREENS.type);
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: 'Resource is not available',
              variant: 'error',
            },
          });
          // goTo(CLOUD_SCREENS.notAvailable);
        }
      } else if (upgrade_required) {
        toggleDisable(false);
        toggleUpgradeModal({ show: true, data });
      } else {
        toggleDisable(false);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  };

  return (
    <>
      <Body>
        <GradientCard
          {...(Object.keys(type).length > 0
            ? type
            : configDetails?.cloud_system)}
          details={[
            selectedSoftware?.title || configDetails?.configurations?.software,
            `${configDetails?.configurations?.title}`,
            `₹${Number(
              selectedPlugin?.length > 0
                ? getTotalPluginsPrice(selectedPlugin) +
                    configDetails?.configurations?.pricing
                : configDetails?.configurations?.pricing
            )} / hr`,
          ]}
        />

        {(selectedPlugin || configDetails?.plugins)?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm
          >
            Add-on Plug-ins:{' '}
            {(selectedPlugin || configDetails?.plugins)
              .map(el => el?.title || el)
              .join(', ')}
          </Text>
        )}

        <CloudScreenHead
          bg
          style={{
            marginTop:
              (selectedPlugin || configDetails?.plugins)?.length > 0
                ? '0rem'
                : '2rem',
          }}
        >
          For how long?
        </CloudScreenHead>

        <GridStyles>
          {(isCloudAccessGranted
            ? [...EXTEND_DURATION_OPTIONS]
            : [...EXTEND_DURATION_OPTIONS, ...DURATION_OPTIONS]
          ).map(el => (
            <Card
              disabled={isDisabled}
              label={el?.label}
              key={el?.value}
              onClick={() => {
                handleNext(el);
              }}
            />
          ))}
        </GridStyles>
      </Body>

      <Footer>
        {!isCloudAccessGranted && (
          <Normal
            color='secondary'
            style={{ marginRight: '2rem' }}
            onClick={() => {
              goTo(CLOUD_SCREENS.hardware);
            }}
          >
            Back
          </Normal>
        )}
      </Footer>
    </>
  );
};

Duration.propTypes = {
  isResourceAvailable: PropTypes.func,
  goTo: PropTypes.func,
  type: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object),
  cloudAccessDetails: PropTypes.instanceOf(Object),
  extendSession: PropTypes.func,
  closeSidebar: PropTypes.func,
  setConfig: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  toggleToast: PropTypes.func,
  selectedSoftware: PropTypes.instanceOf(Object),
  selectedPlugin: PropTypes.instanceOf(Object),
};

export default Duration;
