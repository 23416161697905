/* eslint-disable no-console */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { RtcTokenBuilder, RtcRole } from 'agora-token';
import { useSelector } from 'react-redux';

import InvitedList from './InvitedList';
import NewInvite from './NewInvite';
import { startAudioCallService } from '../../duck/operations';
import { LIVE_COLLABORATION_STEPS } from '../../constants';

const client = AgoraRTC.createClient({
  mode: 'rtc',
  codec: 'vp8',
});

const localTracks = {
  audioTrack: null,
};

const remoteUsers = {};

const options = {
  appId: process.env.AGORA_APP_ID,
  appCertificate: process.env.AGORA_APP_CERTIFICATE,
  uid: 0,
  token: null,
};

const LiveCollaboration = ({
  toggleSidebar,
  closeSidebar,
  toggleToast,
  match,
  pusher,
}) => {
  const [isAudioMuted, setAudioMuted] = useState(false);
  const [screen, setScreen] = useState(LIVE_COLLABORATION_STEPS.invite);
  const [otherDetails, setOtherDetails] = useState(null);
  const cloudDetails = useSelector(
    store => store?.storyboard?.cloudAccessDetails
  );

  const channelName = `ps-${cloudDetails?.cloud_access_db_id}`;
  const role = RtcRole.PUBLISHER;
  const expirationTimeInSeconds = 3600;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds;

  const createToken = () => {
    const token = RtcTokenBuilder.buildTokenWithUid(
      options.appId,
      options.appCertificate,
      channelName,
      options.uid,
      role,
      privilegeExpiredTs
    );

    if (token) {
      options.token = token;
    }
  };

  AgoraRTC.onMicrophoneChanged = async changedDevice => {
    // When plugging in a device, switch to a device that is newly plugged in.
    if (changedDevice.state === 'ACTIVE') {
      localTracks.audioTrack.setDevice(changedDevice.device.deviceId);
      // Switch to an existing device when the current device is unplugged.
    } else if (
      changedDevice.device.label === localTracks.audioTrack.getTrackLabel()
    ) {
      const oldMicrophones = await AgoraRTC.getMicrophones();
      if (oldMicrophones?.length) {
        localTracks.audioTrack.setDevice(oldMicrophones[0]?.deviceId);
      }
    }
  };

  const subscribe = async (user, mediaType) => {
    // subscribe to a remote user
    await client.subscribe(user, 'audio');
    console.log('subscribe success');
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };

  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === 'audio') {
      const id = user.uid;
      delete remoteUsers[id];
    }
  };

  const toggleMute = async (value = 'mute') => {
    if (!localTracks.audioTrack) return;

    if (value === 'unmute') {
      await localTracks.audioTrack.setEnabled(true);
      setAudioMuted(false);
      return;
    }

    await localTracks.audioTrack.setEnabled(false);
    setAudioMuted(true);
  };

  const startJoinAudioCall = async (data, type = 'editor') => {
    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);

    if (type === 'editor') {
      createToken();
    }

    const updatedChannelName =
      type === 'collaborator'
        ? data?.audio_call_detail?.channel_name
        : channelName;

    try {
      // Join a channel and create local tracks. Best practice is to use Promise.all and run them concurrently.
      [options.uid, localTracks.audioTrack] = await Promise.all([
        // Join the channel.
        client.join(
          options.appId,
          updatedChannelName,
          data?.audio_call_detail?.token || options.token,
          type === 'editor' ? options.uid : 1
        ),

        // Create tracks to the local microphone and camera.
        AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: 'music_standard',
        }),
      ]);

      // Publish the local audio tracks to the channel.
      await client.publish(Object.values(localTracks));
      console.log('Joined & Publish Call Success!');
      const response = await startAudioCallService({
        live_collaboration_db_id: data?.live_collaboration_db_id,
        uid: type === 'editor' ? options.uid : 1,
        token: data?.audio_call_detail?.token || options.token,
        channel_name: updatedChannelName,
      });
      if (response?.data?.response) {
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: response?.data?.data?.message,
            variant: 'success',
          },
        });
      }
    } catch (err) {
      console.log({ err }, 'while joining audio call');
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: 'Unable to join the audio call.',
          variant: 'error',
        },
      });
    }
  };

  const leaveAudioCall = async (data, type = 'editor') => {
    setAudioMuted(false);

    try {
      Object.keys(localTracks).forEach(name => {
        const track = localTracks[name];
        if (track) {
          track.stop();
          track.close();
          localTracks[name] = undefined;
        }
      });

      await client.leave();
      console.log('You left the channel');
      const response = await startAudioCallService(
        {
          live_collaboration_db_id:
            type === 'collaborator'
              ? data?.live_collaboration_db_id
              : otherDetails?.live_collaboration_db_id,
          token:
            type === 'collaborator'
              ? data?.audio_call_detail?.token
              : otherDetails?.audio_call_detail?.token,
        },
        'put'
      );

      if (response?.data?.response) {
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: response?.data?.data?.message,
            variant: 'success',
          },
        });
      }
    } catch (err) {
      console.log({ err }, 'while leaving agora call');
    }

    if (type === 'editor') {
      window.location.reload();
    }
  };

  const COMMON_PROPS = {
    goTo: step => setScreen(step),
    toggleSidebar,
    closeSidebar,
    toggleToast,
    match,
    leaveAudioCall,
    startJoinAudioCall,
    channelName,
    pusher,
    otherDetails,
    setOtherDetails,
    localTracks,
    toggleMute,
    isAudioMuted,
  };

  const renderScreen = index => {
    let component = <InvitedList {...COMMON_PROPS} />;

    switch (index) {
      case LIVE_COLLABORATION_STEPS.new: {
        component = <NewInvite {...COMMON_PROPS} />;
        break;
      }

      default:
        break;
    }

    return component;
  };

  return <>{renderScreen(screen)}</>;
};

LiveCollaboration.propTypes = {
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
};

export default LiveCollaboration;
