import styled from 'styled-components';

// Styles
import { colors, fs } from './variables';

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiSvgIcon-root {
    opacity: 0.8;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }
`;

const Title = styled.h3`
  color: ${colors.white};
  font-size: ${fs.lg};
  font-weight: 300;
  padding: 0;
`;

const Body = styled.div`
  padding: 4rem 0;
  position: relative;
  color: ${colors.white};
  line-height: 3rem;
`;

const Footer = styled.div`
  padding: 0;
  justify-content: center;
  position: relative;
  display: flex;

  > button {
    width: 100%;
  }
`;

export { Header, Title, Body, Footer };
