/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Modal from './index';
import VerificationCodeGrid from '../VerificationCodeGrid';

import { setUserDetails } from '../../main-wrapper/duck/actions';

// Styles
import { Body } from '../../styles/modal';
import { Text } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { Message } from '../../portal/styles';
import { colors } from '../../styles/variables';

const INITIAL_MESSAGE_ERROR_STATE = {
  show: false,
  message: null,
};

const VerificationCodeModal = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(INITIAL_MESSAGE_ERROR_STATE);
  const dispatch = useDispatch();
  const common = useSelector(store => store?.common);

  const {
    onChangeCode,
    code,
    show,
    onModalClose,
    onUpdateCode,
    onSendResendCode,
    toggleToast,
    closeSidebar,
  } = props;

  const onModalCancel = () => {
    closeSidebar();
    onModalClose(false);
  };

  const onSubmit = () => {
    setSubmitting(true);
    onUpdateCode()
      .then(
        ({
          res: {
            data: { data, response, errormessage },
          },
          two_factor_authentication_enabled,
          authenticationFor,
        }) => {
          setSubmitting(false);
          if (response) {
            setError(INITIAL_MESSAGE_ERROR_STATE);

            if (authenticationFor === 'account') {
              dispatch(
                setUserDetails({
                  user_details: {
                    ...common?.userDetails,
                    two_factor_authentication_enabled,
                  },
                  teams: common?.teams,
                  permissions: common?.permissions,
                })
              );
            }

            closeSidebar();
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody: data?.message,
                variant: 'success',
              },
            });
          } else throw new Error(errormessage);
        }
      )
      .catch(err => {
        setSubmitting(false);
        setError({ show: true, message: err?.message });
      });
  };

  useEffect(() => {
    if (error?.show) {
      setTimeout(() => setError(INITIAL_MESSAGE_ERROR_STATE), 5000);
    }
  }, [error]);

  return (
    <Modal
      title='Two-Factor Authentication'
      onClose={onModalCancel}
      open={show}
    >
      <Body style={{ paddingBottom: 0 }}>
        <Text padding='0' marginBottom='1.5rem' color={whiteAlpha(0.6)}>
          Enter the two factor authentication code sent to your email
        </Text>
        <VerificationCodeGrid
          setValues={onChangeCode}
          values={code}
          onResend={onSendResendCode}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />

        {error.show && (
          <Message center style={{ color: colors.error }}>
            {error.message}
          </Message>
        )}
      </Body>
    </Modal>
  );
};

VerificationCodeModal.propTypes = {
  toggleToast: PropTypes.func,
  closeSidebar: PropTypes.func,
  onUpdateCode: PropTypes.func,
  onSendResendCode: PropTypes.func,
  onModalClose: PropTypes.func,
  onChangeCode: PropTypes.func,
  code: PropTypes.instanceOf(Object),
  show: PropTypes.bool,
};

export default VerificationCodeModal;
