/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

// Utils
import Mixpanel from '../../../utils/mixpanel';

// Components
import { TextField, useForm } from '../../components/FormInputs';
import LabelValue from './LabelValue';
import Pill from '../../components/pill';
import { Normal } from '../../components/Button';

// Helper Functions
import { required } from '../../components/FormInputs/helperFunctions';
import { formatDateTime } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { Flex, FlexWrap } from '../../styles/common';
import { colors } from '../../styles/variables';

const Head = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 1.8rem;
  color: ${colors.white};
  flex: 1;
  margin-right: 3rem;
`;

const INITIAL_STATE = {
  reason: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },
};

const TransferTask = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    loadSelectedTaskDetails,
    closeSidebar,
    toggleToast,
    createTransferRequest,
    toggleUpgradeModal,
    match: { params },
  } = props;

  // Selectors
  const selected = useSelector(state => state.storyboard.selectedTask.details);

  const {
    task_type_label,
    is_transfer_request,
    task_status,
    task_db_id,
    assigned_by,
    expire_at,
    transfer_message,
  } = selected;

  const { formValues, onSubmit, onChange } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      Mixpanel.track('Task Transfer');
      const requestResponse = await createTransferRequest({
        project_db_id: params.project_db_id,
        task_db_id,
        msg: formValues.reason.value,
      });

      const {
        data: { response, errormessage, data },
      } = requestResponse;
      setSubmitting(false);
      if (response) {
        loadSelectedTaskDetails({ ...selected, is_transfer_request: true });
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: 'Transfer request created',
            variant: 'success',
          },
        });
        closeSidebar();
      } else if (data && data.upgrade_required) {
        toggleUpgradeModal({ show: true, data });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  return (
    <>
      <Body>
        <Head>
          <Title>{task_type_label}</Title>
          <Pill
            status={is_transfer_request ? 'Transfer Request' : task_status}
          />
        </Head>

        <FlexWrap style={{ marginTop: '1rem' }}>
          <LabelValue
            label='Assigned By'
            value={assigned_by && assigned_by.name}
          />
          <LabelValue
            label='Due Date'
            value={formatDateTime(expire_at)?.date}
          />
          {is_transfer_request && (
            <LabelValue label='Reason' value={transfer_message} />
          )}
        </FlexWrap>

        {!is_transfer_request && (
          <TextField
            multiline
            rows='4'
            id='reason'
            label='Reason'
            value={formValues.reason.value}
            onChange={onChange}
            error={!!formValues.reason.error}
            helperText={formValues.reason.error}
            autoFocus
          />
        )}
      </Body>

      {!is_transfer_request && (
        <Footer>
          <Normal onClick={onSubmit} isLoading={isSubmitting}>
            Submit
          </Normal>
        </Footer>
      )}
    </>
  );
};

TransferTask.propTypes = {
  loadSelectedTaskDetails: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  createTransferRequest: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  match: PropTypes.instanceOf(Object),
};

export default TransferTask;
