/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { Normal } from '../../components/Button';
import {
  TextField,
  useForm,
  AutoComplete,
  Select,
} from '../../components/FormInputs';
import EnableTwoFactor from '../../components/homeSidebars/enableTwoFactor';

// Helper Functions
import {
  fullName,
  required,
  noBlankSpaces,
} from '../../components/FormInputs/helperFunctions';
import { formatLabelValue } from '../../helperFunctions';
import { requestForIdsEmails } from '../../storyboard/helperFunctions';
import {
  fetchAwsRegions,
  getTeamMembersServiceCall,
  manageTeamsSerivceCall,
} from '../duck/operations';
import { loadTeamDetails } from '../duck/actions';
import { toggleTeamMembersSidebar } from '../SettingsComponent';
import { VALIDATION_MESSAGES } from '../../constants';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { AppLinkButton } from '../../storyboard/components/CloudAccess/styles';

const INITIAL_STATE = {
  name: {
    value: '',
    error: undefined,
    required: true,
    validation: value => {
      if (!value) return required(value);
      if (!fullName(value)) return VALIDATION_MESSAGES.noSpecialCharacters;
      if (value?.length > 30) return 'Name should be less than 30 characters';
      if (value) return noBlankSpaces(value);
      return undefined;
    },
  },

  region: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  members: {
    value: [],
    error: undefined,
    required: true,
  },
};

const CreateTeam = ({
  closeSidebar,
  toggleToast,
  toggleUpgradeModal,
  toggleSidebar,
  team_id,
  is_two_factor_authentication_enabled_by_team,
}) => {
  const dispatch = useDispatch();
  const teamDetails = useSelector(store => store?.settings?.teamDetails);
  const [isSubmitting, setSubmitting] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      const baseRequest = {
        name: formValues.name.value?.trim(),
        aws_region: formValues?.region?.value,
        is_active: true,
        user_emails: [],
        user_db_ids: [],
        permissions: [],
        ...(team_id && { team_id }),
      };

      const request = requestForIdsEmails(
        formValues?.members?.value,
        baseRequest,
        'user_emails',
        'user_db_ids'
      );

      const collaboratorsResponse = await manageTeamsSerivceCall(
        request,
        team_id ? 'put' : 'post'
      );

      const {
        data: { response, errormessage, data },
      } = collaboratorsResponse;
      setSubmitting(false);
      if (response) {
        closeSidebar();
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: data?.message,
            variant: 'success',
          },
        });
      } else if (data && data.upgrade_required) {
        toggleUpgradeModal({ show: true, data });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  const searchEligibleUser = async (e, value, reason) => {
    // onChange
    if (reason === 'select-option' || reason === 'remove-option') {
      const updatedUsers = value.map(item => ({
        label: item.label,
        value: item.value,
      }));
      updateValues({
        ...formValues,
        members: {
          ...formValues.members,
          error:
            !updatedUsers.length && !team_id
              ? 'This field is required'
              : undefined,
          required: !team_id,
          value: updatedUsers,
        },
      });
    } else if (e.target.value && e.target.value.length > 2) {
      setOpen(true);
      const usersResponse = await getTeamMembersServiceCall({
        search_text: e.target.value?.trim(),
      });
      const {
        data: {
          data: { users },
        },
      } = usersResponse;
      const formated = formatLabelValue(users, 'name', 'user_id', 'user_email');
      setOptions(formated || []);
    } else {
      setOptions([]);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (team_id) {
      manageTeamsSerivceCall({ team_id })
        .then(res => {
          const {
            data: { detail },
            response,
            errormessage,
          } = res?.data;
          if (response) {
            const count = [...detail?.owners, ...(detail?.members || [])]
              ?.length;
            setTotalUsersCount(count);
            dispatch(loadTeamDetails(detail));
            updateValues({
              ...formValues,
              name: {
                ...formValues?.name,
                value: detail?.name,
              },
              region: {
                ...formValues?.region,
                value: detail?.aws_region?.region_name || '',
              },
              members: {
                ...formValues.members,
                required: false, // no required while edit
              },
            });
          } else {
            throw new Error(errormessage);
          }
        })
        .catch(err => {
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          });
        });
    }
    return () => {
      dispatch(loadTeamDetails(null));
    };
  }, [team_id]);

  const getRegions = async () => {
    const res = await fetchAwsRegions();
    if (res?.data?.response) {
      setRegionOptions(res?.data?.data?.aws_configs);
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  return (
    <>
      <Body>
        <Select
          id='region'
          label='Region'
          value={formValues.region.value}
          onChange={onChange}
          error={!!formValues.region.error}
          helperText={formValues.region.error}
          options={regionOptions}
          disabled={!!team_id}
        />
        <TextField
          id='name'
          label='Team Name'
          value={formValues.name.value}
          onChange={onChange}
          error={!!formValues.name.error}
          helperText={formValues.name.error}
        />
        <AutoComplete
          id='members'
          label='Add Members'
          multiple
          open={open}
          onClose={() => setOpen(false)}
          value={formValues.members.value}
          onChange={searchEligibleUser}
          options={options}
          error={!!formValues.members.error}
          helperText={formValues.members.error}
          getOptionDisabled={option => {
            if (team_id) {
              return [
                ...teamDetails?.owners,
                ...teamDetails?.members,
                ...teamDetails?.invited_users,
              ].some(el => {
                if (el.user_db_id) {
                  return el.user_db_id === option.value;
                }
                return el === option.value;
              });
            }
            return option?.value;
          }}
        />

        {totalUsersCount && (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <AppLinkButton
              onClick={() => {
                toggleTeamMembersSidebar({
                  toggleSidebar,
                  componentProps: {
                    isBack: true,
                    team_id,
                  },
                });
              }}>
              {totalUsersCount} Member{totalUsersCount > 1 && 's'}
            </AppLinkButton>
          </div>
        )}

        <EnableTwoFactor
          authenticationFor='team'
          closeSidebar={closeSidebar}
          toggleToast={toggleToast}
          isTwoFactorEnabledByTeam={
            is_two_factor_authentication_enabled_by_team
          }
        />
      </Body>

      <Footer>
        <Normal type='submit' onClick={onSubmit} isLoading={isSubmitting}>
          {team_id ? 'Update' : 'Create'}
        </Normal>
      </Footer>
    </>
  );
};

CreateTeam.propTypes = {
  toggleToast: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleUpgradeModal: PropTypes.func,
  team_id: PropTypes.string,
  toggleSidebar: PropTypes.func,
  is_two_factor_authentication_enabled_by_team: PropTypes.bool,
};

export default CreateTeam;
