/* eslint-disable camelcase */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Pusher from 'pusher-js';

// Components
import { UpgradePlanModal } from './components';
import UpgradeTeamModal from '../settings/components/upgradeTeamModal';
import Header from '../components/header';
import SecondaryModal from '../components/modal/secondary';
import Sidebar from '../components/Sidebar';

class MainWrapperComponent extends Component {
  inactivity = 0;

  constructor(props) {
    super(props);

    this.state = {
      pusher: null,
    };

    this.checkSessionExpiry();
  }

  // getProductTourDetails = async () => {
  //   const { productTourService, setProductTourSkipDone } = this.props;
  //   const res = await productTourService();
  //   const {
  //     data: { response, data },
  //   } = res;
  //   if (response) {
  //     setProductTourSkipDone(data?.skipped_or_done_tour_pages);
  //   }
  // };

  componentDidMount = () => {
    const { userDetails, isUserLoggedIn, setUserDetails, location } =
      this.props;

    this.getRequestedSystem();

    const searchParams = new URLSearchParams(location?.search);
    const inviteCode = searchParams.get('inviteCode') ?? '';

    const pusherObj = new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER,
      authEndpoint: process.env.PUSHER_ENDPOINT,
      auth: {
        params: {
          ...(inviteCode && {
            invite_code: inviteCode,
          }),
          Authorization: window.localStorage.Authorization ?? '',
        },
      },
    });

    pusherObj.connection.bind('state_change', states => {
      // eslint-disable-next-line no-console
      console.log('States ====>', states);
    });

    this.setState({ pusher: pusherObj });

    if (!userDetails) {
      const current = localStorage.getItem('team_db_id') || null;
      isUserLoggedIn().then(res => {
        const {
          data: { response, data },
        } = res;
        if (response) {
          setUserDetails(data);
          // this.getProductTourDetails();
          const index =
            data.teams.findIndex(
              item => (item?.id || item?.team_db_id) === current
            ) || 0;

          if (index < 0) {
            this.teamSelect(data);
          } else {
            this.teamSelect(data, index);
          }
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedTeam, userDetails } = this.props;

    if (prevProps.selectedTeam?.team_db_id !== selectedTeam?.team_db_id) {
      this.getRequestedSystem();
    }

    if (
      !prevProps?.userDetails ||
      (prevProps?.userDetails &&
        prevProps?.userDetails.user_id &&
        prevProps?.userDetails.user_id !== userDetails.user_id)
    ) {
      if (userDetails) {
        this.attachUser();
      }
    }
  }

  attachUser = () => {
    const { userDetails } = this.props;

    const pusherObj = new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER,
      authEndpoint: process.env.PUSHER_ENDPOINT,
      auth: {
        params: {
          Authorization: window.localStorage.Authorization,
        },
      },
    });

    pusherObj.connection.bind('state_change', states => {
      // eslint-disable-next-line no-console
      console.log('States ====>', states);
    });

    this.channel = pusherObj.subscribe(`private-${userDetails.user_id}`);

    this.setState(
      {
        pusher: pusherObj,
      },
      () => {
        if (this.channel) {
          this.attachPusher();
        }
      }
    );
  };

  getRequestedSystem = async () => {
    const { getCloudTime, loadRequestedCloudSytemDetails } = this.props;
    const response = await getCloudTime();
    const { session } = response?.data?.data;
    const isDataPresent = session ? Object.values(session)?.length > 0 : false;

    loadRequestedCloudSytemDetails(isDataPresent ? session : null);

    if (window?.fcWidget && isDataPresent) {
      window.fcWidget.setConfig({
        cf_cloud_session_id: session?.host_name,
      });
    }
  };

  attachPusher = () => {
    const { toggleUpgradeModal, userDetails } = this.props;
    this.channel.bind('user_logged_in', data => {
      // eslint-disable-next-line no-console
      console.log('user_logged_in --->', data);
    });

    if (process.env.TYPE !== 'app') {
      // this.channel.bind('team_update', data => {
      //   updateTeams({ data, key: 'team_update' });
      // });
      // this.channel.bind('team_delete', data => {
      //   updateTeams({ data, key: 'team_delete' });
      //   history.push('/');
      // });
      // this.channel.bind('team_add', data => {
      //   updateTeams({ data });
      // });

      this.channel.bind(`subscription_detail_${userDetails?.user_id}`, data => {
        if (data?.team_detail?.is_team_plan) {
          toggleUpgradeModal({
            show: true,
            data: {
              welcomeTeamModal: true,
              team: data?.team_detail?.team,
            },
          });
        }
      });
    }
  };

  teamSelect = (data, index = 0) => {
    const { selectTeam } = this.props;
    localStorage.setItem(
      'team_db_id',
      data?.teams[index].id || data?.teams[index].team_db_id
    );
    selectTeam({ ...data?.teams[index] });
  };

  checkSessionExpiry = () => {
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keydown', this.resetTimer);
    document.addEventListener('DOMMouseScroll', this.resetTimer);
    document.addEventListener('mousewheel', this.resetTimer);
    document.addEventListener('mousedown', this.resetTimer);
    document.addEventListener('touchstart', this.resetTimer);
    this.checkForLogout();
  };

  resetTimer = () => {
    this.inactivity = 0;
  };

  checkForLogout = () => {
    this.inactivity = +1;
    if (this.inactivity <= 1800) {
      this.timeUpdate();
    } else {
      this.logout();
    }
  };

  logout = () => {
    const { history } = this.props;
    history.push('/logout');
  };

  timeUpdate = () => {
    setTimeout(() => {
      this.checkForLogout();
    }, 1000);
  };

  componentWillUnmount = () => {
    const { pusher } = this.state;
    if (pusher && this.channel) {
      this.channel.unbind();
      pusher.unsubscribe(this.channel);
      pusher.disconnect();
      this.setState({
        pusher: null,
      });
    }
  };

  render() {
    const { component, sidebar, toggleUpgradeModal, upgradePlan, history } =
      this.props;
    const { pusher } = this.state;
    const UpdatedComponent = component;
    return (
      <>
        <div className='wrapper-body-holder'>
          <div className='wrapper-body container'>
            <Header {...this.props} />
            <div
              className='component-container'
              style={{ position: 'relative', top: '7.5rem' }}>
              <UpdatedComponent pusher={pusher} />
            </div>
          </div>
        </div>
        <Sidebar {...sidebar} pusher={pusher} />
        {upgradePlan && upgradePlan.show && (
          <SecondaryModal
            type='Upgrade Plan'
            onClose={() => toggleUpgradeModal({ show: false })}
            open={upgradePlan?.show || false}>
            {upgradePlan?.data?.welcomeTeamModal ? (
              <UpgradeTeamModal
                data={{
                  name: upgradePlan?.data?.team,
                }}
              />
            ) : (
              <UpgradePlanModal
                upgradePlan={upgradePlan}
                history={history}
                onClose={() => toggleUpgradeModal({ show: false })}
              />
            )}
          </SecondaryModal>
        )}
      </>
    );
  }
}

MainWrapperComponent.propTypes = {
  isUserLoggedIn: PropTypes.func,
  setUserDetails: PropTypes.func,
  fetchTrays: PropTypes.func,
  history: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object),
  component: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  upgradePlan: PropTypes.instanceOf(Object),
  toggleUpgradeModal: PropTypes.func,
  sidebar: PropTypes.instanceOf(Object),
  getCloudTime: PropTypes.func,
  loadRequestedCloudSytemDetails: PropTypes.func,
  selectTeam: PropTypes.func,
  selectedTeam: PropTypes.instanceOf(Object),
  updateTeams: PropTypes.func,
  // productTourService: PropTypes.func,
  setProductTourSkipDone: PropTypes.func,
};

export default MainWrapperComponent;
