const SET_USER_DETAILS = 'SET_USER_DETAILS';
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const UPDATE_UPLOAD_FILE = 'UPDATE_UPLOAD_FILE';
const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE';
const REMOVE_UPLOAD_FILE = 'REMOVE_UPLOAD_FILE';
const TOGGLE_UPGRADE_MODAL = 'TOGGLE_UPGRADE_MODAL';
const TOGGLE_EDITOR = 'TOGGLE_EDITOR';
const LOAD_REQUESTED_CLOUD_SYSTEM_DETAILS =
  'LOAD_REQUESTED_CLOUD_SYSTEM_DETAILS';
const SET_TEAM = 'SET_CURRENT_TEAM';
const UPDATE_USER_TEAM = 'UPDATE_USER_TEAM';
const LOAD_PRODUCT_TOUR_SKIP_DONE = 'LOAD_PRODUCT_TOUR_SKIP_DONE';
const RESET_COMMON_STATE = 'RESET_COMMON_STATE';

export default {
  SET_TEAM,
  SET_USER_DETAILS,
  TOGGLE_SIDEBAR,
  UPDATE_UPLOAD_FILE,
  SET_UPLOAD_FILE,
  REMOVE_UPLOAD_FILE,
  TOGGLE_UPGRADE_MODAL,
  TOGGLE_EDITOR,
  LOAD_REQUESTED_CLOUD_SYSTEM_DETAILS,
  UPDATE_USER_TEAM,
  LOAD_PRODUCT_TOUR_SKIP_DONE,
  RESET_COMMON_STATE,
};
