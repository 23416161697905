/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../components/Button';
import { useForm, TextField } from '../../components/FormInputs';
import { manageTeamsSerivceCall } from '../duck/operations';
import { Text } from '../../styles/common';
import { required } from '../../components/FormInputs/helperFunctions';

// Styles
import { Body, Footer } from '../../styles/modal';
import { whiteAlpha } from '../../styles/utils';

const INITIAL_STATE = {
  name: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },
};

const DeleteTeam = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    toggleModal,
    toggleToast,
    modal: { selected, initial },
  } = props;
  const { formValues, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true); // disable button
      const deleteResponse = await manageTeamsSerivceCall(
        { team_id: selected?.team_db_id },
        'delete'
      );

      const {
        data: { response, errormessage, data },
      } = deleteResponse;

      setSubmitting(false);

      if (response) {
        toggleModal(initial);
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: data?.message,
            variant: 'success',
          },
        });
      } else {
        toggleModal(initial);
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  const onNameChange = async ({ target: { value } }) => {
    let error = null;
    if (value !== selected?.name) {
      error = 'Team name doesn’t match';
    } else {
      error = undefined;
    }

    updateValues({
      ...formValues,
      name: {
        ...formValues?.name,
        value,
        error,
      },
    });
  };

  return (
    <>
      <form name='delete-team' onSubmit={onSubmit}>
        <Body>
          <Text paddingLeft='0' paddingRight='0' md>
            {selected?.name}
          </Text>

          <Text color={whiteAlpha(0.8)} paddingLeft='0' paddingRight='0'>
            Deleting this Team will delete all the Projects, Assets, Shared
            Links, Tasks in the team and the team Storage permanently.
          </Text>

          <div className='mt-2'>
            <TextField
              id='name'
              label='Team Name'
              value={formValues.name.value}
              onChange={onNameChange}
              error={!!formValues.name.error}
              helperText={formValues.name.error}
            />
            <Text color={whiteAlpha(0.8)} paddingLeft='0' paddingRight='0'>
              To confirm deletion, type the team name in the above field.
            </Text>
          </div>
        </Body>

        <Footer>
          <Normal
            type='submit'
            id='delete-asset-yes'
            isLoading={isSubmitting}
            color='secondary'
            style={{ marginRight: '2rem' }}
          >
            Delete
          </Normal>
          <Normal id='delete-asset-no' onClick={() => toggleModal(initial)}>
            Cancel
          </Normal>
        </Footer>
      </form>
    </>
  );
};

DeleteTeam.propTypes = {
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  modal: PropTypes.instanceOf(Object),
};

export default DeleteTeam;
