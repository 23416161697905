import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MainWrapperComponent from './MainWrapperComponent';

// Operations
import { portalOperations } from '../portal/duck';
import { commonOperations } from './duck';
import { homeOperations } from '../home/duck';

const mapStateToProps = state => ({
  ...state.common,
  ...state.storyboard,
});

const mapDispatchToProps = dispatch => {
  // check is user logged in
  const selectTeam = data => dispatch(commonOperations.saveTeam(data));
  const isUserLoggedIn = data => portalOperations.isUserLoggedIn(data);
  const toggleSidebar = data => dispatch(commonOperations.sidebarToggle(data));
  const setUserDetails = data =>
    dispatch(commonOperations.getUserDetails(data));
  const toggleUpgradeModal = data =>
    dispatch(commonOperations.upgradeModalAction(data));
  const getCloudTime = request => commonOperations.getCloudTime(request); // to delete storyboard
  const loadRequestedCloudSytemDetails = request =>
    dispatch(commonOperations.loadRequestedCloudSytemDetails(request)); // to load requested cloud system details
  const removeUploadData = data =>
    dispatch(commonOperations.removeUploadData(data));
  const updateTeams = data => dispatch(commonOperations.updateTeam(data));
  const resetHomePrograms = data => dispatch(homeOperations.clearData(data));
  const productTourService = request =>
    commonOperations.productTourService(request);
  const setProductTourSkipDone = data =>
    dispatch(commonOperations.setProductTourSkipDone(data));

  return {
    isUserLoggedIn,
    setUserDetails,
    toggleSidebar,
    toggleUpgradeModal,
    getCloudTime,
    loadRequestedCloudSytemDetails,
    removeUploadData,
    selectTeam,
    updateTeams,
    resetHomePrograms,
    productTourService,
    setProductTourSkipDone,
  };
};

const MainWrapperContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainWrapperComponent)
);

export default MainWrapperContainer;
