/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useRef, useState, useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FileCopyOutlined } from '@material-ui/icons';
import styled from 'styled-components';

// Components
import Normal from '../../../components/Button/Normal';
import GradientCard from './GradientCard';

// Constants
import { MODAL_TYPES, APP_TYPES } from '../../../constants';
import { CLOUD_SCREENS } from './constants';
import { UPLOADER_CONNECTOR_APP_KEYS } from '../../constants';

// Utils
import Mixpanel from '../../../../utils/mixpanel';
import { getDecrypted } from '../../../../utils/encrytion';
import { launchApplication } from '../../../helperFunctions';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import {
  Text,
  Label,
  Flex,
  CarouselViewParentStyles,
} from '../../../styles/common';
import { radius, fs, colors, padding, font } from '../../../styles/variables';
import {
  AppItem,
  AppTitle,
  AppLinkButton,
  CloudScreenHead,
  DownloadNativeAppParentStyles,
} from './styles';
import { whiteAlpha } from '../../../styles/utils';

const IndexStyles = styled.span`
  font-size: 2rem;
  margin-right: 1.2rem;
`;

const CopiedBoxStyles = styled(Label)`
  margin-left: ${padding.h};
  padding: 0.25rem 0.5rem;
  font-size: ${fs.xs};
  border-radius: ${radius.default};
  background-color: ${whiteAlpha(0.15)};
`;

const CredentialsStyles = styled(Flex)`
  align-items: center;

  textarea {
    opacity: 0;
    width: 0.2px;
    height: 0.2px;
  }

  .icon {
    cursor: pointer;
    display: none;
    margin-left: ${padding.h};

    svg {
      fill: ${colors.white};
    }
  }

  &:hover {
    .icon {
      display: block;
    }
  }
`;

const INITIAL_COPY_STATE = {
  username: false,
  password: false,
  ip: false,
  cloudTicket: false,
};

const Ready = props => {
  const {
    closeSidebar,
    goTo,
    toggleModal,
    cloudAccessDetails,
    downloadNiceDcvSdk,
  } = props;
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const ipRef = useRef(null);
  const cloudTicketRef = useRef(null);

  const [step, setStep] = useState(1);
  const [copied, setCopied] = useState(INITIAL_COPY_STATE);
  const [isDetailsVisible, setVisible] = useState(false);

  const toggleDetails = () => setVisible(prev => !prev);

  const onLaunchApplication = portal => {
    Mixpanel.track(
      `Launch Session ${portal === 'web' ? 'Web' : 'App'} Sidebar`,
      {
        config: { ...cloudAccessDetails.configurations },
      }
    );
    launchApplication(portal, cloudAccessDetails);
    closeSidebar();
  };

  // copy text to clipboard
  const copyClipboard = useCallback(
    (e, label) => {
      const allRefs = { usernameRef, passwordRef, ipRef, cloudTicketRef };
      navigator.clipboard
        .writeText(allRefs[`${label}Ref`].current.value)
        .then(() => {
          setCopied(prev => ({
            ...prev,
            [label]: true,
          }));
          setTimeout(() => setCopied(INITIAL_COPY_STATE), 3000);
        });
    },
    [usernameRef, passwordRef, ipRef, cloudTicketRef]
  );

  const decryptedPassword = useMemo(
    () => getDecrypted(cloudAccessDetails?.password),
    [cloudAccessDetails?.password]
  );

  return (
    <>
      <Body>
        <GradientCard
          label={cloudAccessDetails?.cloud_system?.label}
          icon={cloudAccessDetails?.cloud_system?.icon}
          value={cloudAccessDetails?.cloud_system?.value}
          details={[
            `${cloudAccessDetails?.configurations?.software}`,
            `${cloudAccessDetails?.configurations?.title}`,
            `₹${cloudAccessDetails?.configurations?.pricing} / hr`,
          ]}
        />

        {cloudAccessDetails?.plugins?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm>
            Add-on plugins: {cloudAccessDetails?.plugins.join(', ')}
          </Text>
        )}

        {process.env.TYPE !== APP_TYPES.app && (
          <>
            <CloudScreenHead bg>
              Your cloud system is ready. The Session ID is{' '}
              {cloudAccessDetails?.cloud_assignment_id}
            </CloudScreenHead>

            <AppItem>
              <AppTitle style={{ marginBottom: '0.5rem' }}>
                <IndexStyles>01</IndexStyles>End Cloud Session
              </AppTitle>
              <Label margin='1rem 0'>You can end session anytime</Label>
              <AppLinkButton
                onClick={() => {
                  closeSidebar();
                  toggleModal(MODAL_TYPES.endSession, true, 'End Session');
                }}>
                End Session
              </AppLinkButton>
            </AppItem>

            {/* <AppItem>
              <AppTitle>
                Native Connect
              </AppTitle>
              <Label margin='1rem 0'>
                Alternatively connect to Postudio cloud session via native app{' '}
              </Label>
              <AppLinkButton>Request Details</AppLinkButton>
            </AppItem> */}
          </>
        )}

        {cloudAccessDetails?.can_extend_session && (
          <AppItem>
            <AppTitle>
              <IndexStyles>02</IndexStyles>
              Extend Cloud Session
            </AppTitle>
            <Label margin='1rem 0'>
              You can extend your cloud edit session by 1 hour{' '}
            </Label>
            <AppLinkButton onClick={() => goTo(CLOUD_SCREENS.duration)}>
              Extend Now
            </AppLinkButton>
          </AppItem>
        )}

        <AppItem>
          <AppTitle style={{ marginBottom: '0.5rem' }}>
            <IndexStyles>
              {cloudAccessDetails?.can_extend_session ? '03' : '02'}
            </IndexStyles>
            Cloud System Support
          </AppTitle>
          <Label margin='1rem 0'>
            You can report an issue for your cloud system
          </Label>
          <AppLinkButton
            onClick={() => {
              closeSidebar();
              toggleModal(
                MODAL_TYPES.systemReport,
                true,
                'Cloud System Support'
              );
            }}>
            Report Issue
          </AppLinkButton>
        </AppItem>

        {process.env.TYPE !== APP_TYPES.app &&
          process.env.DISPLAY_CLOUD_CREDENTIALS === 'true' &&
          cloudAccessDetails?.username &&
          cloudAccessDetails?.password &&
          cloudAccessDetails?.ip_address &&
          cloudAccessDetails?.cloud_assignment_id && (
            <CarouselViewParentStyles>
              <div className='head'>
                <AppTitle>Native Connect</AppTitle>
                <div aria-label='indicators'>
                  {[1, 2].map(el => (
                    <button
                      key={el}
                      type='button'
                      className={el === step ? 'indicator active' : 'indicator'}
                      aria-label={el}
                      onClick={() => setStep(el)}
                    />
                  ))}
                </div>
              </div>
              <Text
                color={whiteAlpha(0.8)}
                margin='1.5rem 0'
                lineHeight='1.4'
                padding='0'
                style={{ fontWeight: font.medium }}>
                {step === 1
                  ? 'Alternatively connect to Postudio cloud session via native app.'
                  : 'Use the below credentials to gain access:'}
              </Text>
              {step === 1 && (
                <DownloadNativeAppParentStyles>
                  {Object.values(UPLOADER_CONNECTOR_APP_KEYS).map(el => (
                    <li>
                      <AppLinkButton
                        type='button'
                        onClick={() => downloadNiceDcvSdk(el)}>
                        {el.label}
                      </AppLinkButton>
                    </li>
                  ))}
                </DownloadNativeAppParentStyles>
              )}
              {step === 2 && (
                <>
                  <CredentialsStyles>
                    <Label style={{ marginBottom: '0.2rem' }}>
                      IP:{' '}
                      <span>
                        {isDetailsVisible
                          ? cloudAccessDetails?.ip_address
                          : '******'}
                      </span>
                    </Label>
                    {isDetailsVisible && (
                      <>
                        <span
                          className='icon'
                          onClick={e => copyClipboard(e, 'ip')}
                          aria-hidden='true'>
                          <FileCopyOutlined />
                        </span>
                        {copied?.ip && (
                          <CopiedBoxStyles>Copied</CopiedBoxStyles>
                        )}
                        <textarea
                          defaultValue={cloudAccessDetails?.ip_address}
                          ref={ipRef}
                        />
                      </>
                    )}
                  </CredentialsStyles>
                  <CredentialsStyles>
                    <Label style={{ marginBottom: '0.2rem' }}>
                      Username:{' '}
                      <span>
                        {isDetailsVisible
                          ? cloudAccessDetails?.username
                          : '******'}
                      </span>
                    </Label>
                    {isDetailsVisible && (
                      <>
                        <span
                          className='icon'
                          onClick={e => copyClipboard(e, 'username')}
                          aria-hidden='true'>
                          <FileCopyOutlined />
                        </span>
                        {copied?.username && (
                          <CopiedBoxStyles>Copied</CopiedBoxStyles>
                        )}
                        <textarea
                          defaultValue={cloudAccessDetails?.username}
                          ref={usernameRef}
                        />
                      </>
                    )}
                  </CredentialsStyles>
                  <CredentialsStyles>
                    <Label style={{ marginBottom: '0.2rem' }}>
                      Password:{' '}
                      <span>
                        {isDetailsVisible ? decryptedPassword : '******'}
                      </span>
                    </Label>
                    {isDetailsVisible && (
                      <>
                        <span
                          className='icon'
                          onClick={e => copyClipboard(e, 'password')}
                          aria-hidden='true'>
                          <FileCopyOutlined />
                        </span>
                        {copied?.password && (
                          <CopiedBoxStyles>Copied</CopiedBoxStyles>
                        )}
                        <textarea
                          defaultValue={decryptedPassword}
                          ref={passwordRef}
                        />
                      </>
                    )}
                  </CredentialsStyles>
                  <CredentialsStyles>
                    <Label>
                      Session ID:{' '}
                      {isDetailsVisible
                        ? cloudAccessDetails?.cloud_assignment_id
                        : '******'}
                    </Label>
                    {isDetailsVisible && (
                      <>
                        <span
                          className='icon'
                          onClick={e => copyClipboard(e, 'cloudTicket')}
                          aria-hidden='true'>
                          <FileCopyOutlined />
                        </span>
                        {copied?.cloudTicket && (
                          <CopiedBoxStyles>Copied</CopiedBoxStyles>
                        )}
                        <textarea
                          defaultValue={cloudAccessDetails?.cloud_assignment_id}
                          ref={cloudTicketRef}
                        />
                      </>
                    )}
                  </CredentialsStyles>
                  <AppLinkButton onClick={toggleDetails} className='mt-1-5'>
                    {isDetailsVisible ? 'Hide' : 'Show'} Details
                  </AppLinkButton>
                </>
              )}
            </CarouselViewParentStyles>
          )}
      </Body>

      <Footer>
        {process.env.TYPE !== APP_TYPES.app &&
          cloudAccessDetails.launch_token && (
            <>
              <Normal onClick={() => onLaunchApplication('web')}>Launch</Normal>
            </>
          )}
      </Footer>
    </>
  );
};

Ready.propTypes = {
  goTo: PropTypes.func,
  cloudAccessDetails: PropTypes.instanceOf(Object),
  toggleModal: PropTypes.func,
  closeSidebar: PropTypes.func,
  downloadNiceDcvSdk: PropTypes.func,
};

export default Ready;
