import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { blackAlpha, whiteAlpha } from '../../styles/utils';
import { blur, fs, colors, font } from '../../styles/variables';

const useStyles = makeStyles({
  root: {
    margin: '0 !important',
    '& .MuiPaper-root': {
      marginTop: '1rem',
      minWidth: '20rem',
      maxHeight: '40rem',
      backgroundColor: blackAlpha(0.5),
      border: `1px solid ${whiteAlpha(0.2)}`,
      backdropFilter: `blur(${blur})`,
      borderRadius: '0.4rem',
      '&::-webkit-scrollbar': {
        width: 0,
      },
      '& .MuiList-root': {
        outline: '0',
        padding: '0 !important',
      },
    },
  },
});

const MenuItemStyles = styled(MenuItem)`
  &.MuiButtonBase-root {
    padding: 1.3rem 1.5rem;
    font-size: 1.8rem;
    &:not(:last-child) {
      border-bottom: 1px solid ${whiteAlpha(0.2)};
    }
    &:hover:not([disabled]) {
      background-color: ${colors.blue.secondary};
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }

  .MuiListItemIcon-root {
    min-width: fit-content;
    margin-right: 1rem;
    .MuiSvgIcon-root {
      color: ${colors.white};
      font-size: 1.8rem;
    }
  }
`;

const MenuItemTextStyles = styled(Typography)`
  &.MuiTypography-root {
    font-size: ${fs.xs};
    padding: 0;
    font-weight: ${font.medium};
  }
`;

const NestedMenu = props => {
  const classes = useStyles(props);
  const { onMenuItemClick, menuPosition, name, icon, nested } = props;
  const nestedMenuItemRef = useRef(null);
  const nestedMenuContainerRef = useRef(null);
  const nestedMenuChildrenRef = useRef(null);
  const [isNestedMenuOpen, setNestedMenu] = useState(false);

  const onClose = () => setNestedMenu(false);

  return (
    <div
      ref={nestedMenuContainerRef}
      onMouseEnter={() => setNestedMenu(true)}
      onMouseLeave={onClose}
    >
      <MenuItemStyles
        ref={nestedMenuItemRef}
        disabled={false}
        style={{
          justifyContent: 'space-between',
          borderBottom: `1px solid ${whiteAlpha(0.2)}`,
        }}
      >
        <div className='flex items-center'>
          <ListItemIcon>{icon}</ListItemIcon>
          <MenuItemTextStyles noWrap>{name}</MenuItemTextStyles>
        </div>
        <ListItemIcon style={{ marginRight: 0 }}>
          <ChevronRightIcon />
        </ListItemIcon>
      </MenuItemStyles>
      <Menu
        id='nested-menu'
        style={{
          pointerEvents: 'none',
        }}
        anchorEl={nestedMenuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        className={classes.root}
        open={isNestedMenuOpen && !!menuPosition}
        onClose={onClose}
      >
        <div
          ref={nestedMenuChildrenRef}
          style={{
            pointerEvents: 'auto',
          }}
        >
          {nested?.map((el, index) => {
            if (el?.isVisible || el?.isVisible === undefined) {
              return (
                <MenuItemStyles
                  key={index}
                  disabled={el?.isDisabled || false}
                  className={classes.menuItem}
                  onClick={() => {
                    onMenuItemClick(el);
                    onClose();
                  }}
                >
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <MenuItemTextStyles noWrap>{el.name}</MenuItemTextStyles>
                </MenuItemStyles>
              );
            }
            return null;
          })}
        </div>
      </Menu>
    </div>
  );
};

NestedMenu.propTypes = {
  onMenuItemClick: PropTypes.func,
  menuPosition: PropTypes.bool,
  name: PropTypes.string,
  icon: PropTypes.element,
  nested: PropTypes.instanceOf(Array),
};

export default NestedMenu;
