import React from 'react';
import styled from 'styled-components';

// Components
import Taskbar from './taskbar';
import Image from './Image';
import Title from './Title';

// Styles

const Row = styled.div`
  position: relative;
  padding: 2.4rem 0;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: ${({ column }) => column && 'column'};
  flex-wrap: ${({ column }) => !column && 'wrap'};
`;

const StoryboardGrid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(23.6rem, 1fr));
`;

const renderHeads = () => {
  return (
    <Head>
      <div className='c-padd'>
        <Title width='12.8rem' />
      </div>
      <div className='c-padd'>
        <Title width='12.8rem' />
      </div>
    </Head>
  );
};

const Home = () => {
  return (
    <div className='container-fluid'>
      <Row>
        {renderHeads()}
        <Grid>
          {Array.from(new Array(4)).map((item, i) => (
            <Image
              withTitle
              width='29rem'
              height='16.2rem'
              key={`task-card-${i}`}
            />
          ))}
        </Grid>
      </Row>

      <Row>
        {renderHeads()}
        <StoryboardGrid>
          {Array.from(new Array(6)).map((item, i) => (
            <Image key={`project-${i}`} />
          ))}
        </StoryboardGrid>
      </Row>
    </div>
  );
};

export default Home;
