import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { MentionsInput, Mention } from 'react-mentions';

import { fetchUsersBasedOnFilters } from '../../storyboard/duck/operations';

import { colors, fs, padding, radius } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const ParentStyles = styled.div`
  width: 100%;

  .mentions__input,
  .mentions__highlighter {
    font-size: ${fs.sm} !important;
    border: 0 !important;
  }

  .mentions__input {
    outline: none;
    color: ${colors.white};
  }

  .mentions__input__suggestions {
    border-radius: ${radius.default} !important;
  }

  .mentions__suggestions__item {
    padding: ${padding.v} ${padding.h};
    font-size: ${fs.sm};
    color: ${colors.black.default};
    &:hover {
      background-color: ${whiteAlpha(0.5)};
    }
  }
`;

const MentionInputField = ({
  onChange,
  value,
  placeholder,
  searchPayload,
  parentStyles,
  inputRef,
  autoFocus,
}) => {
  const fetchUsers = (query, callback) => {
    if (!query) return;

    fetchUsersBasedOnFilters(
      {
        search_type: 'collaborators',
        search_text: query,
        ...searchPayload,
      },
      'get'
    )
      .then(res =>
        res.data.data.map(el => ({
          id: el.user_id,
          display: el.name,
        }))
      )
      .then(callback);
  };

  return (
    <ParentStyles style={parentStyles}>
      <MentionsInput
        className='mentions'
        singleLine
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        forceSuggestionsAboveCursor
        allowSpaceInQuery
        inputRef={inputRef}
        autoFocus={autoFocus}
      >
        <Mention
          appendSpaceOnAdd
          displayTransform={(id, display) => `@${display?.replace(' ', '')}`}
          trigger='@'
          markup='@[__display__](user:__id__)'
          data={fetchUsers}
        />
      </MentionsInput>
    </ParentStyles>
  );
};

MentionInputField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  searchPayload: PropTypes.instanceOf(Object),
  parentStyles: PropTypes.instanceOf(Object),
  inputRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default MentionInputField;
