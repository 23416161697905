import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

// styles
import { Text } from '../../styles/common';
import { colors } from '../../styles/variables';

export const CommonBoxStyles = styled.div`
  position: relative;
  padding: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 100%;
`;

export const DefaultCardStyles = styled(CommonBoxStyles)`
  background-color: ${colors.blue.navy};
  display: flex;
  align-items: center;
  &:hover {
    .icon-block {
      background-color: ${colors.blue.primary};
      > svg {
        fill: #fff;
      }
    }
  }
`;

export const Head = styled.h3`
  color: ${colors.blue.primary};
  font-size: 1.6rem;
  padding: 0;
  margin-bottom: 1rem;
  font-weight: 400;
`;

export const IconStyles = styled.div`
  width: 3.6rem;
  border-radius: 100%;
  background-color: transparent;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid ${colors.blue.primary};
  margin-right: 1rem;
  > svg {
    fill: ${colors.blue.primary};
    width: 3.6rem;
    height: 2rem;
  }
`;

const ActionCard = ({ para, icon, onClick, head, additional, className }) => {
  return useMemo(() => {
    return (
      <DefaultCardStyles onClick={onClick} className={className}>
        <IconStyles className='icon-block'>{icon}</IconStyles>
        <div className='w-full'>
          <Head>{head}</Head>
          {typeof para === 'string' ? (
            <Text sm className='line-clamp-1 p-0'>
              {para}
            </Text>
          ) : (
            para
          )}
        </div>
        {typeof additional !== 'string' && additional}
      </DefaultCardStyles>
    );
  }, [para, icon, onClick, head, additional]);
};

ActionCard.propTypes = {
  head: PropTypes.string,
  para: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  additional: PropTypes.node,
  onClick: PropTypes.func,
};

export default ActionCard;
