import * as types from './types';

const actionLoadTabs = payload => {
  return {
    type: types.LOAD_TABS,
    payload,
  };
};

const actionLoadBillingDetails = payload => {
  return {
    type: types.LOAD_BILLING_DETAILS,
    payload,
  };
};

const actionLoadPermissions = payload => {
  return {
    type: types.LOAD_TEAM_PERMISSIONS,
    payload,
  };
};

export const loadTeamDetails = payload => {
  return {
    type: types.LOAD_TEAM_DETAILS,
    payload,
  };
};

export default {
  actionLoadTabs,
  actionLoadBillingDetails,
  actionLoadPermissions,
};
