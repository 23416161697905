import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import ChangePassword from './changePassword';
import EnableTwoFactor from './enableTwoFactor';

// Styles
import { Body } from '../../styles/sidebar';
// import { ComingSoon } from '../../styles/common';

const AccountSettings = props => {
  const userDetails = useSelector(store => store?.common?.userDetails);
  return (
    <>
      <Body noFooter>
        {/* <ComingSoon>Coming Soon</ComingSoon> */}
        <ChangePassword {...props} />
        <EnableTwoFactor
          {...props}
          isTwoFactorEnabled={userDetails?.two_factor_authentication_enabled}
          isTwoFactorEnabledByTeam={
            userDetails?.is_two_factor_authentication_enabled_by_team
          }
        />
      </Body>
    </>
  );
};

AccountSettings.propTypes = {
  props: PropTypes.instanceOf(Object),
};

export default AccountSettings;
