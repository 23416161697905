import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Normal } from '../../../../components/Button';
import { TextField, Select, useForm } from '../../../../components/FormInputs';

import {
  fetchIntegrationDetailService,
  sprinklrIntegrationService,
  validateCampaignService,
} from '../../../duck/operations';
import { enqueueSnackbar } from '../../../../snackbar/duck/actions';

// Helper Functions
import { required } from '../../../../components/FormInputs/helperFunctions';
import { formatLabelValue } from '../../../../helperFunctions';

import { Body, Footer } from '../../../../styles/sidebar';
import { CarouselViewParentStyles, Text } from '../../../../styles/common';
import {
  AppTitle,
  AppLinkButton,
} from '../../../../storyboard/components/CloudAccess/styles';
import { font } from '../../../../styles/variables';
import { whiteAlpha } from '../../../../styles/utils';
import { SPRINKLR_STEPS } from '../../../constants/integration';

const INITIAL_STATE = {
  environment: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  businessUnit: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  mediaAssets: {
    value: '',
    error: undefined,
    required: false,
  },

  campaignId: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  campaignName: {
    value: '',
    error: undefined,
    required: false,
  },
};

const INITIAL_STATE_CAMPAIGN_STATUS = {
  isValidating: false,
  name: '',
};

const CreateEdit = ({ goTo }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [environmentOptions, setEnvironmentOptions] = useState([]);
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [mediaAssetsOptions, setMediaAssetsOptions] = useState([]);
  const [step, setStep] = useState(1);
  const [campaignStatus, setCampaignStatus] = useState(
    INITIAL_STATE_CAMPAIGN_STATUS
  );
  const selectedTeam = useSelector(store => store?.common.selectedTeam);
  const dispatch = useDispatch();

  useEffect(() => {
    const getEnvironmentOptions = async () => {
      try {
        const { data } = await fetchIntegrationDetailService({
          integration_type: 'sprinklr_integration',
        });

        if (data?.response) {
          setEnvironmentOptions(data?.data?.details);
          setMediaAssetsOptions([{ label: 'None', value: 'none' }]);
        } else throw new Error(data?.errormessage);
      } catch (err) {
        console.log('🚀 ~ getEnvironmentOptions ~ err:', err?.message);
      }
    };
    getEnvironmentOptions();
  }, []);

  const { formValues, onChange, onSubmit, setFormValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      try {
        const { data } = await sprinklrIntegrationService(
          {
            team_db_id: selectedTeam?.team_db_id,
            team_id: selectedTeam?.team_db_id,
            campaign_id: formValues?.campaignId?.value,
            environment: formValues?.environment?.value,
            business_unit: formValues?.businessUnit?.value,
            ...(formValues?.mediaAssets?.value &&
              formValues?.mediaAssets?.value !== 'None' && {
                agency_for_media_assets: formValues?.mediaAssets?.value,
              }),
          },
          'post'
        );

        if (data?.response) {
          goTo(SPRINKLR_STEPS.list);
          setSubmitting(false);
        } else throw new Error(data?.errormessage);
      } catch (err) {
        setSubmitting(false);
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          })
        );
      }
    }
  );

  const fetchBusinessUnitsOptions = async e => {
    try {
      const { data } = await fetchIntegrationDetailService({
        integration_type: 'sprinklr_integration',
        environment: e?.target?.value,
        business_units: true,
      });

      if (data?.response) {
        const campaignDetails = data?.data?.campaign;
        const formattedBusinessUnits = formatLabelValue(
          data?.data?.business_units,
          'label',
          'key'
        );

        const formatedMediaAssets = formatLabelValue(
          data?.data?.agency_for_media_assets,
          'label',
          'key'
        );

        setBusinessUnitOptions(formattedBusinessUnits);
        setMediaAssetsOptions([
          ...formatedMediaAssets,
          { label: 'None', value: 'None' },
        ]);
        setCampaignStatus(prev => ({
          ...prev,
          name: campaignDetails?.name,
        }));
        setFormValues(prev => ({
          ...prev,
          campaignId: {
            ...prev.campaignId,
            value: campaignDetails?.campaign_id,
          },
          campaignName: {
            ...prev.campaignName,
            value: campaignDetails?.name,
          },
        }));
      } else throw new Error(data?.errormessage);
    } catch (err) {
      setBusinessUnitOptions([]);
      console.log('🚀 ~ fetchBusinessUnitsOptions ~ err:', err?.message);
    }
  };

  const onEnvironmentChange = e => {
    onChange(e);
    fetchBusinessUnitsOptions(e);
  };

  const onCampaignIdChange = e => {
    onChange(e);
    setFormValues(prev => ({
      ...prev,
      campaignName: {
        ...prev.campaignName,
        value: '',
      },
    }));
  };

  const onCampaignValidate = useCallback(async () => {
    setCampaignStatus(prev => ({
      ...prev,
      isValidating: true,
    }));
    try {
      const { data } = await validateCampaignService({
        environment: formValues?.environment?.value,
        campaign_id: formValues?.campaignId?.value,
      });

      if (data?.response) {
        setCampaignStatus(prev => ({
          ...prev,
          isValidating: false,
        }));
        if (
          data?.data?.deleted ||
          data?.data?.archived ||
          data?.data?.status === 'DRAFT'
        ) {
          dispatch(
            enqueueSnackbar({
              message: {
                messageHead: 'Error',
                messageBody: data?.errormessage,
                variant: 'error',
              },
            })
          );
        } else if (data?.data?.status === 'APPROVED') {
          setFormValues(prev => ({
            ...prev,
            campaignName: {
              ...prev.campaignName,
              value: data?.data?.name,
            },
          }));
        }
      } else throw new Error(data?.errormessage);
    } catch (err) {
      setCampaignStatus(prev => ({
        ...prev,
        isValidating: false,
      }));
      dispatch(
        enqueueSnackbar({
          message: {
            messageHead: 'Error',
            messageBody: 'Invalid Campaign ID',
            variant: 'error',
          },
        })
      );
    }
  }, [formValues?.environment?.value, formValues?.campaignId?.value]);

  return (
    <form name='create-new-integration' onSubmit={onSubmit}>
      <Body>
        <CarouselViewParentStyles style={{ margin: '0 0 1rem 0' }}>
          <div className='head'>
            <AppTitle>Steps</AppTitle>
            <div aria-label='indicators'>
              {[1, 2, 3].map(el => (
                <button
                  key={el}
                  type='button'
                  className={el === step ? 'indicator active' : 'indicator'}
                  aria-label={el}
                  onClick={() => setStep(el)}
                />
              ))}
            </div>
          </div>
          <Text
            color={whiteAlpha(0.8)}
            lineHeight='1.4'
            marginTop='1rem'
            padding='0'
            style={{ fontWeight: font.medium }}>
            {step === 1 &&
              `Select the Environment and the Business Unit. The Campaign ID & Name are preselected.
            `}
            {step === 2 &&
              `Validate the Campaign ID and make sure you’ve granted the permissions to cloud+dev@postud.io on your Sprinklr account.
            `}
            {step === 3 &&
              `Reach out to us via Support Chat if you need any help.
            `}
          </Text>
        </CarouselViewParentStyles>

        <Select
          id='environment'
          label='Environment'
          value={formValues?.environment?.value}
          onChange={onEnvironmentChange}
          error={!!formValues?.environment?.error}
          helperText={formValues?.environment?.error}
          options={environmentOptions ?? []}
        />

        <Select
          id='businessUnit'
          label='Business Unit'
          value={formValues?.businessUnit?.value}
          onChange={onChange}
          disabled={!businessUnitOptions?.length}
          error={!!formValues?.businessUnit?.error}
          helperText={formValues?.businessUnit?.error}
          options={businessUnitOptions ?? []}
        />

        <Select
          id='mediaAssets'
          label='Agency - Media Assets'
          value={formValues?.mediaAssets?.value}
          onChange={onChange}
          disabled={!businessUnitOptions?.length}
          error={!!formValues?.mediaAssets?.error}
          helperText={formValues?.mediaAssets?.error}
          options={mediaAssetsOptions || []}
        />

        {formValues?.businessUnit?.value && (
          <>
            <div>
              <TextField
                id='campaignId'
                label='Campaign ID'
                value={formValues?.campaignId?.value}
                onChange={onCampaignIdChange}
                error={!!formValues?.campaignId?.error}
                helperText={formValues?.campaignId?.error}
                disabled={campaignStatus?.name}
              />

              {!campaignStatus?.name && (
                <AppLinkButton
                  disabled={campaignStatus?.isValidating}
                  type='button'
                  onClick={onCampaignValidate}
                  style={{ textAlign: 'left' }}>
                  Validate
                </AppLinkButton>
              )}
            </div>

            {formValues?.campaignName?.value && (
              <div style={{ marginTop: !campaignStatus?.name ? '1rem' : '0' }}>
                <TextField
                  id='campaignName'
                  label='Campaign Name'
                  value={formValues?.campaignName?.value}
                  error={!!formValues?.campaignName?.error}
                  helperText={formValues?.campaignName?.error}
                  disabled
                />
              </div>
            )}
          </>
        )}
      </Body>

      <Footer
        style={{
          columnGap: '2rem',
        }}>
        <Normal
          type='button'
          onClick={() => goTo(SPRINKLR_STEPS.list)}
          color='secondary'>
          Back
        </Normal>

        <Normal
          type='submit'
          isLoading={isSubmitting}
          disabled={!formValues?.campaignName?.value}>
          Create
        </Normal>
      </Footer>
    </form>
  );
};

CreateEdit.propTypes = {
  goTo: PropTypes.func,
};

export default CreateEdit;
