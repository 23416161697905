/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Normal } from '../../components/Button';
import { useForm, TextField, Select } from '../../components/FormInputs';

// Helper Functions
import {
  required,
  email,
  // panRegex,
} from '../../components/FormInputs/helperFunctions';
import {
  onInputChangeCharactersLimit,
  formatLabelValue,
} from '../../helperFunctions';
import { manageBillingServiceCall } from '../duck/operations';
import { getFiltersService } from '../../subscription/duck/operations';

// Styles
import { Body, Footer } from '../../styles/sidebar';

const INITIAL_STATE = {
  name: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  billingAddress: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  email: {
    value: '',
    error: undefined,
    required: true,
    validation: email,
  },

  contactPerson: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },

  alternateEmail: {
    value: '',
    error: undefined,
    required: true,
    validation: email,
  },

  gst: {
    value: '',
    error: undefined,
    validation: value => {
      if (
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value)
      ) {
        return 'Enter a valid GST';
      }
      return undefined;
    },
  },

  pan: {
    value: '',
    error: undefined,
    validation: value => {
      if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
        return 'Enter a valid PAN';
      }
      return undefined;
    },
  },

  placeOfSupply: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },
};

const BillingDetails = ({ closeSidebar, toggleToast, userDetails }) => {
  const billingDetails = useSelector(store => store?.settings?.billing);
  const [stateCodeOptions, setStateCodes] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      manageBillingServiceCall(
        {
          ...(billingDetails?.billing_db_id && {
            billing_db_id: billingDetails?.billing_db_id,
          }),
          company_name: formValues?.name?.value,
          biling_address: formValues?.billingAddress?.value,
          contact_email: formValues?.email?.value,
          alternate_email: formValues?.alternateEmail?.value,
          contact_person: formValues?.contactPerson?.value,
          organisation_db_id: userDetails?.organisation_db_id,
          gst_no: formValues?.gst?.value,
          pan_number: formValues?.pan?.value,
          state_code: formValues?.placeOfSupply?.value,
        },
        billingDetails?.billing_db_id ? 'put' : 'post'
      )
        .then(res => {
          const { errormessage, data, response } = res?.data;
          setSubmitting(false);
          if (response) {
            closeSidebar();
            toggleToast({
              message: {
                messageHead: 'Success',
                messageBody: data?.message || 'Details Updated Successfully',
                variant: 'success',
              },
            });
          } else {
            throw new Error(errormessage);
          }
        })
        .catch(err => {
          setSubmitting(false);
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          });
        });
    }
  );

  const onFieldChange = e => onInputChangeCharactersLimit(e, () => onChange(e));

  useEffect(() => {
    const fetchFilters = async () => {
      const res = await getFiltersService({
        filter_type: 'state_codes',
      });
      if (
        res?.data?.response &&
        Object.keys(res?.data?.data?.state_codes).length
      ) {
        const options = formatLabelValue(
          res?.data?.data?.state_codes,
          'label',
          'value'
        );
        setStateCodes(options);
      } else {
        setStateCodes([]);
      }
    };

    fetchFilters();
  }, []);

  useEffect(() => {
    if (billingDetails?.billing_db_id) {
      updateValues({
        ...formValues,
        name: {
          ...formValues?.name,
          value: billingDetails?.company_name,
        },
        billingAddress: {
          ...formValues?.billingAddress,
          value: billingDetails?.biling_address,
        },
        contactPerson: {
          ...formValues?.contactPerson,
          value: billingDetails?.contact_person,
        },
        email: {
          ...formValues?.email,
          value: billingDetails?.contact_email,
        },
        alternateEmail: {
          ...formValues?.alternateEmail,
          value: billingDetails?.alternate_email,
        },
        gst: {
          ...formValues?.gst,
          value: billingDetails?.gst_no,
        },
        pan: {
          ...formValues?.pan,
          value: billingDetails?.pan_no,
        },
        placeOfSupply: {
          ...formValues.placeOfSupply,
          value: billingDetails.place_of_supply,
        },
      });
    } else {
      updateValues({
        ...formValues,
        contactPerson: {
          ...formValues?.contactPerson,
          value: userDetails?.name,
        },
        email: {
          ...formValues?.email,
          value: userDetails?.username,
        },
      });
    }
  }, [billingDetails]);

  return useMemo(() => {
    return (
      <>
        <Body style={{ paddingRight: '1.5rem' }}>
          <TextField
            id='name'
            label='Company Name'
            value={formValues.name.value}
            onChange={onChange}
            error={!!formValues.name.error}
            helperText={formValues.name.error}
          />

          <TextField
            id='contactPerson'
            label='Contact Person'
            value={formValues.contactPerson.value}
            onChange={onChange}
            error={!!formValues.contactPerson.error}
            helperText={formValues.contactPerson.error}
          />

          <TextField
            id='email'
            label='Contact Email'
            value={formValues.email.value}
            onChange={onFieldChange}
            error={!!formValues.email.error}
            helperText={formValues.email.error}
          />

          <TextField
            id='alternateEmail'
            label='Alternate Email'
            value={formValues.alternateEmail.value}
            onChange={onFieldChange}
            error={!!formValues.alternateEmail.error}
            helperText={formValues.alternateEmail.error}
          />

          <TextField
            id='pan'
            label='PAN No.'
            value={formValues.pan.value}
            onChange={onChange}
            error={!!formValues.pan.error}
            helperText={formValues.pan.error}
          />

          <TextField
            id='gst'
            label='GST No.'
            value={formValues.gst.value}
            onChange={onChange}
            error={!!formValues.gst.error}
            helperText={formValues.gst.error}
          />

          <Select
            id='placeOfSupply'
            label='Place of Supply/State Code'
            value={formValues.placeOfSupply.value}
            onChange={onChange}
            error={!!formValues.placeOfSupply.error}
            helperText={formValues.placeOfSupply.error}
            options={stateCodeOptions || []}
          />

          <TextField
            multiline
            rows='4'
            id='billingAddress'
            label='Billing Address'
            value={formValues.billingAddress.value}
            onChange={onChange}
            error={!!formValues.billingAddress.error}
            helperText={formValues.billingAddress.error}
          />
        </Body>

        <Footer>
          <Normal type='submit' onClick={onSubmit} isLoading={isSubmitting}>
            Update
          </Normal>
        </Footer>
      </>
    );
  }, [formValues, onChange, onSubmit, setSubmitting, isSubmitting]);
};

BillingDetails.propTypes = {
  toggleToast: PropTypes.func,
  closeSidebar: PropTypes.func,
  userDetails: PropTypes.instanceOf(Object),
};

export default BillingDetails;
