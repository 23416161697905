import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../components/Button';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';

const DeleteStoryboard = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    title,
    toggleModal,
    toggleToast,
    deleteStoryboard,
    match: { params },
    history,
  } = props;

  const onClose = () => toggleModal(null, false, null);
  const handleDelete = async () => {
    setSubmitting(true);
    const deleteResponse = await deleteStoryboard({
      project_db_id: params.project_db_id,
    });
    const {
      data: { response, errormessage },
    } = deleteResponse;
    setSubmitting(false);
    if (response) {
      history.push('/');
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Project deleted successfully',
          variant: 'success',
        },
      });
    } else {
      onClose();
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <Text padding='0' md>{title}</Text>
      </Body>

      <Footer>
        <Normal
          id='delete-project-no'
          onClick={onClose}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          No
        </Normal>
        <Normal
          id='delete-project-yes'
          onClick={handleDelete}
          isLoading={isSubmitting}
        >
          Yes
        </Normal>
      </Footer>
    </>
  );
};

DeleteStoryboard.propTypes = {
  toggleModal: PropTypes.func,
  deleteStoryboard: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  title: PropTypes.string,
};

export default DeleteStoryboard;
