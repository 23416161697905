const LOAD_STORYBOARD = 'LOAD_STORYBOARD';
const UPDATE_PUSHER_DATA = 'UPDATE_PUSHER_DATA';
const RESET_STATE = 'RESET_STATE';
const UPDATE_TRAY_DATA = 'UPDATE_TRAY_DATA';
const FETCH_PROGRAM_STRUCTURE = 'FETCH_PROGRAM_STRUCTURE';
const FETCH_PROGRAM_DATA = 'FETCH_PROGRAM_DATA';

export default {
  LOAD_STORYBOARD,
  UPDATE_PUSHER_DATA,
  RESET_STATE,
  UPDATE_TRAY_DATA,
  FETCH_PROGRAM_STRUCTURE,
  FETCH_PROGRAM_DATA,
};
