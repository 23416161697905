/* eslint-disable indent */
import React from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import {
  PermMedia,
  Theaters,
  InvertColors,
  DynamicFeed,
  Mic,
  Layers,
} from '@material-ui/icons';

// styles
import { radius, colors } from '../../../styles/variables';
import { Text } from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';

const ParentStyles = styled.button`
  margin-top: 1rem;
  position: relative;
  padding: 1.4rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${radius.default};
  border: 1px solid ${colors.black.default};
  cursor: ${({ pointer }) => pointer && 'pointer'};

  &:hover,
  &:focus {
    border-color: ${({ pointer }) => pointer && colors.white};
  }

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      &:disabled {
        cursor: not-allowed;
      }
    `}

  ${({ gradient, value }) =>
    !gradient &&
    css`
      background-image: ${() => {
        if (value === 'image_editing') {
          return 'linear-gradient(90deg, #f2709c, #ff9472)';
        }

        if (value === 'video_editing') {
          return 'linear-gradient(90deg, #43CEA2, #2F7FD1)';
        }

        if (value === 'colorgrading_editing') {
          return 'linear-gradient(90deg, #6085FF, #C16DC4)';
        }

        if (value === 'gfx_editing') {
          return 'linear-gradient(90deg, #FFA636, #FFDF4A)';
        }

        if (value === 'compositing_editing') {
          return 'linear-gradient(90deg, #1CC05D, #5BADFF)';
        }

        if (value === 'audio_editing') {
          return 'linear-gradient(90deg, #FF8162, #A355A9)';
        }
        return `linear-gradient(90deg, ${whiteAlpha(0.15)}, ${whiteAlpha(
          0.15
        )})`;
      }};
    `};
`;

const Icon = styled.span`
  background-color: ${whiteAlpha(0.2)};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  margin-right: 1rem;

  svg {
    fill: ${colors.white};
    width: 1.7rem;
    height: 1.7rem;
  }
`;

const Head = styled(Text)`
  text-align: left;
`;

const Para = styled(Text)`
  text-align: left;
  margin-top: 0.5rem;
`;

const ListParentStyles = styled.ul`
  list-style-type: none;
  display: flex;
  column-gap: 0.5rem;
`;

const GradientCard = props => {
  const {
    onClick,
    icon,
    label,
    details,
    pointer = false,
    disabled = false,
    ...rest
  } = props;

  const renderIcons = () => {
    let iconComponent = null;
    switch (icon) {
      case 'PermMedia':
        iconComponent = <PermMedia />;
        break;
      case 'Theaters':
        iconComponent = <Theaters style={{ transform: 'rotate(90deg)' }} />;
        break;
      case 'InvertColors':
        iconComponent = <InvertColors />;
        break;
      case 'DynamicFeed':
        iconComponent = <DynamicFeed />;
        break;
      case 'School':
        iconComponent = <Layers />;
        break;
      case 'Mic':
        iconComponent = <Mic />;
        break;
      default:
    }

    return iconComponent;
  };

  return (
    <ParentStyles
      id={label || ''}
      onClick={onClick}
      disabled={disabled}
      pointer={pointer}
      aria-disabled={disabled}
      {...rest}
    >
      {icon && <Icon className='icon'>{renderIcons()}</Icon>}
      <div>
        <Head padding='0' md>
          {label}
        </Head>
        {details && (
          <ListParentStyles>
            {details?.map(el => (
              <li key={el}>
                <Para padding='0' sm>
                  {el}
                </Para>
              </li>
            ))}
          </ListParentStyles>
        )}
      </div>
    </ParentStyles>
  );
};

GradientCard.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  pointer: PropTypes.bool,
  details: PropTypes.arrayOf(PropTypes.string),
};

export default GradientCard;
