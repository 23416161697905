import React from 'react';
import { PropTypes } from 'prop-types';

import noResults from './noResults.png';
import { Normal } from '../../components/Button';

// Styles
import {
  Wrapper,
  ImageWrapper,
  Header,
  SubHeader,
} from '../../styles/errorScreen';

const NoResults = ({ refresh = true }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={noResults} alt='error' />
      </ImageWrapper>
      <SubHeader>No Results Found.</SubHeader>
      {refresh && (
        <>
          <Header>Refresh this page or try again later.</Header>
          <Normal onClick={() => window.location.reload()}>Refresh</Normal>
        </>
      )}
    </Wrapper>
  );
};

NoResults.propTypes = {
  refresh: PropTypes.bool,
};

export default NoResults;
