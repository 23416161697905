/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React from 'react';

// Styles
import { Text } from '../../../styles/common';

const Pricing = props => {
  return (
    <Text padding='0' lineHeight='1.3' md>
      ₹{props?.pricing}
      <span style={{ fontSize: '1.2rem' }}>/ hr</span>
    </Text>
  );
};

Pricing.propTypes = {};

export default Pricing;
