/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { Normal } from '../../components/Button';
import { Checkbox } from '../../components/FormInputs';

// Helper Functions
// import { formatLabelValue } from '../../helperFunctions';
// import { emailRegex } from '../../components/FormInputs/helperFunctions';
// import { requestForIdsEmails } from '../../storyboard/helperFunctions';
import { toggleTeamMembersSidebar } from '../SettingsComponent';
import { teamPermissionsServiceCall } from '../duck/operations';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import {
  Flex,
  AvatarSecondaryTextStyles,
  CustomAvatarStyles,
  AvatarUsernameStyles,
} from '../../styles/common';
import {
  Row,
  AvatarBadgeParent,
  Detail,
} from '../../storyboard/components/styles';

const TextWithCheckbox = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  ${({ is_disabled }) =>
    is_disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}:
`;

const TeamMemberPermission = ({
  team_id,
  toggleSidebar,
  user,
  toggleToast,
}) => {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await teamPermissionsServiceCall({
        team_id,
        user_db_id: user?.user_db_id,
      });

      if (res?.data?.response) {
        setLoading(false);
        const filtered = res?.data?.data?.permissions.reduce(
          (acc, curr) => {
            acc.all[curr.code] = { ...curr };
            if (curr?.is_selected) {
              acc.selected.push(curr?.permission_db_id);
            }
            return acc;
          },
          { all: {}, selected: [] }
        );
        setPermissions({
          ...permissions,
          ...filtered,
        });
      } else {
        setLoading(false);
      }
    })();
  }, []);

  const onBack = () => {
    toggleTeamMembersSidebar({ toggleSidebar, componentProps: { team_id } });
  };

  const handleCheckboxChange = (e, item) => {
    const { checked } = e.target;
    const { selected } = permissions;

    let updated = selected; // making copy
    const copyPermissions = { ...permissions?.all };

    if (checked) {
      copyPermissions[item?.code].is_selected = true;
      if (item.code === 'added_as_team_owner') {
        delete copyPermissions.added_as_team_owner;
        const restPermissions = Object.values(copyPermissions)?.map(el => {
          copyPermissions[el.code].is_selected = true;
          copyPermissions[el.code].is_disabled = true;
          return el?.permission_db_id;
        });
        updated = [item.permission_db_id, ...restPermissions];
      } else {
        updated.push(item?.permission_db_id);
      }
    } else {
      updated = updated.filter(el => el !== item?.permission_db_id);
      copyPermissions[item?.code].is_selected = false;
      copyPermissions[item.code].is_disabled = false;

      if (item.code === 'added_as_team_owner') {
        Object.values(copyPermissions).forEach(el => {
          copyPermissions[el.code].is_disabled = false;
        });
      }
    }

    setPermissions({
      ...permissions,
      selected: updated,
    });
  };

  const updatePermissions = async () => {
    setSubmitting(true);
    const permissionsResponse = await teamPermissionsServiceCall(
      {
        team_id,
        user_db_id: user?.user_db_id,
        permissions: permissions.selected,
      },
      'post'
    );
    const {
      data: { response, errormessage },
    } = permissionsResponse;
    setSubmitting(false);
    if (response) {
      onBack();
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Permissions updated successfully',
          variant: 'success',
        },
      });
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  const isPermissionsAvailable =
    permissions?.all &&
    Object.values(permissions?.all) &&
    Object.values(permissions?.all).length > 0;

  return (
    <>
      <Body>
        <Row>
          <Detail>
            <AvatarBadgeParent>
              <CustomAvatarStyles color={user?.user_badge_color}>
                {user?.name?.charAt(0)}
              </CustomAvatarStyles>
            </AvatarBadgeParent>
            <div>
              <AvatarUsernameStyles>{user?.name}</AvatarUsernameStyles>
              <AvatarSecondaryTextStyles secondary>
                {user?.email}
              </AvatarSecondaryTextStyles>
            </div>
          </Detail>
        </Row>

        <Row style={{ margin: '2rem 0' }}>
          {loading ? (
            <AvatarSecondaryTextStyles>
              Fetching permissions...
            </AvatarSecondaryTextStyles>
          ) : (
            <>
              {isPermissionsAvailable ? (
                Object.values(permissions?.all)?.map(item => {
                  const { title, is_selected, code, is_disabled } = item;
                  return (
                    <TextWithCheckbox
                      key={title}
                      is_disabled={is_disabled ?? false}
                    >
                      <AvatarSecondaryTextStyles
                        secondary
                        style={{ fontSize: '1.4rem' }}
                      >
                        {title}
                      </AvatarSecondaryTextStyles>
                      <Checkbox
                        value={code}
                        checked={is_selected ?? false}
                        disabled={is_disabled ?? false}
                        onChange={e => handleCheckboxChange(e, item)}
                      />
                    </TextWithCheckbox>
                  );
                })
              ) : (
                <AvatarSecondaryTextStyles>
                  No permissions found
                </AvatarSecondaryTextStyles>
              )}
            </>
          )}
        </Row>
      </Body>

      <Footer style={{ columnGap: '2rem' }}>
        <Normal onClick={onBack} color='secondary'>
          Back
        </Normal>
        {isPermissionsAvailable && (
          <Normal onClick={updatePermissions} isLoading={isSubmitting}>
            Update
          </Normal>
        )}
      </Footer>
    </>
  );
};

TeamMemberPermission.propTypes = {
  toggleSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  team_id: PropTypes.string,
  user: PropTypes.instanceOf(Object),
};

export default TeamMemberPermission;
