/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// components
import { Normal } from '../../components/Button';
import Timer from '../../components/timer';

import apiConfig from '../../../config';

// styles
import { SecondaryModalBodyStyles } from '../../components/modal/secondary';
import { fs } from '../../styles/variables';
import { Text } from '../../styles/common';

const PlanUpgrade = `${apiConfig.assets}/plan-upgrade.png`;

const StyledTimer = styled.div`
  .timer-wrapper {
    margin-top: 1rem;
    justify-content: center;

    .time {
      font-size: 1.5rem;
      color: #18b96e;
    }

    .time-tag {
      color: rgba(12, 29, 51, 0.6);
    }
  }
`;

const UpgradeSubscriptionModal = () => {
  const { userDetails } = useSelector(state => state.common);
  return (
    <SecondaryModalBodyStyles>
      <div>
        <img src={PlanUpgrade} alt='Upgrading Subscription' />
        <h2 className='head'>Upgrading Subscription</h2>
        <Text className='text'>
          We are upgrading your subscription. You can use Cloud Systems in:
        </Text>
        {userDetails?.waiting_time && (
          <StyledTimer>
            <Timer />
          </StyledTimer>
        )}
      </div>
      <div className='cta-section'>
        <a
          href='/subscription?sidebar=upgrade-plan'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Normal fontSize={fs.sm}>See Plans</Normal>
        </a>
      </div>
    </SecondaryModalBodyStyles>
  );
};

export default UpgradeSubscriptionModal;
