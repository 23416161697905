/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';
import { Paper } from '@material-ui/core';
import { Add } from '@material-ui/icons';

// Form Inputs
import TextField from './TextField';

// Helper Functions
import { emailRegex } from './helperFunctions';

// styles
import { colors, padding, fs } from '../../styles/variables';
import { Flex, Text } from '../../styles/common';
import { blackAlpha } from '../../styles/utils';

export const MaterialAutocompleteStyles = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    width: 100%;

    .MuiFilledInput-root {
      padding: ${padding.input};
      padding-left: 0.9rem !important;
      padding-right: 0.9rem !important;
      .MuiFilledInput-input {
        padding: 0 0.3rem !important;
      }
    }

    .MuiAutocomplete-tag {
      background-color: ${colors.blue.shadeTwo};
      font-size: 1.4rem;
      margin: 0.3rem;

      .MuiChip-label {
        padding: 1rem;
      }

      .MuiChip-deleteIcon {
        width: 2rem;
        height: 2rem;
        color: ${blackAlpha(0.5)};
      }

      &:hover {
        background-color: ${colors.blue.secondary};
        .MuiChip-deleteIcon {
          color: ${colors.white};
        }

        .MuiChip-label {
          color: ${colors.white};
        }
      }
    }
  }
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    background-color: ${colors.white};
    font-size: ${fs.sm};
    box-shadow: none;
    color: ${colors.black.default};
  }
`;

export const StyledUser = styled.div`
  position: relative;
  padding: 0;

  ${Flex} {
    position: relative;
    align-items: center;
    justify-content: space-between;

    .icon {
      display: inline-block;
      margin-right: ${padding.v};
      background-color: ${colors.blue.secondary};
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: ${colors.white};
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

const User = ({ label, username, ...other }) => {
  // User - Username, Email, Tag
  if (other?.new) {
    return (
      <StyledUser>
        <Flex>
          <span className='icon'>
            <Add />
          </span>
          <Text color={colors.black.default} className='py-0'>
            Invite this user to Postudio
          </Text>
        </Flex>
      </StyledUser>
    );
  }
  return (
    <StyledUser>
      <Text color={colors.black.default} className='py-0'>
        {label}
      </Text>
      <Text color={blackAlpha(0.7)} className='py-0'>
        {username}
      </Text>
    </StyledUser>
  );
};

const AutoComplete = props => {
  const filter = createFilterOptions();
  const {
    label,
    error,
    helperText,
    onChange,
    filteredValue,
    invite = true,
    ...otherProps
  } = props;
  return (
    <MaterialAutocompleteStyles
      {...otherProps}
      onChange={onChange}
      popupIcon={null}
      PaperComponent={paperProps => <StyledPaper {...paperProps} />}
      getOptionLabel={option => option?.label}
      getOptionSelected={(option, value) => option.value === value.value}
      disableClearable
      autoHighlight
      filterSelectedOptions
      noOptionsText='No user found'
      renderOption={option => {
        if (option.title) {
          return option.title;
        }
        return <User {...option} />;
      }}
      filterOptions={(options, params) => {
        const value = filteredValue?.trim() || params?.inputValue?.trim();
        let filtered = filter(options, params);
        if (invite && emailRegex(value) && !options.length) {
          filtered.push({
            title: <User label={value} new />,
            label: value,
            value,
          });
        } else {
          filtered = options;
        }
        return filtered;
      }}
      renderInput={params => (
        <TextField
          {...params}
          onChange={onChange}
          label={label}
          error={error}
          helperText={helperText}
          fullWidth
        />
      )}
    />
  );
};

AutoComplete.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  multiple: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.shape([]).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  invite: PropTypes.bool,
  filteredValue: PropTypes.string,
};

export default AutoComplete;
