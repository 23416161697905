/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { Text } from '../../../styles/common';
import { CloudScreenHead } from './styles';

const Preparing = props => {
  const {
    type,
    cloudAccessDetails,
    config,
    closeSidebar,
    selectedSoftware,
  } = props;

  const details =
    cloudAccessDetails &&
    (cloudAccessDetails.machine_status === 'machine_booting' ||
      cloudAccessDetails.machine_status === 'machine_started')
      ? cloudAccessDetails
      : config;

  const {
    configurations: { pricing },
    cloud_assignment_id,
  } = details;

  return (
    <>
      <Body>
        <GradientCard
          {...type}
          details={[selectedSoftware?.title, `₹${pricing} / hr`]}
        />

        <CloudScreenHead bg>
          You will need the Postudio connector app to launch your session when
          its ready
        </CloudScreenHead>

        <Text>Cloud ticket reference no. - {cloud_assignment_id}</Text>
      </Body>

      <Footer>
        <Normal onClick={closeSidebar}>Close</Normal>
      </Footer>
    </>
  );
};

Preparing.propTypes = {
  config: PropTypes.instanceOf(Object),
  cloudAccessDetails: PropTypes.instanceOf(Object),
  selectedSoftware: PropTypes.instanceOf(Object),
  type: PropTypes.instanceOf(Object),
  closeSidebar: PropTypes.func,
};

export default Preparing;
