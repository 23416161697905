/* eslint-disable indent */
import types from './types';

const INITIAL_STATE = {
  userDetails: null,
  selectedTeam: null,
  teams: null,
  permissions: null,
  sidebar: {},
  uploadFiles: [],
  upgradePlan: {},
  productTourSkipDonePages: null,
  isEditor: false,
  requestedCloudSystemDetails: null,
};

const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TEAM: {
      return {
        ...state,
        selectedTeam: {
          ...state?.selectedTeam,
          ...action.payload,
        },
      };
    }

    case types.SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload?.user_details,
        teams: action.payload?.teams,
        permissions: action.payload?.permissions,
      };
    }

    case types.UPDATE_USER_TEAM: {
      let updated = state?.teams;
      if (action?.payload?.key === 'team_delete') {
        updated = state?.teams.filter(
          item =>
            (item?.id || item?.team_db_id) !== action?.payload?.data?.team_db_id
        );
        localStorage.setItem(
          'team_db_id',
          state?.teams[0]?.id || state?.teams[0].team_db_id
        );
      } else if (action?.payload?.key === 'team_update') {
        const index = state?.teams.findIndex(
          item =>
            (item?.id || item?.team_db_id) === action?.payload?.data?.team_db_id
        );
        updated[index < -1 ? 0 : index] = { ...action?.payload?.data };
      } else {
        updated.push(action?.payload?.data);
      }
      return {
        ...state,
        teams: updated,
        selectedTeam: {
          ...updated[0],
        },
      };
    }

    case types.TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebar: action.payload,
      };
    }

    case types.TOGGLE_UPGRADE_MODAL: {
      return {
        ...state,
        upgradePlan: action.payload,
      };
    }

    case types.SET_UPLOAD_FILE: {
      const fileObj = { ...action.payload };
      fileObj.abort = false;
      fileObj.pause = false;
      fileObj.status = 'pending';
      fileObj.percentage = 0;
      fileObj.completed = 0;
      fileObj.total = 0;
      return {
        ...state,
        uploadFiles: [fileObj, ...state.uploadFiles],
      };
    }

    case types.UPDATE_UPLOAD_FILE: {
      const filesArr = Object.assign([], state.uploadFiles);
      const index = filesArr.findIndex(file => file.ingest_db_id === action.id);
      if (index > -1) {
        filesArr[index] = {
          ...filesArr[index],
          ...action.data,
        };
      }
      return {
        ...state,
        uploadFiles: filesArr,
      };
    }

    case types.REMOVE_UPLOAD_FILE: {
      const { id, key } = action.payload;
      const filesArr = Object.assign([], state.uploadFiles);
      const index = filesArr.findIndex(file => file.ingest_db_id === id);
      if (index > -1) {
        if (!key) {
          // remove the file
          filesArr.splice(index, 1);
        } else if (key === 'abort') {
          // abort the file
          filesArr[index].abort = true;
        }
      }
      return {
        ...state,
        uploadFiles: filesArr,
      };
    }

    case types.TOGGLE_EDITOR: {
      const copy = { ...state };
      copy.isEditor = !copy.isEditor;
      return copy;
    }

    case types.LOAD_REQUESTED_CLOUD_SYSTEM_DETAILS: {
      return {
        ...state,
        requestedCloudSystemDetails: action.payload,
      };
    }

    case types.LOAD_PRODUCT_TOUR_SKIP_DONE: {
      return {
        ...state,
        productTourSkipDonePages: action?.payload,
      };
    }

    case types.RESET_COMMON_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default commonReducer;
