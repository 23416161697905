import * as types from './types';

const actionLoadSubscriptionDetails = (payload) => {
  return {
    type: types.LOAD_SUBSCRIPTON_DETAILS,
    payload,
  };
};

const actionLoadCurrentPlanDetails = (payload) => {
  return {
    type: types.LOAD_CURRENT_PLAN_DETAILS,
    payload,
  };
};

const actionLoadAvailablePlans = (payload) => {
  return {
    type: types.LOAD_AVAILABLE_PLANS,
    payload,
  };
};

const actionLoadTabData = (payload) => {
  return {
    type: types.LOAD_TAB_DATA,
    payload,
  };
};

const actionLoadSelectedPlan = (payload) => {
  return {
    type: types.LOAD_SELECTED_PLAN,
    payload,
  };
};

const actionUpdateCreditsAvailable = (payload) => {
  return {
    type: types.UPDATE_CREDITS_AVAILABLE,
    payload,
  };
};

export default {
  actionLoadSubscriptionDetails,
  actionLoadCurrentPlanDetails,
  actionLoadAvailablePlans,
  actionLoadTabData,
  actionLoadSelectedPlan,
  actionUpdateCreditsAvailable,
};
