import { randomBytes, secretbox } from 'tweetnacl';
import { encodeBase64, decodeBase64, encodeUTF8 } from 'tweetnacl-util';

const SECRET_KEY = Buffer.from(process.env.ENCRYPTION_KEY, 'utf8');

const getEncrypted = data => {
  const nonce = randomBytes(24);
  const secretData = Buffer.from(data, 'utf8');
  const encryptedData = secretbox(secretData, nonce, SECRET_KEY);
  return `${encodeBase64(nonce)}:${encodeBase64(encryptedData)}` ?? '';
};

export const getDecrypted = data => {
  const str = data?.split(':');
  const message = decodeBase64(str[0]);
  const nonce = message.slice(0, 24);
  const ciphertext = message.slice(24);
  return encodeUTF8(secretbox.open(ciphertext, nonce, SECRET_KEY));
};

export default getEncrypted;
