/* eslint-disable react/no-unused-prop-types */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { ArrowBackOutlined, MoreHoriz, FolderOpen } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Normal, Fab } from '../../components/Button';
import StepsCarousel from '../../components/stepsCarousel';
import { useForm } from '../../components/FormInputs';
import CustomMenu from '../../components/menu';
import {
  getSourceDestinationFolders,
  getCameraUploadFilesListService,
} from '../duck/operations';
import { getTeamStorageService } from '../../subscription/duck/operations';
import { enqueueSnackbar } from '../../snackbar/duck/actions';
import { formatLabelValue } from '../../helperFunctions';

import { Body, Footer } from '../../styles/sidebar';
import { Text, IconBreadCrumbStyles } from '../../styles/common';
import { colors, font } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const BreadcrumbStyles = styled(IconBreadCrumbStyles)`
  margin-bottom: 1.5rem;

  .project-btn {
    background: transparent;
    border: none;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }

    &:not:([disabled]) {
      p {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .options {
    .MuiSvgIcon-root {
      margin-left: 0.5rem;
      width: 2.2rem;
      height: 2.2rem;
      fill: ${colors.white};
    }
  }

  .MuiFab-root {
    width: 2.5rem;
    height: 2.5rem;

    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    padding: 0;
    margin-left: 0.5rem;
  }
`;

const FoldersParentListStyles = styled.ul`
  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  > li {
    align-items: start;
    button {
      background: transparent;
      text-align: left;
      cursor: pointer;
      border: none;
      padding: 0.5rem 0;
      color: ${colors?.white};
      font-weight: ${font.normal};
      &:hover {
        color: ${colors?.blue?.primary};
      }
    }
  }
`;

const DestinationStyles = styled.div`
  margin-top: 1rem;
  border-top: 0.1rem solid ${whiteAlpha(0.3)};
`;

const INITIAL_STATE = {
  sourceFolder: {
    value: '',
    error: undefined,
  },

  destinationFolder: {
    value: '',
    error: undefined,
  },
};

const FOLDER_TYPES = {
  source: 'source',
  destination: 'destination',
};

export default function FetchToUploadRawFiles() {
  const { project_db_id } = useParams();
  const dispatch = useDispatch();
  const common = useSelector(store => store?.common);
  const storyboard = useSelector(store => store?.storyboard);
  const [selectedSourceFolders, selectSourceFolders] = useState([]);
  const [sourceFolderOptions, setSourceFolderOptions] = useState([]);

  const [selectedDestinationFolders, selectDestinationFolders] = useState([]);
  const [destinationFolderOptions, setDestinationFolderOptions] = useState([]);

  const onLaunch = async formValues => {
    try {
      const {
        data: { data, response, errormessage },
      } = await getTeamStorageService({
        project_db_id,
        team_id: storyboard?.details?.team_db_id,
      });

      if (response) {
        if (!data?.is_subscription_free) {
          const domain = `${window.location.origin}/`;
          const token = window.localStorage.getItem('Authorization');
          const folders = selectedDestinationFolders
            .map(folder => folder?.label)
            .join('/');
          const foldersPath = folders && `/${folders}`;
          const href = `ps-uploader://breadcrumbs=${
            storyboard?.details?.title
          }${foldersPath.trim()}&teamDbId=${
            storyboard?.details?.team_db_id
          }&projectDbId=${storyboard?.details?.project_db_id}&folderDbId=${
            formValues?.destinationFolder?.value?.value
          }&token=${token}&uploadedBy=${
            common?.userDetails?.user_id
          }&domain=${domain}&sourceFolderDbId=${
            formValues?.sourceFolder?.value?.value
          }`;
          window.location.href = href;
        }
      } else {
        throw new Error(errormessage);
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: {
            messageHead: 'Error',
            messageBody: err?.errormessage,
            variant: 'error',
          },
        })
      );
    }
  };

  const { formValues, updateValues, onSubmit } = useForm(
    INITIAL_STATE,
    async () => {
      await getCameraUploadFilesListService({
        project_db_id,
        folder_db_id: formValues?.sourceFolder?.value?.value,
      })
        .then(res => {
          if (res?.data?.response && res?.data?.data?.files?.length > 0) {
            onLaunch(formValues);
            return;
          }
          throw new Error();
        })
        .catch(() => {
          dispatch(
            enqueueSnackbar({
              message: {
                messageHead: 'Error',
                messageBody:
                  'There are no files in the source folder. Please select another source folder.',
                variant: 'error',
              },
            })
          );
        });
    }
  );

  const onFetchFolders = async (id, type) => {
    try {
      const {
        data: { response, data, errormessage },
      } = await getSourceDestinationFolders({
        project_db_id,
        ...(id && { folder_db_id: id }),
      });
      if (response) {
        const options = formatLabelValue(data?.results, 'path', 'folder_db_id');
        if (!type) {
          setSourceFolderOptions(options);
          setDestinationFolderOptions(options);
          return;
        }

        if (type === FOLDER_TYPES.source) {
          setSourceFolderOptions(options);
          return;
        }

        if (type === FOLDER_TYPES.destination) {
          setDestinationFolderOptions(options);
          return;
        }
      }

      throw new Error(errormessage);
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: {
            messageHead: 'Error',
            messageBody: err?.errormessage,
            variant: 'error',
          },
        })
      );
    }
  };

  useEffect(() => {
    onFetchFolders();
  }, []);

  const sourceFolderMenuOptions = useMemo(() => {
    const copy = [...selectedSourceFolders];
    return copy.slice(0, -1).map((el, index) => ({
      index: index + 1,
      name: el.label,
      field: el.value,
      isSelected: false,
      icon: <FolderOpen style={{ marginRight: '0.5rem' }} />,
    }));
  }, [selectedSourceFolders]);

  const destinationFolderMenuOptions = useMemo(() => {
    const copy = [...selectedDestinationFolders];
    return copy.slice(0, -1).map((el, index) => ({
      index: index + 1,
      name: el.label,
      field: el.value,
      isSelected: false,
      icon: <FolderOpen style={{ marginRight: '0.5rem' }} />,
    }));
  }, [selectedDestinationFolders]);

  const onBreadcrumbProject = (e, value, folderType) => {
    onFetchFolders(value?.value, folderType);

    if (folderType === FOLDER_TYPES.source) {
      selectSourceFolders([value]);
    }

    if (folderType === FOLDER_TYPES.destination) {
      selectDestinationFolders([value]);
    }

    updateValues({
      ...formValues,
      ...(folderType === FOLDER_TYPES.source && {
        sourceFolder: {
          ...formValues.sourceFolder,
          value: '',
        },
      }),

      ...(folderType === FOLDER_TYPES.destination && {
        destinationFolder: {
          ...formValues.destinationFolder,
          value: '',
        },
      }),
    });
  };

  const onFolderSelect = useCallback(
    (option, add, folderType) => {
      const updatedOption = {
        label: option?.name || option?.label || '',
        value: option?.value || option?.field || '',
      };

      updateValues({
        ...formValues,

        ...(folderType === FOLDER_TYPES.source && {
          sourceFolder: {
            ...formValues.sourceFolder,
            value: updatedOption,
            error: undefined,
          },
        }),

        ...(folderType === FOLDER_TYPES.destination && {
          destinationFolder: {
            ...formValues.destinationFolder,
            value: updatedOption,
            error: undefined,
          },
        }),
      });

      onFetchFolders(option?.value || option?.field, folderType);

      if (!add) {
        if (option?.index?.toString()) {
          const updated = option.index - 1;
          if (updated || updated.toString() === '0') {
            if (folderType === FOLDER_TYPES.source) {
              selectSourceFolders(selectedSourceFolders.slice(0, option.index));
            } else if (folderType === FOLDER_TYPES.destination) {
              selectDestinationFolders(
                selectedDestinationFolders.slice(0, option.index)
              );
            }
          } else if (folderType === FOLDER_TYPES.source) {
            selectSourceFolders(selectedSourceFolders.slice(0, -1));
          } else {
            selectDestinationFolders(selectedDestinationFolders.slice(0, -1));
          }
        } else if (folderType === FOLDER_TYPES.source) {
          selectSourceFolders(selectedSourceFolders.slice(0, -1));
        } else {
          selectDestinationFolders(selectedDestinationFolders.slice(0, -1));
        }
      } else if (folderType === FOLDER_TYPES.source) {
        selectSourceFolders(prev => [...prev, option]);
      } else {
        selectDestinationFolders(prev => [...prev, option]);
      }
    },
    [selectedSourceFolders, selectedDestinationFolders]
  );

  const onBack = useCallback(
    (e, folderType) => {
      const copy = [
        ...(folderType === FOLDER_TYPES.source
          ? selectedSourceFolders
          : selectedDestinationFolders),
      ];

      const lastTwoElement = copy.slice(-2);
      onFolderSelect(
        lastTwoElement?.length === 2 ? lastTwoElement[0] : undefined,
        '',
        folderType
      );
    },
    [selectedSourceFolders, selectedDestinationFolders]
  );

  return (
    <>
      <Body style={{ paddingRight: '1rem' }}>
        <StepsCarousel
          options={[
            'Select the source folder to fetch the proxy assets and select the destination folder where you want to upload the original assets.',
            'aunch the Uploader App once the source and the destination folder is selected.',
            'Drag & Drop the raw files/folder on the Uploader App to upload the original assets.',
          ]}
        />

        <div aria-label='source-folders'>
          <Text padding='1rem 0' color={whiteAlpha(0.7)}>
            Select Source Folder
          </Text>

          {(formValues?.sourceFolder?.value?.value ||
            selectedSourceFolders?.length > 0) && (
            <BreadcrumbStyles>
              <Fab
                type='button'
                onClick={e => onBack(e, FOLDER_TYPES.source)}
                size='small'
                color='primary'
                aria-label='back'
                icon={<ArrowBackOutlined />}
              />

              <button
                type='button'
                disabled={selectedSourceFolders?.length === 1}
                className='project-btn'
                onClick={e =>
                  onBreadcrumbProject(
                    e,
                    selectedSourceFolders[0],
                    FOLDER_TYPES.source
                  )
                }>
                <span>
                  <Text color={whiteAlpha(0.7)}>
                    {selectedSourceFolders[0]?.label}
                  </Text>
                </span>
              </button>

              {selectedSourceFolders?.length > 2 && (
                <>
                  <Text>/</Text>
                  <div className='options'>
                    <CustomMenu
                      maxHeight='20rem'
                      menuItemTextTransform={false}
                      onMenuItemClick={item =>
                        onFolderSelect(item, '', FOLDER_TYPES.source)
                      }
                      menuList={sourceFolderMenuOptions}
                      icon={<MoreHoriz />}
                    />
                  </div>
                </>
              )}
              {selectedSourceFolders?.length > 1 && (
                <>
                  <Text>/</Text>
                  <Text>
                    {
                      selectedSourceFolders[selectedSourceFolders?.length - 1]
                        ?.label
                    }
                  </Text>
                </>
              )}
            </BreadcrumbStyles>
          )}

          {sourceFolderOptions?.length > 0 && (
            <>
              <FoldersParentListStyles>
                {sourceFolderOptions?.map(el => (
                  <li className='flex'>
                    <button
                      className='flex items-center w-full'
                      type='button'
                      key={el?.value}
                      value={el?.value}
                      onClick={() =>
                        onFolderSelect(el, 'push', FOLDER_TYPES.source)
                      }>
                      <span>
                        <FolderOpen style={{ marginRight: '0.5rem' }} />
                      </span>
                      <span>{el?.label}</span>
                    </button>
                  </li>
                ))}
              </FoldersParentListStyles>
            </>
          )}
        </div>

        <DestinationStyles
          aria-label='destination-folders'
          className='destination-section'>
          <Text padding='1rem 0' color={whiteAlpha(0.7)}>
            Select Destination Folder
          </Text>

          {(formValues?.destinationFolder?.value?.value ||
            selectedDestinationFolders?.length > 0) && (
            <BreadcrumbStyles>
              <Fab
                type='button'
                onClick={e => onBack(e, FOLDER_TYPES.destination)}
                size='small'
                color='primary'
                aria-label='back'
                icon={<ArrowBackOutlined />}
              />

              <button
                type='button'
                disabled={selectedDestinationFolders?.length === 1}
                className='project-btn'
                onClick={e =>
                  onBreadcrumbProject(
                    e,
                    selectedDestinationFolders[0],
                    FOLDER_TYPES.destination
                  )
                }>
                <span>
                  <Text color={whiteAlpha(0.7)}>
                    {selectedDestinationFolders[0]?.label}
                  </Text>
                </span>
              </button>

              {selectedDestinationFolders?.length > 2 && (
                <>
                  <Text>/</Text>
                  <div className='options'>
                    <CustomMenu
                      maxHeight='20rem'
                      menuItemTextTransform={false}
                      onMenuItemClick={item =>
                        onFolderSelect(item, '', FOLDER_TYPES.destination)
                      }
                      menuList={destinationFolderMenuOptions}
                      icon={<MoreHoriz />}
                    />
                  </div>
                </>
              )}
              {selectedDestinationFolders?.length > 1 && (
                <>
                  <Text>/</Text>
                  <Text>
                    {
                      selectedDestinationFolders[
                        selectedDestinationFolders?.length - 1
                      ]?.label
                    }
                  </Text>
                </>
              )}
            </BreadcrumbStyles>
          )}

          {destinationFolderOptions?.length > 0 && (
            <>
              <FoldersParentListStyles>
                {destinationFolderOptions?.map(el => (
                  <li className='flex'>
                    <button
                      className='flex items-center w-full'
                      type='button'
                      key={el?.value}
                      value={el?.value}
                      onClick={() =>
                        onFolderSelect(el, 'push', FOLDER_TYPES.destination)
                      }>
                      <span>
                        <FolderOpen style={{ marginRight: '0.5rem' }} />
                      </span>
                      <span>{el?.label}</span>
                    </button>
                  </li>
                ))}
              </FoldersParentListStyles>
            </>
          )}
        </DestinationStyles>
      </Body>
      <Footer>
        {formValues?.sourceFolder?.value?.value &&
          formValues?.destinationFolder?.value?.value && (
            <Normal type='button' onClick={onSubmit}>
              Launch Uploader App
            </Normal>
          )}
      </Footer>
    </>
  );
}

FetchToUploadRawFiles.propTypes = {
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  view: PropTypes.string,
  match: PropTypes.instanceOf(Object),
  pusher: PropTypes.instanceOf(Object),
};
