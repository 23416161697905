/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../components/Button';

// Styles
import { Body, Footer } from '../../styles/modal';
import { Text } from '../../styles/common';

const DeleteTask = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    toggleModal,
    toggleToast,
    createUpdateFetchTask,
    toggleSidebar,
    loadSelectedTaskDetails,
    assetDetails: { task_db_id, title },
  } = props;

  const handleDelete = async () => {
    setSubmitting(true);
    const deleteResponse = await createUpdateFetchTask(
      {
        task_db_id,
        message: 'Deleted',
      },
      'delete'
    );

    const {
      data: { response, errormessage },
    } = deleteResponse;
    setSubmitting(false);
    if (response) {
      loadSelectedTaskDetails({});
      toggleModal(null, false, null);
      toggleSidebar({ show: false });
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Task deleted successfully',
          variant: 'success',
        },
      });
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <Text padding='0' md>
          {title}
        </Text>
      </Body>

      <Footer>
        <Normal
          onClick={() => {
            toggleModal(null, false, null);
            loadSelectedTaskDetails({});
          }}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          No
        </Normal>
        <Normal onClick={handleDelete} isLoading={isSubmitting}>
          Yes
        </Normal>
      </Footer>
    </>
  );
};

DeleteTask.propTypes = {
  toggleModal: PropTypes.func,
  assetDetails: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  createUpdateFetchTask: PropTypes.func,
  loadSelectedTaskDetails: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

export default DeleteTask;
