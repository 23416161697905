import * as types from './types';

const defaultState = {
  snackbarList : [],
};

const snackbarReducer = (state = defaultState, action) => {
  switch (action.type) {
  case types.ENQUEUE_SNACKBAR:
    if(action.snackbar && action.snackbar.message && typeof action.snackbar.message === 'object'){
      action.snackbar.message = {
        ...action.snackbar.message,
        type: action.snackbar.message.type? action.snackbar.message.type : 'toast', // String default value 'toast'
        variant: action.snackbar.message.variant? action.snackbar.message.variant : (action.snackbar.options && action.snackbar.options.variant ? action.snackbar.options.variant : 'info'), // String default value 'info'
      };
    }else if(action.snackbar && action.snackbar.message && typeof action.snackbar.message === 'string'){
      action.snackbar.message = {
        messageBody: action.snackbar.message,
        type: 'toast', // String default value 'toast'
        variant: action.snackbar.options && action.snackbar.options.variant ? action.snackbar.options.variant : 'info', // 
      };
    }
    return {
      ...state,
      snackbarList: [
        ...state.snackbarList,
        {
          key: action.key,
          ...action.snackbar,
        },
      ],
    };

  case types.CLOSE_SNACKBAR:
    return {
      ...state,
      snackbarList: state.snackbarList.map((snackbar) => (
        (action.dismissAll || snackbar.key === action.key)
          ? { ...snackbar, dismissed: true }
          : { ...snackbar }
      )),
    };

  case types.REMOVE_SNACKBAR:
    return {
      ...state,
      snackbarList: state.snackbarList.filter(
        (snackbar) => snackbar.key !== action.key,
      ),
    };

  default:
    return state;
  }
};

export default snackbarReducer;

