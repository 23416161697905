/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Chip as MaterialChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import { colors, padding, fs } from '../../styles/variables';
import { blackAlpha } from '../../styles/utils';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.blue.shadeTwo,
    width: 'auto',
    height: 'auto',

    '&:hover': {
      backgroundColor: colors.blue.secondary,

      '& .MuiChip-deleteIcon': {
        color: colors.white,
      },

      '& .MuiChip-label': {
        color: colors.white,
      },
    },

    '& .MuiChip-label': {
      padding: padding.container,
      color: colors.black.default,
      fontSize: fs.xs,
    },

    '& .MuiChip-deleteIcon': {
      color: blackAlpha(0.26),
    }
  },
});

const Chip = (props) => {
  const classes = useStyles(props);

  return (
    <MaterialChip
      {...props}
      color="primary"
      className={classes.root}
    />
  );
};

export default Chip;