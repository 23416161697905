import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { ImportExport } from '@material-ui/icons';

import { getImportAssetStatusService } from '../duck/operations';
import { setImportAssetStatus } from '../duck/actions';
import CustomMenu from '../../components/menu';
import InfiniteScroll from '../../components/infiniteScroll';

import { Text } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { ActionButtonStyles } from './styles';

const TransfersListStyles = styled.ul`
  list-style-type: none;
  li {
    padding: 1.3rem 1.5rem;
    &:not(:last-child) {
      border-bottom: 0.1rem solid ${whiteAlpha(0.2)};
    }

    p {
      padding: 0;
    }
  }
`;

const TransferDataStatus = ({ match: { params } }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dispatch = useDispatch();
  const { transfers } = useSelector(state => state.storyboard);
  const selectedTeam = useSelector(store => store.common?.selectedTeam);

  const commonRequest = {
    team_db_id: selectedTeam?.team_db_id,
    project_db_id: params?.project_db_id,
    entries_per_page: 25,
  };

  useEffect(() => {
    const getImportStatus = async () => {
      const res = await getImportAssetStatusService({
        ...commonRequest,
        page_no: 1,
      });

      if (res?.data?.response) {
        if (res?.data?.data?.show_notification) {
          dispatch(setImportAssetStatus({ data: res?.data?.data }));
        }
      }
    };

    getImportStatus();
  }, []);

  const onScrollLoad = async () => {
    if (transfers?.transfers?.length < transfers?.total_entries) {
      setIsLoadingMore(true);
      const res = await getImportAssetStatusService({
        ...commonRequest,
        page_no: transfers?.page_no + 1,
      });

      if (res?.data?.response) {
        setIsLoadingMore(false);
        dispatch(
          setImportAssetStatus({ type: 'pagination', data: res?.data?.data })
        );
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  if (transfers?.transfers?.length > 0) {
    return (
      <CustomMenu
        maxHeight='30rem'
        icon={
          <ActionButtonStyles role='presentation'>
            <ImportExport />
            <Text>Transfers</Text>
          </ActionButtonStyles>
        }
        customMenu={
          <>
            <TransfersListStyles>
              <InfiniteScroll
                isLoading={isLoadingMore}
                loadAssets={onScrollLoad}>
                {transfers?.transfers?.map((el, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Text style={{ maxWidth: '30rem' }} paddingBottom='0.5rem'>
                      {el?.title}
                    </Text>
                    <Text xs color={whiteAlpha(0.8)}>
                      <span>
                        {el?.status} |{' '}
                        {el?.share_from_ps ? 'Shared' : 'Imported'}
                      </span>
                      <span>
                        {el?.transfer_type && `| ${el?.transfer_type}`}
                      </span>
                    </Text>
                  </li>
                ))}
              </InfiniteScroll>
              {isLoadingMore && (
                <Text
                  color={whiteAlpha(0.8)}
                  style={{ textAlign: 'center' }}
                  sm>
                  Loading more...
                </Text>
              )}
            </TransfersListStyles>
          </>
        }
      />
    );
  }

  return null;
};

TransferDataStatus.propTypes = {
  match: PropTypes.instanceOf(Object),
};

export default withRouter(TransferDataStatus);
