import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Select, useForm } from '../../../components/FormInputs';
import StepsCarousel from '../../../components/stepsCarousel';
import { Normal } from '../../../components/Button';
import {
  getCameraModelService,
  getCameraFoldersService,
  cameraSessionsService,
} from '../../duck/operations';
import { enqueueSnackbar } from '../../../snackbar/duck/actions';
import { CAMERA_TO_EDIT_SESSION_STEPS, TAB_KEYS } from '../../constants';
import { required } from '../../../components/FormInputs/helperFunctions';
import { formatLabelValue } from '../../../helperFunctions';
import { Body, Footer } from '../../../styles/sidebar';
import { AnchorLinkStyles, Text } from '../../../styles/common';

const INITIAL_STATE = {
  model: {
    value: '',
    error: undefined,
    required: true,
    validation: required,
  },
  folder: {
    value: '',
    error: undefined,
    required: false,
  },
};

export default function NewSession({ goTo }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [cameraModelOptions, setCameraModelOptions] = useState([]);
  const [destinationFolderOptions, setDestinationFolderOptions] = useState([]);
  const { project_db_id } = useParams();

  useEffect(() => {
    const getCameraModels = async () => {
      try {
        const res = await getCameraModelService({
          project_db_id,
        });
        setCameraModelOptions(res?.data?.data?.camera_details ?? []);
      } catch (err) {
        console.log('🚀 ~ getCameraModels ~ err:', err);
      }
    };

    const getFolders = async () => {
      try {
        const res = await getCameraFoldersService({
          project_db_id,
        });
        const updated = formatLabelValue(
          res?.data?.data?.folders,
          'path',
          'folder_db_id'
        );
        setDestinationFolderOptions(
          [{ label: 'None', value: '' }, ...updated] ?? []
        );
      } catch (err) {
        console.log('🚀 ~ getFolders ~ err:', err);
      }
    };
    getCameraModels();
    getFolders();
  }, []);

  const { formValues, onChange, onSubmit } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);

      try {
        const res = await cameraSessionsService(
          {
            camera_model: formValues?.model?.value,
            folder_db_id: formValues?.folder?.value,
            project_db_id,
          },
          'post'
        );

        if (res?.data?.response) {
          history.replace(
            `${location.pathname}?tab=${TAB_KEYS.cameraToEdit}&s=${res?.data?.data?.c2e_session?.c2e_session_db_id}`
          );

          setTimeout(() => {
            setSubmitting(false);
            goTo(CAMERA_TO_EDIT_SESSION_STEPS.details);
            dispatch(
              enqueueSnackbar({
                message: {
                  messageHead: 'Success',
                  messageBody:
                    res?.data?.data?.message || 'C2E session initiated',
                  variant: 'success',
                },
              })
            );
          }, 1000);

          return;
        }
        throw new Error(res?.data?.errormessage);
      } catch (err) {
        setSubmitting(false);
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          })
        );
      }
    }
  );

  return (
    <>
      <Body>
        <StepsCarousel
          options={[
            'Select the camera model and the destination folder where the assets need to be uploaded.',
            'Once the C2E session is created, share the session details with the camera person.',
            'Assets will start uploading once the camera is connected to Postudio C2E.',
            () => (
              <Text padding='0'>
                Download the{' '}
                <span>
                  <AnchorLinkStyles href='https://bit.ly/c2e-cert'>
                    certificate file
                  </AnchorLinkStyles>
                </span>{' '}
                and keep it inside the memory card(SDXC) root directory.
              </Text>
            ),
          ]}
        />
        <Select
          id='model'
          label='Camera Model'
          value={formValues.model.value}
          onChange={onChange}
          error={!!formValues.model.error}
          helperText={formValues.model.error}
          options={cameraModelOptions}
        />

        <Select
          id='folder'
          label='Destination Folder (Optional)'
          value={formValues.folder.value}
          onChange={onChange}
          error={!!formValues.folder.error}
          helperText={formValues.folder.error}
          options={destinationFolderOptions}
        />
      </Body>

      <Footer>
        <Normal
          type='submit'
          style={{ marginRight: '2rem' }}
          onClick={() => goTo(CAMERA_TO_EDIT_SESSION_STEPS.list)}
          color='secondary'>
          Back
        </Normal>

        <Normal type='submit' onClick={onSubmit} isLoading={isSubmitting}>
          {isSubmitting ? 'Creating...' : 'Create'}
        </Normal>
      </Footer>
    </>
  );
}

NewSession.propTypes = {
  goTo: PropTypes.func,
  // toggleSidebar: PropTypes.func,
};
