/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Delete,
  Settings,
  Person,
  Headset,
  HelpOutline,
} from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Utils
import Mixpanel from '../../../../utils/mixpanel';

// Components
import { AutoComplete } from '../../../components/FormInputs';
import { Normal } from '../../../components/Button';

// Constants
import sidebarTypes from '../../../components/Sidebar/constants';

// Helper Functions
import { emailRegex } from '../../../components/FormInputs/helperFunctions';
import { formatLabelValue } from '../../../helperFunctions';
import { requestForIdsEmails } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import {
  Flex,
  TextButton,
  CustomAvatarStyles,
  AvatarUsernameStyles,
  AvatarSecondaryTextStyles,
  BadgeStyles,
  Text,
} from '../../../styles/common';
import { Row, User, Detail, AvatarBadgeParent, IconButton } from '../styles';
import Tooltip from '../../../components/tooltip';

const UserNameStyles = styled(AvatarUsernameStyles)`
  max-width: 20rem;
`;

export const StatusStyles = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  background-color: ${({ bgColor }) => bgColor || '#aaa'};
`;

const TASK_STATUS = {
  in_progress: '#FFD700',
  pending: '#A5CCFF',
  overdue: '#FF6C56',
  completed: '#18B96E',
};

const Collaborate = props => {
  const [state, setState] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [eligibleUsers, setEligibleUsers] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    toggleSidebar,
    closeSidebar,
    members,
    getUsersBasedOnFilters,
    loadCollaborators,
    updateCollaboratedUsers,
    userDetails,
    creatorDetails,
    match: { params },
    toggleToast,
    toggleUpgradeModal,
  } = props;

  const users = members && Object.keys(members).length > 0 ? members : {};
  const { creator, collaborators, admins } = users;

  const userNameWithTooltip = name => {
    return (
      <Tooltip
        title={name.trim().length > 28 ? name : ''}
        hide
        placement='top'
        arrow
      >
        <UserNameStyles>{name}</UserNameStyles>
      </Tooltip>
    );
  };

  const formatStatus = str =>
    str
      .split(' ')
      .map(el => el.toLowerCase())
      .join('_');

  useEffect(() => {
    const formated = formatLabelValue(
      eligibleUsers,
      'name',
      'user_id',
      'username'
    );
    setOptions(formated);
  }, [eligibleUsers]);

  useEffect(() => {
    // this will open sidebar based on state.userFormPermissions
    if (
      state.userForPermissions &&
      Object.keys(state.userForPermissions).length > 0
    ) {
      toggleSidebar({
        show: true,
        type: sidebarTypes.permissions,
        title: (
          <>
            <h3>Permissions</h3>
            <a
              href='https://postudio.tawk.help/article/collaborator-permissions'
              rel='noopener noreferrer'
              target='_blank'
              style={{ display: 'inherit', marginLeft: '0.5rem' }}
            >
              <HelpOutline style={{ fontSize: '2rem' }} />
            </a>
          </>
        ),
        componentProps: {
          user: { ...state.userForPermissions },
        },
      });
    }
  }, [state.userForPermissions]);

  const onSubmit = async () => {
    // submit

    setSubmitting(true);
    const baseRequest = {
      project_db_id: params.project_db_id,
      collabrated_with: [],
      invited_collaborators: [],
    };

    const request = requestForIdsEmails(
      selectedOptions,
      baseRequest,
      'invited_collaborators',
      'collabrated_with'
    );

    const collaboratorsResponse = await updateCollaboratedUsers(
      request,
      'post'
    ); // API call

    Mixpanel.track('Add Collaborators', {
      collaborators: request.invited_collaborators,
    });

    const {
      data: { response, errormessage, data },
    } = collaboratorsResponse;
    setSubmitting(false);
    if (response) {
      closeSidebar();
      toggleToast({
        message: {
          messageHead: 'Success',
          messageBody: 'Collaborators updated successfully',
          variant: 'success',
        },
      });
    } else if (data && data.upgrade_required) {
      toggleUpgradeModal({ show: true, data });
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  const searchEligibleUser = async (e, value, reason) => {
    // onChange
    if (reason === 'select-option' || reason === 'remove-option') {
      const updatedUsers = value.map(item => ({
        label: item.label,
        value: item.value,
      }));
      setSelectedOptions(updatedUsers);
    } else if (e.target.value && e.target.value.length > 2) {
      setOpen(true);
      const usersResponse = await getUsersBasedOnFilters({
        filters: 'collaborators',
        search_text: e.target.value?.trim(),
      });
      const {
        data: { data },
      } = usersResponse;
      setEligibleUsers(data || []);
    } else {
      setEligibleUsers([]);
      setOpen(false);
    }
  };

  const deleteUser = async (user, role) => {
    const request = {
      project_db_id: params.project_db_id,
      role,
    };

    if (emailRegex(user)) {
      request.user_email = user;
    } else request.user_db_id = user.user_id;

    const collaboratorsResponse = await updateCollaboratedUsers(
      request,
      'delete'
    ); // API call

    const {
      data: { response, errormessage, data },
    } = collaboratorsResponse;

    toggleToast({
      message: {
        messageHead: response ? 'Success' : 'Error',
        messageBody: response
          ? `${
              role.charAt(0).toUpperCase() + role.slice(1)
            } deleted successfully`
          : errormessage,
        variant: response ? 'success' : 'error',
      },
    });
    loadCollaborators(data.collaborators);
  };

  const handlePermissions = user => {
    setState({
      ...state,
      userForPermissions: { ...user },
    });
    /*
     * this will set the state with user
     * after setting will toggleSidebar and need to pass state
     * to achieve this we are using useEffect
     */
  };

  const resendEmailInvite = (email, role) => {
    updateCollaboratedUsers(
      {
        project_db_id: params.project_db_id,
        user_email: email,
        role,
      },
      'put'
    )
      .then(res => {
        const {
          data: { response, errormessage },
        } = res;
        if (response) {
          toggleToast({
            message: {
              messageHead: 'Success',
              messageBody: 'Invite sent successfully',
              variant: 'success',
            },
          });
        } else {
          toggleToast({
            message: {
              messageHead: 'Error',
              messageBody: errormessage,
              variant: 'error',
            },
          });
        }
      })
      .catch(err => {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: err.data.errormessage,
            variant: 'error',
          },
        });
      });
  };

  return (
    <>
      <Body noFooter={!selectedOptions?.length}>
        {creatorDetails?.permissions.can_add_update_collaborators && (
          <AutoComplete
            id='users'
            label='Add by Username or Email'
            multiple
            open={open}
            onClose={() => setOpen(false)}
            value={selectedOptions}
            onChange={searchEligibleUser}
            options={options}
            getOptionDisabled={option =>
              [
                ...collaborators.members,
                creator,
                ...collaborators.invited,
              ].some(item => {
                if (item.user_id) {
                  return item.user_id === option.value;
                }
                return item === option.value;
              })
            }
          />
        )}

        {admins?.length > 0 && (
          <Row>
            <AvatarSecondaryTextStyles style={{ marginBottom: '1rem' }}>
              Project Admins
            </AvatarSecondaryTextStyles>
            {admins?.map(el => (
              <User>
                <Detail>
                  <AvatarBadgeParent className='flex items-center'>
                    <CustomAvatarStyles color={el?.user_badge_color}>
                      {!el?.is_active_in_live_collaboration ? (
                        <>
                          {el?.first_name?.charAt(0)}
                          {el?.last_name?.charAt(0)}
                        </>
                      ) : (
                        <Headset />
                      )}
                    </CustomAvatarStyles>
                    {el?.is_session_active && <BadgeStyles />}
                  </AvatarBadgeParent>
                  <div>
                    {userNameWithTooltip(el.name)}

                    {(el?.user_badge || el?.task_type) && (
                      <div className='flex items-center gap-x-1'>
                        {el.task_type && (
                          <div className='flex items-center'>
                            {el.task_status && (
                              <StatusStyles
                                bgColor={
                                  TASK_STATUS[formatStatus(el.task_status)]
                                }
                              />
                            )}
                            <AvatarSecondaryTextStyles secondary>
                              {el.task_type}
                            </AvatarSecondaryTextStyles>
                          </div>
                        )}
                        {el?.user_badge && (
                          <Text padding='0' sm>
                            {el?.user_badge}
                          </Text>
                        )}
                      </div>
                    )}
                  </div>
                </Detail>
                {creatorDetails?.permissions?.can_manage_project && (
                  <Flex>
                    <IconButton>
                      <Settings onClick={() => handlePermissions(el)} />
                    </IconButton>
                  </Flex>
                )}
              </User>
            ))}
          </Row>
        )}

        {collaborators &&
          (collaborators.members.length > 0 ||
            collaborators.invited.length > 0) && (
            <Row>
              <Flex
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                }}
              >
                <AvatarSecondaryTextStyles>
                  Collaborators
                </AvatarSecondaryTextStyles>
              </Flex>

              {collaborators.members.map(item => {
                const {
                  first_name,
                  last_name,
                  name,
                  user_id,
                  task_type,
                  task_status,
                  user_badge_color,
                  is_active_in_live_collaboration,
                  is_session_active,
                  user_badge,
                } = item;
                return (
                  <User key={user_id}>
                    <Detail>
                      <AvatarBadgeParent>
                        <CustomAvatarStyles color={user_badge_color}>
                          {!is_active_in_live_collaboration ? (
                            <>
                              {first_name?.charAt(0)}
                              {last_name?.charAt(0)}
                            </>
                          ) : (
                            <Headset />
                          )}
                        </CustomAvatarStyles>

                        {is_session_active && <BadgeStyles />}
                      </AvatarBadgeParent>
                      <div>
                        {userNameWithTooltip(name)}
                        {(task_type || user_badge) && (
                          <div className='flex items-center gap-x-1'>
                            {task_type && (
                              <div
                                aria-label='task-type'
                                className='flex items-center'
                              >
                                {task_status && (
                                  <StatusStyles
                                    bgColor={
                                      TASK_STATUS[formatStatus(task_status)]
                                    }
                                  />
                                )}
                                <AvatarSecondaryTextStyles secondary>
                                  {task_type}
                                </AvatarSecondaryTextStyles>
                              </div>
                            )}
                            {user_badge && (
                              <Text padding='0' sm>
                                {user_badge}
                              </Text>
                            )}
                          </div>
                        )}
                      </div>
                    </Detail>

                    <Flex>
                      {creatorDetails?.permissions?.can_manage_project && (
                        <IconButton style={{ marginRight: '1rem' }}>
                          <Settings onClick={() => handlePermissions(item)} />
                        </IconButton>
                      )}

                      {creatorDetails?.permissions
                        ?.can_add_update_collaborators &&
                        user_id !== userDetails.user_id && (
                          <IconButton>
                            <Delete
                              onClick={() => deleteUser(item, 'collaborator')}
                            />
                          </IconButton>
                        )}
                    </Flex>
                  </User>
                );
              })}

              {collaborators.invited.map(item => {
                return (
                  <User key={item}>
                    <Detail>
                      <AvatarBadgeParent>
                        <CustomAvatarStyles secondary>
                          <Person />
                        </CustomAvatarStyles>
                      </AvatarBadgeParent>
                      <div>
                        {userNameWithTooltip(item)}
                        <Flex>
                          <AvatarSecondaryTextStyles secondary>
                            Pending
                          </AvatarSecondaryTextStyles>
                          <TextButton
                            onClick={() =>
                              resendEmailInvite(item, 'collaborator')
                            }
                            style={{ marginLeft: '0.5rem' }}
                          >
                            Resend Invite
                          </TextButton>
                        </Flex>
                      </div>
                    </Detail>

                    {creatorDetails?.permissions
                      ?.can_add_update_collaborators && (
                      <Flex>
                        <IconButton>
                          <Delete
                            onClick={() => deleteUser(item, 'collaborator')}
                          />
                        </IconButton>
                      </Flex>
                    )}
                  </User>
                );
              })}
            </Row>
          )}
      </Body>

      {selectedOptions && selectedOptions.length > 0 && (
        <Footer>
          <Normal onClick={onSubmit} isLoading={isSubmitting}>
            Update
          </Normal>
        </Footer>
      )}
    </>
  );
};

Collaborate.propTypes = {
  loadCollaborators: PropTypes.func,
  closeSidebar: PropTypes.func,
  updateCollaboratedUsers: PropTypes.func,
  getUsersBasedOnFilters: PropTypes.func,
  toggleToast: PropTypes.func,
};

export default Collaborate;
