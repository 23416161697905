/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

// Utils
import Mixpanel from '../../../../utils/mixpanel';

// Components
import GradientCard from './GradientCard';

import { CLOUD_SCREENS } from './constants';

// Styles
import { Body } from '../../../styles/sidebar';
import { Label, Text } from '../../../styles/common';
import { colors } from '../../../styles/variables';
import { whiteAlpha } from '../../../styles/utils';
import { CloudScreenHead } from './styles';

const Slide = styled.div`
  animation-name: slideUp;
  animation-duration: 1.3s;
  @keyframes slideUp {
    from {
      transform: translateY(2rem);
      opacity: 0;
    }
    to {
      transform: translateY(0rem);
      opacity: 1;
    }
  }
`;

const SystemTypes = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [taskTypes, setTaskTypes] = useState([]);
  const {
    match: { params },
    getSystemResources,
    setType,
    goTo,
    task,
  } = props;

  useEffect(() => {
    (async () => {
      const taskTypesResponse = await getSystemResources({
        project_db_id: params?.project_db_id,
        ...(task?.task_db_id && { task_db_id: task?.task_db_id }),
      }); // to fetch task types

      const {
        data: { response, data, errormessage },
      } = taskTypesResponse;
      if (response) {
        setLoading(false);
        setTaskTypes(data);
        setError(null);
      } else {
        setLoading(false);
        setError(errormessage);
        setTaskTypes([]);
      }
    })();
  }, []);

  return (
    <>
      <Body noFooter>
        {!error && (
          <CloudScreenHead bg paddingTop='0'>
            Choose the type of system you want to work on:
          </CloudScreenHead>
        )}

        {loading ? (
          <Text color={whiteAlpha(0.8)} paddingLeft='0' paddingRight='0'>
            Fetching
          </Text>
        ) : (
          <Slide>
            {taskTypes &&
              taskTypes.map(item => (
                <GradientCard
                  {...item}
                  pointer
                  key={`task-config-${item.value}`}
                  onClick={() => {
                    Mixpanel.track(item.label);
                    setType(item);
                    goTo(CLOUD_SCREENS.softwares);
                  }}
                />
              ))}
          </Slide>
        )}

        {error && <Label color={colors.error}>{error}</Label>}
      </Body>
    </>
  );
};

SystemTypes.propTypes = {
  setType: PropTypes.func,
  getSystemResources: PropTypes.func,
  goTo: PropTypes.func,
  task: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};

export default SystemTypes;
