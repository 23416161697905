import { whiteAlpha } from './utils';

const blur = '27.1828px';

const colors = {
  white: '#FFF',

  // error
  error: '#ff6c56',

  // Blue
  blue: {
    primary: '#2c86ff',
    secondary: '#559eff',
    navy: '#0c1d33',
    shadeOne: '#6faaff',
    shadeTwo: '#a5ccff',
    shadeThree: '#1D4376',
    shadeFour: '#122a4a',
  },

  yellow: '#fcd500',

  green: '#18B96E',

  // Black
  black: {
    default: '#000',
    main: '#121212',
    primary: whiteAlpha(0.15),
    secondary: whiteAlpha(0.2),
  },
};

const padding = {
  input: '2.5rem 1.2rem 0.7rem',
  // input: '4.7rem 1.6rem 2rem',
  container: '0.8rem 1.2rem',
  v: '0.8rem',
  h: '1.2rem',
};

const fs = {
  // font size
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.6rem',
  lg: '2.6rem',
};

const font = {
  normal: 300,
  medium: 400,
  semibold: 500,
  bold: 700,
};

const radius = {
  default: '0.5rem',
  full: '100%',
};

const devices = {
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

export { colors, padding, fs, font, radius, devices, blur };
