import React from 'react';

import apiConfig from '../../../../config';
import { Wrapper, ImageWrapper, SubHeader } from '../../../styles/errorScreen';
import { AnchorLinkStyles, Text } from '../../../styles/common';

const NoResultsPermissionsImage = `${apiConfig.assets}/c2e.svg`;

const CameraToEditSessionNoPermissions = () => {
  return (
    <Wrapper style={{ height: 'auto', padding: '5rem' }}>
      <ImageWrapper style={{ width: '30rem' }}>
        <img src={NoResultsPermissionsImage} alt='error' />
      </ImageWrapper>
      <SubHeader style={{ textAlign: 'center' }}>
        Contact your project admin to grant you Camera to Edit permission for
        this project.
      </SubHeader>
      <Text padding='0' marginTop='1rem'>
        Learn how{' '}
        <span>
          <span>
            <AnchorLinkStyles
              href='https://postudio.tawk.help/article/camera-edit'
              target='_blank'
              rel='noreferrer'>
              Camera to Edit
            </AnchorLinkStyles>
          </span>
        </span>{' '}
        works.
      </Text>
    </Wrapper>
  );
};

export default CameraToEditSessionNoPermissions;
