/* eslint-disable max-len */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SvgIcon from '@material-ui/core/SvgIcon';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Components
import { Normal } from '../../components/Button';

// constants
import sidebarTypes from '../../components/Sidebar/constants';

// helper functions
import { amountWithCommas } from '../../helperFunctions';

// Styles
import { Body, Footer } from '../../styles/sidebar';
import { padding, colors } from '../../styles/variables';
import { Text, Flex } from '../../styles/common';

const GradientBoxStyles = styled(Flex)`
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid ${({ plan }) => {
    if (plan === 'Pro') {
      return '#E2C00F';
    }
    if (plan === 'Team') {
      return '#ff8d29';
    }
    if (plan === 'SME') {
      return '#6085FF';
    }
    return '#18B96E';
  }};

  background: ${({ plan }) => {
    if (plan === 'Pro') {
      return 'linear-gradient(to right, #E2C00F, #FFA751)';
    }
    if (plan === 'Team') {
      return 'linear-gradient(to right, #ff8d29, #f6462c)';
    }
    if (plan === 'SME') {
      return 'linear-gradient(to right, #6085FF, #C16DC4)';
    }
    return 'linear-gradient(to right, #18B96E, #2C86FF)';
  }};

  &:hover, &:focus {
    border-color: ${colors.white};
  }
`;

const AnchorStyles = styled.a`
  color: ${colors.white};
  &:hover {
    text-decoration: none;
  }
`;

const Plans = ({
  closeSidebar,
  upgradePlan,
  renewSubscription,
  loadAvailablePlans,
  selectPlan,
  toggleSidebar,
  modal,
  toggleModal,
  loadSubscriptionDetails,
}) => {
  const { availablePlans, plan_detail } = useSelector((state) => state.subscription);
  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    toggleLoading(true);
    upgradePlan()
      .then((res) => {
        const { data, errormessage } = res?.data;
        if (res?.data?.response) {
          loadAvailablePlans(data);
          toggleLoading(false);
        } else {
          throw new Error(errormessage);
        }
      })
      .catch(() => {
        toggleLoading(false);
      });
  }, []);

  const onPlanChange = useCallback((data) => {
    selectPlan(data);
    toggleSidebar({
      type: sidebarTypes.useCredits,
      show: true,
      title: 'Subscription Upgrade',
      componentProps: {
        upgradePlan,
        renewSubscription,
        selectPlan,
        modal,
        toggleModal,
        loadSubscriptionDetails,
      },
    });
  }, []);

  return useMemo(() => {
    return (
      <>
        <Body>
          {loading ? (
            <Text md className="px-0">
              Fetching plans. Please wait
            </Text>
          ) : (
            <>
              <Text paddingRight='0' paddingLeft='0' md>{`You have subscribed to the ${plan_detail?.title} plan.`}</Text>
              <Text sm padding='0' margin='1rem 0'>You can upgrade at any time</Text>
              {availablePlans?.length ? (
                <>
                  {availablePlans.map((item) => {
                    if (item?.is_enterprise) {
                      return (
                        <a
                          href="https://stg.postud.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          <GradientBoxStyles>
                            <div>
                              <SvgIcon
                                viewBox="0 0 37 34"
                                style={{
                                  width: '3.7rem',
                                  height: '3.4rem',
                                  fill: '#FFF',
                                  margin: padding.container,
                                }}
                              >
                                <path d="M6.071 14.247h7.893c.336 0 .607-.272.607-.608 0-.335-.271-.607-.607-.607H6.071c-.335 0-.607.272-.607.607 0 .336.272.608.607.608zM21.857 16.675H6.071c-.335 0-.607.272-.607.607 0 .335.272.607.607.607h15.786c.336 0 .607-.272.607-.607 0-.335-.271-.607-.607-.607zM21.857 20.318H6.071c-.335 0-.607.272-.607.607 0 .335.272.607.607.607h15.786c.336 0 .607-.272.607-.607 0-.335-.271-.607-.607-.607z" />
                                <path d="M32.83.325L12.1.282c-1.985 0-3.6 1.615-3.6 3.6v3.112l-4.9.01c-1.985 0-3.6 1.615-3.6 3.6V23.44c0 1.985 1.615 3.6 3.6 3.6h3.686v6.071c0 .25.154.476.388.567.071.027.145.04.219.04.167 0 .332-.07.45-.198l5.89-6.482 10.095-.04c1.986 0 3.6-1.616 3.6-3.6v-.07l3.194 3.514c.117.129.282.198.45.198.073 0 .147-.013.218-.04.234-.09.389-.316.389-.567v-6.071h.65c1.985 0 3.6-1.615 3.6-3.6V3.925c0-1.985-1.615-3.6-3.6-3.6zm-6.116 23.072c0 1.316-1.07 2.385-2.388 2.385l-10.364.043c-.17 0-.333.073-.447.198L8.5 31.54v-5.108c0-.335-.271-.607-.607-.607H3.6c-1.315 0-2.386-1.07-2.386-2.386V10.604c0-1.316 1.07-2.386 2.388-2.386l5.505-.012h.001l15.22-.031c1.316 0 2.386 1.07 2.386 2.386v12.836zm8.5-6.636c0 1.315-1.07 2.385-2.386 2.385h-1.257c-.335 0-.607.272-.607.607v5.109l-3.035-3.34v-10.96c0-1.986-1.615-3.601-3.602-3.601l-14.613.03V3.882c0-1.316 1.07-2.386 2.385-2.386l20.729.042c1.316 0 2.387 1.07 2.387 2.387V16.76z" />
                              </SvgIcon>
                              <Text md className="pt-0">Enterprise</Text>
                            </div>
                            <Text semibold>CONTACT US</Text>
                          </GradientBoxStyles>
                        </a>
                      );
                    }
                    return (
                      <GradientBoxStyles
                        key={item?.subscription_db_id}
                        plan={item?.title}
                        onClick={() => onPlanChange(item)}
                      >
                        <div>
                          <Text style={{ fontSize: '3rem' }}>₹{amountWithCommas(item?.amount)}</Text>
                          <Text md className="pt-0">
                            {item?.title}
                          </Text>
                        </div>
                        <Text semibold>UPGRADE</Text>
                      </GradientBoxStyles>
                    );
                  })}

                  <Text sm className="px-0 py-1">
                    Check the plan details & features on the{' '}
                    <AnchorStyles href="https://stg.postud.io/" target="_blank" rel="noopener noreferrer">
                      Pricing
                    </AnchorStyles>{' '}
                    page.
                  </Text>
                </>
              ) : (
                <Text className="px-0" md>
                  Currently there are no plans available
                </Text>
              )}
            </>
          )}
        </Body>

        <Footer>
          <Normal onClick={closeSidebar} color="secondary">
            Close
          </Normal>
        </Footer>
      </>
    );
  }, [availablePlans, loadAvailablePlans, loading, toggleLoading, upgradePlan, closeSidebar]);
};

Plans.propTypes = {
  closeSidebar: PropTypes.func,
  upgradePlan: PropTypes.func,
  plans: PropTypes.instanceOf(Object),
  toggleSidebar: PropTypes.func,
  renewSubscription: PropTypes.func,
  selectPlan: PropTypes.func,
  loadAvailablePlans: PropTypes.func,
  loadSubscriptionDetails: PropTypes.func,
};

export default Plans;
