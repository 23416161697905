/* eslint-disable max-len */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SidebarComponent from './SidebarComponent';

// Actions
import Creators from '../../storyboard/duck/actions';

// Operations
import { commonOperations } from '../../main-wrapper/duck';
import { storyboardOperations } from '../../storyboard/duck';
import { enqueueSnackbar } from '../../snackbar/duck/actions';

const { loadMessages } = Creators;

const mapStateToProps = state => ({
  ...state.common,
  ...state.storyboard,
});

const mapDispatchToProps = dispatch => {
  const toggleSidebar = data => dispatch(commonOperations.sidebarToggle(data));

  // get users
  const getUsersBasedOnFilters = request =>
    storyboardOperations.fetchUsersBasedOnFilters(request);

  // fetch collaborators
  const getCollaborators = request =>
    storyboardOperations.fetchCollaborators(request);

  // to save collaborators
  const loadCollaborators = data =>
    dispatch(storyboardOperations.loadCollaborators(data));

  // to update collaborators
  const updateCollaboratedUsers = (request, method) =>
    storyboardOperations.updateCollaboratedUsers(request, method);

  // create / update / delete task approval and review request
  const createUpdateTaskRequest = (request, method) =>
    storyboardOperations.taskRequest(request, method);

  // send brief with API
  const sendBrief = request =>
    dispatch(storyboardOperations.sendBrief(request));

  const loadSelectedTaskDetails = data =>
    dispatch(storyboardOperations.loadSelectedTask(data));

  const loadAllTaskDetails = data =>
    dispatch(storyboardOperations.loadAllTask(data)); // load all task details

  // this will create / update task
  const createUpdateFetchTask = (request, method) =>
    storyboardOperations.createUpdateTask(request, method);

  // this will get assign to users based on taskType
  const getAssignToUsers = request =>
    storyboardOperations.fetchAssignToUsers(request);

  // to fetch task types
  const getTaskTypes = request => storyboardOperations.fetchTaskTypes(request);

  const getSystemResources = request =>
    storyboardOperations.fetchSystemResources(request); // to fetch system resources

  const getSystems = request => storyboardOperations.fetchSystems(request); // to fetch system based on resource

  const isResourceAvailable = (request, method) =>
    storyboardOperations.isResourceAvailable(request, method); // to check if cloud system is available or not

  const systemSession = (request, method) =>
    storyboardOperations.systemSession(request, method);

  const loadCloudAccessDetails = data =>
    dispatch(storyboardOperations.loadCloudAccessDetails(data)); // to save cloud access details

  const extendSession = (request, method) =>
    storyboardOperations.extendSession(request, method); // to extend the session of resource system

  const getNiceDcvSdkDownloadLink = request =>
    storyboardOperations.downloadNiceDcvSdkService(request); // to get connector app download link

  const createCancelQueueRequest = (request, method) =>
    storyboardOperations.systemInQueue(request, method); // to create / cancel queue request

  // to transfer task
  const createTransferRequest = request =>
    storyboardOperations.transferTaskRequest(request);

  // Snackbar toast
  const toggleToast = data => dispatch(enqueueSnackbar(data));

  // to get, update member permissions of team members, collaborators
  const getUpdateMemberPermissions = (request, method) =>
    storyboardOperations.memberPermissions(request, method);

  const shareAsset = (request, method) =>
    storyboardOperations.shareAsset(request, method);
  const shareAssetSettings = request =>
    storyboardOperations.shareAssetSettings(request);
  const toggleUpgradeModal = data =>
    dispatch(commonOperations.upgradeModalAction(data));

  // to get, add task messages
  const addGetTaskMessages = (request, method) =>
    storyboardOperations.addGetTaskMessages(request, method);
  const loadTaskMessages = (data, type) => dispatch(loadMessages(data, type));

  return {
    toggleSidebar,
    loadCollaborators,
    getUsersBasedOnFilters,
    getCollaborators,
    updateCollaboratedUsers,
    sendBrief,
    createUpdateTaskRequest,
    loadSelectedTaskDetails,
    loadAllTaskDetails,
    createUpdateFetchTask,
    getAssignToUsers,
    getTaskTypes,
    getSystemResources,
    getSystems,
    systemSession,
    isResourceAvailable,
    loadCloudAccessDetails,
    extendSession,
    createCancelQueueRequest,
    createTransferRequest,
    toggleToast,
    getUpdateMemberPermissions,
    shareAsset,
    shareAssetSettings,
    toggleUpgradeModal,
    addGetTaskMessages,
    loadTaskMessages,
    getNiceDcvSdkDownloadLink,
  };
};

const SidebarContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarComponent)
);

export default SidebarContainer;
