import app from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  appId: process.env.FIREBASE_APP_ID,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
};

app.initializeApp(config);

export const db = app.database();
export const CHAT_REF = 'task_chats';
export const CHAT_COUNT_REF = 'task_chats_count';
export const CHAT_NOTIFICATION_REF = 'chat_notifications';
export const NOTIFICATION_REF = 'notifications';
export const NOTIFICATION_TRACKER = 'new_notification_tracker';
export const CLOUD_ACCESS_DETAILS_REF = 'cloud_system';
export const PROJECT_CHAT_REF = 'project_chats';
export const PROJECT_CHAT_COUNT_REF = 'project_chats_count';
export const PROJECT_CHAT_NOTIFICATION_REF = 'project_chat_notifications';
