import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Components
import Title from './Title';

// Styles
import { padding } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';

const useStyles = makeStyles({

  card: {
    width: (props) => props.width || '100%',
    position: 'relative',
    margin: '0.75rem',
    boxSizing: 'border-box',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '& .inner': {
      width: '100%',
      position: 'relative',
      backgroundColor: '#191919',
      borderRadius: '0.4rem',
    }
  },

  root: {
    backgroundColor: whiteAlpha(0.08),
    height: (props) => props.height || props.withTitle && "10rem" || "16rem",
  },

});

const Image = (props) => {
  const { withTitle } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.card}>
      <div className="inner">
        <Skeleton
          variant="rect"
          width="100%"
          className={classes.root}
        />

        {
          withTitle && <div style={{ padding: '1.2rem' }}>
            <Title
              width="70%"
            />

            <Title
              width="30%"
            />
          </div>
        }
      </div>
    </div>
  );
};

Image.propTypes = {
  withTitle: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Image;