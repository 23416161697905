/* eslint-disable indent */
import types from './types';

const INITIAL_STATE = {
  programStructure: [
    {
      index: 1,
      field: 'task_tabs',
      values: [
        {
          field: 'my_tasks',
          display_type: 'task_card',
          index: 1,
          title: 'My Tasks',
          type: 'carousel',
        },
        {
          field: 'assigned_tasks',
          display_type: 'task_card',
          index: 2,
          title: 'Assigned Tasks',
          type: 'carousel',
        },
        {
          field: 'shared_assets',
          display_type: 'task_card',
          index: 3,
          title: 'Shared Assets',
          type: 'carousel',
        },
      ],
    },
    {
      field: 'project_tabs',
      index: 2,
      values: [
        {
          field: 'projects',
          display_type: 'project_card',
          index: 1,
          title: 'My Projects',
          type: 'scroll_load',
        },
        {
          field: 'collaborated_projects',
          display_type: 'project_card',
          index: 2,
          title: 'Collaborated Projects',
          type: 'scroll_load',
        },
      ],
    },
  ],
  programData: {},
};

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET_STATE: {
      return INITIAL_STATE;
    }

    case types.FETCH_PROGRAM_STRUCTURE: {
      return {
        ...state,
        programStructure: action.payload,
      };
    }

    case types.FETCH_PROGRAM_DATA: {
      return {
        ...state,
        programData: {
          ...state.programData,
          [action.payload.field]: { ...action.payload },
        },
      };
    }

    case types.UPDATE_TRAY_DATA: {
      const { data, ...otherProps } = action.payload;
      return {
        ...state,
        programData: {
          ...state.programData,
          [action?.payload?.field]: {
            ...otherProps,
            data: [...state.programData[action?.payload?.field]?.data, ...data],
          },
        },
      };
    }

    case types.UPDATE_PUSHER_DATA: {
      const { data, trayName, type } = action.payload;
      let updatedObject = null;
      if (type === 'add') {
        updatedObject = {
          ...state,
          programData: {
            ...state.programData,
            [trayName]: {
              ...state.programData[trayName],
              data: [data, ...state.programData[trayName].data],
              total_entries: state.programData[trayName].total_entries + 1,
            },
          },
        };
      }

      if (type === 'delete') {
        updatedObject = {
          ...state,
          programData: {
            ...state.programData,
            [trayName]: {
              ...state.programData[trayName],
              data: state.programData[trayName].data.filter(
                item => item.project_db_id !== data.project_db_id
              ),
              total_entries: state.programData[trayName].total_entries - 1,
            },
          },
        };
      }

      if (type === 'update') {
        const getIndex = state.programData[trayName].data.findIndex(
          item => item.project_db_id === data.project_db_id
        );
        const updated = [...state.programData[trayName].data];
        updated[getIndex] = { ...data };
        updatedObject = {
          ...state,
          programData: {
            ...state.programData,
            [trayName]: {
              ...state.programData[trayName],
              data: updated,
            },
          },
        };
      }
      return updatedObject;
    }

    default:
      return state;
  }
};

export default homeReducer;
