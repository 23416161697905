import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

// Styles
import { slideUp } from '../../styles/animations';

const DialogWrapper = styled(Dialog)`
  animation: ${slideUp} 1s;
  .MuiDialog-paperWidthSm {
    width: 400px;
    border-radius: 2rem;
  }
`;

export const SecondaryModalBodyStyles = styled.div`
  position: relative;
  text-align: center;
  padding: 5rem;

  .head {
    color: rgba(12, 29, 51, 0.6);
    margin-top: 2rem;
    font-size: 2.7rem;
  }

  .text {
    font-size: 1.8rem;
    line-height: 1.5;
    color: rgba(12, 29, 51, 0.8);
  }

  .cta-section {
    margin-top: 3rem;
  }

  a {
    text-decoration: none;
  }
`;

const SecondaryModal = props => {
  const { open, type = 'upgrade', children, onClose } = props;
  return (
    <>
      <DialogWrapper aria-labelledby={type} open={open} onClose={onClose}>
        {children}
      </DialogWrapper>
    </>
  );
};

SecondaryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default SecondaryModal;
