const LOAD_FILES_STORAGE = 'LOAD_FILES_STORAGE';
const LOAD_PROJECT_STORAGE = 'LOAD_PROJECT_STORAGE';
const LOAD_PROJECT_USAGE = 'LOAD_PROJECT_USAGE';
const LOAD_ACTIVITY_USAGE = 'LOAD_ACTIVITY_USAGE';
const RESET_STATISTICS_STATE = 'RESET_STATISTICS_STATE';

export default {
  LOAD_PROJECT_STORAGE,
  LOAD_FILES_STORAGE,
  LOAD_PROJECT_USAGE,
  LOAD_ACTIVITY_USAGE,
  RESET_STATISTICS_STATE
};
