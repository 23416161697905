/* eslint-disable import/prefer-default-export */
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';

// import { serviceCallBasedOnMethod } from '../../helperFunctions';

export const s3BucketService = request =>
  serviceCall.get(`${apiConfig.upload}/s3-buckets/`, request);

export const s3UploadService = request =>
  serviceCall.post(`${apiConfig.upload}/s3-uploads/`, request);
