import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import { colors } from '../../styles/variables';

const TimerWrapper = styled.div`
  display: flex;
  margin: 1.5rem 0;
  margin: 0;
  align-items: baseline;
  .time {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    color: ${({ color }) => color || colors.white};
    display: inline-block;
    margin: 0;
    padding: 0;
    font-weight: 400;
  }
  .time-tag {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: ${({ color }) => color || colors.white};
    padding: 0;
    font-size: 1rem;
    display: inline-block;
    padding-right: 1rem;
    padding-left: 0.2rem;
  }
`;
class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timeLeft: 0,
    };
    this.countdownInterval = null;
  }

  componentDidMount = () => {
    const timeObj = this.getTimeLeft();
    this.setState(
      {
        time: {
          hours: timeObj.hours,
          minutes: timeObj.minutes,
          seconds: timeObj.seconds,
        },
        timeLeft: timeObj.timeLeft,
      },
      () => {
        const { time } = this.state;
        if (time.hours || time.minutes || time.seconds) {
          this.startCountdown();
        }
      }
    );
  };

  componentDidUpdate(newTime) {
    const { startTime, endTime } = this.props;
    if (newTime.startTime !== startTime || newTime.endTime !== endTime) {
      const timeObj = this.getTimeLeft();
      this.setState(
        {
          time: {
            hours: timeObj.hours,
            minutes: timeObj.minutes,
            seconds: timeObj.seconds,
          },
          timeLeft: timeObj.timeLeft,
        },
        () => {
          const { time } = this.state;
          if (time.hours || time.minutes || time.seconds) {
            this.startCountdown(true);
          }
        }
      );
    }
  }

  getTimeLeft = () => {
    const { endTime, startTime, getTime } = this.props;
    const presentTime = startTime || new Date().getTime();
    let timeLeft = endTime - presentTime;
    if (timeLeft < 0) {
      timeLeft = 0;
    }
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    const data = {
      hours,
      minutes,
      seconds,
      timeLeft,
    };
    if (getTime) {
      getTime(data?.timeLeft);
    }
    return data;
  };

  startCountdown = resetTimer => {
    const { getTime } = this.props;
    if (!this.countdownInterval || resetTimer) {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      const { timeLeft } = this.state;
      const self = this;
      let updatedTime = timeLeft;
      this.countdownInterval = setInterval(function () {
        updatedTime -= 1000;

        const hours = Math.floor(
          (updatedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );

        const minutes = Math.floor(
          (updatedTime % (1000 * 60 * 60)) / (1000 * 60)
        );

        const seconds = Math.floor((updatedTime % (1000 * 60)) / 1000);

        if (hours >= 0 || minutes >= 0 || seconds >= 0) {
          self.setState(
            {
              time: {
                hours,
                minutes,
                seconds,
              },
              timeLeft: updatedTime,
            },
            () => {
              if (getTime) {
                getTime(updatedTime);
              }
            }
          );
        } else {
          clearInterval(self.countdownInterval);
        }
      }, 1000);
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.countdownInterval);
    this.countdownInterval = null;
  };

  render() {
    const { color } = this.props;
    const { time } = this.state;
    return (
      <TimerWrapper className='timer-wrapper' color={color}>
        {time.hours !== 0 && (
          <>
            <p className='time'>
              {time.hours > 9 ? time.hours : `0${time.hours}`}
            </p>
            <p className='time-tag'>Hr</p>
          </>
        )}

        <p className='time'>
          {time.minutes > 9 ? time.minutes : `0${time.minutes}`}
        </p>
        <p className='time-tag'>Min</p>

        <p className='time'>
          {time.seconds > 9 ? time.seconds : `0${time.seconds}`}
        </p>
        <p className='time-tag'>Sec</p>
      </TimerWrapper>
    );
  }
}

Timer.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  color: PropTypes.string,
  getTime: PropTypes.func,
};

export default Timer;
