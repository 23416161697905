/* eslint-disable max-len */
/* eslint-disable no-console */
import Creators from './actions';
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';
import { serviceCallBasedOnMethod } from '../../helperFunctions';

const {
  actionLoadTabs,
  actionLoadBillingDetails,
  actionLoadPermissions,
} = Creators;

const teamSettings = request =>
  serviceCall.get(`${apiConfig.auth}/settings/`, request);
const teamCreatePermissions = request =>
  serviceCall.get(`${apiConfig.auth}/check/permissions`, request);
export const manageTeamsSerivceCall = (request, method = 'get') =>
  serviceCallBasedOnMethod(`${apiConfig.auth}/team/`, request, method);
export const teamPermissionsServiceCall = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/team/permissions/`,
    request,
    method
  );
export const manageBillingServiceCall = (request, method = 'get') =>
  serviceCallBasedOnMethod(`${apiConfig.auth}/billing/`, request, method);
export const getTeamMembersServiceCall = (request, method = 'get') =>
  serviceCallBasedOnMethod(`${apiConfig.auth}/team/members/`, request, method);
export const fetchAwsRegions = request =>
  serviceCall.get(`${apiConfig.auth}/aws/regions/`, request);
export const s3IntegrationService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/s3-integration/`,
    request,
    method
  );

export const sprinklrIntegrationService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.auth}/sprinklr/integration/`,
    request,
    method
  );

export const fetchIntegrationDetailService = request =>
  serviceCall.get(`${apiConfig.auth}/integrations/detail/`, request);

export const validateCampaignService = request =>
  serviceCall.get(`${apiConfig.auth}/validate/sprinklr/campaign-id/`, request);

const saveTabs = data => dispatch => dispatch(actionLoadTabs(data));
const saveBillingDetails = data => dispatch =>
  dispatch(actionLoadBillingDetails(data));
const saveSettingsPermissions = data => dispatch =>
  dispatch(actionLoadPermissions(data));

export default {
  saveTabs,
  saveBillingDetails,
  teamSettings,
  saveSettingsPermissions,
  teamCreatePermissions,
};
