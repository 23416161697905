import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Add } from '@material-ui/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { cameraSessionsService } from '../../duck/operations';
import { CAMERA_TO_EDIT_SESSION_STEPS, TAB_KEYS } from '../../constants';

import { Body } from '../../../styles/sidebar';
import {
  NewAddButtonStyles,
  Text,
  ListKeyValueItemStyles,
} from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';
import { colors, radius } from '../../../styles/variables';

import Icon from './C2E.svg';

const ListParentStyles = styled.ul`
  display: grid;
  row-gap: 1.5rem;
  list-style-type: none;

  li {
    a {
      display: flex;
      align-items: center;
      padding: 1.4rem;
      text-align: left;
      border-radius: 1rem;
      border: 0.1rem solid ${whiteAlpha(0.5)};
      background-color: transparent;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        border-color: ${colors.white};
      }

      .icon {
        position: relative;
        width: 4rem;
        height: 4rem;
        border-radius: ${radius.full};
        margin-right: 1rem;
        background-color: ${whiteAlpha(0.1)};

        .status {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 0.7rem;
          height: 0.7rem;
          border-radius: ${radius.full};
          background-color: #ffd700;

          &.active {
            background-color: #18b96e;
          }
        }
      }

      .text-parent {
        flex: 1;
      }

      p {
        font-size: 1.2rem;
        text-transform: none;
        margin-bottom: 0;
        margin-top: 0.5rem;
      }
    }
  }
`;

export default function SessionList({ goTo, pusher }) {
  const [activeSessions, setActiveSessions] = useState(null);
  const location = useLocation();
  const { project_db_id } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res = await cameraSessionsService({
          project_db_id,
        });
        const data = res?.data?.data?.c2e_sessions.reduce((acc, curr) => {
          acc[curr.c2e_session_db_id] = { ...curr };
          return acc;
        }, {});
        if (Object.keys(data)?.length) {
          setActiveSessions(data);
        }
      } catch (err) {
        console.log('🚀 ~ fetchDetails ~ err:', err);
      }
    };
    fetchDetails();
  }, [project_db_id]);

  useEffect(() => {
    let channel = null;
    if (pusher && project_db_id) {
      channel = pusher.subscribe(`private-${project_db_id}`);
      if (channel) {
        channel.bind('c2e_session_update', data => {
          setActiveSessions(prev => ({
            ...prev,
            [data.c2e_session_db_id]: { ...data },
          }));
        });
      }
    }

    return () => {
      if (channel && pusher) {
        channel.unbind();
        pusher.unsubscribe();
      }
    };
  }, [pusher, project_db_id]);

  return (
    <>
      <Body>
        <NewAddButtonStyles
          className='flex items-center'
          onClick={() => goTo(CAMERA_TO_EDIT_SESSION_STEPS.new)}>
          <span className='icon'>
            <Add />
          </span>
          <h4>New Session</h4>
        </NewAddButtonStyles>
        {activeSessions ? (
          <>
            <Text padding='2.5rem 0 1.5rem 0'>Active Sessions</Text>
            <ListParentStyles>
              {Object.values(activeSessions).map(el => (
                <ListKeyValueItemStyles key={el?.c2e_session_db_id}>
                  <Link
                    to={`${location.pathname}?tab=${TAB_KEYS.cameraToEdit}&s=${el?.c2e_session_db_id}`}
                    onClick={() => goTo(CAMERA_TO_EDIT_SESSION_STEPS.details)}>
                    <div className='icon flex items-center justify-center'>
                      <img src={Icon} alt={el?.folder_name} />
                      <span
                        className={`status ${el?.is_active ? 'active' : ''}`}
                      />
                    </div>
                    <div className='text-parent'>
                      <h4>{el?.folder_name}</h4>
                      <p>
                        {el?.camera_model} | {el?.c2e_session_id}
                      </p>
                    </div>
                  </Link>
                </ListKeyValueItemStyles>
              ))}
            </ListParentStyles>
          </>
        ) : (
          <Text padding='2.5rem 0 1.5rem 0'>No active sessions</Text>
        )}
      </Body>
    </>
  );
}

SessionList.propTypes = {
  goTo: PropTypes.func,
  pusher: PropTypes.instanceOf(Object),
};
