/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Flex, Text, NoDetailsFound } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';
import { padding } from '../../styles/variables';
import { Body } from '../../styles/sidebar';
import DateTime from '../dateTime';

// Firebase
import { db, NOTIFICATION_TRACKER, NOTIFICATION_REF } from '../../Firebase';

// Utils
import Mixpanel from '../../../utils/mixpanel';
import apiConfig from '../../../config';

// Constants
import sidebarTypes from '../Sidebar/constants';
import { TAB_KEYS } from '../../storyboard/constants';
import { SIDEBAR_QUERY_TYPES } from '../../constants';

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NotificationStrip = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  background-color: ${({ unread }) =>
    unread ? 'rgba(138, 187, 255, 0.5)' : whiteAlpha(0.15)}};
  margin-bottom: 1rem;
  padding: 0.5rem;

  .media {
    padding: ${padding.v};
    padding-right: 0;
    > img {
      width: 4rem;
      height: 4rem;
      object-fit: cover;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .details {
    flex: 1;
    padding: ${padding.v} ${padding.h};

    p, h3 {
      padding: 0;
    }
  }

  .title {
    font-weight: 400;
  }

`;

const NotificationComponent = props => {
  // Props
  const { history, userDetails, toggleSidebar } = props;

  // useState
  const [loadedNotification, setLoadedNotification] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);

  const userId = userDetails.user_id;
  const ALL_NOTIFICATION_REF = NOTIFICATION_REF + '/' + userId;

  const notificationRedirection = ({
    asset_id,
    project_id,
    task_id,
    id,
    notification_type,
    asset_url,
    chat_title,
    task_assigned_to,
    c2e_session_db_id,
  }) => {
    if (asset_url) {
      history.push(asset_url);
    } else if (asset_id) {
      history.push({
        pathname: `/player/${asset_id}`,
        search: '',
      });
    } else if (project_id && task_id) {
      if (notification_type === 'chat') {
        history.push({
          pathname: `/project/${project_id}`,
          search: `?task=${task_id}`,
        });
        Mixpanel.track('Task Chat');
        toggleSidebar({
          show: true,
          type: sidebarTypes.chat,
          title: 'Task Chat',
          componentProps: {
            taskDbId: task_id,
            subTitle: `${chat_title} - ${task_assigned_to ?? ''}`,
          },
        });
      } else if (notification_type === 'task_transfer') {
        history.push({
          pathname: `/project/${project_id}`,
          search: `?task=${task_id}`,
        });
        Mixpanel.track('Task Details');
        toggleSidebar({
          show: true,
          type: sidebarTypes.taskDetails,
          title: 'Task Details',
          componentProps: {
            taskDbId: task_id,
          },
        });
      } else {
        history.push({
          pathname: `/project/${project_id}`,
          search: `?task=${task_id}`,
        });
      }
    } else if (notification_type === 'project_chat') {
      history.push({
        pathname: `/project/${project_id}`,
        search: `?s=${SIDEBAR_QUERY_TYPES.projectChat}`,
      });
    } else if (
      project_id &&
      c2e_session_db_id &&
      notification_type === 'c2e_session'
    ) {
      history.push({
        pathname: `/project/${project_id}`,
        search: `?tab=${TAB_KEYS.cameraToEdit}&s=${c2e_session_db_id}`,
      });
    } else if (project_id && !task_id) {
      if (notification_type === 'live_collaboration') {
        history.push({
          pathname: `/project/${project_id}`,
          search: `?s=${SIDEBAR_QUERY_TYPES.joinLiveCollaboration}`,
        });
        // window.open(collaboration_link, '_blank');
      } else {
        history.push({
          pathname: `/project/${project_id}`,
          search: '',
        });
      }
    } else {
      console.log('Check notification type & path for redirection');
    }

    // set true when notification is seen
    db.ref(ALL_NOTIFICATION_REF + '/' + id).update({ has_seen: true });
  };

  useEffect(() => {
    db.ref(ALL_NOTIFICATION_REF).on('value', snapshot => {
      const values = snapshot.val();
      setTotalNotifications(values ? Object.values(values).length : 0);
      setLoadedNotification(values ? Object.values(values).reverse() : []);
    });

    return () => {
      db.ref(ALL_NOTIFICATION_REF).off();
    };
  }, []);

  const clearNotificationDot = () => {
    const userID = userDetails.user_id;
    const NOTIFICATION_PING = NOTIFICATION_TRACKER + '/' + userID;
    db.ref(NOTIFICATION_PING).update({ has_new_notification: false });
  };

  const fetchNewNotification = () => {
    loadedNotification.reverse();
    clearNotificationDot();
  };

  useEffect(() => {
    if (loadedNotification.length > 0) {
      fetchNewNotification();
    }
  }, [totalNotifications]);

  return (
    <Body style={{ height: 'calc(100% - 5rem)' }}>
      <NotificationWrapper>
        {totalNotifications > 0 ? (
          loadedNotification &&
          loadedNotification.map(item => {
            const {
              title,
              message,
              user_name,
              created_on,
              project_id,
              task_id,
              has_seen,
              image_url,
              id,
            } = item;
            const date = new Date(created_on);
            const imageSrc = image_url || `${apiConfig?.assets}/no-img.svg`;
            return (
              <NotificationStrip
                unread={!has_seen}
                key={project_id + task_id + id}
                onClick={() => {
                  notificationRedirection(item);
                }}>
                <div className='media'>
                  <img alt={title} src={imageSrc} />
                </div>
                <div className='details'>
                  <Flex className='items-center'>
                    <h3 className='title'>{title}</h3>
                    <Text
                      sm
                      color={whiteAlpha(0.8)}
                      className='line-clamp-2'
                      style={{ paddingLeft: '1.2rem' }}>
                      {user_name}
                    </Text>
                  </Flex>
                  <Text
                    xs
                    className='line-clamp-1'
                    style={{ paddingTop: '0.4rem' }}>
                    {message}
                  </Text>
                  <Flex className='items-center'>
                    {created_on && (
                      <Text xs className='date' color={whiteAlpha(0.6)}>
                        {DateTime.toDateTimeString(date)}
                      </Text>
                    )}
                  </Flex>
                </div>
              </NotificationStrip>
            );
          })
        ) : (
          <NoDetailsFound>
            <svg
              width='60'
              height='55'
              viewBox='0 0 60 55'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M35.0094 16.8837C35.0094 16.3983 34.616 16.0048 34.1305 16.0048H9.65381C9.1683 16.0048 8.7749 16.3983 8.7749 16.8837C8.7749 17.3691 9.1683 17.7626 9.65381 17.7626H34.1305C34.616 17.7626 35.0094 17.3691 35.0094 16.8837Z'
                fill='white'
                fillOpacity='0.4'
              />
              <path
                d='M29.9884 24.0817H9.65381C9.1683 24.0817 8.7749 24.4752 8.7749 24.9606C8.7749 25.446 9.1683 25.8395 9.65381 25.8395H29.9883C30.4738 25.8395 30.8672 25.446 30.8672 24.9606C30.8672 24.4752 30.4738 24.0817 29.9884 24.0817Z'
                fill='white'
                fillOpacity='0.4'
              />
              <path
                d='M29.9884 32.1586H9.65381C9.1683 32.1586 8.7749 32.5521 8.7749 33.0375C8.7749 33.5229 9.1683 33.9164 9.65381 33.9164H29.9883C30.4738 33.9164 30.8672 33.5229 30.8672 33.0375C30.8672 32.5521 30.4738 32.1586 29.9884 32.1586Z'
                fill='white'
                fillOpacity='0.4'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M54.5822 12.6409C54.5822 16.4384 56.2774 19.9837 59.2332 22.3675C60.0299 23.0092 60.2411 24.1324 59.6988 25.0191C59.326 25.6287 58.677 25.9926 57.9623 25.9926H52.3439C51.5887 28.3107 49.4133 29.9269 46.9185 29.9269C44.4237 29.9269 42.2481 28.3107 41.4929 25.9926H35.8745C35.16 25.9926 34.5108 25.6286 34.1379 25.019C33.5967 24.1341 33.8052 23.011 34.6038 22.3674C37.5593 19.9838 39.2546 16.4387 39.2546 12.641V11.5991C39.2546 8.68847 40.8858 6.15194 43.2817 4.85479V3.64718C43.2817 1.64198 44.9132 0.010498 46.9184 0.010498C48.9237 0.010498 50.5552 1.64187 50.5552 3.64718V4.85479C52.9511 6.15194 54.5822 8.68847 54.5822 11.5989V12.6409ZM46.9185 1.76819C45.8824 1.76819 45.0395 2.61112 45.0395 3.64718V4.16913C46.2841 3.85425 47.5773 3.86046 48.7973 4.16913V3.64718C48.7973 2.61112 47.9545 1.76819 46.9185 1.76819ZM46.9185 28.169C45.3954 28.169 44.0447 27.2985 43.3888 25.9925H50.448C49.7923 27.2985 48.4417 28.169 46.9185 28.169ZM35.8746 24.2347H57.9623C58.2114 24.2347 58.3537 23.9163 58.13 23.736C54.7581 21.0167 52.8244 16.9727 52.8244 12.6409V11.5989C52.8244 8.34241 50.175 5.69304 46.9184 5.69304C43.6618 5.69304 41.0125 8.34241 41.0125 11.5989V12.6409C41.0125 16.9727 39.0787 21.0167 35.707 23.7358C35.4832 23.9165 35.6256 24.2347 35.8746 24.2347Z'
                fill='white'
                fillOpacity='0.4'
              />
              <path
                d='M52.2183 28.7226C51.7328 28.7226 51.3394 29.1161 51.3394 29.6015V37.1231C51.3394 40.0317 48.9731 42.398 46.0645 42.398H19.4101C18.6707 42.398 17.968 42.6785 17.4318 43.1875L7.91977 52.217L9.32063 44.4385C9.51234 43.3745 8.69414 42.3981 7.61496 42.3981H7.03266C4.12406 42.3981 1.75781 40.0318 1.75781 37.1232V13.1014C1.75781 10.1928 4.12406 7.82654 7.03266 7.82654H37.5941C38.0796 7.82654 38.473 7.43302 38.473 6.94763C38.473 6.46224 38.0796 6.06873 37.5941 6.06873H7.03266C3.15492 6.06873 0 9.22353 0 13.1014V37.1231C0 41.0009 3.15492 44.1558 7.03266 44.1558H7.58543L6.07453 52.5456C5.95582 53.2046 6.27211 53.861 6.86121 54.1788C7.44773 54.4951 8.1702 54.4028 8.65816 53.9396L18.642 44.4623C18.8502 44.2646 19.123 44.1557 19.4102 44.1557H46.0646C49.9425 44.1557 53.0973 41.0008 53.0973 37.1229V29.6014C53.0972 29.1161 52.7037 28.7226 52.2183 28.7226Z'
                fill='white'
                fillOpacity='0.4'
              />
            </svg>

            <div className='content'>
              <div className='head'>It`s quiet in here...</div>
              <div className='sub-head'>You will hear from your team soon!</div>
            </div>
          </NoDetailsFound>
        )}
      </NotificationWrapper>
    </Body>
  );
};

NotificationComponent.propTypes = {
  history: PropTypes.instanceOf(Object),
  userDetails: PropTypes.instanceOf(Object),
  toggleSidebar: PropTypes.func,
};

export default NotificationComponent;
