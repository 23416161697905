import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Radio as MaterialRadio } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import PropTypes from 'prop-types';

// Styles
import { colors } from '../../styles/variables';

const useStyles = makeStyles({
  icon: {
    width: '2rem',
    height: '2rem',
    border: `0.2rem solid ${colors.blue.secondary}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Radio = (props) => {
  const classes = useStyles(props);
  const { value, onChange, ...otherProps } = props;

  return (
    <MaterialRadio
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      value={value}
      onChange={onChange}
      icon={<span className={classes.icon} />}
      checkedIcon={
        <span
          className={classes.icon}
          style={{
            backgroundColor: colors.blue.primary,
            borderColor: 'transparent',
          }}
        >
          <FiberManualRecord style={{ color: colors.white, width: '1.2rem', height: '1.2rem' }} />
        </span>
      }
    />
  );
};

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
};

export default Radio;
