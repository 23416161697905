const SET_ASSET_DATA = 'SET_ASSET_DATA';
const SET_PLAYLIST_DATA = 'SET_PLAYLIST_DATA';
const SET_TAB_DATA = 'SET_TAB_DATA';
const CLEAR_DATA = 'CLEAR_DATA';
const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
const GUEST_REVIEW_ASSET_DATA = 'GUEST_REVIEW_ASSET_DATA';

export default {
  SET_ASSET_DATA,
  SET_PLAYLIST_DATA,
  SET_TAB_DATA,
  CLEAR_DATA,
  UPDATE_COMMENTS,
  GUEST_REVIEW_ASSET_DATA,
};
