// eslint-disable-next-line import/prefer-default-export
export const CLOUD_SCREENS = {
  type: 'TYPE',
  softwares: 'SOFTWARES',
  plugins: 'PLUGINS',
  hardware: 'HARDWARE',
  duration: 'DURATION',
  notAvailable: 'NOT_AVAILABLE',
  available: 'AVAILABLE',
  inQueue: 'IN_QUEUE',
  preparing: 'PREPARING',
  ready: 'READY',
  nextSteps: 'NEXT_STEPS',
};
