import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { CalendarToday } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { colors } from '../../styles/variables';

const CalendarInputStyles = styled(KeyboardDatePicker)`
  width: 100%;
  .MuiFormLabel-root {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.4rem;
    transform: translate(1.2rem, 2rem) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(1.2rem, 1rem) scale(0.75);
    }
    &.Mui-focused {
      color: rgba(255, 255, 255, 0.6);
    }
    &.Mui-error {
      color: ${colors.error};
    }
    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .MuiFormHelperText-root {
    margin: 0;
    font-size: 1.3rem;
    padding-bottom: 0;
    &.Mui-error {
      color: ${colors.error};
    }
  }

  .MuiInputBase-root {
    color: #fff;
    border: 0.1rem solid transparent;
    padding: 0.21rem 0;
    border-radius: 0.5rem;
    margin-top: 0;
    font-size: 1.4rem;
    background-color: rgba(255, 255, 255, 0.15);
    &.Mui-error {
      border-color: ${colors.error};
    }
    .MuiInputBase-input {
      padding: 2.5rem 1.2rem 0.7rem;
      caret-color: transparent;
      cursor: pointer;
    }
    .MuiIconButton-root {
      padding: 0;
      color: rgba(255, 255, 255, 0.8);
      &:hover {
        background: none;
      }
      .MuiIcon-root {
        font-size: 1.8rem;
      }
    }
    &.Mui-disabled {
      color: #fff;
      cursor: not-allowed;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiPickersBasePicker-container {
    background: rgba(255, 255, 255, 0.15) !important;
  }
  .MuiSvgIcon-root {
    color: #fff;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
  .MuiInput-underline:before,
  .MuiInput-underline.Mui-disabled:before {
    border-bottom: none !important;
  }
  .MuiPickersBasePicker-container {
    background: rgba(255, 255, 255, 0.15) !important;
  }
`;

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: '16px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    color: colors.white,
    borderRadius: '0.5rem',
    boxShadow: 'none',
    backdropFilter: 'blur(1rem)',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    width: '34rem',
    '& .MuiPickersBasePicker-pickerView': {
      overflow: 'hidden',
      maxWidth: '34rem',
      '& .MuiPickersCalendar-transitionContainer': {
        padding: '1.2rem 0',
        height: '100%',
        marginTop: '0',
        '& .MuiPickersCalendar-week': {
          justifyContent: 'space-around',
          '& .MuiPickersDay-day': {
            color: colors.white,
            fontSize: '1.4rem',
            '&.MuiPickersDay-daySelected': {
              backgroundColor: colors.blue.secondary,
              '&:hover': {
                backgroundColor: colors.blue.secondary,
              },
            },
            '&.MuiPickersDay-current': {
              backdropFilter: 'blur(10px)',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: colors.white,
            },
            '&.MuiPickersDay-dayDisabled': {
              color: 'rgba(255, 255, 255, 0.6)',
              cursor: 'not-allowed',
            },
            '& .MuiTypography-body2': {
              fontSize: '1.4rem !important',
              fontWeight: '300',
            },
          },
        },
      },
    },
    '& .MuiPickersCalendarHeader-daysHeader': {
      justifyContent: 'space-around',
      '& .MuiPickersCalendarHeader-dayLabel': {
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.3)',
        fontSize: '1.4rem',
      },
    },

    // override css
    '& .MuiPickersCalendarHeader-switchHeader': {
      color: colors.white,
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      marginTop: '0%',
      '& .MuiPickersCalendarHeader-transitionContainer': {
        width: 'auto',
        height: '3.5rem',
        color: colors.white,
        '& .MuiTypography-root': {
          fontSize: '1.8rem',
          position: 'static',
          padding: '0.5rem 0',
        },
      },
      '& .MuiPickersCalendarHeader-dayLabel': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
      '& .MuiTypography-colorInherit': {
        color: colors.white,
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: colors.blue.secondary,
        '& .MuiPickersDay-current': {
          color: '#fff !imporant',
        },
      },
      '& .MuiPickersCalendarHeader-iconButton': {
        backgroundColor: 'transparent',
        color: colors.white,
        '& .MuiSvgIcon-root': {
          width: '3rem',
          height: '3rem',
        },
      },
    },
  },
  calendar: {},
}));

export default function Calendar(props) {
  const [open, setOpen] = useState(false);
  const {
    isDisabled,
    allowPast,
    id,
    onChange,
    parentStyles,
    value,
    ...otherProps
  } = props;
  const classes = useStyles();

  const handleKeypress = e => {
    e.preventDefault();
    return false;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className='wrapper'>
      <div className='v-padd'>
        <CalendarInputStyles
          {...otherProps}
          style={parentStyles}
          disabled={isDisabled}
          disablePast={allowPast}
          disableToolbar
          value={value}
          InputLabelProps={{ shrink: value && true }}
          variant='inline'
          format='dd MMM yyyy'
          minDate={new Date()}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          keyboardIcon={<CalendarToday />}
          onChange={date => {
            onChange({ target: { value: Date.parse(date), id } });
            setOpen(false);
          }}
          onClick={() => {
            setOpen(true);
          }}
          onKeyPress={handleKeypress}
          autoComplete='off'
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          PopoverProps={{
            classes: {
              paper: classes.paper,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          InputAdornmentProps={{ position: 'end' }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

Calendar.propTypes = {
  isDisabled: PropTypes.bool,
  allowPast: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  parentStyles: PropTypes.instanceOf(Object),
};
