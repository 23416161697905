/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Utils
import Mixpanel from '../../../../utils/mixpanel';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';

import { CLOUD_SCREENS } from './constants';

import { getPluginRequest, getTotalPluginsPrice } from '../../helperFunctions';
import { getTimeZone } from '../../../helperFunctions';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { Text } from '../../../styles/common';
import { CloudScreenHead } from './styles';

const Available = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    goTo,
    systemSession,
    task: { task_db_id },
    cloudAccessDetails,
    closeSidebar,
    config,
    type,
    selectedSoftware,
    selectedHardware,
    selectedPlugin,
    match: {
      params: { project_db_id },
    },
    toggleToast,
  } = props;

  const details =
    cloudAccessDetails &&
    (cloudAccessDetails.machine_status === 'machine_available' ||
      cloudAccessDetails.machine_status === 'machine_booting')
      ? cloudAccessDetails
      : config;

  const { duration, cloud_configuration_db_id } = details;

  const onSubmit = async () => {
    setSubmitting(true);
    Mixpanel.track('Confirm Session', {
      config: { ...details.configurations },
    });
    const sessionResponse = await systemSession(
      {
        project_db_id,
        duration,
        cloud_configuration_db_id,
        task_db_id,
        task_type: type?.value,
        hardware_slug: selectedHardware?.slug,
        software_slug: selectedSoftware?.slug,
        timezone: {
          name: getTimeZone().name,
          offset: getTimeZone().offset,
        },
        ...(selectedPlugin?.length > 0 && {
          plugin_slug: getPluginRequest(selectedPlugin),
        }),
      },
      'post'
    );

    const {
      data: { response, errormessage },
    } = sessionResponse;
    setSubmitting(false);
    if (response) {
      closeSidebar();
    } else {
      goTo(CLOUD_SCREENS.type);
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <GradientCard
          {...type}
          details={[
            selectedSoftware?.title,
            `${config?.configurations?.title}`,
            `₹${Number(
              selectedPlugin?.length > 0
                ? getTotalPluginsPrice(selectedPlugin) +
                    config?.configurations?.pricing
                : config?.configurations?.pricing
            )} / hr`,
          ]}
        />

        {selectedPlugin?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm
          >
            Add-on Plug-ins: {selectedPlugin.map(el => el?.title).join(', ')}
          </Text>
        )}

        <CloudScreenHead
          bg
          style={{
            marginTop: selectedPlugin?.length > 0 ? '0rem' : '2rem',
          }}
        >
          Your requested system is available. Confirm to start preparing your
          session.
        </CloudScreenHead>

        {details &&
          details.in_queue &&
          details.machine_status === 'machine_available' && (
            <Text style={{ marginTop: '2rem' }}>Confirm within 5 minutes</Text>
          )}
      </Body>

      <Footer>
        {Object.keys(config).length > 0 && (
          <Normal
            onClick={() => goTo(CLOUD_SCREENS.duration)}
            color='secondary'
            style={{ marginRight: '2rem' }}
          >
            Back
          </Normal>
        )}
        <Normal onClick={onSubmit} isLoading={isSubmitting}>
          Confirm
        </Normal>
      </Footer>
    </>
  );
};

Available.propTypes = {
  systemSession: PropTypes.func,
  config: PropTypes.instanceOf(Object),
  closeSidebar: PropTypes.func,
  goTo: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  task: PropTypes.instanceOf(Object),
  cloudAccessDetails: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  type: PropTypes.instanceOf(Object),
  selectedSoftware: PropTypes.instanceOf(Object),
  selectedHardware: PropTypes.instanceOf(Object),
  selectedPlugin: PropTypes.instanceOf(Object),
};

export default Available;
