/*
 * import Creators from './actions';
 * import serviceCall from '../../../utils/api';
 */

import Creators, { updateComments, setAssetData } from './actions';
import serviceCall from '../../../utils/api';
import apiConfig from '../../../config';
import { serviceCallBasedOnMethod } from '../../helperFunctions';

const { setTabData, clearData, setPlaylistData, setUpdateGuestReviewData } =
  Creators;

const fetchAsset = request =>
  serviceCall.get(`${apiConfig.project}/asset/info/`, request);

const fetchPlaylist = request => dispatch => {
  serviceCall
    .get(`${apiConfig.project}/asset/playlist/`, request)
    .then(data => {
      dispatch(setPlaylistData(data.data.data));
    });
};

export const fetchCommentsService = request =>
  serviceCall.get(`${apiConfig.project}/asset/tab/metadata/`, request);

const fetchTabData = request => dispatch => {
  return serviceCall
    .get(`${apiConfig.project}/asset/tab/metadata/`, request)
    .then(data => {
      const resData = { ...data.data.data };
      resData.tab_name = request.tabname;
      resData.field = request.tabname;
      dispatch(setTabData(resData));
    });
};

const addCommentReply = request => () => {
  serviceCall.post(`${apiConfig.project}/asset/comment/`, request);
};

const changeAssetStatus = request => () => {
  serviceCall.post(`${apiConfig.project}/task/approval/request/`, request);
};

const resolveComment = data => () => {
  serviceCall.put(`${apiConfig.project}/asset/comment/`, data);
};

const updateComment = data => dispatch => {
  dispatch(updateComments(data));
};

const verifySharedAsset = (request, method) =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/shared/asset/`,
    request,
    method
  );

const clearState = () => dispatch => {
  return dispatch(clearData());
};

const loadAssetData = data => dispatch => dispatch(setAssetData(data));

export const updateStatusService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/asset/status/`,
    request,
    method
  );

export const downloadAssetService = (request, method = 'get') =>
  serviceCallBasedOnMethod(
    `${apiConfig.project}/asset/download/`,
    request,
    method
  );

const setUpdateGuestReviewDetails = data => dispatch =>
  dispatch(setUpdateGuestReviewData(data));

const validateInvitationCodeService = request =>
  serviceCall.get(`${apiConfig.auth}/validate/invitation/code/`, request);

export default {
  fetchAsset,
  fetchPlaylist,
  fetchTabData,
  clearState,
  updateComment,
  addCommentReply,
  changeAssetStatus,
  resolveComment,
  verifySharedAsset,
  loadAssetData,
  downloadAssetService,
  setUpdateGuestReviewDetails,
  validateInvitationCodeService,
};
