import types from './types';

const setTeam = payload => {
  // this will set team i.e selected team
  return {
    type: types.SET_TEAM,
    payload,
  };
};

export const setUserDetails = payload => {
  // this will set user details & teams in our reducer
  return {
    type: types.SET_USER_DETAILS,
    payload,
  };
};

export const updateUserTeam = payload => {
  // this will set user details & teams in our reducer
  return {
    type: types.UPDATE_USER_TEAM,
    payload,
  };
};

const setUploadFile = payload => {
  return {
    type: types.SET_UPLOAD_FILE,
    payload,
  };
};

const updateUploadFile = (id, data) => {
  return {
    type: types.UPDATE_UPLOAD_FILE,
    id,
    data,
  };
};

const removeUploadFile = payload => {
  return {
    type: types.REMOVE_UPLOAD_FILE,
    payload,
  };
};

export const toggleSidebar = payload => {
  // this will toggle sidebar
  return {
    type: types.TOGGLE_SIDEBAR,
    payload,
  };
};

export const toggleUpgradeModal = payload => {
  return {
    type: types.TOGGLE_UPGRADE_MODAL,
    payload,
  };
};

const toggleEditorView = payload => {
  return {
    type: types.TOGGLE_EDITOR,
    payload,
  };
};

export const loadRequestedCloudSytemDetails = payload => {
  return {
    type: types.LOAD_REQUESTED_CLOUD_SYSTEM_DETAILS,
    payload,
  };
};

export const loadProductTourSkipDonePages = payload => {
  return {
    type: types.LOAD_PRODUCT_TOUR_SKIP_DONE,
    payload,
  };
};

export const resetState = payload => {
  return {
    type: types.RESET_COMMON_STATE,
    payload,
  };
};

export default {
  setTeam,
  setUserDetails,
  setUploadFile,
  updateUploadFile,
  removeUploadFile,
  toggleSidebar,
  toggleUpgradeModal,
  toggleEditorView,
  loadRequestedCloudSytemDetails,
  updateUserTeam,
  resetState,
};
