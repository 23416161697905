import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import CreateEdit from './CreateEdit';
import BucketDetails from './BucketDetails';
import IntegratedRegions from './IntegratedRegions';

import { S3_STEPS } from '../../../constants/integration';

const S3Integration = ({ toggleSidebar, closeSidebar, toggleToast }) => {
  const [selectedBucketId, selectBucketId] = useState(null);
  const [screen, setScreen] = useState(S3_STEPS.integratedRegions);

  const COMMON_PROPS = {
    goTo: step => setScreen(step),
    toggleSidebar,
    closeSidebar,
    selectedBucketId,
    selectBucketId,
    toggleToast,
  };

  const renderScreen = index => {
    let component = <CreateEdit {...COMMON_PROPS} />;

    switch (index) {
      case S3_STEPS.bucketDetails: {
        component = <BucketDetails {...COMMON_PROPS} />;
        break;
      }

      case S3_STEPS.integratedRegions: {
        component = <IntegratedRegions {...COMMON_PROPS} />;
        break;
      }

      default:
        break;
    }

    return component;
  };

  return <>{renderScreen(screen)}</>;
};

S3Integration.propTypes = {
  toggleSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
};

export default S3Integration;
