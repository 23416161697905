import types from './types';

const actionLoadProjectStortage = (payload) => {
  return {
    type: types.LOAD_PROJECT_STORAGE,
    payload,
  };
};

const actionLoadFilesStortage = (payload) => {
  return {
    type: types.LOAD_FILES_STORAGE,
    payload,
  };
};

const actionLoadProjectUsage = (payload) => {
  return {
    type: types.LOAD_PROJECT_USAGE,
    payload,
  };
};

const actionLoadActivityUsage = (payload) => {
  return {
    type: types.LOAD_ACTIVITY_USAGE,
    payload,
  };
};

const resetState = (action) => {
  return {
    type: types.RESET_STATISTICS_STATE,
    action,
  };
};

export default {
  actionLoadProjectStortage,
  actionLoadFilesStortage,
  actionLoadProjectUsage,
  actionLoadActivityUsage,
  resetState,
};
