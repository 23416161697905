import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import { whiteAlpha } from '../../styles/utils';

// Components
import Title from './Title';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#191919',
    borderRadius: '0.4rem',
    padding: '0.8rem 1.2rem',
    marginRight: '0.8rem',
    boxSizing: 'border-box',
    '&:last-child': {
      marginRight: '0%',
    },
  },
  icon: {
    backgroundColor: whiteAlpha(0.08),
  },
  textWrapper: {
    width: '100%',
    padding: '0 1.5rem',
  },
  skeletonText: {
    backgroundColor: whiteAlpha(0.08),
  },
});

const Card = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton
        variant='circle'
        width='4.6rem'
        height='4rem'
        className={classes.icon}
      />
      <div className={classes.textWrapper}>
        <Title width='40%' className={classes.skeletonText} />
        <Title width='80%' className={classes.skeletonText} />
      </div>
    </div>
  );
};

export default Card;
