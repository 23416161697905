import React from 'react';
import { PropTypes } from 'prop-types';

import somethingWentWrong from './somethingWentWrong.png';
import { Normal } from '../../components/Button';

// Styles
import {
  Wrapper,
  ImageWrapper,
  Header,
  SubHeader,
  ButtonWrapper,
} from '../../styles/errorScreen';

const SomethingWentWrong = ({ error, resetError }) => {
  console.log('🚀 ~ SomethingWentWrong ~ resetError:', resetError);
  console.log('🚀 ~ SomethingWentWrong ~ error:', error);
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={somethingWentWrong} alt='error' />
      </ImageWrapper>
      <SubHeader>Something Went Wrong.</SubHeader>
      <Header>Refresh this page or try again later.</Header>
      <ButtonWrapper>
        <Normal href='/'>Home</Normal>
        <Normal onClick={() => window.location.reload()}>Refresh</Normal>
      </ButtonWrapper>
    </Wrapper>
  );
};

SomethingWentWrong.propTypes = {
  error: PropTypes.instanceOf(Object),
  resetError: PropTypes.instanceOf(Object),
};

export default SomethingWentWrong;
