/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

// Components
import { Normal } from '../../../components/Button';

import { loadRequestedCloudSytemDetails } from '../../../main-wrapper/duck/actions';

// Styles
import { Body, Footer } from '../../../styles/modal';
import { Text } from '../../../styles/common';

import Mixpanel from '../../../../utils/mixpanel';

const EndSession = props => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    toggleModal,
    systemSession,
    match: { params },
    cloudAccessDetails: { cloud_access_db_id },
    toggleToast,
  } = props;

  const onSubmit = async () => {
    setSubmitting(true);
    const configsResponse = await systemSession(
      {
        project_db_id: params.project_db_id,
        cloud_id: cloud_access_db_id,
        message: 'End',
      },
      'put'
    );

    const {
      data: { response, errormessage },
    } = configsResponse;
    setSubmitting(false);
    if (response) {
      dispatch(loadRequestedCloudSytemDetails(null));
      Mixpanel.track('End Session');
      toggleModal(null, false, null);
    } else {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: errormessage,
          variant: 'error',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <Text padding='0' md>
          Are you sure you want to end the session?
        </Text>
      </Body>

      <Footer>
        <Normal
          onClick={() => toggleModal(null, false, null)}
          color='secondary'
          style={{ marginRight: '2rem' }}
        >
          No
        </Normal>
        <Normal onClick={onSubmit} isLoading={isSubmitting}>
          Yes
        </Normal>
      </Footer>
    </>
  );
};

EndSession.propTypes = {
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  systemSession: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  cloudAccessDetails: PropTypes.instanceOf(Object),
};

export default EndSession;
