/* eslint-disable camelcase */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Normal } from '../../../components/Button';
import Modal from '../../../components/modal';

import { cameraSessionsService } from '../../duck/operations';
import { enqueueSnackbar } from '../../../snackbar/duck/actions';

// Styles
import { Body, Footer } from '../../../styles/modal';
import { Text } from '../../../styles/common';
import { CAMERA_TO_EDIT_SESSION_STEPS } from '../../constants';

const DeleteSession = props => {
  const { project_db_id } = useParams();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const { toggleModal, isModalOpen, details, goTo } = props;
  const onClose = () => toggleModal(false);

  const onDelete = async () => {
    setSubmitting(true);
    try {
      const { data } = await cameraSessionsService(
        {
          project_db_id,
          c2e_session_db_id: details?.c2e_session_db_id,
        },
        'delete'
      );
      if (data?.response) {
        setSubmitting(false);
        goTo(CAMERA_TO_EDIT_SESSION_STEPS.list);
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Success',
              messageBody: data?.data?.message || 'C2E session deleted',
              variant: 'success',
            },
          })
        );
      } else throw new Error(data?.errormessage);
    } catch (err) {
      setSubmitting(false);
      dispatch(
        enqueueSnackbar({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        })
      );
    }
  };

  return (
    <>
      <Modal onClose={onClose} open={isModalOpen} title='Delete Session'>
        <Body>
          <div style={{ display: 'grid', rowGap: '0.5rem' }}>
            {details?.folder_name && (
              <Text padding='0' md>
                Folder Name: {details?.folder_name}
              </Text>
            )}
            {details?.camera_model && (
              <Text padding='0' md>
                Camera Model: {details?.camera_model}
              </Text>
            )}

            <Text padding='0' md>
              Session ID: {details?.c2e_session_id}
            </Text>
          </div>
        </Body>

        <Footer>
          <Normal
            id='no'
            onClick={onClose}
            color='secondary'
            style={{ marginRight: '2rem' }}>
            No
          </Normal>
          <Normal id='yes' onClick={onDelete} isLoading={isSubmitting}>
            Yes
          </Normal>
        </Footer>
      </Modal>
    </>
  );
};

DeleteSession.propTypes = {
  toggleModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  details: PropTypes.instanceOf(Object),
  goTo: PropTypes.func,
};

export default DeleteSession;
