/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import { Normal } from '../../../components/Button';
import { TextField, Select, useForm } from '../../../components/FormInputs';
import { systemReportService } from '../../duck/operations';
import Mixpanel from '../../../../utils/mixpanel';

// Styles
import { Body, Footer } from '../../../styles/modal';
import { Text } from '../../../styles/common';
import { colors } from '../../../styles/variables';
import { whiteAlpha } from '../../../styles/utils';

const INITIAL_STATE = {
  reportType: {
    value: '',
    error: undefined,
    required: true,
  },

  id: {
    value: '',
    error: undefined,
    required: true,
  },
};

const SystemReportDialog = props => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [reportingOptions, setReportingOptions] = useState([]);
  const {
    toggleModal,
    cloudAccessDetails: { cloud_assignment_id, cloud_resource_assignment_id },
    toggleToast,
  } = props;

  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      setSubmitting(true);
      const configsResponse = await systemReportService(
        {
          cloud_assignment_id,
          cloud_assignment_db_id: cloud_resource_assignment_id,
          error_type: formValues.reportType.value,
          error_message: 'Cloud Session Error',
        },
        'post'
      );

      const {
        data: { response, errormessage, data },
      } = configsResponse;
      setSubmitting(false);
      if (response) {
        Mixpanel.track('System Report');
        toggleModal(null, false, null);
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: data?.message,
            variant: 'success',
          },
        });
      } else {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: errormessage,
            variant: 'error',
          },
        });
      }
    }
  );

  useEffect(() => {
    (async () => {
      const optionsResponse = await systemReportService({
        cloud_assignment_db_id: cloud_resource_assignment_id,
      });
      const {
        data: { data, response },
      } = optionsResponse;
      if (response) setReportingOptions(data?.error_types);
    })();
  }, []);

  const onFieldChange = e => {
    onChange(e);
    if (e.target.value !== cloud_assignment_id) {
      updateValues({
        ...formValues,
        id: {
          ...formValues.id,
          value: e.target.value,
          error: 'Invalid Cloud Session ID',
        },
      });
    }
  };

  return (
    <>
      <Body>
        <Text padding='0' md color={whiteAlpha(0.8)} marginBottom='1rem'>
          You’re about to report an issue for the session ID{' '}
          <span style={{ color: colors.white }}>{cloud_assignment_id}.</span>{' '}
          This session would terminate if any issue is found.
        </Text>
        <Select
          id='reportType'
          label='Reporting Type'
          value={formValues.reportType.value}
          onChange={onChange}
          error={!!formValues.reportType.error}
          helperText={formValues.reportType.error}
          options={reportingOptions || []}
        />
        <TextField
          id='id'
          label='Enter Session ID'
          value={formValues.id.value}
          onChange={onFieldChange}
          error={!!formValues.id.error}
          helperText={formValues.id.error}
        />
      </Body>

      <Footer>
        <Normal
          onClick={() => toggleModal(null, false, null)}
          color='secondary'
          style={{ marginRight: '2rem' }}>
          Cancel
        </Normal>
        <Normal onClick={onSubmit} isLoading={isSubmitting}>
          Report
        </Normal>
      </Footer>
    </>
  );
};

SystemReportDialog.propTypes = {
  toggleModal: PropTypes.func,
  toggleToast: PropTypes.func,
  cloudAccessDetails: PropTypes.instanceOf(Object),
};

export default SystemReportDialog;
