import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

// Styles
import { Header, Title } from '../../styles/modal';
import { colors, blur } from '../../styles/variables';
import { blackAlpha, whiteAlpha } from '../../styles/utils';
import { slideUp } from '../../styles/animations';

const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    position: relative;
    color: ${colors.white};
    font-size: 1.7rem;
    padding: 4rem;
    border-radius: 2rem;
    width: 45rem;
    animation: ${slideUp} 1s;
    background-color: ${blackAlpha(0.1)};
    border: 1px solid ${whiteAlpha(0.2)};
    backdrop-filter: blur(${blur});
  }
`;

const Modal = props => {
  const { open, title, children, onClose } = props;
  return (
    <>
      <DialogWrapper open={open} onClose={onClose} maxWidth={false}>
        <Header>
          <Title>{title}</Title>
          <Close
            onClick={onClose}
            style={{ cursor: 'pointer', fontSize: '2.7rem' }}
          />
        </Header>

        {children}
      </DialogWrapper>
    </>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Modal;
