import { createGlobalStyle } from 'styled-components';

import { padding, colors, font, devices } from './variables';
import { whiteAlpha } from './utils';
import { slideUp } from './animations';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: Roboto, sans-serif;
    background-color: #121212;
    font-size: 10px;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: ${colors.white};
    margin: 0;
    padding: ${padding.container};
    font-weight: ${font.normal};
  }

  .logo {
    padding: ${padding.container};
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #ffd700;
    font-size: 2.4rem;
  }

  .text-center {
    text-align: center;
  }

  .container-fluid {
    padding: ${padding.container};
  }

  .c-padd {
    padding: ${padding.v} ${padding.h};
  }

  .h-padd {
    padding-left: ${padding.h};
    padding-right: ${padding.h};
  }

  .v-padd {
    padding-top: ${padding.v};
    padding-bottom: ${padding.v};
  }

  .slide-up {
    animation: ${slideUp} 1s;
  }

  .p-1-5 {
    padding: 1.5rem;
  }

  .pt-1-5 {
    padding-top: 1.5rem;
  }

  .p-1 {
    padding: 1rem;
  }

  .p-2 {
    padding: 2rem;
  }

  .p-0 {
    padding: 0;
  }

  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .px-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pb-0 {
    padding-bottom: 0;
  }

  .pt-0 {
    padding-top: 0;
  }

  .px-0-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .pt-1 {
    padding-top: 1rem;
  }

  .pr-0-5 {
    padding-right: 0.5rem;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .items-start {
    align-items: self-start;
  }

  .items-baseline {
    align-items: baseline;
  }

  .my-1-5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .mx-0-8 {
    margin-left: ${padding.v};
    margin-right: ${padding.v};
  }

  .my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mt-0 {
    margin-top: 0rem;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mt-1-5 {
    margin-top: 1.5rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .ml-2 {
    margin-left: ${padding.v};
  }

  .w-full {
    width: 100%;
  }

  .border {
    border: 1px solid;
  }

  .border-t {
    border-top: 1px solid;
  }

  .border-white-0-point-3 {
    border-color: ${whiteAlpha(0.3)};
  }

  .border-1 {
    border-width: 1px;
  }

  .border-2 {
    border-width: 2px;
  }

  .rounded {
    border-radius: 1rem;
  }

  .bg-white-0-point-15 {
    background-color: ${whiteAlpha(0.15)};
  }

  .bg-transparent {
    background-color: transparent;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .outline-none {
    outline: none;
  }

  .focus-outline-none {
    &:focus {
      outline: none;
    }
  }

  .border-none {
    border: none;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .inline-block {
    display: inline-block;
  }

  .line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .grid {
    display: grid;
  }

  .gap-y-1-point-5 {
    row-gap: 1.5rem;
  }

  .gap-x-1 {
    column-gap: 1rem;
  }

  .lg-hide {
    @media screen and (max-width: ${devices.lg}) {
      display: none;
    }
  }

`;

export default GlobalStyle;
