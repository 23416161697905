import {
  LOAD_ALL_BUCKET_DETAILS,
  LOAD_BUCKET_FILES,
  RESET_IMPORT_ASSETS,
} from './types';

const INITIAL_STATE = {
  destination: null,
  bucketDetails: null,
  folderFiles: null,
};

export default function importAssetsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_ALL_BUCKET_DETAILS: {
      return {
        ...state,
        bucketDetails: { ...action?.payload },
      };
    }

    case LOAD_BUCKET_FILES: {
      return {
        ...state,
        folderFiles: action?.payload ? { ...action?.payload } : null,
      };
    }

    case RESET_IMPORT_ASSETS: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
}
