import React, { useState } from 'react';
import { Avatar } from '@material-ui/core';
import { ArrowUpward, Delete } from '@material-ui/icons';
import styled from 'styled-components';

// Components
import { Normal } from '../Button';
import { TextField, useForm } from '../FormInputs';
import { ComingSoon } from '../../styles/common';

// Styles
import { Body, Footer } from '../../styles/sidebar';

const ProfileImage = styled.div`
  .MuiAvatar-root {
    margin: 0 auto;
    width: 20rem;
    height: 20rem;
  }
`;

const Upload = styled.div`
  display: flex;
  overflow: hidden;
  margin-top: 3rem;
  position: relative;
  .image-wrapper {
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .remove {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.25s ease;
      .MuiSvgIcon-root {
        fill: #fff;
        font-size: 1.8rem;
      }
      &:hover {
        background-color: #fff;
        .MuiSvgIcon-root {
          fill: #ff6c56;
        }
      }
    }
  }
  .upload-button {
    color: #fff;
    border: none;
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    font-size: 1.6rem;
    font-weight: 100;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
    svg {
      display: block;
      margin: 0 auto;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  }
  input[type='file'] {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
`;

const UserEmail = styled.div`
  font-size: 1.2rem;
  margin-top: 4rem;
  color: rgba(255, 255, 255, 0.6);
  .email-id {
    font-size: 1.4rem;
    padding: 1rem 0;
  }
`;

const ProfileSettings = props => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [file, setFile] = useState(null);

  const {
    userDetails: { first_name, last_name, user_email },
  } = props;

  function changeProfilePic() {
    console.log('I want to change my proflie picture');
  }

  function Update() {
    alert(
      `You changed your name from ${first_name} ${last_name} to ${firstName} ${lastName}`
    );
  }

  function Cancel() {
    alert('Cancel');
  }

  const handleChange = event => {
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  function removeImage() {
    setFile(null);
  }

  return (
    <>
      <Body>
        <ComingSoon>Coming Soon</ComingSoon>
        <div className='wrapper'>
          {/* <ProfileImage onClick={changeProfilePic}>
            { props.user ? props.user : <Avatar /> }
          </ProfileImage> */}
          <Upload>
            {file ? (
              <div className='image-wrapper'>
                <img src={file} alt='' />
                <div className='remove' onClick={removeImage}>
                  <Delete />
                </div>
              </div>
            ) : (
              <>
                <button className='upload-button'>
                  <ArrowUpward />
                  Upload Photo
                </button>
                <input type='file' accept='image/*' onChange={handleChange} />
              </>
            )}
          </Upload>

          <UserEmail>
            Email
            <p className='email-id'>{user_email}</p>
          </UserEmail>

          <TextField
            id='firstName'
            label='First Name'
            value={firstName}
            // placeholder={first_name}
            style={{ width: '100%', maxWidth: '34rem' }}
            onChange={e => {
              setFirstName(e.target.value);
            }}
          />

          <TextField
            id='lastName'
            label='Last Name'
            value={lastName}
            // placeholder={last_name}
            style={{ width: '100%', maxWidth: '34rem' }}
            onChange={e => {
              setLastName(e.target.value);
            }}
          />
        </div>
      </Body>

      <Footer>
        <Normal
          color='secondary'
          style={{ marginRight: '2rem' }}
          onClick={Cancel}
        >
          Cancel
        </Normal>
        <Normal onClick={Update}>Update</Normal>
      </Footer>
    </>
  );
};

export default ProfileSettings;
