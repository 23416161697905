/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import { PropTypes } from 'prop-types';

// Styles
import { Text, Flex } from '../../styles/common';
import { padding } from '../../styles/variables';
import { whiteAlpha } from '../../styles/utils';
import { formatDateTime } from '../../helperFunctions';

const Media = styled.div`
  position: relative;
  border-radius: 0.4rem;
  flex: 1;
  overflow: hidden;
  height: 6.5rem;
  > img {
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
  }
`;

const Thumbnail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  height: 8rem;
  flex: 1;
  object-fit: cover;
  .MuiIcon-root {
    font-size: 4rem;
    color: #1d4376;
  }
`;

const TitleStyles = styled(Text)`
  overflow-wrap: anywhere;
  padding: 0;
  line-height: 1.4;
`;

const Info = styled.div`
  flex: 2;
  position: relative;
`;

const AssetOverview = props => {
  const { thumbnail, title, uploaded_date, file_type, background_hex } = props;

  const renderIcons = () => {
    let iconComponent = null;
    switch (file_type) {
      case 'folder':
        iconComponent = 'folder_open';
        break;
      case 'image':
        iconComponent = 'insert_photo';
        break;
      case 'audio':
        iconComponent = 'music_note';
        break;
      case 'video':
        iconComponent = 'movie_creation';
        break;
      default:
        iconComponent = 'subject';
    }
    return iconComponent;
  };

  return (
    <Flex style={{ columnGap: padding.h }} className='items-start'>
      {thumbnail ? (
        <Media>
          <img src={thumbnail} alt={title} />
        </Media>
      ) : (
        <Thumbnail style={{ backgroundColor: background_hex }}>
          <span className='iconWrapper'>
            <Icon>{renderIcons()}</Icon>
          </span>
        </Thumbnail>
      )}
      <Info>
        <TitleStyles>{title}</TitleStyles>
        <Text
          xs
          color={whiteAlpha(0.8)}
          style={{ marginTop: '1.3rem', padding: '0' }}
        >
          {`${formatDateTime(uploaded_date)?.date} | ${
            formatDateTime(uploaded_date)?.time
          }`}
        </Text>
      </Info>
    </Flex>
  );
};

AssetOverview.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  uploaded_date: PropTypes.string,
  file_type: PropTypes.string,
  background_hex: PropTypes.string,
};

export default AssetOverview;
