import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import {
  ReportProblemOutlined,
  CloseOutlined,
  ReportOutlined,
  CheckCircleOutline,
  InfoOutlined,
} from '@material-ui/icons';

import { Text } from '../styles/common';
import { colors, fs, font } from '../styles/variables';

const SnackbarStyled = styled.div`
  width: 27.5rem;
  height: auto;
  border-radius: 0.4rem;
  background-image: ${props =>
    props.variant == 'success'
      ? 'linear-gradient(to right, #18b96e, #2c86ff)'
      : props.variant == 'info'
      ? 'linear-gradient(to right, #2c86ff, #6faaff)'
      : props.variant == 'error'
      ? 'linear-gradient(to right, #ff8d29, #f6462c)'
      : props.variant == 'warning'
      ? 'linear-gradient(to right, #e2c00f, #ffa751)'
      : ''};

  color: ${colors.white};
  display: flex;
  padding: 1.5rem;
  align-items: center;

  h5 {
    font-size: ${fs.sm};
    font-weight: ${font.medium};
    color: ${colors.white};
    text-transform: capitalize;
    margin: 0 0 0.5rem 0;
    padding: 0;
  }

  .icon-holder {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    min-width: 3rem;
  }

  .icon-holder.close-action {
    margin-right: 0;
    margin-left: 0.5rem;
    width: auto;
    height: auto;
    background: transparent;
    cursor: pointer;
    min-width: max-content;
    color: rgba(255, 255, 255, 0.8);
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
`;

const CustomSanckbar = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };
  const IconComponentsMap = {
    warning: ReportProblemOutlined,
    success: CheckCircleOutline,
    info: InfoOutlined,
    error: ReportOutlined,
  };
  var messageObj = {};
  if (!props.message || typeof props.message != 'object') {
    messageObj = {
      messageBody: props.message,
      variant: 'info',
    };
  } else {
    messageObj = props.message;
  }

  const IconComponent = IconComponentsMap[messageObj.variant];

  return (
    <SnackbarStyled ref={ref} variant={messageObj.variant}>
      <div className='icon-holder'>
        {messageObj.messageIcon ? (
          messageObj.messageIcon
        ) : (
          <IconComponent style={{ fontSize: '2rem' }} />
        )}
      </div>
      <div style={{ flexGrow: 2 }}>
        <h5>
          {messageObj.messageHead
            ? messageObj.messageHead
            : messageObj.variant == 'success'
            ? 'Success'
            : messageObj.variant == 'info'
            ? 'Info'
            : messageObj.variant == 'error'
            ? 'Error'
            : messageObj.variant == 'warning'
            ? 'Warning'
            : ''}
        </h5>
        <Text sm style={{ padding: '0' }}>
          {messageObj.messageBody}
        </Text>
      </div>
      {messageObj.allowClose ? (
        <div className='icon-holder close-action' onClick={handleDismiss}>
          <CloseOutlined style={{ fontSize: '1.8rem' }} />
        </div>
      ) : null}
    </SnackbarStyled>
  );
});

export default CustomSanckbar;

CustomSanckbar.propTypes = {
  message: PropTypes.shape({
    messageHead: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // If not provided default text for provided variant is shown
    messageBody: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // String
    type: PropTypes.string, // String default value 'toast' (always pass 'toast')
    variant: PropTypes.string, // String default value 'info'. 'success' | 'info' | 'warning' | 'error'
    allowClose: PropTypes.bool, // boolean default false.
  }),
};

CustomSanckbar.defaultProps = {
  message: {
    type: 'toast',
    variant: 'info',
    allowClose: 'false',
  },
};

/*

Example

======= Redux =======


import {enqueueSnackbar} from 'src\app\snackbar\duck\actions.js'; // check for relative path

// ***using dispatch func of redux connect mapDispatchToProps

dispatch(enqueueSnackbar({
		message: {
				messageHead: ,   // String. If not provided default text for provided variant is shown
				messageBody: , // String. If not provided then blank
				type:'toast', // String default value 'toast' (always pass 'toast')
				variant: 'info', // String default value 'info'. 'success' | 'info' | 'warning' | 'error'
				allowClose: false // boolean default false.
		},
		options: {
				// same as notistack options
				// *Note* : 'variant' is ignored and message.variant is used
				key: , // String || number unique required if not provided then 'new Date().getTime() + Math.random()' is randomly generated key
		},
}))


// *check snackbarReducer file ( 'src\app\snackbar\duck\reducers.js' ) to get infomation about placing default value



// example 1 : Display messageHead default - success style (variant passed in message) - without close action - key created by default
// IDEALLY TO BE USED for simple toast message


		dispatch(enqueueSnackbar({
				message: {
						messageBody:'Amanda Wilson has been added as collaborator. You can start collaborating.',
						variant: 'success', // 'success' | 'info' | 'warning' | 'error'
				}
		}));



// example 2 : Display messageHead (messageHead passed in message) - warning style (variant passed in options) - with close action  - key (key passed from options)

		dispatch(enqueueSnackbar({
				message: {
						messageHead: 'warning from server',
						messageBody:'To create and assign tasks upgrade to a new plan.',
						// variant: 'warning', // can be passed in options as well. If provided in both object, then options.varient is ignored and message.varient is considered
						allowClose: true
				},
				options: {
						variant: 'warning',
						key: 'someUniqueKeyHere',
				},
		}));



// example 3 : Display messageHead (messageHead passed in message) - warning style (variant passed in message) - with close action  - key ( key created by default)
//Persistent snackbars can only get dismissed programmatically or by providing an action button. In options object 'persist' value is true

		dispatch(enqueueSnackbar({
				message: {
						messageHead: 'Memory almost full',
						messageBody: 'This storyboard memory is almost full. Consider upgrading plan',
						variant: 'warning',
						allowClose: true
				},
				options:{
						persist: true,
				}
		}));

// example 4 : using as mentioned notistack specification

		dispatch(enqueueSnackbar({
				message: 'this is error message text',
				options: {
						key: new Date().getTime() + Math.random(), // not required, as it will generated automatically if not provided
						variant: 'error'
				},
		}));



======= Without Redux ======= THIS IS NOT RECOMMENDED
// This method is recommended. only simple info snackbar can be display by this methode i.e, variant = 'info'
// customize snackback do not provide 'options' agrument in the content prop function of SnackbarProvider


import { withSnackbar } from 'notistack';


myComponent = (props)=>{

		const someHandlerFunc = ()=>{
				props.enqueueSnackbar('This is info snackbar only');
		}

		return ( ... );
};


export default withSnackbar(myComponent);


*/
