/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// Components
import { Normal } from '../../../components/Button';
import GradientCard from './GradientCard';
import { UPLOADER_CONNECTOR_APP_KEYS } from '../../constants';

import { MODAL_TYPES, APP_TYPES } from '../../../constants';

// Styles
import { Body, Footer } from '../../../styles/sidebar';
import { Label, Text, CarouselViewParentStyles } from '../../../styles/common';
import { font } from '../../../styles/variables';
import { whiteAlpha } from '../../../styles/utils';
import {
  AppItem,
  AppTitle,
  CloudScreenHead,
  AppLinkButton,
  DownloadNativeAppParentStyles,
} from './styles';

const IndexStyles = styled.span`
  font-size: 2rem;
  margin-right: 1.2rem;
`;

const ExtendLabelStyles = styled(Label)`
  margin-top: 0.5rem;
`;

const STEPS = [
  {
    title: 'Launch System',
    description:
      'Always launch the Postudio App from the web browser to get access to the cloud system.',
  },
  {
    title: 'Extend Cloud Session',
    description:
      'You can extend your cloud edit session by 1 hr before 15-20 mins of your requested session.',
  },
  {
    title: 'End Cloud Session',
    description:
      'To end your cloud edit session use the Postudio application in cloud system or the web browser.',
  },
];

const NextSteps = props => {
  const { closeSidebar, cloudAccessDetails, downloadNiceDcvSdk, toggleModal } =
    props;

  const UPDATED = cloudAccessDetails?.is_adobe_license_required
    ? STEPS
    : STEPS.slice(1);

  return (
    <>
      <Body>
        <GradientCard
          {...cloudAccessDetails?.cloud_system}
          details={[
            `${cloudAccessDetails?.configurations?.software}`,
            `${cloudAccessDetails?.configurations?.title}`,
            `₹${cloudAccessDetails?.configurations?.pricing} / hr`,
          ]}
        />

        {cloudAccessDetails?.plugins?.length > 0 && (
          <Text
            lineHeight='1.3'
            paddingLeft='0'
            paddingRight='0'
            marginTop='1rem'
            sm>
            Add-on plugins: {cloudAccessDetails?.plugins.join(', ')}
          </Text>
        )}

        {process.env.TYPE !== APP_TYPES.app && (
          <>
            <CloudScreenHead bg>
              Next steps after you gain access. The Session ID is{' '}
              {cloudAccessDetails?.cloud_assignment_id}
            </CloudScreenHead>

            {UPDATED.map((item, index) => (
              <AppItem key={item?.title}>
                <AppTitle>
                  <IndexStyles>0{index + 1}</IndexStyles>
                  {item?.title}
                </AppTitle>
                <ExtendLabelStyles>{item?.description}</ExtendLabelStyles>
              </AppItem>
            ))}

            <AppItem>
              <AppTitle>
                <IndexStyles>{UPDATED.length === 3 ? '04' : '03'}</IndexStyles>
                Cloud System Support
              </AppTitle>
              <Label margin='1rem 0'>
                You can report an issue for your cloud system
              </Label>
              <AppLinkButton
                onClick={() => {
                  closeSidebar();
                  toggleModal(
                    MODAL_TYPES.systemReport,
                    true,
                    'Cloud System Support'
                  );
                }}>
                Report Issue
              </AppLinkButton>
            </AppItem>

            <CarouselViewParentStyles>
              <div className='head'>
                <AppTitle>Native Connect</AppTitle>
              </div>
              <Text
                color={whiteAlpha(0.8)}
                margin='1.5rem 0'
                lineHeight='1.4'
                padding='0'
                style={{ fontWeight: font.medium }}>
                Alternatively connect to Postudio cloud session via native app.
              </Text>

              <DownloadNativeAppParentStyles>
                {Object.values(UPLOADER_CONNECTOR_APP_KEYS).map(el => (
                  <li>
                    <AppLinkButton
                      type='button'
                      onClick={() => downloadNiceDcvSdk(el)}>
                      {el.label}
                    </AppLinkButton>
                  </li>
                ))}
              </DownloadNativeAppParentStyles>
            </CarouselViewParentStyles>
          </>
        )}
      </Body>

      <Footer>
        <Normal color='secondary' onClick={closeSidebar}>
          Close
        </Normal>
      </Footer>
    </>
  );
};

NextSteps.propTypes = {
  cloudAccessDetails: PropTypes.instanceOf(Object),
  closeSidebar: PropTypes.func,
  downloadNiceDcvSdk: PropTypes.func,
  toggleModal: PropTypes.func,
};

export default NextSteps;
