import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BottomStyles = styled.div`
  position: relative;
  height: 0;
  background-color: #000;
  bottom: 114px;
  z-index: 0;
  pointer-events: none;

  &.reverse {
    bottom: -40px;
  }
`;

const InfiniteScroll = props => {
  const { children, isLoading, loadAssets, scrollDirection } = props;
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadAssets();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    if (ref.current && !isLoading) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [ref, isLoading, loadAssets]);

  return (
    <>
      {!isLoading && scrollDirection === 'reverse' && (
        <BottomStyles id='page-bottom-boundary' ref={ref} className='reverse' />
      )}
      {children}
      {!isLoading && scrollDirection !== 'reverse' && (
        <BottomStyles id='page-bottom-boundary' ref={ref} />
      )}
    </>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  loadAssets: PropTypes.func,
  isLoading: PropTypes.bool,
  scrollDirection: PropTypes.string,
};

export default InfiniteScroll;
