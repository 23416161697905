import React from 'react';
import { Apple } from '@material-ui/icons';
import { getUploaderAppService } from '../duck/operations';
import { UPLOADER_CONNECTOR_APP_KEYS } from '../constants';

const WindowsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      className='MuiSvgIcon-root'
    >
      <path
        id='Windows'
        d='M0 1.99772L5.70548 1.21461V6.72831H0M6.39269 1.10274L13.9521 0V6.68037H6.39269M0 7.31005H5.70548V12.8397L0 12.0406M6.39269 7.38356H13.9521V14L6.39269 12.9292'
        fill='white'
      />
    </svg>
  );
};

const onClick = (item, toast) => {
  getUploaderAppService({ os: item?.field, key: 'ps_uploader' })
    .then(res => {
      const { data } = res;
      if (data && data.response) {
        window.open(data.data, '_blank');
      } else {
        throw new Error(data.errormessage);
      }
    })
    .catch(err => {
      toast({
        message: {
          messageHead: 'Error',
          messageBody: err?.message || 'Something went wrong',
          variant: 'error',
        },
      });
    });
};

const nestedMenu = () => {
  return [
    {
      icon: <WindowsIcon />,
      name: UPLOADER_CONNECTOR_APP_KEYS.windows.label,
      field: UPLOADER_CONNECTOR_APP_KEYS.windows.key,
    },
    {
      icon: <Apple />,
      name: UPLOADER_CONNECTOR_APP_KEYS.macosIntel.label,
      field: UPLOADER_CONNECTOR_APP_KEYS.macosIntel.key,
    },
    {
      icon: <Apple />,
      name: UPLOADER_CONNECTOR_APP_KEYS.macosSilicon.label,
      field: UPLOADER_CONNECTOR_APP_KEYS.macosSilicon.key,
    },
  ];
};

export { onClick, nestedMenu };
