import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import VerificationCodeModal from '../modal/VerificationCodeModal';

import { twoFactorAuthenticationService } from '../../portal/duck/operations';
import Checkbox from '../FormInputs/Checkbox';
import { getOtp } from '../../helperFunctions';

// Styles
import { Text } from '../../styles/common';
import { whiteAlpha } from '../../styles/utils';

const CheckboxParentStyles = styled.div`
  label {
    width: 100%;
    cursor: pointer;

    p {
      display: inline-block;
    }
  }
`;

const EnableTwoFactor = ({
  closeSidebar,
  toggleToast,
  authenticationFor = 'account',
  isTwoFactorEnabled = false,
  isTwoFactorEnabledByTeam = false,
}) => {
  const selectedTeam = useSelector(store => store.common?.selectedTeam);
  const [modal, toggleModal] = useState(false);
  const [values, setValues] = useState([
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
  ]);

  const updateCode = () => {
    return new Promise((resolve, reject) => {
      twoFactorAuthenticationService(
        {
          ...(authenticationFor === 'team' && {
            team_id: selectedTeam?.team_db_id,
          }),
          team_db_id: selectedTeam?.team_db_id,
          otp: getOtp(values),
          two_factor_authentication_enabled: !isTwoFactorEnabled,
        },
        'put',
        authenticationFor
      )
        .then(res => {
          resolve({
            res,
            two_factor_authentication_enabled: !isTwoFactorEnabled,
            authenticationFor,
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  const sendResendCode = async () => {
    try {
      const {
        data: { response, errormessage, data },
      } = await twoFactorAuthenticationService(
        {
          team_id: selectedTeam?.team_db_id,
        },
        'post',
        authenticationFor
      );

      if (response) {
        toggleToast({
          message: {
            messageHead: 'Success',
            messageBody: data?.message,
            variant: 'success',
          },
        });
      } else {
        throw new Error(errormessage);
      }
    } catch (err) {
      toggleToast({
        message: {
          messageHead: 'Error',
          messageBody: err?.message,
          variant: 'error',
        },
      });
    }
  };

  const toggleEnableDisable = () => {
    toggleModal(true);
    sendResendCode();
  };

  return (
    <>
      <CheckboxParentStyles
        className='flex items-center justify-between'
        style={{ marginTop: '2rem' }}
      >
        <label htmlFor='verificationToggle'>
          <Text
            paddingLeft='0'
            paddingRight={
              isTwoFactorEnabledByTeam || isTwoFactorEnabled ? '2rem' : '0rem'
            }
          >
            {!isTwoFactorEnabled &&
              !isTwoFactorEnabledByTeam &&
              'Enable two-factor authentication'}

            {!isTwoFactorEnabledByTeam &&
              isTwoFactorEnabled &&
              'Two-factor authentication is enabled for your account'}

            {isTwoFactorEnabledByTeam &&
              (authenticationFor === 'team'
                ? 'Two-factor authentication is enabled for the team'
                : 'Two-factor authentication is enabled for your account by the team owner')}
          </Text>
          <Text
            sm
            paddingLeft='0'
            paddingRight='0'
            paddingTop='0'
            color={whiteAlpha(0.6)}
          >
            Provides enhanced security
          </Text>
        </label>
        <Checkbox
          name='verificationToggle'
          id='verificationToggle'
          onChange={toggleEnableDisable}
          value={isTwoFactorEnabledByTeam || !isTwoFactorEnabled}
          defaultChecked={isTwoFactorEnabledByTeam || isTwoFactorEnabled}
          disabled={isTwoFactorEnabledByTeam && authenticationFor === 'account'}
        />
      </CheckboxParentStyles>

      <VerificationCodeModal
        code={values}
        show={modal}
        onChangeCode={setValues}
        onModalClose={toggleModal}
        onSendResendCode={sendResendCode}
        onUpdateCode={updateCode}
        toggleToast={toggleToast}
        closeSidebar={closeSidebar}
      />
    </>
  );
};

EnableTwoFactor.propTypes = {
  closeSidebar: PropTypes.func,
  toggleToast: PropTypes.func,
  authenticationFor: PropTypes.string,
  isTwoFactorEnabled: PropTypes.bool,
  isTwoFactorEnabledByTeam: PropTypes.bool,
};

export default EnableTwoFactor;
